import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Auth } from 'aws-amplify';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-careplan',
  templateUrl: './careplan.component.html',
  styleUrls: ['./careplan.component.css']
})

export class CareplanComponent implements OnInit {
  userGroup: string = '';
  subId: any;
  subTrans: any;
  validdata: any;

  processing: HTMLElement = document.getElementById('cover') as HTMLElement;
  screen: HTMLElement = document.getElementById('screen') as HTMLElement;
  
  userDetails: any;

  careplanList: any;

  CMScareplanList: any;

  myDate: any;

  devicesstring = "";

  primaryIns: any;
  secondaryIns: any;

  summarydata = [];

  constructor( private route: ActivatedRoute, private router: Router, private httpClient: HttpClient ) {
    let authData = JSON.parse(localStorage.getItem('AUTH'));
    console.log(authData);
    this.userGroup = authData.group;
    let todaydate = new Date();
    this.myDate = this.formatDateMSlash(todaydate);
  }

  formatDateM = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [month, day, year].join('-');
  }

  formatDateMDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  formatDateMSlash = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [month, day, year].join('/');
  }

  formatDateCustom = (date) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    const days = [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat'
    ]
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hour = d.getHours(),
      minute = d.getMinutes();
    var time = "AM";
    if(hour >= 12) {
      time = "PM";
      if(hour > 12) {
        hour = hour - 12;
      }
    }
    var strhour = ''+ hour;
    if(hour == 0) {
      strhour = '12';
    }
    else if(hour < 10){
      strhour = '0' + hour;
    }
    var strminute = '' + minute;
    if(minute < 10){
      strminute = '0' + minute;
    }

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    // return [year, month, day].join('-');
    return strhour+":"+strminute+" "+time+" "+days[d.getDay()]+" "+day+" "+months[d.getMonth()]+" "+year;
  }

  ReportingProvider = "";

  InventoryList = [];
  isHub = "";

  Escalationsysmax = "";
  Escalationdiamax = "";
  Escalationpulsemax = "";

  EscalationsysmaxText = "";
  EscalationdiamaxText = "";
  EscalationpulsemaxText = "";

  Escalationsysmin = "";
  Escalationdiamin = "";
  Escalationpulsemin = "";

  EscalationsysminText = "";
  EscalationdiaminText = "";
  EscalationpulseminText = "";

  EscalationweightAvobemin = "";
  EscalationweightAvobeminText = "";
  EscalationweightAvobemax = "";
  EscalationweightAvobemaxText = "";

  EscalationweightBelowmin = "";
  EscalationweightBelowminText = "";
  EscalationweightBelowmax = "";
  EscalationweightBelowmaxText = "";

  EscalationThermometrmin = "";
  EscalationThermometrminText = "";
  EscalationThermometrmax = "";
  EscalationThermometrmaxText = "";

  EscalationOximeterspo2min = "";
  EscalationOximeterspo2max = "";
  EscalationOximeterspo2minText = "";
  EscalationOximeterspo2maxText = "";

  EscalationOximeterprmin = "";
  EscalationOximeterprmax = "";
  EscalationOximeterprminText = "";
  EscalationOximeterprmaxText = "";


  EscalationBloodAmin = "";
  EscalationBloodFmin = "";
  EscalationBloodNmin = "";
  EscalationBloodAmax = "";
  EscalationBloodFmax = "";
  EscalationBloodNmax = "";
  EscalationBloodAminText = "";
  EscalationBloodFminText = "";
  EscalationBloodNminText = "";
  EscalationBloodAmaxText = "";
  EscalationBloodFmaxText = "";
  EscalationBloodNmaxText = "";



  PatientDeviceList = [];

  make_number_mask = (num, masklength=7) =>{
    var numstr = num.toString();
    if(this.userGroup == "Superadmin"){
      return numstr;
    }
    var masked_num = numstr;
    if(numstr.length > masklength){
      masked_num = numstr.substr(0,numstr.length-masklength)+Array(masklength+1).join('x');
    }
    return masked_num;
  }

  ngOnInit() {
    let PatientClass = this;
    PatientClass.screen.style.display = 'block';

    let subDetail = this.route.snapshot.paramMap.get('sub');
    this.subId = atob(subDetail);
    console.log(this.subId);

    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
        patient_sub: this.subId
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/careplanlist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      if (response.status === "success") {
        this.careplanList = JSON.parse(response.data);
      }
      console.log(this.careplanList);
      let params = new HttpParams({
        fromObject: {
          userpull_id: localStorage.getItem('userpull_id'),
          group: 'Patient',
          sub: this.subId
        }
      });
      this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getuserdetail', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response);
        if (response.status === "success") {
          let responsedata = JSON.parse(response.data);
          console.log(responsedata);
          
          let providerlist = JSON.parse(response.ProviderList);

          if (responsedata.length > 0) {
            let params = new HttpParams({
              fromObject: {
                userpull_id: localStorage.getItem('userpull_id'),
                patient_sub: this.subId
              }
            });
            //EligibilitY Api Call Start
            this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/cmscareplan', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
            .subscribe((responsecmscareplan: any) => {
              if (responsecmscareplan.status === "success") {
                let responsecmscareplanData = JSON.parse(responsecmscareplan.data);

                console.log(responsecmscareplanData)

                for (var i = 0; i < responsecmscareplanData.length; i++) {

                  if(responsecmscareplanData[i]['StartDate'] != null) {
                    responsecmscareplanData[i]['CycleStartDateStr'] = this.formatDateM(responsecmscareplanData[i]['StartDate'].replaceAll('-','/'));
                    // responsecmscareplanData[i]['CycleStartDateStrDB'] = this.formatDate(responsedata[i]['CycleStartDate']);
                  }
                  if(responsecmscareplanData[i]['EndDate'] != null) {
                    responsecmscareplanData[i]['CycleEndDateStr'] = this.formatDateM(responsecmscareplanData[i]['EndDate'].replaceAll('-','/'));
                    // responsecmscareplanData[i]['CycleEndDateStrDB'] = this.formatDate(responsedata[i]['CycleEndDate']);
                  }
                }

                this.CMScareplanList = responsecmscareplanData

                let params = new HttpParams({
                  fromObject: {
                    userpull_id: localStorage.getItem('userpull_id'),
                    patient_sub: this.subId
                  }
                });

                this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getpatientstock', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
                .subscribe((responseinventory: any) => {
                  if (responseinventory.status === "success") {
                    let responseinventoryData = JSON.parse(responseinventory.data);

                    for (var i = 0; i < responseinventoryData.length; i++) {

                      if(responseinventoryData[i]['update_at'] != null) {
                        responseinventoryData[i]['IssueDate'] = this.formatDateM(responseinventoryData[i]['update_at'].replaceAll('-','/'));
                      }

                      if(responseinventoryData[i]['id'] != null && responseinventoryData[i]['id'] == 1) {
                        this.isHub = "Wi-Fi";
                      }

                    }

                    this.InventoryList = responseinventoryData;


                    let params = new HttpParams({
                      fromObject: {
                        userpull_id: localStorage.getItem('userpull_id')
                      }
                    });

                    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getescalation', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
                    .subscribe((responseEscalation: any) => {
                      if (responseEscalation.status === "success") {
                        let responseEscalationData = JSON.parse(responseEscalation.data);

                        console.log(responseEscalationData)

                        let sys = [];
                        let dia = [];
                        let pulse = [];

                        let AvobeWeight = [];
                        let BelowWeight = [];

                        let Temp = [];

                        let spo2 = [];
                        let pr = [];

                        let Blooda = [];
                        let Bloodf = [];
                        let Bloodn = [];

                        for (var i = 0; i < responseEscalationData.length; i++) {
                          if(responseEscalationData[i]['device_param'] == 'sys') {
                            sys.push(responseEscalationData[i]['device_escalation_val']);
                          }
                          if(responseEscalationData[i]['device_param'] == 'dia') {
                            dia.push(responseEscalationData[i]['device_escalation_val']);
                          }
                          if(responseEscalationData[i]['device_param'] == 'pulse') {
                            pulse.push(responseEscalationData[i]['device_escalation_val']);
                          }

                          if(responseEscalationData[i]['device_param'] == 'Plus') {
                            AvobeWeight.push(responseEscalationData[i]['device_escalation_val']);
                          }
                          if(responseEscalationData[i]['device_param'] == 'Minus') {
                            BelowWeight.push(responseEscalationData[i]['device_escalation_val']);
                          }

                          if(responseEscalationData[i]['device_param'] == 'temp') {
                            Temp.push(responseEscalationData[i]['device_escalation_val']);
                          }


                          if(responseEscalationData[i]['device_param'] == 'spo2') {
                            spo2.push(responseEscalationData[i]['device_escalation_val']);
                          }
                          if(responseEscalationData[i]['device_param'] == 'pr') {
                            pr.push(responseEscalationData[i]['device_escalation_val']);
                          }

                          if(responseEscalationData[i]['device_param'] == 'glucoseAfterMeal') {
                            Blooda.push(responseEscalationData[i]['device_escalation_val']);
                          }
                          if(responseEscalationData[i]['device_param'] == 'glucoseFasting') {
                            Bloodf.push(responseEscalationData[i]['device_escalation_val']);
                          }
                          if(responseEscalationData[i]['device_param'] == 'glucoseNormalTest') {
                            Bloodn.push(responseEscalationData[i]['device_escalation_val']);
                          }
                        }

                        this.Escalationsysmax = sys.reduce((a, b) => Math.max(a, b));
                        this.Escalationdiamax = dia.reduce((a, b) => Math.max(a, b));
                        this.Escalationpulsemax = pulse.reduce((a, b) => Math.max(a, b));

                        this.Escalationsysmin = sys.reduce((a, b) => Math.min(a, b));
                        this.Escalationdiamin = dia.reduce((a, b) => Math.min(a, b));
                        this.Escalationpulsemin = pulse.reduce((a, b) => Math.min(a, b));

                        this.EscalationweightAvobemax = AvobeWeight.reduce((a, b) => Math.max(a, b));
                        this.EscalationweightAvobemin = AvobeWeight.reduce((a, b) => Math.min(a, b));

                        this.EscalationweightBelowmax = BelowWeight.reduce((a, b) => Math.max(a, b));
                        this.EscalationweightBelowmin = BelowWeight.reduce((a, b) => Math.min(a, b));

                        this.EscalationThermometrmax = Temp.reduce((a, b) => Math.max(a, b));
                        this.EscalationThermometrmin = Temp.reduce((a, b) => Math.min(a, b));

                        this.EscalationOximeterspo2max = spo2.reduce((a, b) => Math.max(a, b));
                        this.EscalationOximeterspo2min = spo2.reduce((a, b) => Math.min(a, b));

                        this.EscalationOximeterprmax = pr.reduce((a, b) => Math.max(a, b));
                        this.EscalationOximeterprmin = pr.reduce((a, b) => Math.min(a, b));

                        this.EscalationBloodAmax = Blooda.reduce((a, b) => Math.max(a, b));
                        this.EscalationBloodAmin = Blooda.reduce((a, b) => Math.min(a, b));
                        
                        this.EscalationBloodFmax = Bloodf.reduce((a, b) => Math.max(a, b));
                        this.EscalationBloodFmin = Bloodf.reduce((a, b) => Math.min(a, b));

                        this.EscalationBloodNmax = Bloodn.reduce((a, b) => Math.max(a, b));
                        this.EscalationBloodNmin = Bloodn.reduce((a, b) => Math.min(a, b));


                        for (var i = 0; i < responseEscalationData.length; i++) {
                          // BPM
                          if(responseEscalationData[i]['device_param'] == 'sys' && responseEscalationData[i]['device_escalation_val'] == this.Escalationsysmax) {
                            this.EscalationsysmaxText = responseEscalationData[i]['escalation_text'];
                          }
                          if(responseEscalationData[i]['device_param'] == 'dia' && responseEscalationData[i]['device_escalation_val'] == this.Escalationdiamax) {
                            this.EscalationdiamaxText = responseEscalationData[i]['escalation_text'];
                          }
                          if(responseEscalationData[i]['device_param'] == 'pulse' && responseEscalationData[i]['device_escalation_val'] == this.Escalationpulsemax) {
                            this.EscalationpulsemaxText = responseEscalationData[i]['escalation_text'];
                          }

                          if(responseEscalationData[i]['device_param'] == 'sys' && responseEscalationData[i]['device_escalation_val'] == this.Escalationsysmin) {
                            this.EscalationsysminText = responseEscalationData[i]['escalation_text'];
                          }
                          if(responseEscalationData[i]['device_param'] == 'dia' && responseEscalationData[i]['device_escalation_val'] == this.Escalationdiamin) {
                            this.EscalationdiaminText = responseEscalationData[i]['escalation_text'];
                          }
                          if(responseEscalationData[i]['device_param'] == 'pulse' && responseEscalationData[i]['device_escalation_val'] == this.Escalationpulsemin) {
                            this.EscalationpulseminText = responseEscalationData[i]['escalation_text'];
                          }

                          // Weight
                          if(responseEscalationData[i]['device_param'] == 'Plus' && responseEscalationData[i]['device_escalation_val'] == this.EscalationweightAvobemax) {
                            this.EscalationweightAvobemaxText = responseEscalationData[i]['escalation_text'];
                          }
                          if(responseEscalationData[i]['device_param'] == 'Plus' && responseEscalationData[i]['device_escalation_val'] == this.EscalationweightAvobemin) {
                            this.EscalationweightAvobeminText = responseEscalationData[i]['escalation_text'];
                          }

                          if(responseEscalationData[i]['device_param'] == 'Minus' && responseEscalationData[i]['device_escalation_val'] == this.EscalationweightBelowmax) {
                            this.EscalationweightBelowmaxText = responseEscalationData[i]['escalation_text'];
                          }
                          if(responseEscalationData[i]['device_param'] == 'Minus' && responseEscalationData[i]['device_escalation_val'] == this.EscalationweightBelowmin) {
                            this.EscalationweightBelowminText = responseEscalationData[i]['escalation_text'];
                          }

                          // Thermometer
                          if(responseEscalationData[i]['device_param'] == 'temp' && responseEscalationData[i]['device_escalation_val'] == this.EscalationThermometrmax) {
                            this.EscalationThermometrmaxText = responseEscalationData[i]['escalation_text'];
                          }
                          if(responseEscalationData[i]['device_param'] == 'temp' && responseEscalationData[i]['device_escalation_val'] == this.EscalationThermometrmin) {
                            this.EscalationThermometrminText = responseEscalationData[i]['escalation_text'];
                          }

                          // Oximeter
                          if(responseEscalationData[i]['device_param'] == 'spo2' && responseEscalationData[i]['device_escalation_val'] == this.EscalationOximeterspo2max) {
                            this.EscalationOximeterspo2maxText = responseEscalationData[i]['escalation_text'];
                          }
                          if(responseEscalationData[i]['device_param'] == 'spo2' && responseEscalationData[i]['device_escalation_val'] == this.EscalationOximeterspo2min) {
                            this.EscalationOximeterspo2minText = responseEscalationData[i]['escalation_text'];
                          }

                          if(responseEscalationData[i]['device_param'] == 'pr' && responseEscalationData[i]['device_escalation_val'] == this.EscalationOximeterprmax) {
                            this.EscalationOximeterprmaxText = responseEscalationData[i]['escalation_text'];
                          }
                          if(responseEscalationData[i]['device_param'] == 'pr' && responseEscalationData[i]['device_escalation_val'] == this.EscalationOximeterprmin) {
                            this.EscalationOximeterprminText = responseEscalationData[i]['escalation_text'];
                          }

                          // Blood Glucose
                          if(responseEscalationData[i]['device_param'] == 'glucoseAfterMeal' && responseEscalationData[i]['device_escalation_val'] == this.EscalationBloodAmax) {
                            this.EscalationBloodAmaxText = responseEscalationData[i]['escalation_text'];
                          }
                          if(responseEscalationData[i]['device_param'] == 'glucoseAfterMeal' && responseEscalationData[i]['device_escalation_val'] == this.EscalationBloodAmin) {
                            this.EscalationBloodAminText = responseEscalationData[i]['escalation_text'];
                          }

                          if(responseEscalationData[i]['device_param'] == 'glucoseFasting' && responseEscalationData[i]['device_escalation_val'] == this.EscalationBloodFmax) {
                            this.EscalationBloodFmaxText = responseEscalationData[i]['escalation_text'];
                          }
                          if(responseEscalationData[i]['device_param'] == 'glucoseFasting' && responseEscalationData[i]['device_escalation_val'] == this.EscalationBloodFmin) {
                            this.EscalationBloodFminText = responseEscalationData[i]['escalation_text'];
                          }

                          if(responseEscalationData[i]['device_param'] == 'glucoseNormalTest' && responseEscalationData[i]['device_escalation_val'] == this.EscalationBloodNmax) {
                            this.EscalationBloodNmaxText = responseEscalationData[i]['escalation_text'];
                          }
                          if(responseEscalationData[i]['device_param'] == 'glucoseNormalTest' && responseEscalationData[i]['device_escalation_val'] == this.EscalationBloodNmin) {
                            this.EscalationBloodNminText = responseEscalationData[i]['escalation_text'];
                          }


                        }



                        console.log("sys", sys)
                        console.log("sysMax", sys.reduce((a, b) => Math.max(a, b)))

                        for (var j = 0; j < responsedata.length; j++) {
                          responsedata[j].masked_phone_number="";
                          if(responsedata[j].phone_number && responsedata[j].phone_number.length > 0){
                            var phonestr = responsedata[j].phone_number.toString();
                            responsedata[j].masked_phone_number = this.make_number_mask(phonestr);
                          }
                          responsedata[j].masked_phoneNoSms="";
                          if(responsedata[j].phoneNoSms && responsedata[j].phoneNoSms.length > 0){
                            var smsphonestr = responsedata[j].phoneNoSms.toString();
                            responsedata[j].masked_phoneNoSms = this.make_number_mask(smsphonestr);
                          }
                          if(responsedata[j]['birthdate']) {
                            responsedata[j]['birthdate']=this.formatDateMSlash(responsedata[j]['birthdate'].replaceAll('-','/') + " 00:00:00");
                            responsedata[j]['birthdate_date']=this.formatDateMDate(responsedata[j]['birthdate'].replaceAll('-','/') + " 00:00:00");
                            responsedata[j]['masked_birthdate'] = this.make_number_mask(this.formatDateMSlash(responsedata[j]['birthdate'].replaceAll('-','/') + " 00:00:00").toString(),4);
                          }
                          responsedata[j]['consentdate'] = "";
                          responsedata[j]['consentdate_date'] = "";
                          if(responsedata[j]['consentDate'] && responsedata[j]['consentDate'] != "0000-00-00 00:00:00") {
                            responsedata[j]['consentdate'] = this.formatDateMSlash(responsedata[j]['consentDate'].replaceAll('-','/') + " UTC");
                            responsedata[j]['consentdate_date'] = this.formatDateMDate(responsedata[j]['consentDate'].replaceAll('-','/') + " UTC");
                          }
                          responsedata[j]['transdate'] = "";
                          responsedata[j]['transdate_date'] = "";
                          if(responsedata[j]['dataTransmissionDate'] && responsedata[j]['dataTransmissionDate'] != "0000-00-00 00:00:00") {
                            responsedata[j]['transdate'] = this.formatDateMSlash(responsedata[j]['dataTransmissionDate'].replaceAll('-','/') + " UTC");
                            responsedata[j]['transdate_date'] = this.formatDateMDate(responsedata[j]['dataTransmissionDate'].replaceAll('-','/') + " UTC");
                          }
                          responsedata[j]['edudate'] = "";
                          responsedata[j]['edudate_date'] = "";
                          if(responsedata[j]['deviceEducationDate'] && responsedata[j]['deviceEducationDate'] != "0000-00-00 00:00:00") {
                            responsedata[j]['edudate'] = this.formatDateMSlash(responsedata[j]['deviceEducationDate'].replaceAll('-','/') + " UTC");
                            responsedata[j]['edudate_date'] = this.formatDateMDate(responsedata[j]['deviceEducationDate'].replaceAll('-','/') + " UTC");
                          }
                          responsedata[j]['primary_diagnosis_str'] = "";
                          if(responsedata[j].Diagnosis && responsedata[j].Diagnosis['primary_diagnosis']) {
                            for (var i = 0; i < responsedata[j].Diagnosis['primary_diagnosis'].length; i++) {
                              if(responsedata[j].Diagnosis['primary_diagnosis'][i] && responsedata[j].Diagnosis['primary_diagnosis'][i]['check'] && responsedata[j].Diagnosis['primary_diagnosis'][i]['check'] == "true"){
                                if(responsedata[j]['primary_diagnosis_str'] == ""){
                                  responsedata[j]['primary_diagnosis_str'] = responsedata[j].Diagnosis['primary_diagnosis'][i]['diagnosis_type'];
                                }
                                else {
                                  responsedata[j]['primary_diagnosis_str'] = responsedata[j]['primary_diagnosis_str'] + ", "+responsedata[j].Diagnosis['primary_diagnosis'][i]['diagnosis_type'];
                                }
                              }
                            }
                          }
                          responsedata[j]['secondary_diagnosis_str'] = "";
                          if(responsedata[j].Diagnosis && responsedata[j].Diagnosis['secondary_diagnosis']) {
                            responsedata[j]['secondary_diagnosis_str'] = responsedata[j].Diagnosis['secondary_diagnosis'];
                          }
                          if(responsedata[j].DeviceEducation) {
                            for (var i = 0; i < responsedata[j].DeviceEducation.length; i++) {
                              responsedata[j].DeviceEducation[i]['timestamp'] = this.formatDateMSlash(responsedata[j].DeviceEducation[i]['timestamp'].replaceAll('-','/') + " UTC");
                              // if(responsedata[j].DeviceEducation[i]['diagnosis_review_id'] > prisecid) {
                              //   // prisecid = responsedata[j].DeviceEducation[i]['diagnosis_review_id'];
                              //   let pri = responsedata[j].DeviceEducation[i]['primary_diagnosis'];
                              //   let sec = responsedata[j].DeviceEducation[i]['secondary_diagnosis'];
                              //   responsedata[j]['primary_diagnosis_latest'] = pri;
                              //   responsedata[j]['secondary_diagnosis_latest'] = sec;
                              // }
                            }
                          }
                          if(responsedata[j].TransmissionDate) {
                            responsedata[j].devicesetting = [];
                            for (var k = 0; k < responsedata[j].TransmissionDate.length; k++) {
                              if(responsedata[j].ReviewPeriod && responsedata[j].ReviewPeriod[k]) {
                                responsedata[j].ReviewPeriod[k].StartDate = this.formatDateMSlash(responsedata[j].ReviewPeriod[k].StartDate.replaceAll('-','/') + " UTC");
                                responsedata[j].ReviewPeriod[k].EndDate = this.formatDateMSlash(responsedata[j].ReviewPeriod[k].EndDate.replaceAll('-','/') + " UTC");
                                for(var i=0; i<responsedata[j].ReviewPeriod[k].Review.length; i++) {
                                  // responsedata[j].ReviewPeriod[k].Review[i].timestamp = this.formatDateCustom(responsedata[j].ReviewPeriod[k].Review[i].timestamp);
                                  responsedata[j].ReviewPeriod[k].Review[i].timestamp = this.formatDateMSlash(responsedata[j].ReviewPeriod[k].Review[i].timestamp.replaceAll('-','/') + " UTC");
                                  if(responsedata[j].ReviewPeriod[k].Review[i].mode == "monthlyreview") {
                                    this.summarydata.push(responsedata[j].ReviewPeriod[k].Review[i].review);
                                    responsedata[j].ReviewPeriod[k].Review[i].review = "[Monthly Summary Review]";
                                  }
                                  if(responsedata[j].ReviewPeriod[k].Review[i].review.trim() == "Recorded Audio link to Review Call"){
                                    responsedata[j].ReviewPeriod[k].Review[i].review = "Patient Phone Call";
                                  }
                                }
                                responsedata[j].TransmissionDate[k].ReviewPeriod = responsedata[j].ReviewPeriod[k];
                              }
                              if(responsedata[j].TransmissionDate[k].StartDate && responsedata[j].TransmissionDate[k].EndDate) {
                                responsedata[j].TransmissionDate[k].StartDate = this.formatDateMSlash(responsedata[j].TransmissionDate[k].StartDate.replaceAll('-','/') + " UTC");
                                responsedata[j].TransmissionDate[k].EndDate = this.formatDateMSlash(responsedata[j].TransmissionDate[k].EndDate.replaceAll('-','/') + " UTC");
                                responsedata[j].TransmissionDate[k].StartDateStr = responsedata[j].TransmissionDate[k].StartDate.replace('/','').replace('/','');
                                // if(responsedata[j].TransmissionDate[k].StartDateStr == this.subTrans) {
                                //   valid_data = k+1;
                                // }
                                if(responsedata[j].TransmissionDate[k].ReportDate == this.subTrans) {
                                  // valid_data = k+1;
                                }
                              }
                              if(responsedata[j].Alerts && responsedata[j].Alerts[k]) {
                                for (var i = 0; i < responsedata[j].Alerts[k].length; i++) {
                                  if(responsedata[j].Alerts[k][i]) {  // && responsedata[j].Alerts[k][i].length > 0
                                    responsedata[j].Alerts[k][i]['timestamp'] = this.formatDateM(responsedata[j].Alerts[k][i]['timestamp']);
                                    // var settingjsonarr = responsedata[j].Alerts[k][i]['alert_setting'].trim().replace(/ : /g, ':').replace(/ :/g, ':').replace(/: /g, ':').replace(/" /g, '"').replace(/ "/g, '"').split(" ");
                                    var settingjsonarr = responsedata[j].Alerts[k][i]['alert_setting'].trim().split(" ");
                                    var settingjson = "";
                                    for(var l = 0; l < settingjsonarr.length; l++) {
                                      if(settingjsonarr[l].trim() != '') {
                                        if(settingjson) {
                                          if(settingjsonarr[l].trim() != ":" && settingjsonarr[l].trim().slice(-1) != '"') {
                                            settingjson = settingjson + settingjsonarr[l].trim() + ',';
                                          }
                                          else {
                                            settingjson = settingjson + settingjsonarr[l].trim();
                                          }
                                        }
                                        else {
                                          settingjson = settingjson + settingjsonarr[l].trim();
                                        }
                                      }
                                    }
                                    if(settingjson.slice(-1) == ",") {
                                      settingjson = settingjson.slice(0, -1);
                                    }
                                    settingjson = '{' + settingjson.replace(/After,Meal/g, 'After Meal').replace(/Normal,Test/g, 'Normal Test') + '}';
                                    var detailjsonarr = responsedata[j].Alerts[k][i]['detailed_value'].trim().replace(/ : /g, ':').replace(/ :/g, ':').replace(/: /g, ':').replace(/" /g, '"').replace(/ "/g, '"').split(" ");
                                    var detailjson = "";
                                    for(var l = 0; l < detailjsonarr.length; l++) {
                                      if(detailjsonarr[l].trim() != '') {
                                        if(detailjson) {
                                          detailjson = detailjson + ',' + detailjsonarr[l].trim();
                                        }
                                        else {
                                          detailjson = detailjson + detailjsonarr[l].trim();
                                        }
                                      }
                                    }
                                    var detailjson = '{' + detailjson.replace(/After,Meal/g, 'After Meal').replace(/Normal,Test/g, 'Normal Test') + '}';
                                    if(responsedata[j].Alerts[k][i]['device_name'].trim() == "BPM" || responsedata[j].Alerts[k][i]['device_name'].trim() == "Oximeter" || responsedata[j].Alerts[k][i]['device_name'].trim() == "Weight" || responsedata[j].Alerts[k][i]['device_name'].trim() == "Blood Glucose" || responsedata[j].Alerts[k][i]['device_name'].trim() == "Thermometer") {
                                      responsedata[j].Alerts[k][i]['alert_setting'] = JSON.parse(settingjson);
                                      responsedata[j].Alerts[k][i]['detailed_value'] = JSON.parse(detailjson);
                                      if(responsedata[j].Alerts[k][i]['detailed_value']['weight']) {
                                        responsedata[j].Alerts[k][i]['detailed_value']['weight'] = Math.floor(responsedata[j].Alerts[k][i]['detailed_value']['weight'])+" lbs";
                                      }
                                      if(responsedata[j].Alerts[k][i]['detailed_value']['bmi']) {
                                        responsedata[j].Alerts[k][i]['detailed_value']['bmi'] = Math.floor(responsedata[j].Alerts[k][i]['detailed_value']['bmi']);
                                      }
                                    }
                                    if(responsedata[j].Alerts[k][i]['device_name'].trim() == "BPM") {
                                      responsedata[j].Alerts[k][i]['device_name_str'] = "BPM (SYS/DIA/Pulse)";
                                    }
                                    else if(responsedata[j].Alerts[k][i]['device_name'].trim() == "Oximeter") {
                                      responsedata[j].Alerts[k][i]['device_name_str'] = "Pulse Oximeter (Spo2%/PR)";
                                    }
                                    else if(responsedata[j].Alerts[k][i]['device_name'].trim() == "Weight") {
                                      responsedata[j].Alerts[k][i]['device_name_str'] = "Weight (lb./BMI)";
                                    }
                                    else if(responsedata[j].Alerts[k][i]['device_name'].trim() == "Blood Glucose") {
                                      responsedata[j].Alerts[k][i]['device_name_str'] = "Blood Glucose in mg/dL (Mode)";
                                    }
                                    else if(responsedata[j].Alerts[k][i]['device_name'].trim() == "Thermometer") {
                                      responsedata[j].Alerts[k][i]['device_name_str'] = "Thermometer (Temp. in Farenheit)";
                                    }
                                    else {
                                      responsedata[j].Alerts[k][i]['device_name_str'] = responsedata[j].Alerts[k][i]['device_name'].trim();
                                    }
                                  }
                                }
                                responsedata[j].TransmissionDate[k].Alerts = responsedata[j].Alerts[k];
                              }
                              if(responsedata[j].Devices && responsedata[j].Devices[k]) {
                                responsedata[j].TransmissionDate[k].Devices = responsedata[j].Devices[k];
                                for (var l = 0; l < responsedata[j].Devices[k].length; l++) {
                                  if(l == 0) {
                                    responsedata[j].TransmissionDate[k].DevicesTotalDays = 0;
                                  }
                                  responsedata[j].TransmissionDate[k].DevicesTotalDays = responsedata[j].TransmissionDate[k].DevicesTotalDays + Number(responsedata[j].Devices[k][l]['total_days']);
                                }
                              }
                              if(responsedata[j].InRange && responsedata[j].InRange[k]) {
                                responsedata[j].TransmissionDate[k].InRange = responsedata[j].InRange[k].length;
                              }
                              if(responsedata[j].UploadHistory && responsedata[j].UploadHistory[k]) {
                                responsedata[j].devicesetting[k] = {};
                                for (var i = 0; i < responsedata[j].UploadHistory[k].length; i++) {
                                  if(responsedata[j].UploadHistory[k][i]) {  // && responsedata[j].UploadHistory[k][i].length > 0
                                    responsedata[j].UploadHistory[k][i]['timestamp'] = this.formatDateCustom(responsedata[j].UploadHistory[k][i]['timestamp'].replaceAll('-','/') + " UTC");
                                    responsedata[j].UploadHistory[k][i]['extended_comments'] = "";
                                    if(responsedata[j].UploadHistory[k][i]['ext_alert_comments'] != null && responsedata[j].UploadHistory[k][i]['ext_alert_comments'] != ''){
                                      var commentarr = responsedata[j].UploadHistory[k][i]['ext_alert_comments'].trim().split(",");
                                      for(var m = 0; m < commentarr.length; m++) {
                                        // if(m==0){
                                        //   responsedata[j].UploadHistory[k][i]['extended_comments'] = this.interventiondataarr[commentarr[m]];
                                        // }
                                        // else {
                                          // responsedata[j].UploadHistory[k][i]['extended_comments'] = responsedata[j].UploadHistory[k][i]['extended_comments']+"<div>"+(Number(m)+1).toString()+". "+this.interventiondataarr[commentarr[m]]+"</div>";
                                        // }
                                      }
                                    }
                                    // var settingjsonarr = responsedata[j].UploadHistory[k][i]['alert_setting'].trim().replace(/ : /g, ':').replace(/ :/g, ':').replace(/: /g, ':').replace(/" /g, '"').replace(/ "/g, '"').split(" ");
                                    var settingjson = "";
                                    if(responsedata[j].UploadHistory[k][i]['alert_setting'] && responsedata[j].UploadHistory[k][i]['alert_setting'] != null) {
                                      var settingjsonarr = responsedata[j].UploadHistory[k][i]['alert_setting'].trim().split(" ");
                                      for(var l = 0; l < settingjsonarr.length; l++) {
                                        if(settingjsonarr[l].trim() != '') {
                                          if(settingjson) {
                                            if(settingjsonarr[l].trim() != ":" && settingjsonarr[l].trim().slice(-1) != '"') {
                                              settingjson = settingjson + settingjsonarr[l].trim() + ',';
                                            }
                                            else {
                                              settingjson = settingjson + settingjsonarr[l].trim();
                                            }
                                          }
                                          else {
                                            settingjson = settingjson + settingjsonarr[l].trim();
                                          }
                                        }
                                      }
                                    }
                                    if(settingjson.slice(-1) == ",") {
                                      settingjson = settingjson.slice(0, -1);
                                    }
                                    settingjson = '{' + settingjson.replace(/After,Meal/g, 'After Meal').replace(/Normal,Test/g, 'Normal Test') + '}';
                                    var detailjson = "";
                                    if(responsedata[j].UploadHistory[k][i]['detailed_value']) {
                                      var detailjsonarr = responsedata[j].UploadHistory[k][i]['detailed_value'].trim().replace(/ : /g, ':').replace(/ :/g, ':').replace(/: /g, ':').replace(/" /g, '"').replace(/ "/g, '"').split(" ");
                                      for(var l = 0; l < detailjsonarr.length; l++) {
                                        if(detailjsonarr[l].trim() != '') {
                                          if(detailjson) {
                                            detailjson = detailjson + ',' + detailjsonarr[l].trim();
                                          }
                                          else {
                                            detailjson = detailjson + detailjsonarr[l].trim();
                                          }
                                        }
                                      }
                                    }
                                    var detailjson = '{' + detailjson.replace(/After,Meal/g, 'After Meal').replace(/Normal,Test/g, 'Normal Test') + '}';
                                    if(responsedata[j].UploadHistory[k][i]['device_name'].trim() == "BPM" || responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Oximeter" || responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Weight" || responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Blood Glucose" || responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Thermometer") {
                                      responsedata[j].UploadHistory[k][i]['alert_setting'] = JSON.parse(settingjson);
                                      if(!(responsedata[j].UploadHistory[k][i]['device_name'].trim() in responsedata[j].devicesetting[k]) && settingjson != '{}') {
                                        if(this.devicesstring == ""){
                                          this.devicesstring = responsedata[j].UploadHistory[k][i]['device_name'].trim();
                                        }
                                        else {
                                          this.devicesstring = this.devicesstring +", "+ responsedata[j].UploadHistory[k][i]['device_name'].trim();
                                        }
                                        responsedata[j].devicesetting[k][responsedata[j].UploadHistory[k][i]['device_name'].trim()] = JSON.parse(settingjson);
                                      }
                                      responsedata[j].UploadHistory[k][i]['detailed_value'] = JSON.parse(detailjson);
                                      if(responsedata[j].UploadHistory[k][i]['detailed_value']['weight']) {
                                        responsedata[j].UploadHistory[k][i]['detailed_value']['weight'] = Math.floor(responsedata[j].UploadHistory[k][i]['detailed_value']['weight'])+" lbs";
                                      }
                                      if(responsedata[j].UploadHistory[k][i]['detailed_value']['bmi']) {
                                        responsedata[j].UploadHistory[k][i]['detailed_value']['bmi'] = Math.floor(responsedata[j].UploadHistory[k][i]['detailed_value']['bmi']);
                                      }
                                    }
                                    if(responsedata[j].UploadHistory[k][i]['device_name'].trim() == "BPM") {
                                      responsedata[j].UploadHistory[k][i]['device_name_str'] = "BPM (SYS/DIA/Pulse)";
                                    }
                                    else if(responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Oximeter") {
                                      responsedata[j].UploadHistory[k][i]['device_name_str'] = "Pulse Oximeter (Spo2%/PR)";
                                    }
                                    else if(responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Weight") {
                                      responsedata[j].UploadHistory[k][i]['device_name_str'] = "Weight (lb./BMI)";
                                    }
                                    else if(responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Blood Glucose") {
                                      responsedata[j].UploadHistory[k][i]['device_name_str'] = "Blood Glucose in mg/dL (Mode)";
                                    }
                                    else if(responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Thermometer") {
                                      responsedata[j].UploadHistory[k][i]['device_name_str'] = "Thermometer (Temp. in Farenheit)";
                                    }
                                    else {
                                      responsedata[j].UploadHistory[k][i]['device_name_str'] = responsedata[j].UploadHistory[k][i]['device_name'].trim();
                                    }
                                  }
                                }
                                responsedata[j].TransmissionDate[k].devicesetting = responsedata[j].devicesetting[k];
                                responsedata[j].TransmissionDate[k].UploadHistory = responsedata[j].UploadHistory[k];
                              }
                            }
                          }
                        }

                        PatientClass.userDetails = responsedata[0];

                        console.log("ddddddd");
                        if(PatientClass.userDetails.reporting_provider != "" && PatientClass.userDetails.reporting_provider != null){
                          for(var provider of providerlist){
                            if(provider.sub == PatientClass.userDetails.reporting_provider){
                              PatientClass.ReportingProvider = provider.name
                            }
                          }
                        }


                        for (let key in PatientClass.userDetails.DevicesAlertSettings) {
                          PatientClass.PatientDeviceList.push(key)
                        }

                        console.log("PatientDeviceList", PatientClass.PatientDeviceList)



                        PatientClass.screen.style.display = 'none';





                        setTimeout (() => {
                          PatientClass.screen.style.display = 'none';
                          window.print();
                          PatientClass.router.navigateByUrl('/patient-detail/'+btoa(this.subId));
                        }, 1000);


                        //  window.print();


                        // window.addEventListener("load", function (event) {
                        //     setTimeout (() => {
                        //       window.print();
                        //       PatientClass.router.navigateByUrl('/careplan/'+btoa(PatientClass.subId));
                        //     }, 500);
                        //   });
                      }
                    },
                    async (error: any) => {
                      console.log(error);
                    });
                  }
                },
                async (error: any) => {
                  console.log(error);
                });                
              }
            },
            async (error: any) => {
              console.log(error);
            });
            //EligibilitY Api Call End
            

            // PatientClass.screen.style.display = 'none';
            // PatientClass.userDetails = responsedata[0];
            // console.log(this.devicesstring);
            // console.log(valid_data);
            // if(!valid_data) {
              // alert("The URI is not valid");
              // PatientClass.router.navigateByUrl('/patient');
            // }
            // else {
              // this.validdata = valid_data-1;
              // console.log(this.validdata);
              // PatientClass.userDetails = responsedata[0];
              // setTimeout (() => {
              //   PatientClass.screen.style.display = 'none';
              //   window.print();
              //   // PatientClass.router.navigateByUrl('/careplan/'+btoa(this.subId));
              // }, 1000);
              // let usersubId = this.subId;
              // window.addEventListener("load", function (event) {
              //   setTimeout (() => {
              //     window.print();
              //     PatientClass.router.navigateByUrl('/careplan/'+btoa(usersubId));
              //   }, 500);
              // });
            // }
          }
          else {
            alert("Could not find Patient Details");
            PatientClass.router.navigateByUrl('/patient');
          }
        }
        else {
          alert("Could not find Patient Details");
          PatientClass.router.navigateByUrl('/patient');
        }
      },
      async (error: any) => {
        Auth.signOut();
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        PatientClass.screen.style.display = 'none';
        PatientClass.router.navigateByUrl('/sign-in');
      });
    },
    async (error: any) => {
      await Auth.signOut({
        global: true
      });
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });
  }
}
