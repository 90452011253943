import { Component, OnInit, OnDestroy, AfterViewInit, QueryList, ViewChildren } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AWS } from '@aws-amplify/core';
import { Auth } from 'aws-amplify';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { PubNubAngular } from 'pubnub-angular2';
import {v4 as uuidv4} from 'uuid';
import * as XLSX from 'xlsx';
declare const Twilio: any;


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  userGroup: string = '';
  batching : boolean = false;

  has_facility:boolean = false;
  facility_arr: any;

  languageArr: any;

  logedInUserName : string = "";
  logedInUserDetails : any = "";
  logedInUserManagePermission : string = "no";
  // @ViewChildren(DataTableDirective, {static: false})
  // dtElement: DataTableDirective;
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<any>;
  // dtElements: DataTableDirective;

  dtOptions: any = {};

  htmlToAdd: any;
  syncStatus: any;

  batchcycle:any;
  batchcyclelist:any;

  eligibitibatchcycle:any;
  eligibitibatchcyclelist:any;

  eligibiliticycleOpenBilledStatus: number = 0;

  eligibilitibatching : boolean = false;

  myTime: any;

  body: HTMLElement = document.getElementById('body') as HTMLElement;
  screen: HTMLElement = document.getElementById('screen') as HTMLElement;
  screencontent: HTMLElement = document.getElementById('screencontent') as HTMLElement;
  processing: HTMLElement = document.getElementById('cover') as HTMLElement;
  patientActiveCount: any;
  patientTotalCount: any;
  doctorActiveCount: any;
  doctorTotalCount: any;

  showallcheck: boolean = false;
  televisitallselected: boolean = false;

  cycleOpenBilledStatus: number = 0;
  teleBilledStatus: number = 0;

  summarydataList:any;
  summarydataListLength: number = 0;

  activitydataList:any;
  activitydataListLength: number = 0;

  billingdataList:any;
  billingdataListLength: number = 0;

  reviewdataList:any;
  reviewdataListLength: number = 0;

  devicedataList: any;
  devicedataListLength: number = 0;

  televisitdataList: any;
  televisitdataListLength: number = 0;

  newbillingdataList: any;
  newbillingdataListLength: number = 0;

  devicedataStatus: any;

  dtSystemAdmin: DataTables.Settings = {};
  dtTrigger: Subject<DashboardComponent> = new Subject();
  dtTrigger1: Subject<DashboardComponent> = new Subject();
  dtTrigger2: Subject<DashboardComponent> = new Subject();
  dtTrigger3: Subject<DashboardComponent> = new Subject();
  dtTrigger4: Subject<DashboardComponent> = new Subject();
  dtTrigger5: Subject<DashboardComponent> = new Subject();

  teleDetails: any;

  deviceDetails: any;
  reviewDetails: any;
  addReviewFormMode: any;
  addReviewFormProvider: any;

  interventionDeviceText: any;
  interventionPreText: any;
  interventionarr: any;

  reviewtemplatearr: any;
  selreviewtemplate: any;
  reviewtext: any;

  taskitemsearr: any;
  selectedtask: any;
  poolusers: any;
  selectedassign: any;
  givenpriority: any;
  givennote: any;

  searchForm: any;
  summaryForm: any;
  myCloseDate: any;
  myBillDate: any;
  myDate: any;
  myFromDate: any;
  myDate2: any;
  myDate3: any;
  myDate4: any;
  myDate5: any;
  thisDate: any;
  myMonth: any;
  myPrevDate: any;
  myPrevMonthDate2: any;
  myPrevMonthDate3: any;
  myPrev2Date: any;
  myPrevMonthDate4: any;
  myPrevMonthDate5: any;
  todate: any;

  rangeval: string = "today";

  reusablesearchdata: any;

  providerlist: any;

  providerCount: number = 0;
  patientCount: number = 0;
  activePatientCount: number = 0;
  activeProviderCount: number = 0;

  //==doctor bar
  doctorBarchartData: any = [];
  doctorBarchartLabels: any;
  doctorBarchartColors = [
    { borderColor: 'black', backgroundColor: 'rgba(221, 75, 57,1)' }
  ];
  // doctorBarchartOptions = {
  //   responsive: true,
  //   scales: {
  //     yAxes: [{
  //       ticks: {
  //         beginAtZero: true
  //       }
  //     }]
  //   }
  // };

  //==Doctor line
  doctorLinechartData: any = [];
  doctorLinechartLabels: any;
  doctorLinechartColors = [
    { borderColor: 'green', backgroundColor: 'green' },
    { borderColor: 'red', backgroundColor: 'red' }
  ];
  // doctorLinechartOptions = {
  //   responsive: true,
  //   scales: {
  //     yAxes: [{
  //       ticks: {
  //         beginAtZero: true,
  //       }
  //     }]
  //   }

  // };

  //==patient line
  patientLineChartData: any = [];
  patientLinechartLabels: any;
  patientLinechartColors = [
    { borderColor: 'green', backgroundColor: 'green' },
    { borderColor: 'red', backgroundColor: 'red' }
  ];
  patientLinechartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
        }
      }]
    }

  };

  //==patient bar

  patientBarchartData: any = [];
  patientBarchartLabels: any;
  patientBarchartColors = [
    { borderColor: 'black', backgroundColor: 'rgba(47, 192, 239,1)' }
  ];
  patientBarchartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };

  //Download chart
  downloadBarchartData: any = [];
  downloadBarchartLabels: any = [];
  downloadBarchartColors = [
    { borderColor: 'green', backgroundColor: 'green' },
    { borderColor: 'red', backgroundColor: 'red' }
  ];
  downloadBarchartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }

  };
  totalDownloadCount: any;
  totalUploadCount: any;
  
  patientallselected: boolean = false;
  selectedPatientStatus: boolean = false;

  htmlToAddSMS: any;
  smsStatus: any;

  smsText:String = "";
  smsTextList = [];
  smstype: any;
  smsError = false;
  smsTypeError = false;

  showpatientlang = false;
  patientlang = false;
  patientlangArr = [];
  patlang: any;

  //

  dtTrigger7: Subject<DashboardComponent> = new Subject();
  myDate7: any;
  myPrevMonthDate7: any;
  inventorydataList: any;
  inventorydataListLength: number = 0;

  dtTrigger8: Subject<DashboardComponent> = new Subject();
  myDate8: any;
  myPrevMonthDate8: any;
  inventorydata2List: any;
  inventorydata2ListLength: number = 0;

  dtTrigger9: Subject<DashboardComponent> = new Subject();
  myDate9: any;
  myPrevMonthDate9: any;
  rollOutdata2List: any;
  rollOutdata2ListLength: number = 0;

  dtTrigger10: Subject<DashboardComponent> = new Subject();
  myDate10: any;
  myPrevWeekDate10: any;
  callList: any;
  callListLength: number = 0;

  myDate11: any;
  myPrevWeekDate11: any;


  conpletedByDate: any;

  dtTrigger11: Subject<DashboardComponent> = new Subject();
  EligibilityReportList: any;
  EligibilityReportListLength: number = 0;


  dtTrigger12: Subject<DashboardComponent> = new Subject();
  BillingEligibilityReportList: any;
  BillingEligibilityReportListLength: number = 0;

  dtTrigger13: Subject<DashboardComponent> = new Subject();
  BillingEligibilityExceptionReportList: any;
  BillingEligibilityExceptionReportListLength: number = 0;
  

  twilioEndPoint = "https://3xcxz84v68.execute-api.us-east-1.amazonaws.com";
  twilioEndPointApiKey = "2pdKyWDlxE6KxzVGV8Dsq5cDxcTVuWhZ3k5TCmYe";
  twilioNumber = "";
  twilioConstantNumber = "+14083518666";
  isTwilioEnabled = false;
  clickCallisDisabled = false;
  endButtonShow = false;
  phonecall = true;
  twliotimer = null;

  patientphone:string = '';


  constructor( private router: Router, private httpClient: HttpClient, public datepipe: DatePipe, private pubnub: PubNubAngular ) {
    let authData = JSON.parse(localStorage.getItem('AUTH'));
    console.log(authData);
    this.userGroup = authData.group;
    if (localStorage.getItem('MANAGE-USER') && localStorage.getItem('MANAGE-USER') == "yes") {
      this.logedInUserManagePermission = "yes";
    }
  	if (localStorage.getItem('USER-DETAILS')) {
  		this.logedInUserDetails = JSON.parse(localStorage.getItem('USER-DETAILS'));
  		console.log(this.logedInUserDetails);
  		if(this.logedInUserDetails.given_name){
  			this.logedInUserName = this.logedInUserDetails.given_name;
      }
      else if(this.logedInUserDetails.email){
  			this.logedInUserName = this.logedInUserDetails.email;
  		}
  		else{
  			this.logedInUserName = (this.logedInUserDetails.name)?this.logedInUserDetails.name:'';
  		}
  	}
    let todaydate = new Date();
    let yesterdaydate = new Date();
    let prev2Date = new Date();
    let prevmonthdate = new Date();
    let prevweekdate = new Date();
    this.myCloseDate = this.formatDate(todaydate);
    this.myBillDate = this.formatTime(todaydate);
    this.myDate = this.formatDate(todaydate);
    this.myFromDate = this.formatDate(todaydate);
    this.myDate2 = this.formatDate(todaydate);
    this.myDate3 = this.formatDate(todaydate);
    this.myDate4 = this.formatDate(todaydate);
    this.myDate5 = this.formatDate(todaydate);
    this.thisDate = this.formatDate(todaydate);
    this.myMonth = this.formatMonth(todaydate);
    yesterdaydate.setDate(todaydate.getDate() - 1);
    this.myPrevDate = this.formatDate(yesterdaydate);
    prev2Date.setDate(todaydate.getDate() - 2);
    this.myPrev2Date = this.formatDate(prev2Date);
    prevmonthdate.setMonth(todaydate.getMonth() - 1);
    prevmonthdate.setDate(prevmonthdate.getDate() + 1);
    this.myPrevMonthDate2 = this.formatDate(prevmonthdate);
    this.myPrevMonthDate3 = this.formatDate(prevmonthdate);
    this.myPrevMonthDate4 = this.formatDate(prevmonthdate);
    this.myPrevMonthDate5 = this.formatDate(prevmonthdate);

    this.myTime = this.formatTime(todaydate);

    this.myDate7 = this.formatDate(todaydate);
    this.myPrevMonthDate7 = this.formatDate(prevmonthdate);

    this.myDate8 = this.formatDate(todaydate);
    this.myPrevMonthDate8 = this.formatDate(prevmonthdate);

    this.myDate9 = this.formatDate(todaydate);
    this.myPrevMonthDate9 = this.formatDate(prevmonthdate);

    this.myDate10 = this.formatDate(todaydate);
    prevweekdate.setDate(todaydate.getDate() - 7);
    this.myPrevWeekDate10 = this.formatDate(prevweekdate);

    this.myDate11 = this.formatDate(todaydate);
    prevweekdate.setDate(todaydate.getDate() - 7);
    this.myPrevWeekDate11 = this.formatDate(prevweekdate);

    this.conpletedByDate = this.formatTime(todaydate);

    this.mybillingeligibilityCloseDate = this.formatDate(todaydate);
    
    //console.log(localStorage);
    // this.searchForm = {
    //   todate: this.datepipe.transform(todaydate, 'MM/dd/yyyy')
    // };
    // this.searchForm = {
    //   todate: this.myDate
    // }
    // console.log(this.myDate);
    // console.log(this.myMonth);
    // console.log(this.myPrevDate);
    // console.log(this.myPrevMonthDate);
    // console.log(this.searchForm.todate);

    if(sessionStorage.getItem('pubnubcred') != null) {
      let pubnubcred = JSON.parse(sessionStorage.getItem('pubnubcred'));
      // console.log(pubnubcred.publish_key);
      // console.log(pubnubcred.subscribe_key);
      // console.log(pubnubcred.secret_key);
      // console.log(localStorage.getItem('AUTH-TOKEN'));
      // pubnub.init({
      //   publishKey: pubnubcred.publish_key,
      //   subscribeKey: pubnubcred.subscribe_key,
      //   channels: pubnubcred.channel_list,
      //   cipherKey: pubnubcred.cipher_key,
      //   authKeys: localStorage.getItem('AUTH-TOKEN'),
      //   // authKeys: "AUTH-123",
      //   uuid: localStorage.getItem('AUTH-TOKEN').replace(/-/g, '_'),
      //   ssl: true,
      //   logVerbosity: true,
      //   origin: 'pubsub.pubnub.com'
      // });

      // pubnub.addListener({
      //   status: function(st) {
      //     console.log(st);
      //   },
      //   // presence: function(m) {
      //   //   console.log(m);
      //   // },
      //   message: function(msg) {
      //     console.log(msg);
      //   }
      // });
      
      // pubnub.subscribe({
      //     channels: pubnubcred.channel_list,
      //     // withPresence: true,
      //     triggerEvents: true
      //     //triggerEvents: ['message', 'presence', 'status'],
      //     //autoload: 100
      // });
    }
  }

  make_number_mask = (num, masklength=7) =>{
    var numstr = num.toString();
    if(this.userGroup == "Superadmin"){
      return numstr;
    }
    var masked_num = numstr;
    if(numstr.length > masklength){
      masked_num = numstr.substr(0,numstr.length-masklength)+Array(masklength+1).join('x');
    }
    return masked_num;
  }

  rangeselection = (rangeval) => {
    console.log(rangeval);
    let todaydate = new Date();
    if(rangeval == 'today') {
      this.myDate = this.formatDate(todaydate);
      this.myFromDate = this.formatDate(todaydate);
    }
    else if(rangeval == 'yesterday'){
      let customdate = new Date();
      customdate.setDate(todaydate.getDate() - 1);
      this.myDate = this.formatDate(customdate);
      this.myFromDate = this.formatDate(customdate);
    }
    else if(rangeval == 'oldyesterday'){
      let customdate = new Date();
      customdate.setDate(todaydate.getDate() - 2);
      this.myDate = this.formatDate(customdate);
      this.myFromDate = this.formatDate(customdate);
    }
    else if(rangeval == '3days'){
      this.myDate = this.formatDate(todaydate);
      let customdate = new Date();
      customdate.setDate(todaydate.getDate() - 2);
      this.myFromDate = this.formatDate(customdate);
    }
    else if(rangeval == '5days'){
      this.myDate = this.formatDate(todaydate);
      let customdate = new Date();
      customdate.setDate(todaydate.getDate() - 4);
      this.myFromDate = this.formatDate(customdate);
    }
    else if(rangeval == '7days'){
      this.myDate = this.formatDate(todaydate);
      let customdate = new Date();
      customdate.setDate(todaydate.getDate() - 6);
      this.myFromDate = this.formatDate(customdate);
    }
    else if(rangeval == '3daysnottoday'){
      let customdate = new Date();
      customdate.setDate(todaydate.getDate() - 1);
      this.myDate = this.formatDate(customdate);
      let customdate2 = new Date();
      customdate2.setDate(todaydate.getDate() - 3);
      this.myFromDate = this.formatDate(customdate2);
    }
    else if(rangeval == '5daysnottoday'){
      let customdate = new Date();
      customdate.setDate(todaydate.getDate() - 1);
      this.myDate = this.formatDate(customdate);
      let customdate2 = new Date();
      customdate2.setDate(todaydate.getDate() - 5);
      this.myFromDate = this.formatDate(customdate2);
    }
    else if(rangeval == '7daysnottoday'){
      let customdate = new Date();
      customdate.setDate(todaydate.getDate() - 1);
      this.myDate = this.formatDate(customdate);
      let customdate2 = new Date();
      customdate2.setDate(todaydate.getDate() - 7);
      this.myFromDate = this.formatDate(customdate2);
    }
    else if(rangeval == 'custom'){
      this.myDate = "";
      this.myFromDate = "";

    }
  }

  // msgpublish() {
  //   if(sessionStorage.getItem('pubnubcred') != null) {
  //     let pubnubcred = JSON.parse(sessionStorage.getItem('pubnubcred'));
  //     this.pubnub.publish(
  //       {
  //         message: "Hello All",
  //         channel: pubnubcred.channel_list[0]
  //       }, 
  //       function (status, response) {
  //         if (status.error) {
  //           console.log(status);
  //         } else {
  //           console.log('message Published w/ timetoken', response.timetoken);
  //         }
  //       }
  //     );
  //   }
  // }

  formatTime = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours(),
      minutes = d.getMinutes();
      var strhour = ''+ hours;
      var strminute = '' + minutes;
      if(hours < 10){
        strhour = '0' + hours;
      }
      if(minutes < 10){
        strminute = '0' + minutes;
      }

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-')+'T'+strhour+":"+strminute;
  }

  formatTimeM = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hour = d.getHours(),
      minute = d.getMinutes();
    var time = "AM";
    if(hour >= 12) {
      time = "PM";
      if(hour > 12) {
        hour = hour - 12;
      }
    }
    var strhour = ''+ hour;
    if(hour == 0) {
      strhour = '12';
    }
    else if(hour < 10){
      strhour = '0' + hour;
    }
    var strminute = '' + minute;
    if(minute < 10){
      strminute = '0' + minute;
    }

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [month, day, year].join('/')+' '+strhour+":"+strminute+" "+time;
  }

  formatMonth = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month].join('-');
  }

  formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  formatDateM = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [month, day, year].join('/');
  }

  formatDateCustom = (date) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    const days = [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat'
    ]
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hour = d.getHours(),
      minute = d.getMinutes();
    
    var time = "AM";
    if(hour >= 12) {
      time = "PM";
      if(hour > 12) {
        hour = hour - 12;
      }
    }
    var strhour = ''+ hour;
    if(hour == 0) {
      strhour = '12';
    }
    else if(hour < 10){
      strhour = '0' + hour;
    }
    var strminute = '' + minute;
    if(minute < 10){
      strminute = '0' + minute;
    }

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    // return [year, month, day].join('-');
    return strhour+":"+strminute+" "+time+" "+days[d.getDay()]+" "+months[d.getMonth()]+" "+day+", "+year;
  }

  getGlobalConfig = () => {
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getglobalconfig', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      if (response.status === "success") {
        let globalConfig = JSON.parse(response.data);

        for(var temp of globalConfig){
          if (temp.config_key === 'twilio_number' && temp.config_value !== ''){
            this.isTwilioEnabled = true;
            this.twilioNumber = temp.config_value;
          }
        }

        console.log(globalConfig)
      }
    },
    async (error: any) => {
      await Auth.signOut({
        global: true
      });
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });
  }

  twillioRecordButton = true;
  roomName = "";

  callNumbers = [];
  // callNumbers = [{phone: "+555552222222", status: "Ring", callsid: "CAf6bac30172197afb0367cfbf2b13cb5c"}]

  twillioaddnumberadd = false;

  twillioaddnumber:string = "";

  twlioConfarencetimer: any;

  twlioSecondtimer: any;

  SecondCallSid:string = "";

  SecondCallStatus:string = "";

  twlioConfarenceCallStatusList = ["queued", "initiated", "ringing", "in-progress"];


  twiliocalllistAddbuttonClick = (val) => {
    console.log("twiliocalllistAddbuttonClick")
    console.log(val)

    this.twillioaddnumberadd = true;
    this.twillioaddnumber = "";

  }

  twiliocalllistAddNumberbuttonClick = (val) => {
    console.log("twiliocalllistAddNumberbuttonClick")
    console.log(val)

    let phone_numberPattern = /^[+]\d+$/;                ///// Checking for +Number /////
    if(!this.twillioaddnumber || typeof this.twillioaddnumber === 'undefined' || !this.twillioaddnumber.match(phone_numberPattern)){
      alert("Invalid Phone number.")
    }else{
      this.twillioaddnumberadd = false;
      let params = {
        roomName: this.roomName,
        fromNumber: this.twilioConstantNumber,
        numbers: [this.twillioaddnumber]
      };

      let tempNumber = this.twillioaddnumber;
      this.httpClient.post(this.twilioEndPoint + '/stage/twilio/conferenceCall', params, { headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) })
        .subscribe( async (response: any) => {
          console.log(response);
          var addNumber = response;
          console.log(addNumber['callSids'][0])

          this.callNumbers.push({phone: tempNumber.toString(), status: "", callsid: addNumber['callSids'][0]});
          
        },
        async (error: any) => {
          console.log(error);
        });
      this.twillioaddnumber = "";
    }

    

  }

  callConfarenceWatcher = async () => {
    this.twlioConfarencetimer = setInterval(async () => {
      // console.log("callConfarenceWatcher")

      if (this.callNumbers.length > 0){
        for(let callNumber of this.callNumbers){

          var tempcallNumber = callNumber;

          let index = this.callNumbers.indexOf(callNumber);

          const params = new HttpParams({
            fromObject: {
              callsid: callNumber.callsid
            }
          });


          let response: any = await this.httpClient.get(this.twilioEndPoint + '/stage/twilio/CallStatus', { params, headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) }).toPromise();

          console.log(response);
          console.log(response['callStatus']['status']);

          tempcallNumber["status"] = response['callStatus']['status'];

          this.callNumbers[index] = tempcallNumber;
    
          await this.delay(100);

          if(this.twlioConfarenceCallStatusList.indexOf(response['callStatus']['status']) == -1){
            this.callNumbers.splice(index, 1);
          }

        }
      }
      
    }, 2000);
  }

  secondcallWatcher = async () => {
    this.twlioSecondtimer = setInterval(async () => {
      console.log("secondcallWatcher")

      const params = new HttpParams({
        fromObject: {
          callsid: this.SecondCallSid
        }
      });

      let response: any = await this.httpClient.get(this.twilioEndPoint + '/stage/twilio/CallStatus', { params, headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) }).toPromise();

      console.log(response);
      console.log(response['callStatus']['status']);

      this.SecondCallStatus = response['callStatus']['status'];
      
    }, 1000);
  }

  twiliocalllistdeletebuttonClick = (val) => {
    console.log("twiliocalllistdeletebuttonClick")
    console.log(val)

    const index: number = this.callNumbers.indexOf(val);
    this.callNumbers.splice(index, 1);
    if (index !== -1) {
        
        let params = {
          roomName: this.roomName,
          entity: val.phone
        };
        this.httpClient.post(this.twilioEndPoint + '/stage/twilio/RemovePersonFromConference', params, { headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) })
          .subscribe( async (response: any) => {
            console.log(response);
            
          },
          async (error: any) => {
            console.log(error);
          });
    }
  }

  getSecondCallSid = () => {
    console.log("getSecondCallSid");
    const params = new HttpParams({
      fromObject: {
        roomName: this.roomName
      }
    });
    this.httpClient.get(this.twilioEndPoint + '/stage/twilio/SecondCallSid', { params, headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) })
    .subscribe((response: any) => { 
      console.log(response);
      this.SecondCallSid = response['callsid']
      this.secondcallWatcher();
    },
    async (error: any) => {
      console.log(error);
    });
  }

  patienttwiliocallButtonClick =  async (userDetails, smsno=false) => {
    console.log("twilio call Button Pressed")

    console.log(this.reusablesearchdata)
    // return

    this.roomName = "";

    this.callNumbers = [];
    
    let usercallingno = userDetails.phone_number;
    if(smsno) {
      usercallingno = userDetails.phoneNoSms;
    }
    this.patientphone = usercallingno;
    if (this.clickCallisDisabled == false) {
      console.log("twilio call")

      this.clickCallisDisabled = true;

      let patientNumber = usercallingno;    
      let practice = localStorage.getItem('userpull_id'); //user pull id
      let patientName = userDetails.sub; //patient sub
      let reason = "general";   
      let tt = new Date();
      console.log(tt.getFullYear());
      let roomName = tt.getFullYear().toString()+tt.getMonth().toString()+tt.getDate().toString()+tt.getHours().toString()+tt.getMinutes().toString()+tt.getSeconds().toString()+tt.getMilliseconds().toString()+'-'+patientNumber.replace('+','');
      this.roomName = roomName;
      console.log(roomName);
      
      var callbackUrl = this.twilioEndPoint + "/stage/twilio/callbackUrl?patientNumber=" + patientNumber +"&twilioNumber=" + this.twilioConstantNumber + "&fromNumber=" + this.twilioNumber + "&practice=" + practice + "&patientName=" + patientName + "&reason=" + reason + "&user_info="+localStorage.getItem('user_sub')+"&roomName=" + roomName +"&isreview=0";
      console.log(callbackUrl)

      let tokenParams = {
        twilioNumber: this.twilioConstantNumber,
        fromNumber: this.twilioNumber,
        patientNumber: patientNumber,
        callbackUrl: encodeURI(callbackUrl),
        roomName: roomName
      };

      this.httpClient.post(this.twilioEndPoint + '/stage/twilio/call/token3', tokenParams, { headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) })
      .subscribe( async (response: any) => {
        console.log("token call Sucess");
        console.log(response.token);
        
        let callDetailsModal = document.getElementById("call-details-ankerTag") as HTMLElement;
        callDetailsModal.click();

        var tokenparam = {
          fakeLocalDTMF: true,
          enableRingingState: true
          // sounds: {
          //   disconnect: ''
          // }
        };

        Twilio.Device.setup(response.token, tokenparam);
        this.endButtonShow = true;
        console.log("device setup");
        await this.delay(3000);
        console.log("call setup");
        var callParams = {
          // phoneNumber: this.twilioConstantNumber,
          phoneNumber: patientNumber,
          recording : true,
          practice: practice,
          patientName: patientName,
          reason : reason,
          roomName: roomName,
          isWeb: true,
          isreview: 0,
          fromNumber: this.twilioNumber,
          twilioNumber: this.twilioConstantNumber, //'+14083518666' // twilio number
          user_info: localStorage.getItem('user_sub'),
          user_group: this.userGroup,
          loction: "Dashboard",
          extra_param: JSON.stringify({subtype:this.reusablesearchdata.devicestatus, description: "" })
        };
        var outcall = Twilio.Device.connect(callParams);

        outcall.on('reject', function(call) {
          console.log(call);
        });

        outcall.on('ringing', function(call) {
          console.log(call);
        });

        outcall.on('accept', function(call) {
          console.log(call);
        });

        outcall.on('disconnect', function(call) {
          console.log(call);
        });

        await this.delay(3000);

        this.getSecondCallSid();

        this.callWatcher();

        this.callConfarenceWatcher();
        
      },
      async (error: any) => {
        console.log(error);
      });
    }

  }

  patienttwilioClickDigit = async (digit) => {
    console.log("digit pressed")
    console.log(digit)
    Twilio.Device.activeConnection().sendDigits(digit)
  }

  patienttwiliocallEndButtonClick = () => {
    console.log("call end Sucess");
    this.endButtonShow = false;
    this.phonecall = true;
    this.clickCallisDisabled = false;
    Twilio.Device.disconnectAll();

    clearInterval(this.twlioConfarencetimer);
    clearInterval(this.twlioSecondtimer);

    let patientCallModal = document.getElementById("patient-call-modal-close-button") as HTMLElement;
    patientCallModal.click();
  }

  callWatcher = () => {
    this.twliotimer = setInterval(async () => {
      console.log("hhhhhh")
      console.log(Twilio.Device.status())

      if (Twilio.Device.status() == 'busy'){
        console.log(Twilio.Device.activeConnection().status())
      }
      if (Twilio.Device.status() == 'ready'){
        console.log("call end by user");
        this.endButtonShow = false;
        this.phonecall = true;
        this.clickCallisDisabled = false;
        clearInterval(this.twliotimer);

        clearInterval(this.twlioConfarencetimer);
        clearInterval(this.twlioSecondtimer);

        let patientCallModal = document.getElementById("patient-call-modal-close-button") as HTMLElement;
        patientCallModal.click();
      }
    }, 600);
  }

  counter: any = {};

  getCounter = (span = 'today') => {
    let ddate = new Date();
    let fromdate = this.formatDate(ddate);
    let todate = this.formatDate(ddate);
    if(span == 'thisweek') {
      // let customdate = new Date();
      // customdate.setDate(ddate.getDate() - 1);
      // todate = this.formatDate(customdate);
      let customdate2 = new Date();
      customdate2.setDate(ddate.getDate() - ddate.getDay());
      fromdate = this.formatDate(customdate2);
    }
    if(span == 'lastweek') {
      let customdate = new Date();
      customdate.setDate(ddate.getDate() - ddate.getDay() - 1);
      todate = this.formatDate(customdate);
      let customdate2 = new Date();
      customdate2.setDate(ddate.getDate() - ddate.getDay() - 7);
      fromdate = this.formatDate(customdate2);
    }
    if(span == 'lastmonth') {
      let customdate = new Date();
      customdate.setDate(ddate.getDate() - 1);
      todate = this.formatDate(customdate);
      let customdate2 = new Date();
      customdate2.setDate(ddate.getDate() - 30);
      fromdate = this.formatDate(customdate2);
    }
    console.log(span+" = "+String(fromdate)+"<-->"+todate);
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
        fromdate: this.formatUTCDBDateTime(fromdate+" 00:00:00"),
        todate: this.formatUTCDBDateTime(todate+" 23:59:59"),
        span: span,
        token: uuidv4()
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getreportdatastatuscount', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      if(response.status === "success") {
        this.counter[response.span] = JSON.parse(response.data);
      }
    },
    async (error: any) => {
      console.log(error);
      if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
        var root = this;
        var callcounter = 0;
        setTimeout (function requestCallbackCount() {
          root.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getreportdatastatuscount2', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
          .subscribe((response: any) => {
            // root.screen.style.display = 'none';
            // console.log(response);
            if(response.status === "success") {
              root.counter[response.span] = JSON.parse(response.data);
            }
            else {
              callcounter++;
              if(callcounter < 120) {
                setTimeout (requestCallbackCount, 5000);
              }
            }
          },
          async (error: any) => {
            console.log(error);
          });
        }, 5000);
      }
    });
  }

  ngOnInit() {
    this.getCounter();
    this.getCounter('thisweek');
    this.getCounter('lastweek');
    this.getCounter('lastmonth');
    this.getGlobalConfig();
    this.body.style.overflow = 'auto';
    this.body.classList.add('skin-blue');
    this.body.classList.add('sidebar-mini');

    this.screen.removeAttribute('style');
    this.screencontent.innerHTML = "";
    this.screen.style.display = 'none';

    sessionStorage.setItem('currentComponent', 'dashboard');
    // this.patientBarchartData = [{ 'data': [], 'label': '' }];
    // this.patientLineChartData = [{ 'data': [], 'label': '', 'fill': false, 'lineTension': 0 }];
    // this.doctorBarchartData = [{ 'data': [], 'label': '' }];
    // this.doctorLinechartData = [{ 'data': [], 'label': '', 'fill': false, 'lineTension': 0 }];
    // this.downloadBarchartData = [{ 'data': [], 'label': '' }];

    this.languageArr = {};
    if(localStorage.getItem('LanguageList')) {
      let langList = JSON.parse(localStorage.getItem('LanguageList'));
      for(var j=0; j<langList.length; j++) {
        var langval = langList[j]['language_value'];
        if(langval.indexOf("(") > 0) {
          this.languageArr[langList[j]['language_code']] = langval.substring(0, langval.indexOf("(")-1);
        }
      }
    }
    console.log(this.languageArr);

    this.onOptionsSelected('current');
    this.eligibilitionOptionsSelected('current');
    this.getProviderPatientData(this);

    if(this.userGroup != 'Provider'){
      console.log("dashboardload");
      if (localStorage.getItem('gotoalertreport')) {
        var alertdate = localStorage.getItem('gotoalertreport');

        this.GotoAlertTrend(alertdate);
      }
    }
  }

  GotoAlertTrend = (alertdate) => {

    this.rangeval = "custom";

    this.myDate = this.formatDate(alertdate);
    this.myFromDate = this.formatDate(alertdate);

    this.screen.style.display = 'block';

    let alertreport = document.getElementById("headingcollapseOne") as HTMLElement;
    alertreport.click(); 

    localStorage.setItem('gotoalertreport', "");

    var searchdata = {
      rangeselect: "custom",
      fromdate: alertdate,
      todate: alertdate,
      devicestatus: "alert"
    };

    this.validateSearchForm(searchdata);
  }

  last_valid_selection: any;

  onCycleSelected = (cycleval) =>{
    console.log(cycleval);
    // console.log(this.last_valid_selection);
    console.log(this.batchcycle);
    // if(cycleval.batchcycle.length > 10) {
      // cycleval.batchcycle = this.last_valid_selection;
      // this.batchcycle = this.last_valid_selection;
      // return false;
    // }
    // else {
      this.cycleOpenBilledStatus = 0;
      if(cycleval.cyclestatus == 'open' && cycleval.batchcycle.length > 0) {
        this.cycleOpenBilledStatus = 1;
      }
      else if(cycleval.cyclestatus == 'billed' && cycleval.batchcycle.length > 0) {
        this.cycleOpenBilledStatus = 2;
      }
      // this.last_valid_selection = cycleval.batchcycle;
    // }
  }

  eligibilitionCycleSelected = (cycleval) =>{
    console.log(cycleval);
    // console.log(this.last_valid_selection);
    console.log(this.batchcycle);
    // if(cycleval.batchcycle.length > 10) {
      // cycleval.batchcycle = this.last_valid_selection;
      // this.batchcycle = this.last_valid_selection;
      // return false;
    // }
    // else {
      this.eligibiliticycleOpenBilledStatus = 0;
      if(cycleval.cyclestatus == 'open' && cycleval.batchcycle.length > 0) {
        this.eligibiliticycleOpenBilledStatus = 1;
      }
      else if(cycleval.cyclestatus == 'billed' && cycleval.batchcycle.length > 0) {
        this.eligibiliticycleOpenBilledStatus = 2;
      }
      // this.last_valid_selection = cycleval.batchcycle;
    // }
  }

  onOptionsSelected = (selval) =>{
    var billdatearr = ["billtoday", "billtomorrow", "bill2days", "bill3days", "bill4days", "bill5days", "bill7days", "bill10days", "bill15days"]
    console.log(selval);
    if(selval == 'open' || selval == 'billed' || billdatearr.indexOf(selval) > -1){
      this.searchbatchcycle(selval);
    }
    else {
      this.searchbatchcycle('current');
    }
  }

  eligibilitionOptionsSelected = (selval) =>{
    if(selval == 'open' || selval == 'billed'){
      this.eligibilitisearchbatchcycle(selval);
    }
    else {
      this.eligibilitisearchbatchcycle('current');
    }
  }

  processBillingCycles = (cycledetails) =>{
    console.log(cycledetails);
    if(cycledetails.cyclestatus == "open" && (cycledetails.closedate == undefined || cycledetails.closedate == "")) {
      alert("Please provide Close Date");
    }
    else {
      if(cycledetails.closedate == undefined || cycledetails.closedate == ""){
        cycledetails.closedate = this.myCloseDate;
      }
      cycledetails.batchcyclestr = cycledetails.batchcycle.join(",")
      this.screencontent.innerHTML = "";
      this.screen.style.display = 'block';
      let params = {
        userpull_id: localStorage.getItem('userpull_id'),
        cyclestatus: cycledetails.cyclestatus,
        batchcycle: cycledetails.batchcyclestr,
        openclosedate: cycledetails.closedate
      };
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/processbillingcycles', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response);
        this.screen.style.display = 'none';
        if(response.status === "success") {
        }
      },
      async (error: any) => {
        // this.screen.style.display = 'none';
        console.log(error);
        this.screencontent.innerHTML = "";
        if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
          alert("Request timeout. Please try again.");
          this.screen.style.display = 'none';
        }
        else {
          await Auth.signOut({
            global: true
          });
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.router.navigateByUrl('/sign-in');
        }
      });
    }
  }

  searchbatchcycle = (cyclestatus) =>{
    this.cycleOpenBilledStatus = 0;
    this.batching = true;
    this.batchcyclelist = [];
    this.batchcycle = undefined;
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
        cycle_status: cyclestatus
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/searchbatchcycle', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      if (response.status === "success") {
        this.batching = false;
        let responsedata = JSON.parse(response.data);
        for (var i = 0; i < responsedata.length; i++) {
          responsedata[i]['StartDateStr'] = "";
          responsedata[i]['EndDateStr'] = "";
          if(responsedata[i]['StartDate']){
            responsedata[i]['StartDateStr'] = this.formatDateM(responsedata[i]['StartDate'].replaceAll('-','/'));
          }
          if(responsedata[i]['EndDate']) {
            responsedata[i]['EndDateStr'] = this.formatDateM(responsedata[i]['EndDate'].replaceAll('-','/'));
          }
        }
        console.log(responsedata);
        this.batchcyclelist = responsedata;
      }
    },
    async (error: any) => {
      // this.screen.style.display = 'none';
      console.log(error);
      this.screencontent.innerHTML = "";
      if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
        alert("Request timeout. Please try again.");
        this.screen.style.display = 'none';
      }
      else {
        // await Auth.signOut({
        //   global: true
        // });
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        this.router.navigateByUrl('/sign-in');
      }
    });
  }

  eligibilitisearchbatchcycle = (cyclestatus) =>{
    this.eligibiliticycleOpenBilledStatus = 0;
    this.eligibilitibatching = true;
    this.eligibitibatchcyclelist = [];
    this.eligibitibatchcycle = undefined;
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
        cycle_status: cyclestatus
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/searchbatchcycle', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      if (response.status === "success") {
        this.eligibilitibatching = false;
        let responsedata = JSON.parse(response.data);
        for (var i = 0; i < responsedata.length; i++) {
          responsedata[i]['StartDateStr'] = "";
          responsedata[i]['EndDateStr'] = "";
          if(responsedata[i]['StartDate']){
            responsedata[i]['StartDateStr'] = this.formatDateM(responsedata[i]['StartDate'].replaceAll('-','/'));
          }
          if(responsedata[i]['EndDate']) {
            responsedata[i]['EndDateStr'] = this.formatDateM(responsedata[i]['EndDate'].replaceAll('-','/'));
          }
        }
        console.log(responsedata);
        this.eligibitibatchcyclelist = responsedata;
      }
    },
    async (error: any) => {
      // this.screen.style.display = 'none';
      console.log(error);
      this.screencontent.innerHTML = "";
      if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
        alert("Request timeout. Please try again.");
        this.screen.style.display = 'none';
      }
      else {
        // await Auth.signOut({
        //   global: true
        // });
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        this.router.navigateByUrl('/sign-in');
      }
    });
  }

  NewBillingisshowselectAll = 0;
  NewBillingtotalselect = 0;
  NewBillingselectAllisselect = 0;

  NewBillingSelectChange = (item, isChecked) => {
    console.log("NewBillingSelectChange");
    console.log(item);
    console.log(isChecked);

    if (isChecked) {
      let index = this.newbillingdataList.indexOf(item);
      item["checked"] = true;
      this.newbillingdataList[index] = item;

      this.NewBillingtotalselect = this.NewBillingtotalselect + 1;
    } else {
      let index = this.newbillingdataList.indexOf(item);
      item["checked"] = false;
      this.newbillingdataList[index] = item;

      this.NewBillingtotalselect = this.NewBillingtotalselect - 1;
    }

    if (this.NewBillingtotalselect == this.newbillingdataList.length){
      this.NewBillingselectAllisselect = 1;
    }else{
      this.NewBillingselectAllisselect = 0;
    }

    
  }

  NewBillingSelectAllChange = (isChecked) => {
    console.log("NewBillingSelectAllChange");
    console.log(isChecked);

    if (isChecked) {
      for(var temp of this.newbillingdataList){
        let index = this.newbillingdataList.indexOf(temp);
        temp["checked"] = true;
        this.newbillingdataList[index] = temp;
      }
      this.NewBillingtotalselect = this.newbillingdataList.length;
      this.NewBillingselectAllisselect = 1;
    }else{
      for(var temp of this.newbillingdataList){
        let index = this.newbillingdataList.indexOf(temp);
        temp["checked"] = false;
        this.newbillingdataList[index] = temp;
      }
      this.NewBillingtotalselect = 0;
      this.NewBillingselectAllisselect = 0;
    }
  }

  validateNewBillingForm = ( newBillingData ) => {
    console.log(newBillingData);
    this.NewBillingisshowselectAll = 0;
    if(!newBillingData.cyclestatus) {
      newBillingData.cyclestatus = "current";
    }
    if(!newBillingData.batchcycle || (newBillingData.batchcycle && newBillingData.batchcycle == '')) {
      newBillingData.batchcyclestr = "all";
    }
    else{
      newBillingData.batchcyclestr = newBillingData.batchcycle.join(",")
    }
    console.log(newBillingData);
    
    this.screencontent.innerHTML = "";
    this.screen.style.display = 'block';
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
        cyclestatus: newBillingData.cyclestatus,
        batchcycle: newBillingData.batchcyclestr,
        token: uuidv4()
      }
    });
    if(newBillingData.filterval){
      params = new HttpParams({
        fromObject: {
          userpull_id: localStorage.getItem('userpull_id'),
          cyclestatus: newBillingData.cyclestatus,
          batchcycle: newBillingData.batchcyclestr,
          filterval: newBillingData.filterval,
          token: uuidv4()
        }
      });
    }
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/batchcyclepatients', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      this.screen.style.display = 'none';
      if(response.status === "success") {
        let responsedata = JSON.parse(response.data);
        console.log(responsedata);
        if(responsedata.length > 0) {
          this.NewBillingisshowselectAll = 1;
          for (var i = 0; i < responsedata.length; i++) {
            responsedata[i]['patient_sub_btoa'] = btoa(responsedata[i]['PatientSub']);
            responsedata[i]['sub'] = responsedata[i]['PatientSub'];
            responsedata[i]['birthdate'] = responsedata[i]['DOB'];
            responsedata[i]['firstName'] = responsedata[i]['FName'];
            responsedata[i]['lastName'] = responsedata[i]['LName'];
            if(responsedata[i]['reporting_provider'] != null) {
              var temp_reporting_provider = "";
              let obj = this.providerlist.find(o => o.sub === responsedata[i]['reporting_provider']);
              if(obj){
                temp_reporting_provider = obj['name'];
              }
              responsedata[i]['reporting_provider_name'] = temp_reporting_provider;
            }
            if(this.has_facility){
              if(responsedata[i]['facility_id'] != null) {
                var temp_facility_name = "";
                let obj = this.facility_arr.find(o => o.idfacility === responsedata[i]['facility_id']);
                if(obj){
                  temp_facility_name = obj['facilitytext'];
                }
                responsedata[i]['facility_name'] = temp_facility_name;
              }
            }
            if(responsedata[i]['BillingBatchDate'] != null) {
              responsedata[i]['BillingBatchDateStr'] = this.formatDateM(responsedata[i]['BillingBatchDate'].replaceAll('-','/'));
            }
            if(responsedata[i]['CycleStartDate'] != null) {
              responsedata[i]['CycleStartDateStr'] = this.formatDateM(responsedata[i]['CycleStartDate'].replaceAll('-','/'));
              responsedata[i]['CycleStartDateStrDB'] = this.formatDate(responsedata[i]['CycleStartDate']);
            }
            if(responsedata[i]['CycleEndDate'] != null) {
              responsedata[i]['CycleEndDateStr'] = this.formatDateM(responsedata[i]['CycleEndDate'].replaceAll('-','/'));
              responsedata[i]['CycleEndDateStrDB'] = this.formatDate(responsedata[i]['CycleEndDate']);
            }
            if(responsedata[i]['eduDate'] && responsedata[i]['eduDate'] != "0000-00-00 00:00:00") {
              responsedata[i]['eduDateStr'] = this.formatDateM(responsedata[i]['eduDate'].replaceAll('-','/') + " UTC");
            }
            else {
              responsedata[i]['eduDateStr'] = "";
            }
            if(responsedata[i]['DOB'] && responsedata[i]['DOB'] != "0000-00-00") {
              responsedata[i]['DOBstr'] = this.formatDateM(responsedata[i]['DOB'].replaceAll('-','/'));
              responsedata[i]['masked_DOBstr'] = this.make_number_mask(this.formatDateM(responsedata[i]['DOB'].replaceAll('-','/') + " 00:00:00").toString(),4);
            }
            else{
              responsedata[i]['DOBstr'] = "";
            }
            if(responsedata[i]['DTB'] && responsedata[i]['DTB'] != "0000-00-00 00:00:00") {
              responsedata[i]['DTBstr'] = this.formatDateM(responsedata[i]['DTB'].replaceAll('-','/'));
            }
            else{
              responsedata[i]['DTBstr'] = "";
            }
            if(responsedata[i]['DTE'] && responsedata[i]['DTE'] != "0000-00-00 00:00:00") {
              responsedata[i]['DTEstr'] = this.formatDateM(responsedata[i]['DTE'].replaceAll('-','/'));
            }
            else{
              responsedata[i]['DTEstr'] = "";
            }
            if(responsedata[i]['BD'] && responsedata[i]['BD'] != "0000-00-00" && responsedata[i]['BD'] != null) {
              responsedata[i]['BDstr'] = this.formatDateM(responsedata[i]['BD'].replaceAll('-','/'));
            }
            else{
              responsedata[i]['BDstr'] = "";
            }
            responsedata[i]['devicestr'] = "";
            if(responsedata[i]['devices']){
              for(var j=0; j<responsedata[i]['devices'].length; j++){
                if(responsedata[i]['devicestr'] != ''){
                  responsedata[i]['devicestr'] = responsedata[i]['devicestr']+", "+responsedata[i]['devices'][j]['device'];
                  if(responsedata[i]['devices'][j]['deviceid'] && responsedata[i]['devices'][j]['deviceid'] != ''){
                    responsedata[i]['devicestr'] = responsedata[i]['devicestr']+" (IoT)";
                  }
                }
                else {
                  responsedata[i]['devicestr'] = responsedata[i]['devices'][j]['device'];
                  if(responsedata[i]['devices'][j]['deviceid'] && responsedata[i]['devices'][j]['deviceid'] != ''){
                    responsedata[i]['devicestr'] = responsedata[i]['devicestr']+" (IoT)";
                  }
                }
              }
            }
            responsedata[i]['PI']="";
            responsedata[i]['PI_CODE']="";
            responsedata[i]['eCW_PI_CODE']="";
            responsedata[i]['PI_ID']="";
            responsedata[i]['PI_DR']="";
            responsedata[i]['PI_DRFlag']="";
            responsedata[i]['PI_Type']="";
            responsedata[i]['PI_STATUS']="";
            responsedata[i]['SI']="";
            responsedata[i]['SI_CODE']="";
            responsedata[i]['eCW_SI_CODE']="";
            responsedata[i]['SI_ID']="";
            responsedata[i]['SI_STATUS']="";
            responsedata[i]['PI_Pdfurl']="";
            responsedata[i]['SI_Pdfurl']="";
            responsedata[i]['PI_R_Pdfurl']="";
            responsedata[i]['SI_R_Pdfurl']="";
            if(responsedata[i]['eligibility']) {
              for(var el=0; el<responsedata[i]['eligibility'].length; el++) {
                if(responsedata[i]['eligibility'][el]['type'] == "Primary"){
                  responsedata[i]['PI_Pdfurl']=responsedata[i]['eligibility'][el]['pdfurl'];
                  responsedata[i]['PI_R_Pdfurl']=responsedata[i]['eligibility'][el]['recursive_pdfurl'];
                  responsedata[i]['PI']=responsedata[i]['eligibility'][el]['payername'];
                  responsedata[i]['primary_payername']=responsedata[i]['eligibility'][el]['payername'];
                  responsedata[i]['PI_CODE']=responsedata[i]['eligibility'][el]['payercode'];
                  responsedata[i]['primary_payercode']=responsedata[i]['eligibility'][el]['payercode'];
                  if(responsedata[i]['eligibility'][el]['ecwpayer'] != '') {
                    responsedata[i]['eCW_PI_CODE']=responsedata[i]['eligibility'][el]['ecwpayer'];
                  }
                  else {
                    responsedata[i]['eCW_PI_CODE']="xxxxxx (Manual populate)";
                  }
                  responsedata[i]['PI_ID']=responsedata[i]['eligibility'][el]['memberid'];
                  responsedata[i]['primary_memberid']=responsedata[i]['eligibility'][el]['memberid'];
                  if(responsedata[i]['eligibility'][el]['data']) {
                    var eldata = JSON.parse(responsedata[i]['eligibility'][el]['data']);
                    if(eldata['remainingamount'] && eldata['remainingamount'] != ""){
                      responsedata[i]['PI_DR']=String(eldata['remainingamount']);
                      if(parseFloat(String(eldata['remainingamount'])) > 0) {
                        responsedata[i]['PI_DRFlag']="1";
                      }
                    }
                    if(eldata['partb'] && eldata['partb'] == 'Active') {
                      responsedata[i]['PI_Type']="PartB";
                    }
                    if(eldata['ppo'] && eldata['ppo'] == 'Active') {
                      if(responsedata[i]['PI_Type']=="") {
                        responsedata[i]['PI_Type']="PPO";
                      }
                      else {
                        responsedata[i]['PI_Type']=responsedata[i]['PI_Type'] + " / PPO";
                      }
                    }
                    if(eldata['hmp'] && eldata['hmo'] == 'Active') {
                      if(responsedata[i]['PI_Type']=="") {
                        responsedata[i]['PI_Type']="HMO";
                      }
                      else {
                        responsedata[i]['PI_Type']=responsedata[i]['PI_Type'] + " / HMO";
                      }
                    }
                    if(eldata['msp'] && eldata['msp'] == 'Active') {
                      if(responsedata[i]['PI_Type']=="") {
                        responsedata[i]['PI_Type']="MSP";
                      }
                      else {
                        responsedata[i]['PI_Type']=responsedata[i]['PI_Type'] + " / MSP";
                      }
                    }
                    if(eldata['status'] && eldata['status'] != ""){
                      responsedata[i]['PI_STATUS']=String(eldata['status']);
                    }
                    if(eldata['rundate'] && eldata['rundate'] != '') {
                      responsedata[i]['PI_Rundate']=String(eldata['rundate']);
                    }
                  }
                }
                if(responsedata[i]['eligibility'][el]['type'] == "Secondary"){
                  responsedata[i]['SI_Pdfurl']=responsedata[i]['eligibility'][el]['pdfurl'];
                  responsedata[i]['SI_R_Pdfurl']=responsedata[i]['eligibility'][el]['recursive_pdfurl'];
                  responsedata[i]['SI']=responsedata[i]['eligibility'][el]['payername'];
                  responsedata[i]['secondary_payername']=responsedata[i]['eligibility'][el]['payername'];
                  responsedata[i]['SI_CODE']=responsedata[i]['eligibility'][el]['payercode'];
                  responsedata[i]['secondary_payercode']=responsedata[i]['eligibility'][el]['payercode'];
                  if(responsedata[i]['eligibility'][el]['ecwpayer'] != '') {
                    responsedata[i]['eCW_SI_CODE']=responsedata[i]['eligibility'][el]['ecwpayer'];
                  }
                  else {
                    responsedata[i]['eCW_SI_CODE']="xxxxxx (Manual populate)";
                  }
                  responsedata[i]['SI_ID']=responsedata[i]['eligibility'][el]['memberid'];
                  responsedata[i]['secondary_memberid']=responsedata[i]['eligibility'][el]['memberid'];
                  if(responsedata[i]['eligibility'][el]['data']) {
                    var eldata = JSON.parse(responsedata[i]['eligibility'][el]['data']);
                    if(eldata['status'] && eldata['status'] != ""){
                      responsedata[i]['SI_STATUS']=String(eldata['status']);;
                    }
                  }
                }
              }
            }

            responsedata[i]['checked'] = false;
          }
          this.newbillingdataList = responsedata;
          this.newbillingdataListLength = responsedata.length;
          this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            if(dtElement.dtInstance) {
            dtElement.dtInstance.then((dtInstance: any) => {
              if(dtInstance.table().node().id == "sixth-table") {
                dtInstance.destroy();
                this.dtTrigger5.next();
              }
            });
            }
            else {
              this.dtTrigger5.next();
            }
          });
        }
        else {
          this.newbillingdataList = responsedata;
          this.newbillingdataListLength = responsedata.length;
          this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            if(dtElement.dtInstance) {
            dtElement.dtInstance.then((dtInstance: any) => {
              if(dtInstance.table().node().id == "sixth-table") {
                dtInstance.destroy();
                this.dtTrigger5.next();
              }
            });
            }
            else {
              this.dtTrigger5.next();
            }
          });
        }
      }
    },
    async (error: any) => {
      // this.screen.style.display = 'none';
      console.log(error);
      this.screencontent.innerHTML = "";
      if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
        // alert("Request timeout. Please try again.");
        // this.screen.style.display = 'none';
        var root = this;
        var callcounter = 0;
        setTimeout (function requestCallback() {
          root.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/batchcyclepatients2', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
          .subscribe((response: any) => {
            console.log(response);
            if(response.status === "success") {
              root.screen.style.display = 'none';
              let responsedata = JSON.parse(response.data);
              console.log(responsedata);
              if(responsedata.length > 0) {
                root.NewBillingisshowselectAll = 1;
                for (var i = 0; i < responsedata.length; i++) {
                  responsedata[i]['patient_sub_btoa'] = btoa(responsedata[i]['PatientSub']);
                  responsedata[i]['birthdate'] = responsedata[i]['DOB'];
                  responsedata[i]['sub'] = responsedata[i]['PatientSub'];
                  responsedata[i]['firstName'] = responsedata[i]['FName'];
                  responsedata[i]['lastName'] = responsedata[i]['LName'];
                  if(responsedata[i]['BillingBatchDate'] != null) {
                    responsedata[i]['BillingBatchDateStr'] = root.formatDateM(responsedata[i]['BillingBatchDate'].replaceAll('-','/'));
                  }
                  if(responsedata[i]['CycleStartDate'] != null) {
                    responsedata[i]['CycleStartDateStr'] = root.formatDateM(responsedata[i]['CycleStartDate'].replaceAll('-','/'));
                    responsedata[i]['CycleStartDateStrDB'] = root.formatDate(responsedata[i]['CycleStartDate']);
                  }
                  if(responsedata[i]['CycleEndDate'] != null) {
                    responsedata[i]['CycleEndDateStr'] = root.formatDateM(responsedata[i]['CycleEndDate'].replaceAll('-','/'));
                    responsedata[i]['CycleEndDateStrDB'] = root.formatDate(responsedata[i]['CycleEndDate']);
                  }
                  if(responsedata[i]['eduDate'] && responsedata[i]['eduDate'] != "0000-00-00 00:00:00") {
                    responsedata[i]['eduDateStr'] = root.formatDateM(responsedata[i]['eduDate'].replaceAll('-','/') + " UTC");
                  }
                  else {
                    responsedata[i]['eduDateStr'] = "";
                  }
                  if(responsedata[i]['DOB'] && responsedata[i]['DOB'] != "0000-00-00") {
                    responsedata[i]['DOBstr'] = root.formatDateM(responsedata[i]['DOB'].replaceAll('-','/'));
                    responsedata[i]['masked_DOBstr'] = root.make_number_mask(root.formatDateM(responsedata[i]['DOB'].replaceAll('-','/') + " 00:00:00").toString(),4);
                  }
                  else{
                    responsedata[i]['DOBstr'] = "";
                  }
                  if(responsedata[i]['DTB'] && responsedata[i]['DTB'] != "0000-00-00 00:00:00") {
                    responsedata[i]['DTBstr'] = root.formatDateM(responsedata[i]['DTB'].replaceAll('-','/'));
                  }
                  else{
                    responsedata[i]['DTBstr'] = "";
                  }
                  if(responsedata[i]['DTE'] && responsedata[i]['DTE'] != "0000-00-00 00:00:00") {
                    responsedata[i]['DTEstr'] = root.formatDateM(responsedata[i]['DTE'].replaceAll('-','/'));
                  }
                  else{
                    responsedata[i]['DTEstr'] = "";
                  }
                  if(responsedata[i]['BD'] && responsedata[i]['BD'] != "0000-00-00" && responsedata[i]['BD'] != null) {
                    responsedata[i]['BDstr'] = root.formatDateM(responsedata[i]['BD'].replaceAll('-','/'));
                  }
                  else{
                    responsedata[i]['BDstr'] = "";
                  }
                  responsedata[i]['devicestr'] = "";
                  if(responsedata[i]['devices']){
                    for(var j=0; j<responsedata[i]['devices'].length; j++){
                      if(responsedata[i]['devicestr'] != ''){
                        responsedata[i]['devicestr'] = responsedata[i]['devicestr']+", "+responsedata[i]['devices'][j]['device'];
                        if(responsedata[i]['devices'][j]['deviceid'] && responsedata[i]['devices'][j]['deviceid'] != ''){
                          responsedata[i]['devicestr'] = responsedata[i]['devicestr']+" (IoT)";
                        }
                      }
                      else {
                        responsedata[i]['devicestr'] = responsedata[i]['devices'][j]['device'];
                        if(responsedata[i]['devices'][j]['deviceid'] && responsedata[i]['devices'][j]['deviceid'] != ''){
                          responsedata[i]['devicestr'] = responsedata[i]['devicestr']+" (IoT)";
                        }
                      }
                    }
                  }
                  responsedata[i]['PI']="";
                  responsedata[i]['PI_CODE']="";
                  responsedata[i]['eCW_PI_CODE']="";
                  responsedata[i]['PI_ID']="";
                  responsedata[i]['PI_DR']="";
                  responsedata[i]['PI_DRFlag']="";
                  responsedata[i]['PI_Type']="";
                  responsedata[i]['PI_STATUS']="";
                  responsedata[i]['SI']="";
                  responsedata[i]['SI_CODE']="";
                  responsedata[i]['eCW_SI_CODE']="";
                  responsedata[i]['SI_ID']="";
                  responsedata[i]['SI_STATUS']="";
                  responsedata[i]['PI_Pdfurl']="";
                  responsedata[i]['SI_Pdfurl']="";
                  responsedata[i]['PI_R_Pdfurl']="";
                  responsedata[i]['SI_R_Pdfurl']="";
                  if(responsedata[i]['eligibility']) {
                    for(var el=0; el<responsedata[i]['eligibility'].length; el++) {
                      if(responsedata[i]['eligibility'][el]['type'] == "Primary"){
                        responsedata[i]['PI_Pdfurl']=responsedata[i]['eligibility'][el]['pdfurl'];
                        responsedata[i]['PI_R_Pdfurl']=responsedata[i]['eligibility'][el]['recursive_pdfurl'];
                        responsedata[i]['PI']=responsedata[i]['eligibility'][el]['payername'];
                        responsedata[i]['primary_payername']=responsedata[i]['eligibility'][el]['payername'];
                        responsedata[i]['PI_CODE']=responsedata[i]['eligibility'][el]['payercode'];
                        responsedata[i]['primary_payercode']=responsedata[i]['eligibility'][el]['payercode'];
                        if(responsedata[i]['eligibility'][el]['ecwpayer'] != '') {
                          responsedata[i]['eCW_PI_CODE']=responsedata[i]['eligibility'][el]['ecwpayer'];
                        }
                        else {
                          responsedata[i]['eCW_PI_CODE']="xxxxxx (Manual populate)";
                        }
                        responsedata[i]['PI_ID']=responsedata[i]['eligibility'][el]['memberid'];
                        responsedata[i]['primary_memberid']=responsedata[i]['eligibility'][el]['memberid'];
                        if(responsedata[i]['eligibility'][el]['data']) {
                          var eldata = JSON.parse(responsedata[i]['eligibility'][el]['data']);
                          if(eldata['remainingamount'] && eldata['remainingamount'] != ""){
                            responsedata[i]['PI_DR']=String(eldata['remainingamount']);
                            if(parseFloat(String(eldata['remainingamount'])) > 0) {
                              responsedata[i]['PI_DRFlag']="1";
                            }
                          }
                          if(eldata['partb'] && eldata['partb'] == 'Active') {
                            responsedata[i]['PI_Type']="PartB";
                          }
                          if(eldata['ppo'] && eldata['ppo'] == 'Active') {
                            if(responsedata[i]['PI_Type']=="") {
                              responsedata[i]['PI_Type']="PPO";
                            }
                            else {
                              responsedata[i]['PI_Type']=responsedata[i]['PI_Type'] + ", PPO";
                            }
                          }
                          if(eldata['hmp'] && eldata['hmo'] == 'Active') {
                            if(responsedata[i]['PI_Type']=="") {
                              responsedata[i]['PI_Type']="HMO";
                            }
                            else {
                              responsedata[i]['PI_Type']=responsedata[i]['PI_Type'] + ", HMO";
                            }
                          }
                          if(eldata['msp'] && eldata['msp'] == 'Active') {
                            if(responsedata[i]['PI_Type']=="") {
                              responsedata[i]['PI_Type']="MSP";
                            }
                            else {
                              responsedata[i]['PI_Type']=responsedata[i]['PI_Type'] + ", MSP";
                            }
                          }
                          if(eldata['status'] && eldata['status'] != ""){
                            responsedata[i]['PI_STATUS']=String(eldata['status']);;
                          }
                        }
                      }
                      if(responsedata[i]['eligibility'][el]['type'] == "Secondary"){
                        responsedata[i]['SI_Pdfurl']=responsedata[i]['eligibility'][el]['pdfurl'];
                        responsedata[i]['SI_R_Pdfurl']=responsedata[i]['eligibility'][el]['recursive_pdfurl'];
                        responsedata[i]['SI']=responsedata[i]['eligibility'][el]['payername'];
                        responsedata[i]['secondary_payername']=responsedata[i]['eligibility'][el]['payername'];
                        responsedata[i]['SI_CODE']=responsedata[i]['eligibility'][el]['payercode'];
                        responsedata[i]['secondary_payercode']=responsedata[i]['eligibility'][el]['payercode'];
                        if(responsedata[i]['eligibility'][el]['ecwpayer'] != '') {
                          responsedata[i]['eCW_SI_CODE']=responsedata[i]['eligibility'][el]['ecwpayer'];
                        }
                        else {
                          responsedata[i]['eCW_SI_CODE']="xxxxxx (Manual populate)";
                        }
                        responsedata[i]['SI_ID']=responsedata[i]['eligibility'][el]['memberid'];
                        responsedata[i]['secondary_memberid']=responsedata[i]['eligibility'][el]['memberid'];
                        if(responsedata[i]['eligibility'][el]['data']) {
                          var eldata = JSON.parse(responsedata[i]['eligibility'][el]['data']);
                          if(eldata['status'] && eldata['status'] != ""){
                            responsedata[i]['SI_STATUS']=String(eldata['status']);;
                          }
                        }
                      }
                    }
                  }

                  responsedata[i]['checked'] = false;
                }
                root.newbillingdataList = responsedata;
                root.newbillingdataListLength = responsedata.length;
                root.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                  if(dtElement.dtInstance) {
                  dtElement.dtInstance.then((dtInstance: any) => {
                    if(dtInstance.table().node().id == "sixth-table") {
                      dtInstance.destroy();
                      root.dtTrigger5.next();
                    }
                  });
                  }
                  else {
                    root.dtTrigger5.next();
                  }
                });
              }
              else {
                root.newbillingdataList = responsedata;
                root.newbillingdataListLength = responsedata.length;
                root.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                  if(dtElement.dtInstance) {
                  dtElement.dtInstance.then((dtInstance: any) => {
                    if(dtInstance.table().node().id == "sixth-table") {
                      dtInstance.destroy();
                      root.dtTrigger5.next();
                    }
                  });
                  }
                  else {
                    root.dtTrigger5.next();
                  }
                });
              }
            }
            else {
              callcounter++;
              if(callcounter < 50) {
                setTimeout (requestCallback, 5000);
              }
              else {
                alert("Request timeout. Please try again.");
                root.screen.style.display = 'none';
              }
            }
          },
          async (error: any) => {
            console.log(error);
          });
        }, 5000);
      }
      else {
        await Auth.signOut({
          global: true
        });
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        this.router.navigateByUrl('/sign-in');
      }
    });
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
    this.dtTrigger1.unsubscribe();
    this.dtTrigger2.unsubscribe();
    this.dtTrigger3.unsubscribe();
    this.dtTrigger4.unsubscribe();
    this.dtTrigger5.unsubscribe();
    this.dtTrigger7.unsubscribe();
    this.dtTrigger8.unsubscribe();
    this.dtTrigger9.unsubscribe();
    this.dtTrigger10.unsubscribe();
    this.dtTrigger11.unsubscribe();
    this.dtTrigger12.unsubscribe();
    this.dtTrigger13.unsubscribe();
  }

  gotoPatientDetail = (deviceData) =>{
    console.log(deviceData);
    if(sessionStorage.getItem('intervention_arr')) {
      sessionStorage.removeItem("intervention_arr");
    }

    if(sessionStorage.getItem('reviewTransmissionEsclation')) {
      sessionStorage.removeItem("reviewTransmissionEsclation");
    }
    if(sessionStorage.getItem('reviewTransmissionData')) {
      sessionStorage.removeItem("reviewTransmissionData");
    }

    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
        iddevice_data_transmission: deviceData['iddevice_data_transmission']
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getdeviceescalation', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {

      this.screen.style.display = 'none';   

      if (response.status === "success") {
        let responsedata = JSON.parse(response.data);
        if (responsedata.length > 0 && responsedata[0]['out_of_range_alert'] == 1 && responsedata[0]['is_escalation'] == 1 ){
          var tempescalationList = responsedata[0]['escalation'];

          sessionStorage.setItem("reviewTransmissionEsclation", JSON.stringify(tempescalationList));

        }
      }

      sessionStorage.setItem("reviewTransmissionData", JSON.stringify(deviceData));

      if(deviceData['ext_alert'] == '1') {
        this.screencontent.innerHTML = "";
        this.screen.style.display = 'block';
        let params = new HttpParams({
          fromObject: {
            userpull_id: localStorage.getItem('userpull_id')
          }
        });
        this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getintervention', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          this.screen.style.display = 'none';
          if (response.status === "success") {
            let responsedata = JSON.parse(response.data);
            var interventionfullarr = responsedata[deviceData['device_name'].trim()];
            sessionStorage.setItem("intervention_arr", JSON.stringify(interventionfullarr));
          }
          if(deviceData['iddevice_data_transmission']) {
            sessionStorage.setItem("reviewTransmission", deviceData['iddevice_data_transmission']);
          }
          sessionStorage.setItem("reviewTransmissionSub", deviceData['sub']);
          //this.router.navigateByUrl('/patient-detail/'+deviceData.sub);
          // window.open('/patient-detail/'+btoa(deviceData.sub), 'PatientProfile');
          window.open('/patient-detail/'+btoa(deviceData.sub));
        },
        async (error: any) => {
          await Auth.signOut({
            global: true
          });
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          //DashboardClass.processing.style.display = 'none';
          this.screen.style.display = 'none';
          this.router.navigateByUrl('/sign-in');
        });
      }
      else {
        if(deviceData['iddevice_data_transmission']) {
          sessionStorage.setItem("reviewTransmission", deviceData['iddevice_data_transmission']);
        }
        sessionStorage.setItem("reviewTransmissionSub", deviceData['sub']);
        //this.router.navigateByUrl('/patient-detail/'+deviceData.sub);
        // window.open('/patient-detail/'+btoa(deviceData.sub), 'PatientProfile');
        window.open('/patient-detail/'+btoa(deviceData.sub));
      }
    },
    async (error: any) => {
      await Auth.signOut({
        global: true
      });
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      //DashboardClass.processing.style.display = 'none';
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });
  }

  syncTransmission = (subDetails = 'all') => {
    if(this.devicedataList && this.devicedataList.length > 0 && this.devicedataList[0]['status']==='Missing') {
      var confirmMsg = "Do you want to sync data transmission of all missing users?"
      if(subDetails != 'all') {
        confirmMsg = "Do you want to sync data transmission of this user?"
      }
      if(confirm(confirmMsg)) {
        var patientListNow = this.devicedataList;
        if(subDetails != 'all') {
          patientListNow = [subDetails];
        }
        console.log(patientListNow);
        var patientlength = patientListNow.length;
        let syncDetailsModal = document.getElementById("sync-details-ankerTag") as HTMLElement;
        syncDetailsModal.click();
        this.htmlToAdd = "<div><b>Syncing process started...</b></div>";
        this.htmlToAdd += "<div><b>Please wait for the 'Close' button at the end...</b></div>";
        this.syncStatus = "Running";
        var counter = 0;
        for(var k = 0; k < patientListNow.length; k++) {
          var patientDetail = patientListNow[k];
          let params = {
            userpull_id: localStorage.getItem('userpull_id'),
            sub: patientDetail['sub'],
            subname: patientDetail['firstName']+" "+patientDetail['lastName'],
            userauth: localStorage.getItem('AUTH-TOKEN')
          };
          this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/synctransmission', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
            .subscribe((response: any) => {
              counter++;
              if(response.status == "success") {
                this.htmlToAdd += '<div class="success"><b>'+counter+' out of '+patientlength+'.</b> Transmission syncing successful for '+response.subname+'</div>';
              }
              else {
                this.htmlToAdd += '<div class="error"><b>'+counter+' out of '+patientlength+'.</b> Could not sync transmission for '+response.subname+'</div>';
              }
              if(counter == patientlength) {
                this.htmlToAdd += '<div><b>Syncing process completed...</b></div>';
                this.syncStatus = "Complete";
              }
          });
        }
      }
    }
    else {
      alert("Don't try anything fishy.");
    }
  }

  getProviderPatientData = ( DashboardClass ) =>{
    //DashboardClass.processing.style.display = 'block';
    DashboardClass.screen.style.display = 'block';
    let paramstr = {
      UserPoolId: localStorage.getItem('userpull_id')
    };
    // console.log(localStorage.getItem('userpull_id'))
    // console.log(localStorage.getItem('AUTH-TOKEN'))
    //AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        //IdentityPoolId: 'us-east-1:d1db5ec8-4be4-48a3-be45-9e88c4d2a573'
    //});
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id')
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/dashboard', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
                .subscribe((response: any) => {
                  console.log(response);
                  if (response.status === "success") {
                    let responsedata = JSON.parse(response.data)[0];
                    console.log(responsedata);
                    DashboardClass.screen.style.display = 'none';
                    DashboardClass.providerCount = responsedata.Total_Provider;
                    DashboardClass.activeProviderCount = responsedata.Active_Provider;
                    DashboardClass.patientCount = responsedata.Total_Patient;
                    DashboardClass.activePatientCount = responsedata.Active_Patient;
                    DashboardClass.providerlist = responsedata.ProviderList;
                    if(responsedata.has_facility) {
                      this.has_facility = responsedata.has_facility;
                      this.facility_arr = responsedata.facility_list;
                    }
                    console.log(this.has_facility);
                    console.log(this.facility_arr);
                    this.dtOptions = {
                      // Declare the use of the extension in the dom parameter
                      // dom: 'lBfrtip',
                      dom: '<"col-sm-2"B><"col-sm-5"l><"col-sm-5"f>rt<"col-sm-5"i><"col-sm-7"p>',
                      // Configure the buttons
                      buttons: [
                        // 'colvis',
                        // 'copy',
                        // 'print',
                        'excel'
                      ],
                      stateSave: true
                    };
                    this.dtTrigger.next();
                    this.dtTrigger1.next();
                    this.dtTrigger2.next();
                    this.dtTrigger3.next();
                    this.dtTrigger4.next();
                    this.dtTrigger5.next();
                    this.dtTrigger7.next();
                    this.dtTrigger8.next();
                    this.dtTrigger9.next();
                    this.dtTrigger10.next();
                    this.dtTrigger11.next();
                    this.dtTrigger12.next();
                    this.dtTrigger13.next();
                  }
                  else {
                    Auth.signOut({
                      global: true
                    });
                    localStorage.removeItem('AUTH');
                    localStorage.removeItem('AUTH-LIFE');
                    localStorage.removeItem('AUTH-PROFILE-PIC');
                    localStorage.removeItem('USER-DETAILS');
                    //DashboardClass.processing.style.display = 'none';
                    DashboardClass.screen.style.display = 'none';
                    DashboardClass.router.navigateByUrl('/sign-in');
                  }
                },
                  async (error: any) => {
                    await Auth.signOut({
                      global: true
                    });
                    localStorage.removeItem('AUTH');
                    localStorage.removeItem('AUTH-LIFE');
                    localStorage.removeItem('AUTH-PROFILE-PIC');
                    localStorage.removeItem('USER-DETAILS');
                    //DashboardClass.processing.style.display = 'none';
                    DashboardClass.screen.style.display = 'none';
                    DashboardClass.router.navigateByUrl('/sign-in');
                  });
    

  }

  formatUTCDBDateTime = (date) => {
    var d = new Date(date),
      month = '' + (d.getUTCMonth() + 1),
      day = '' + d.getUTCDate(),
      year = d.getUTCFullYear(),
      hours = d.getUTCHours(),
      minutes = d.getUTCMinutes(),
      seconds = d.getUTCSeconds();
      var strhour = ''+ hours;
      var strminute = '' + minutes;
      var strsecond = '' + seconds;
      if(hours < 10){
        strhour = '0' + hours;
      }
      if(minutes < 10){
        strminute = '0' + minutes;
      }
      if(seconds < 10){
        strsecond = '0' + seconds;
      }
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-')+" "+strhour+":"+strminute+":"+strsecond;
  }

  formatDBDateTime = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours(),
      minutes = d.getMinutes(),
      seconds = d.getSeconds();
      var strhour = ''+ hours;
      var strminute = '' + minutes;
      var strsecond = '' + seconds;
      if(hours < 10){
        strhour = '0' + hours;
      }
      if(minutes < 10){
        strminute = '0' + minutes;
      }
      if(seconds < 10){
        strsecond = '0' + seconds;
      }
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-')+" "+strhour+":"+strminute+":"+strsecond;
  }

  addtask = (devicedata = "") =>{
    console.log(devicedata);
    this.taskitemsearr = [];
    this.selectedtask = undefined;
    this.poolusers = [];
    this.selectedassign = "";
    this.conpletedByDate = "";
    this.givenpriority = "";
    this.givennote = "";
    if(sessionStorage.getItem('taskitems') != null) {
      this.taskitemsearr = [];
      this.selectedtask = undefined;
      this.poolusers = [];
      this.selectedassign = "";
      this.conpletedByDate = "";
      this.givenpriority = "";
      this.givennote = "";
      let addTaskModal = document.getElementById("task-ankerTag") as HTMLElement;
      addTaskModal.click();
      setTimeout (() => {
        var taskitemsobj = JSON.parse(sessionStorage.getItem('taskitems'));
        for(var i=0; i<taskitemsobj.length; i++) {
          this.taskitemsearr.push(taskitemsobj[i]);
        }
        console.log(this.taskitemsearr);
        this.poolusers = JSON.parse(localStorage.getItem('POOL-USERS'));
        console.log(this.poolusers);
        let todaydate = new Date();
        this.conpletedByDate = this.formatTime(todaydate);
      }, 500);
    }
  }

  validateTaskForm = ( addTaskData ) =>{
    console.log(addTaskData);
    if(addTaskData.rate == "") {
      alert("Please provide priority");
      return false;
    }
    if(addTaskData.task == undefined || addTaskData.task.trim() == "") {
      alert("Please choose a task");
      return false;
    }

  }

  reviewtemplateChange = (temptype) => {
    console.log(temptype);
    if (temptype == 0){
      this.reviewtext = "";
    }
    else {
      for(var temp of this.reviewtemplatearr){
        if (temp.id == temptype){
          this.reviewtext = temp.template_text;
          break;
        }
      }
    }
  }

  escalationList = [];
  escalationdeviceData:any;

  addreview = (deviceData = "") =>{
    console.log(deviceData);
    this.interventionarr = [];
    this.reviewtemplatearr = [];
    this.selreviewtemplate = "0";
    this.reviewtext = "";

    this.escalationList = [];

    this.escalationdeviceData = deviceData
    
    this.screen.style.display = 'block';

    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
        iddevice_data_transmission: deviceData['iddevice_data_transmission']
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getdeviceescalation', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {

      console.log(JSON.parse(response.data));


      this.screen.style.display = 'none';   

      if (response.status === "success") {
        let responsedata = JSON.parse(response.data);
        if (responsedata[0]['out_of_range_alert'] == 1 && responsedata[0]['is_escalation'] == 1 ){
          this.escalationList = responsedata[0]['escalation']
        }
      }
      

      if(deviceData['ext_alert'] == '1') {
        this.screencontent.innerHTML = "";
        this.screen.style.display = 'block';
        let params = new HttpParams({
          fromObject: {
            userpull_id: localStorage.getItem('userpull_id')
          }
        });
        this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getintervention', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          this.screen.style.display = 'none';
          if (response.status === "success") {
            let responsedata = JSON.parse(response.data);
            var interventionfullarr = responsedata[deviceData['device_name'].trim()];
            this.interventionarr = [];
            for(var i=0; i<interventionfullarr.length; i++) {
              if(interventionfullarr[i]['is_devicetext'] == '1'){
                this.interventionDeviceText = interventionfullarr[i]['question_provider'];
              }
              else if(interventionfullarr[i]['is_pretext'] == '1'){
                this.interventionPreText = interventionfullarr[i]['question_provider'];
              }
              else {
                var tempobj = {
                  'idintervention': interventionfullarr[i]['idintervention'],
                  'question_provider': interventionfullarr[i]['question_provider']
                }
                this.interventionarr.push(tempobj);
              }
            }
            console.log(this.interventionarr);
          }
          this.reviewtemplatearr = [];
          this.reviewDetails = "";
          let addReviewModal = document.getElementById("review-details-ankerTag") as HTMLElement;
          addReviewModal.click();
          setTimeout (() => {
            let todaydate = new Date();
            this.myTime = this.formatTime(todaydate);
            this.reviewDetails = deviceData;
            this.selreviewtemplate = "0";
            this.reviewtext = "";
            var tempobj = {
              'id': '0',
              'shortname': 'Custom',
              'template_text': ''
            }
            this.reviewtemplatearr.push(tempobj);
            if(sessionStorage.getItem('reviewtemplate') != null) {
              var reviewtemplateobj = JSON.parse(sessionStorage.getItem('reviewtemplate'));
              for(var i=0; i<reviewtemplateobj.length; i++) {
                this.reviewtemplatearr.push(reviewtemplateobj[i]);
              }
            }
            if(sessionStorage.getItem('Review_Mode') == null) {
              this.addReviewFormMode = 'datareview';
            }
            else {
              this.addReviewFormMode = sessionStorage.getItem('Review_Mode');
            }
            if(sessionStorage.getItem('Review_Provider') == null) {
              this.addReviewFormProvider = '';
            }
            else {
              var provmatch = false;
              for(var z=0; z<this.providerlist.length; z++){
                if(this.providerlist[z]['sub'] == sessionStorage.getItem('Review_Provider')){
                  provmatch = true;
                }
              }
              if(provmatch) {
                this.addReviewFormProvider = sessionStorage.getItem('Review_Provider');
              }
              else {
                this.addReviewFormProvider = '';
              }
            }
            console.log(this.reviewtemplatearr);
          }, 500);
        },
        async (error: any) => {
          await Auth.signOut({
            global: true
          });
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          //DashboardClass.processing.style.display = 'none';
          this.screen.style.display = 'none';
          this.router.navigateByUrl('/sign-in');
        });
      }
      else {
        this.reviewtemplatearr = [];
        this.reviewDetails = "";
        let addReviewModal = document.getElementById("review-details-ankerTag") as HTMLElement;
        addReviewModal.click();
        setTimeout (() => {
          let todaydate = new Date();
          this.myTime = this.formatTime(todaydate);
          this.reviewDetails = deviceData;
          this.selreviewtemplate = "0";
          this.reviewtext = "";
          var tempobj = {
            'id': '0',
            'template_text': 'Custom'
          }
          this.reviewtemplatearr.push(tempobj);
          if(sessionStorage.getItem('reviewtemplate') != null) {
            var reviewtemplateobj = JSON.parse(sessionStorage.getItem('reviewtemplate'));
            for(var i=0; i<reviewtemplateobj.length; i++) {
              this.reviewtemplatearr.push(reviewtemplateobj[i]);
            }
          }
          if(sessionStorage.getItem('Review_Mode') == null) {
            this.addReviewFormMode = 'datareview';
          }
          else {
            this.addReviewFormMode = sessionStorage.getItem('Review_Mode');
          }
          if(sessionStorage.getItem('Review_Provider') == null) {
            this.addReviewFormProvider = '';
          }
          else {
            var provmatch = false;
            for(var z=0; z<this.providerlist.length; z++){
              if(this.providerlist[z]['sub'] == sessionStorage.getItem('Review_Provider')){
                provmatch = true;
              }
            }
            if(provmatch) {
              this.addReviewFormProvider = sessionStorage.getItem('Review_Provider');
            }
            else {
              this.addReviewFormProvider = '';
            }
          }
        }, 500);
      }
    },
    async (error: any) => {
      await Auth.signOut({
        global: true
      });
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      //DashboardClass.processing.style.display = 'none';
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });
  }

  validateAddReviewForm = ( addreviewData ) =>{
    console.log(addreviewData);
    let symptomsarr = ['fatigue','urine','swell','nausea','appetite','taste','concentrate','faint','cold','pain','breate','palpitaion','fever','others'];
    let symptoms = {}
    for(var symptomtype of symptomsarr) {
      if(symptomtype in addreviewData) {
        if(addreviewData[symptomtype]){
          symptoms[symptomtype] = addreviewData[symptomtype];
        }
      }
    }
    // console.log(symptoms);
    // return false;
    var interventionData = "";
    if(addreviewData.patient_sub == undefined || addreviewData.patient_sub.trim() == "") {
      alert("Some error occurred. Please refresh the page and try again.");
      return false;
    }
    if(addreviewData.transmission_id == undefined || String(addreviewData.transmission_id).trim() == "") {
      alert("Some error occurred. Please refresh the page and try again.");
      return false;
    }
    if(addreviewData.provider_sub == undefined || addreviewData.provider_sub.trim() == "") {
      alert("Please choose Provider.");
      return false;
    }
    if(addreviewData.mode == undefined || addreviewData.mode.trim() == "") {
      alert("Please choose mode.");
      return false;
    }
    /*if(addreviewData.duration == undefined || addreviewData.duration == "") {
      alert("Please provide duration.");
      return false;
    }
    if(addreviewData.duration < 1) {
      alert("Duration must be greater or equal to 1 minute.");
      return false;
    }*/
    if(addreviewData.durationmin < 0) {
      alert("Duration minute must not be less than 0");
      return false;
    }
    if(addreviewData.durationmin != null && addreviewData.durationmin % 1 != 0) {
      alert("Duration minute should not be decimal number");
      return false;
    }
    if(addreviewData.durationsec < 0) {
      alert("Duration second must not be less than 0");
      return false;
    }
    if(addreviewData.durationsec != null && addreviewData.durationsec % 1 != 0) {
      alert("Duration second should not be decimal number");
      return false;
    }
    if(addreviewData.durationsec >= 60) {
      alert("Duration second must not be greater or equal to 1 minute.");
      return false;
    }
    var dursec = "";
    if(addreviewData.durationsec != null) {
      dursec = addreviewData.durationsec;
      if((String(addreviewData.durationsec).length) == 1) {
        dursec = "0"+dursec;
      }
    }
    var durmin = "";
    if(addreviewData.durationmin != null) {
      if(dursec == "") {
        durmin = addreviewData.durationmin+".00";
      }
      else {
        durmin = addreviewData.durationmin+"."+dursec;
      }
    }
    else if(dursec != ""){
      durmin = "0."+dursec;
    }
    addreviewData.duration = Number(durmin);
    if(addreviewData.reviewtime == undefined || addreviewData.reviewtime == "") {
      alert("Please provide Review Time.");
      return false;
    }
    if(addreviewData.review == undefined || addreviewData.review.trim() == "") {
      alert("Please provide review text.");
      return false;
    }
    var reviewDataArr = addreviewData.reviewtime.split("-");
    if(reviewDataArr.length != 3) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(reviewDataArr[0].length != 4) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(reviewDataArr[0] < 1900 || reviewDataArr[0] > (new Date()).getFullYear()) {
      alert("Please provide Valid Time, not more than current year");
    }
    else if(reviewDataArr[1] > 12) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(reviewDataArr[0] == (new Date()).getFullYear() && reviewDataArr[1] > new Date().getMonth() + 1){
      alert("Please provide Valid Time, not more than current month and year");
    }
    else if(reviewDataArr[2].split("T")[0] > 31) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(reviewDataArr[2].split("T")[1] && reviewDataArr[2].split("T")[1].split(":")[0] && reviewDataArr[2].split("T")[1].split(":")[0] > 23) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(reviewDataArr[2].split("T")[1] && reviewDataArr[2].split("T")[1].split(":")[1] && reviewDataArr[2].split("T")[1].split(":")[1] > 59) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else {
      if(addreviewData.intervention && addreviewData.intervention.length > 0){
        interventionData = addreviewData.intervention.join();
      }
      sessionStorage.setItem('Review_Mode', addreviewData.mode);
      sessionStorage.setItem('Review_Provider', addreviewData.provider_sub);
      let addreviewClass = this;
      addreviewClass.screen.style.display = 'block';
      let params = {
        userpull_id: localStorage.getItem('userpull_id'),
        user_sub: localStorage.getItem('user_sub'),
        user_group: this.userGroup,
        patient_sub: addreviewData.patient_sub,
        transmission_id: addreviewData.transmission_id,
        provider_sub: addreviewData.provider_sub,
        mode: addreviewData.mode,
        duration: (addreviewData.duration)?addreviewData.duration:'',
        intervention_ids: interventionData,
        // month: addreviewData.forreviewmonth.split("-")[1],
        // year: addreviewData.forreviewmonth.split("-")[0],
        timestamp: this.formatUTCDBDateTime(addreviewData.reviewtime),
        review: addreviewData.review,
        userauth: localStorage.getItem('AUTH-TOKEN'),
        symptoms: JSON.stringify(symptoms),
        escalated_intervention: addreviewData.escalated_intervention
      };
      console.log(params);
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addrpmreview', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          // console.log(response);
          if(response.status == "success") {
            alert("Review added successfully");
            let responsedata = JSON.parse(response.data);
            // addreviewClass.screen.style.display = 'none';
            // location.reload();
            let addReviewModal = document.getElementById("review-details-ankerTag") as HTMLElement;
            addReviewModal.click();
            // console.log(this.devicedataList);
            // console.log(this.devicedataListLength);
            // console.log(this.reusablesearchdata);
            if('devicestatus' in this.reusablesearchdata && this.reusablesearchdata.devicestatus.includes('missed')){
              for(var i=0; i<this.devicedataList.length; i++){
                if(this.devicedataList[i]['iddevice_data_transmission'] == addreviewData.transmission_id){
                  this.devicedataList.splice(i, 1);
                }
              }
              this.devicedataListLength = this.devicedataList.length;
              this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                if(dtElement.dtInstance) {
                dtElement.dtInstance.then((dtInstance: any) => {
                  if(dtInstance.table().node().id == "first-table") {
                    dtInstance.destroy();
                    this.dtTrigger.next();
                  }
                });
                }
                else {
                  this.dtTrigger.next();
                }
              });
            }
            else {
              for(var i=0; i<this.devicedataList.length; i++){
                if(this.devicedataList[i]['iddevice_data_transmission'] == addreviewData.transmission_id){
                  if(responsedata['rpmreview_id'] && responsedata['rpmreview_id'] != "") {
                    this.devicedataList[i]['reviewed'] = responsedata['rpmreview_id'];
                    this.devicedataList[i]['reviewedon'] = this.formatTimeM(responsedata['review_details'][0]['timestamp'].replaceAll('-','/') + " UTC");
                  }
                }
              }
            }
            // console.log(this.devicedataList);
            // console.log(this.devicedataListLength);
            addreviewClass.screen.style.display = 'none';
            // this.validateSearchForm(this.reusablesearchdata);
          }
          else if(response.message){
            alert(response.message);
            addreviewClass.screen.style.display = 'none';
          }
          else {
            alert("Could not add review. Please try again.");
            addreviewClass.screen.style.display = 'none';
          }
        },
        async (error: any) => {
          alert("Could not add review. Please try again.");
          addreviewClass.screen.style.display = 'none';
          console.log(error);
        });
    }
  }

  inrangeAutoReview = () =>{
    console.log(this.devicedataList);
    if(this.devicedataList.length > 0) {
      // this.screencontent.innerHTML = "";
      // this.screen.style.display = 'block';
      let responsedata = this.devicedataList;
      var responsedatalength = responsedata.length;
      var counter = 0;
      var countersuccess = 0;
      var counterfail = 0;
      for (let k = 0; k < responsedatalength; k++) {
        setTimeout (() => {
          if(responsedata[k].created_actual){
            var d1 = new Date(responsedata[k].created_actual);
            var d2 = new Date(responsedata[k].timestamp_actual);
            var reqdate = d1;
            if (d1.getTime() < d2.getTime()) {
              reqdate = d2;
            }
          }
          else{
            var reqdate = new Date(responsedata[k].timestamp_actual);
          }
          var reqdatefinal = new Date((reqdate.getTime() + 5*60000) + (Math.random() * ((reqdate.getTime() + 10*60000) - (reqdate.getTime() + 5*60000)+1)));
          var reqdatedb = this.formatDBDateTime(reqdatefinal);
          let params = {
            userpull_id: localStorage.getItem('userpull_id'),
            user_sub: localStorage.getItem('user_sub'),
            user_group: this.userGroup,
            patient_sub: responsedata[k].sub,
            transmission_id: responsedata[k].iddevice_data_transmission,
            mode: "datareview",
            duration: "0."+String(Math.floor(Math.random() * (40 - 20 + 1) + 20)),
            review: "Readings has been reviewed and it is within set range",
            timestamp: reqdatedb,
            userauth: localStorage.getItem('AUTH-TOKEN')
          };
          console.log(params);
          this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addrpmreview', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
          .subscribe((response: any) => {
            counter++;
            countersuccess++;
            var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
            screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
            screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
            this.screencontent.innerHTML = screenContent;
            if(counter == responsedatalength) {
              this.screencontent.innerHTML = "";
              // this.screen.style.display = 'none';
              this.validateSearchForm(this.reusablesearchdata);
            }
          },
          async (error: any) => {
            counter++;
            counterfail++;
            var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
            screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
            screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
            this.screencontent.innerHTML = screenContent;
            if(counter == responsedatalength) {
              this.screencontent.innerHTML = "";
              // this.screen.style.display = 'none';
              this.validateSearchForm(this.reusablesearchdata);
            }
          });
        }, 100*(k+1));
      }
    }
  }

  devicedataDetails = ( deviceData = "" ) =>{
    console.log(deviceData);
    this.deviceDetails = deviceData;
  	let deviceDetailsModal = document.getElementById("alert-details-ankerTag") as HTMLElement;
  	deviceDetailsModal.click();
  }

  calculateDiff(dateSent1,dateSent2) {
    dateSent1 = new Date(dateSent1);
    dateSent2 = new Date(dateSent2);

    return Math.floor((Date.UTC(dateSent1.getFullYear(), dateSent1.getMonth(), dateSent1.getDate()) - Date.UTC(dateSent2.getFullYear(), dateSent2.getMonth(), dateSent2.getDate()) ) /(1000 * 60 * 60 * 24));
  }

  validateActivityForm = (activityData) => {
    console.log(activityData);
    if(activityData.activityfromdate == undefined || activityData.activityfromdate == "") {
      alert("Please provide From Date");
    }
    else if(activityData.activitytodate == undefined || activityData.activitytodate == "") {
      alert("Please provide To Date");
    }
    else if(activityData.activityfromdate > activityData.activitytodate) {
      alert("From Date must be less than from To Date");
    }
    else {
      this.screencontent.innerHTML = "";
      this.screen.style.display = 'block';
      let params = new HttpParams({
        fromObject: {
          userpull_id: localStorage.getItem('userpull_id'),
          fromdate: this.formatUTCDBDateTime(activityData.activityfromdate+" 00:00:00"),
          todate: this.formatUTCDBDateTime(activityData.activitytodate+" 23:59:59")
        }
      });
      this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getactivitysummarysimple', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        this.screen.style.display = 'none';
        console.log(response);
        if(response.status === "success") {
          var offset = new Date().getTimezoneOffset();
          console.log(offset);
          if(offset >= 0) {
            var offsetstring = "+"+offset;
          }
          else {
            var offsetstring = ""+offset;
          }
          let users = []
          if(localStorage.getItem('POOL-USERS')) {
            var poolusers = JSON.parse(localStorage.getItem('POOL-USERS'));
            for(var i=0; i<poolusers.length; i++){
              users.push(poolusers[i]);
            }
          }
          if(localStorage.getItem('SYS-USERS')) {
            var sysusers = JSON.parse(localStorage.getItem('SYS-USERS'));
            for(var i=0; i<sysusers.length; i++){
              users.push(sysusers[i]);
            }
          }
          let responsedata = JSON.parse(response.data);
          console.log(responsedata);
          console.log(users);
          if(responsedata.length > 0) {
            // console.log(responsedata);
            this.screen.style.display = 'block';
            var responsedatalength = responsedata.length;
            var counter = 0;
            var countersuccess = 0;
            var counterfail = 0;
            for (let k = 0; k < responsedata.length; k++) {
              setTimeout (() => {
                let params = new HttpParams({
                  fromObject: {
                    userpull_id: localStorage.getItem('userpull_id'),
                    fromdate: this.formatUTCDBDateTime(activityData.activityfromdate+" 00:00:00"),
                    todate: this.formatUTCDBDateTime(activityData.activitytodate+" 23:59:59"),
                    patientsub: responsedata[k]['patient_sub'],
                    numbering: k.toString()
                  }
                });
                this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getsubreportdata', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
                .subscribe((responseuser: any) => {
                  // console.log(responseuser);
                  var responseuserdata = JSON.parse(responseuser.data);
                  counter++;
                  countersuccess++;
                  var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
                  screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
                  screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
                  this.screencontent.innerHTML = screenContent;
                  responsedata[responseuserdata['numbering']]['tDate'] = responseuserdata['tDate'];
                  responsedata[responseuserdata['numbering']]['rDate'] = responseuserdata['rDate'];
                  responsedata[responseuserdata['numbering']]['DaysUploads'] = responseuserdata['DaysUploads'];
                  responsedata[responseuserdata['numbering']]['ReviewTime'] = responseuserdata['ReviewTime'];
                  responsedata[responseuserdata['numbering']]['ReviewTimeString'] = responseuserdata['ReviewTimeString'];
                  if(counter == responsedatalength) {
                    for (var i = 0; i < responsedata.length; i++) {
                      responsedata[i]['date'] = this.formatDateM(responsedata[i]['created'].replaceAll('-','/') + " UTC");
                      if(responsedata[i]['tDate'] && responsedata[i]['tDate']['StartDate'] && responsedata[i]['tDate']['EndDate']) {
                        responsedata[i]['transmission_start_date'] = this.formatDateM(responsedata[i]['tDate']['StartDate'].replaceAll('-','/') + " UTC");
                        responsedata[i]['transmission_end_date'] = this.formatDateM(responsedata[i]['tDate']['EndDate'].replaceAll('-','/') + " UTC");
                      }
                      else {
                        responsedata[i]['transmission_start_date'] = "";
                        responsedata[i]['transmission_end_date'] = "";
                      }
                      if(responsedata[i]['rDate'] && responsedata[i]['rDate']['StartDate'] && responsedata[i]['rDate']['EndDate']) {
                        responsedata[i]['review_start_date'] = this.formatDateM(responsedata[i]['rDate']['StartDate'].replaceAll('-','/') + " UTC");
                        responsedata[i]['review_end_date'] = this.formatDateM(responsedata[i]['rDate']['EndDate'].replaceAll('-','/') + " UTC");
                      }
                      else {
                        responsedata[i]['review_start_date'] = "";
                        responsedata[i]['review_end_date'] = "";
                      }
                      if(responsedata[i]['type'] == "1") {
                        responsedata[i]['review'] = "[Add] " + responsedata[i]['review'];
                      }
                      else if(responsedata[i]['type'] == "2") {
                        responsedata[i]['review'] = "[Edit] " + responsedata[i]['review'];
                      }
                      else if(responsedata[i]['type'] == "3") {
                        responsedata[i]['review'] = "[Delete] " + responsedata[i]['review'];
                      }
                      for(var j = 0; j < users.length; j++) {
                        if(responsedata[i]['user_sub'] == users[j]['sub']) {
                          responsedata[i]['user'] = users[j]['email'];
                        }
                      }
                      responsedata[i]['patient_sub_btoa'] = btoa(responsedata[i]['patient_sub']);
                    }
                    console.log(responsedata);
                    this.activitydataList = responsedata;
                    this.activitydataListLength = responsedata.length;
                    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                      if(dtElement.dtInstance) {
                      dtElement.dtInstance.then((dtInstance: any) => {
                        if(dtInstance.table().node().id == "third-table") {
                          dtInstance.destroy();
                          this.dtTrigger2.next();
                        }
                      });
                      }
                      else {
                        this.dtTrigger2.next();
                      }
                    });
                    this.screencontent.innerHTML = "";
                    this.screen.style.display = 'none';
                  }
                },
                async (error: any) => {
                  counter++;
                  counterfail++;
                  var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
                  screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
                  screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
                  this.screencontent.innerHTML = screenContent;
                  if(counter == responsedatalength) {
                    this.activitydataList = responsedata;
                    this.activitydataListLength = responsedata.length;
                    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                      if(dtElement.dtInstance) {
                      dtElement.dtInstance.then((dtInstance: any) => {
                        if(dtInstance.table().node().id == "third-table") {
                          dtInstance.destroy();
                          this.dtTrigger2.next();
                        }
                      });
                      }
                      else {
                        this.dtTrigger2.next();
                      }
                    });
                    this.screencontent.innerHTML = "";
                    this.screen.style.display = 'none';
                  }
                });
              }, 100*(k+1));
            }
          }
          else {
            this.activitydataList = responsedata;
            this.activitydataListLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "third-table") {
                  dtInstance.destroy();
                  this.dtTrigger2.next();
                }
              });
              }
              else {
                this.dtTrigger2.next();
              }
            });
          }
        }
      },
      async (error: any) => {
        console.log(error);
        this.screencontent.innerHTML = "";
        if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
          alert("Request timeout. Please try again.");
          this.screen.style.display = 'none';
        }
        else {
          this.screen.style.display = 'none';
          console.log(error);
          await Auth.signOut({
            global: true
          });
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.router.navigateByUrl('/sign-in');
        }
      });
    }
  }

  downloadExcel = () => {
    console.log(this.BillingEligibilityReportList);

    var ExcelPatientList = [];
    var mappingObj = {};

    /* table id is passed over here */   
    const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(ExcelPatientList);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, "eCW.xlsx");
  }

  downloadMissinngBilling = () => {
    console.log(this.newbillingdataList);
    let finalarr = [];
    let headerarr = [];
    headerarr.push("First");
    headerarr.push("Last");
    headerarr.push("DOB");
    headerarr.push("Date");
    headerarr.push("Oximeter");
    headerarr.push("Oximeter-Time");
    headerarr.push("Thermometer");
    headerarr.push("Thermometer-Time");
    headerarr.push("BPM");
    headerarr.push("BPM-Time");
    headerarr.push("Blood Glucose");
    headerarr.push("Blood Glucose-Time");
    headerarr.push("Weight");
    headerarr.push("Weight-Time");
    if(this.newbillingdataList && this.newbillingdataList.length > 0) {
      this.screen.style.display = 'block';
      var resdata = this.newbillingdataList;
      var responsedatalength = this.newbillingdataList.length;
      var counter = 0;
      var countersuccess = 0;
      var counterfail = 0;
      for (let k = 0; k < responsedatalength; k++) {
        setTimeout (() => {
          let params = new HttpParams({
            fromObject: {
              userpull_id: localStorage.getItem('userpull_id'),
              fromdate: this.newbillingdataList[k]['CycleStartDateStrDB'],
              todate: this.newbillingdataList[k]['CycleEndDateStrDB'],
              patientsub: this.newbillingdataList[k]['PatientSub'].replaceAll('-','_'),
              numbering: k.toString()
            }
          });
          this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/syncmissingdata', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
          .subscribe((responseuser: any) => {
            var responseuserdata = JSON.parse(responseuser.data);
            console.log(responseuserdata['numbering']);
            console.log(resdata[responseuserdata['numbering']]);
            console.log(this.newbillingdataList[responseuserdata['numbering']]);
            console.log(headerarr);
            counter++;
            countersuccess++;
            var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
            screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
            screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
            this.screencontent.innerHTML = screenContent;
            for(let j=0; j< responseuserdata['days'].length; j++) {
              let tempitem = {};
              for(var i=0; i<headerarr.length; i++){
                if(headerarr[i] == "First") {
                  tempitem[headerarr[i]] = resdata[responseuserdata['numbering']].FName;
                }
                else if(headerarr[i] == "Last") {
                  tempitem[headerarr[i]] = resdata[responseuserdata['numbering']].LName;
                }
                else if(headerarr[i] == "DOB") {
                  tempitem[headerarr[i]] = resdata[responseuserdata['numbering']].DOBstr;
                }
                else if(headerarr[i] == "Date") {
                  tempitem[headerarr[i]] = responseuserdata['days'][j];
                }
                else{
                  tempitem[headerarr[i]] = "";
                }
              }
              console.log(tempitem);
              finalarr.push(tempitem);
            }
            if(counter == responsedatalength) {
              console.log(finalarr);
              if(finalarr.length > 0) {
                /* table id is passed over here */   
                const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(finalarr);

                /* generate workbook and add the worksheet */
                const wb: XLSX.WorkBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

                /* save to file */
                XLSX.writeFile(wb, "MissingTrans.xlsx");
              }
              this.screencontent.innerHTML = "";
              this.screen.style.display = 'none';
            }
          },
          async (error: any) => {
            counter++;
            counterfail++;
            var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
            screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
            screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
            this.screencontent.innerHTML = screenContent;
            if(counter == responsedatalength) {
              console.log(finalarr);
              if(finalarr.length > 0) {
                /* table id is passed over here */   
                const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(finalarr);

                /* generate workbook and add the worksheet */
                const wb: XLSX.WorkBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

                /* save to file */
                XLSX.writeFile(wb, "MissingTrans.xlsx");
              }
              this.screencontent.innerHTML = "";
              this.screen.style.display = 'none';
            }
          });
        }, 100*(k+1));
      }
    }
  }

  downloadMissinngDays = (searchData) => {
    console.log(searchData);
    console.log(this.devicedataList);
    let finalarr = [];
    let headerarr = [];
    headerarr.push("First");
    headerarr.push("Last");
    headerarr.push("DOB");
    headerarr.push("Date");
    headerarr.push("Oximeter");
    headerarr.push("Oximeter-Time");
    headerarr.push("Thermometer");
    headerarr.push("Thermometer-Time");
    headerarr.push("BPM");
    headerarr.push("BPM-Time");
    headerarr.push("Blood Glucose");
    headerarr.push("Blood Glucose-Time");
    headerarr.push("Weight");
    headerarr.push("Weight-Time");
    if(searchData.fromdate == undefined || searchData.fromdate == "") {
      alert("Please provide From Date");
    }
    else if(searchData.todate == undefined || searchData.todate == "") {
      alert("Please provide To Date");
    }
    else if(searchData.fromdate > searchData.todate) {
      alert("From Date must be less than from To Date");
    }
    else if(this.devicedataList && this.devicedataList.length > 0) {
      this.screen.style.display = 'block';
      var resdata = this.devicedataList;
      var responsedatalength = this.devicedataList.length;
      var counter = 0;
      var countersuccess = 0;
      var counterfail = 0;
      for (let k = 0; k < responsedatalength; k++) {
        setTimeout (() => {
          let params = new HttpParams({
            fromObject: {
              userpull_id: localStorage.getItem('userpull_id'),
              fromdate: searchData.fromdate,
              todate: searchData.todate,
              patientsub: this.devicedataList[k]['sub'].replaceAll('-','_'),
              numbering: k.toString()
            }
          });
          this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/syncmissingdata', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
          .subscribe((responseuser: any) => {
            var responseuserdata = JSON.parse(responseuser.data);
            console.log(responseuserdata['numbering']);
            console.log(resdata[responseuserdata['numbering']]);
            console.log(this.devicedataList[responseuserdata['numbering']]);
            console.log(headerarr);
            counter++;
            countersuccess++;
            var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
            screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
            screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
            this.screencontent.innerHTML = screenContent;
            for(let j=0; j< responseuserdata['days'].length; j++) {
              let tempitem = {};
              for(var i=0; i<headerarr.length; i++){
                if(headerarr[i] == "First") {
                  tempitem[headerarr[i]] = resdata[responseuserdata['numbering']].firstName;
                }
                else if(headerarr[i] == "Last") {
                  tempitem[headerarr[i]] = resdata[responseuserdata['numbering']].lastName;
                }
                else if(headerarr[i] == "DOB") {
                  tempitem[headerarr[i]] = this.formatDateM(resdata[responseuserdata['numbering']].birthdate);
                }
                else if(headerarr[i] == "Date") {
                  tempitem[headerarr[i]] = responseuserdata['days'][j];
                }
                else{
                  tempitem[headerarr[i]] = "";
                }
              }
              console.log(tempitem);
              finalarr.push(tempitem);
            }
            if(counter == responsedatalength) {
              console.log(finalarr);
              if(finalarr.length > 0) {
                /* table id is passed over here */   
                const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(finalarr);

                /* generate workbook and add the worksheet */
                const wb: XLSX.WorkBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

                /* save to file */
                XLSX.writeFile(wb, "MissingTrans.xlsx");
              }
              this.screencontent.innerHTML = "";
              this.screen.style.display = 'none';
            }
          },
          async (error: any) => {
            counter++;
            counterfail++;
            var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
            screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
            screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
            this.screencontent.innerHTML = screenContent;
            if(counter == responsedatalength) {
              console.log(finalarr);
              if(finalarr.length > 0) {
                /* table id is passed over here */   
                const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(finalarr);

                /* generate workbook and add the worksheet */
                const wb: XLSX.WorkBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

                /* save to file */
                XLSX.writeFile(wb, "MissingTrans.xlsx");
              }
              this.screencontent.innerHTML = "";
              this.screen.style.display = 'none';
            }
          });
        }, 100*(k+1));
      }
    }
  }

  downloadECWBilling = () => {
    let finalarr = [];
    let headerarr = [];
    headerarr.push("InstaMDOrganizationID");
    headerarr.push("PracticeID");
    headerarr.push("PracticeName");
    headerarr.push("PracticeAddress1");
    headerarr.push("PracticeAddress2");
    headerarr.push("PracticeCity");
    headerarr.push("PracticeState");
    headerarr.push("PracticeZip");
    headerarr.push("PracticePhone");
    headerarr.push("TransactionDate");
    headerarr.push("TransactionID");
    headerarr.push("TransactionStatus");
    headerarr.push("InstaMDPatientSub");
    headerarr.push("PracticePatientID");
    headerarr.push("PatientFirstName");
    headerarr.push("PatientMiddleName");
    headerarr.push("PatientLastName");
    headerarr.push("PatientDOB");
    headerarr.push("PatientSex");
    headerarr.push("PatientAddress1");
    headerarr.push("PatientAddress2");
    headerarr.push("PatientCity");
    headerarr.push("PatientState");
    headerarr.push("PatientZip");
    headerarr.push("PatientPhone");
    headerarr.push("ProviderFirstName");
    headerarr.push("ProviderMiddleName");
    headerarr.push("ProviderLastName");
    headerarr.push("ProvderNPI");
    headerarr.push("ProviderTaxID");
    headerarr.push("InstaMDProviderSub");
    headerarr.push("PracticeProviderID");
    headerarr.push("ICD1");
    headerarr.push("Diagnosis 1");
    headerarr.push("ICD2");
    headerarr.push("Diagnosis 2");
    headerarr.push("ICD3");
    headerarr.push("Diagnosis 3");
    headerarr.push("ICD4");
    headerarr.push("Diagnosis 4");
    headerarr.push("ICD5");
    headerarr.push("Diagnosis 5");
    headerarr.push("99453BillDate");
    headerarr.push("99453Flag");
    headerarr.push("99453BDOS");
    headerarr.push("99453EDOS");
    headerarr.push("99453Unit");
    headerarr.push("99454Flag");
    headerarr.push("99454BDOS");
    headerarr.push("99454EDOS");
    headerarr.push("99454Unit");
    headerarr.push("99457Flag");
    headerarr.push("99457BDOS");
    headerarr.push("99457EDOS");
    headerarr.push("99457Unit");
    headerarr.push("99458Flag");
    headerarr.push("99458BDOS");
    headerarr.push("99458EDOS");
    headerarr.push("99458Unit");
    // headerarr.push("AdjustedDOS");
    headerarr.push("InstaMDPrimaryPayerID");
    headerarr.push("InstaMDPrimaryPayerName");
    headerarr.push("InstaMDPrimaryPayerDeductibleRemainingFlag");
    headerarr.push("InstaMDPrimaryPayerType");
    headerarr.push("PracticePrimaryPayerID");
    headerarr.push("PracticePrimaryPatientMemberID");
    headerarr.push("InstaMDSecondaryPayerID");
    headerarr.push("InstaMDSecondaryPayerName");
    headerarr.push("InstaMDSecondaryPayerActiveStatus");
    headerarr.push("PracticeSecondaryPayerID");
    headerarr.push("PracticeSecondaryPayerPatientID");
    if(this.newbillingdataList && this.newbillingdataList.length > 0) {
      var mybillingdata = this.newbillingdataList;
      mybillingdata.sort(function(a, b){
        var nameA=a.PatientName.toLowerCase(), nameB=b.PatientName.toLowerCase()
        if (nameA < nameB) //sort string ascending
            return -1 
        if (nameA > nameB)
            return 1
        return 0 //default return value (no sorting)
      })
      console.log(mybillingdata);
      for(var j=0; j<mybillingdata.length; j++) {
        let tempitem = {};
        for(var k=0; k<headerarr.length; k++){
          if(headerarr[k] == "InstaMDOrganizationID") {
            tempitem[headerarr[k]] = "";
            if(localStorage.getItem('userpull_id') == 'us-east-1_mpzo5KZea') {
              tempitem[headerarr[k]] = "drchirala";
            }
          }
          else if(headerarr[k] == "PracticeID") {
            tempitem[headerarr[k]] = "";
            if(localStorage.getItem('userpull_id') == 'us-east-1_mpzo5KZea') {
              tempitem[headerarr[k]] = "xxxxxx";
            }
          }
          else if(headerarr[k] == "PracticeName") {
            tempitem[headerarr[k]] = "";
            if(localStorage.getItem('userpull_id') == 'us-east-1_mpzo5KZea') {
              tempitem[headerarr[k]] = "DrChirala";
            }
          }
          else if(headerarr[k] == "PracticeAddress1") {
            tempitem[headerarr[k]] = "";
            if(localStorage.getItem('userpull_id') == 'us-east-1_mpzo5KZea') {
              tempitem[headerarr[k]] = "118511 Mission View Drive";
            }
          }
          else if(headerarr[k] == "PracticeAddress2") {
            tempitem[headerarr[k]] = "";
            if(localStorage.getItem('userpull_id') == 'us-east-1_mpzo5KZea') {
              tempitem[headerarr[k]] = "Suite 120";
            }
          }
          else if(headerarr[k] == "PracticeCity") {
            tempitem[headerarr[k]] = "";
            if(localStorage.getItem('userpull_id') == 'us-east-1_mpzo5KZea') {
              tempitem[headerarr[k]] = "Morgan Hill";
            }
          }
          else if(headerarr[k] == "PracticeState") {
            tempitem[headerarr[k]] = "";
            if(localStorage.getItem('userpull_id') == 'us-east-1_mpzo5KZea') {
              tempitem[headerarr[k]] = "CA";
            }
          }
          else if(headerarr[k] == "PracticeZip") {
            tempitem[headerarr[k]] = "";
            if(localStorage.getItem('userpull_id') == 'us-east-1_mpzo5KZea') {
              tempitem[headerarr[k]] = "95037";
            }
          }
          else if(headerarr[k] == "PracticePhone") {
            tempitem[headerarr[k]] = "";
            if(localStorage.getItem('userpull_id') == 'us-east-1_mpzo5KZea') {
              tempitem[headerarr[k]] = "408-396-4020";
            }
          }
          else if(headerarr[k] == "TransactionDate") {
            tempitem[headerarr[k]] = "xxxxxx (Manual populate)";
          }
          else if(headerarr[k] == "TransactionID") {
            tempitem[headerarr[k]] = "xxxxxx (Manual populate)";
          }
          else if(headerarr[k] == "TransactionStatus") {
            tempitem[headerarr[k]] = "new";
          }
          else if(headerarr[k] == "InstaMDPatientSub") {
            tempitem[headerarr[k]] = mybillingdata[j]['PatientSub'].replaceAll('-','').substr(0, 30);
          }
          else if(headerarr[k] == "PracticePatientID") {
            tempitem[headerarr[k]] = mybillingdata[j]['mrn'];
          }
          else if(headerarr[k] == "PatientFirstName") {
            tempitem[headerarr[k]] = mybillingdata[j]['FName'];
          }
          else if(headerarr[k] == "PatientMiddleName") {
            tempitem[headerarr[k]] = "";
          }
          else if(headerarr[k] == "PatientLastName") {
            tempitem[headerarr[k]] = mybillingdata[j]['LName'];
          }
          else if(headerarr[k] == "PatientDOB") {
            tempitem[headerarr[k]] = mybillingdata[j]['DOBstr'];
          }
          else if(headerarr[k] == "PatientSex") {
            tempitem[headerarr[k]] = mybillingdata[j]['Gender'];
          }
          else if(headerarr[k] == "PatientAddress1") {
            tempitem[headerarr[k]] = mybillingdata[j]['PatAddress1'];
          }
          else if(headerarr[k] == "PatientAddress2") {
            tempitem[headerarr[k]] = mybillingdata[j]['PatAddress2'];
          }
          else if(headerarr[k] == "PatientCity") {
            tempitem[headerarr[k]] = mybillingdata[j]['PatCity'];
          }
          else if(headerarr[k] == "PatientState") {
            tempitem[headerarr[k]] = mybillingdata[j]['PatState'];
          }
          else if(headerarr[k] == "PatientZip") {
            tempitem[headerarr[k]] = mybillingdata[j]['PatPin'];
          }
          else if(headerarr[k] == "PatientPhone") {
            tempitem[headerarr[k]] = mybillingdata[j]['PatPhone'];
          }
          else if(headerarr[k] == "ProviderFirstName") {
            tempitem[headerarr[k]] = "";
            if(localStorage.getItem('userpull_id') == 'us-east-1_mpzo5KZea') {
              tempitem[headerarr[k]] = "Anuradha";
            }
          }
          else if(headerarr[k] == "ProviderMiddleName") {
            tempitem[headerarr[k]] = "";
          }
          else if(headerarr[k] == "ProviderLastName") {
            tempitem[headerarr[k]] = "";
            if(localStorage.getItem('userpull_id') == 'us-east-1_mpzo5KZea') {
              tempitem[headerarr[k]] = "Chirala";
            }
          }
          else if(headerarr[k] == "ProvderNPI") {
            tempitem[headerarr[k]] = "";
            if(localStorage.getItem('userpull_id') == 'us-east-1_mpzo5KZea') {
              tempitem[headerarr[k]] = "1952597478";
            }
          }
          else if(headerarr[k] == "ProviderTaxID") {
            tempitem[headerarr[k]] = "";
            if(localStorage.getItem('userpull_id') == 'us-east-1_mpzo5KZea') {
              tempitem[headerarr[k]] = "xxxxxx";
            }
          }
          else if(headerarr[k] == "InstaMDProviderSub") {
            tempitem[headerarr[k]] = "";
            if(localStorage.getItem('userpull_id') == 'us-east-1_mpzo5KZea') {
              tempitem[headerarr[k]] = "96c6a1d72a44434c845f967d6c10e5";
            }
          }
          else if(headerarr[k] == "PracticeProviderID") {
            tempitem[headerarr[k]] = "";
          }
          else if(headerarr[k] == "ICD1") {
            tempitem[headerarr[k]] = "I10";
          }
          else if(headerarr[k] == "Diagnosis 1") {
            tempitem[headerarr[k]] = "Hypertension";
          }
          else if(headerarr[k] == "99453BillDate") {
            tempitem[headerarr[k]] = mybillingdata[j]['eduDateStr'];
          }
          else if(headerarr[k] == "99453Flag") {
            tempitem[headerarr[k]] = "";
            var codestr = mybillingdata[j]['Code'];
            if(codestr && codestr != "") {
              var foundCount = codestr.split("99453").length - 1;
              if(foundCount > 0) {
                tempitem[headerarr[k]] = "1";
              }
            }
          }
          else if(headerarr[k] == "99453BDOS") {
            tempitem[headerarr[k]] = "";
            var codestr = mybillingdata[j]['Code'];
            if(codestr && codestr != "") {
              var foundCount = codestr.split("99453").length - 1;
              if(foundCount > 0) {
                // tempitem[headerarr[k]] = mybillingdata[j]['CycleStartDateStr'];
                tempitem[headerarr[k]] = mybillingdata[j]['doss'];
              }
            }
          }
          else if(headerarr[k] == "99453EDOS") {
            tempitem[headerarr[k]] = "";
            var codestr = mybillingdata[j]['Code'];
            if(codestr && codestr != "") {
              var foundCount = codestr.split("99453").length - 1;
              if(foundCount > 0) {
                // tempitem[headerarr[k]] = mybillingdata[j]['CycleEndDateStr'];
                tempitem[headerarr[k]] = mybillingdata[j]['dose'];
              }
            }
          }
          else if(headerarr[k] == "99453Unit") {
            tempitem[headerarr[k]] = "";
            var codestr = mybillingdata[j]['Code'];
            if(codestr && codestr != "") {
              var foundCount = codestr.split("99453").length - 1;
              if(foundCount > 0) {
                tempitem[headerarr[k]] = String(foundCount);
              }
            }
          }
          else if(headerarr[k] == "99454Flag") {
            tempitem[headerarr[k]] = "";
            var codestr = mybillingdata[j]['Code'];
            if(codestr && codestr != "") {
              var foundCount = codestr.split("99454").length - 1;
              if(foundCount > 0) {
                tempitem[headerarr[k]] = "1";
              }
            }
          }
          else if(headerarr[k] == "99454BDOS") {
            tempitem[headerarr[k]] = "";
            var codestr = mybillingdata[j]['Code'];
            if(codestr && codestr != "") {
              var foundCount = codestr.split("99454").length - 1;
              if(foundCount > 0) {
                // tempitem[headerarr[k]] = mybillingdata[j]['CycleStartDateStr'];
                tempitem[headerarr[k]] = mybillingdata[j]['doss'];
              }
            }
          }
          else if(headerarr[k] == "99454EDOS") {
            tempitem[headerarr[k]] = "";
            var codestr = mybillingdata[j]['Code'];
            if(codestr && codestr != "") {
              var foundCount = codestr.split("99454").length - 1;
              if(foundCount > 0) {
                // tempitem[headerarr[k]] = mybillingdata[j]['CycleEndDateStr'];
                tempitem[headerarr[k]] = mybillingdata[j]['dose'];
              }
            }
          }
          else if(headerarr[k] == "99454Unit") {
            tempitem[headerarr[k]] = "";
            var codestr = mybillingdata[j]['Code'];
            if(codestr && codestr != "") {
              var foundCount = codestr.split("99454").length - 1;
              if(foundCount > 0) {
                tempitem[headerarr[k]] = String(foundCount);
              }
            }
          }
          else if(headerarr[k] == "99457Flag") {
            tempitem[headerarr[k]] = "";
            var codestr = mybillingdata[j]['Code'];
            if(codestr && codestr != "") {
              var foundCount = codestr.split("99457").length - 1;
              if(foundCount > 0) {
                tempitem[headerarr[k]] = "1";
              }
            }
          }
          else if(headerarr[k] == "99457BDOS") {
            tempitem[headerarr[k]] = "";
            var codestr = mybillingdata[j]['Code'];
            if(codestr && codestr != "") {
              var foundCount = codestr.split("99457").length - 1;
              if(foundCount > 0) {
                // tempitem[headerarr[k]] = mybillingdata[j]['CycleStartDateStr'];
                tempitem[headerarr[k]] = mybillingdata[j]['doss'];
              }
            }
          }
          else if(headerarr[k] == "99457EDOS") {
            tempitem[headerarr[k]] = "";
            var codestr = mybillingdata[j]['Code'];
            if(codestr && codestr != "") {
              var foundCount = codestr.split("99457").length - 1;
              if(foundCount > 0) {
                // tempitem[headerarr[k]] = mybillingdata[j]['CycleEndDateStr'];
                tempitem[headerarr[k]] = mybillingdata[j]['dose'];
              }
            }
          }
          else if(headerarr[k] == "99457Unit") {
            tempitem[headerarr[k]] = "";
            var codestr = mybillingdata[j]['Code'];
            if(codestr && codestr != "") {
              var foundCount = codestr.split("99457").length - 1;
              if(foundCount > 0) {
                tempitem[headerarr[k]] = String(foundCount);
              }
            }
          }
          else if(headerarr[k] == "99458Flag") {
            tempitem[headerarr[k]] = "";
            var codestr = mybillingdata[j]['Code'];
            if(codestr && codestr != "") {
              var foundCount = codestr.split("99458").length - 1;
              if(foundCount > 0) {
                tempitem[headerarr[k]] = "1";
              }
            }
          }
          else if(headerarr[k] == "99458BDOS") {
            tempitem[headerarr[k]] = "";
            var codestr = mybillingdata[j]['Code'];
            if(codestr && codestr != "") {
              var foundCount = codestr.split("99458").length - 1;
              if(foundCount > 0) {
                // tempitem[headerarr[k]] = mybillingdata[j]['CycleStartDateStr'];
                tempitem[headerarr[k]] = mybillingdata[j]['doss'];
              }
            }
          }
          else if(headerarr[k] == "99458EDOS") {
            tempitem[headerarr[k]] = "";
            var codestr = mybillingdata[j]['Code'];
            if(codestr && codestr != "") {
              var foundCount = codestr.split("99458").length - 1;
              if(foundCount > 0) {
                // tempitem[headerarr[k]] = mybillingdata[j]['CycleEndDateStr'];
                tempitem[headerarr[k]] = mybillingdata[j]['dose'];
              }
            }
          }
          else if(headerarr[k] == "99458Unit") {
            tempitem[headerarr[k]] = "";
            var codestr = mybillingdata[j]['Code'];
            if(codestr && codestr != "") {
              var foundCount = codestr.split("99458").length - 1;
              if(foundCount > 0) {
                tempitem[headerarr[k]] = String(foundCount);
              }
            }
          }
          else if(headerarr[k] == "AdjustedDOS") {
            tempitem[headerarr[k]] = this.formatDateM(mybillingdata[j]['dos'].replaceAll('-','/'));
          }
          else if(headerarr[k] == "InstaMDPrimaryPayerID") {
            tempitem[headerarr[k]] = mybillingdata[j]['PI_CODE'];
          }
          else if(headerarr[k] == "InstaMDPrimaryPayerName") {
            tempitem[headerarr[k]] = mybillingdata[j]['PI'];
          }
          else if(headerarr[k] == "InstaMDPrimaryPayerDeductibleRemainingFlag") {
            tempitem[headerarr[k]] = mybillingdata[j]['PI_DRFlag'];
          }
          else if(headerarr[k] == "InstaMDPrimaryPayerType") {
            tempitem[headerarr[k]] = mybillingdata[j]['PI_Type'];
          }
          else if(headerarr[k] == "PracticePrimaryPayerID") {
            tempitem[headerarr[k]] = mybillingdata[j]['eCW_PI_CODE'];
          }
          else if(headerarr[k] == "PracticePrimaryPatientMemberID") {
            tempitem[headerarr[k]] = mybillingdata[j]['PI_ID'];
          }
          else if(headerarr[k] == "InstaMDSecondaryPayerID") {
            tempitem[headerarr[k]] = mybillingdata[j]['SI_CODE'];
          }
          else if(headerarr[k] == "InstaMDSecondaryPayerName") {
            tempitem[headerarr[k]] = mybillingdata[j]['SI'];
          }
          else if(headerarr[k] == "InstaMDSecondaryPayerActiveStatus") {
            tempitem[headerarr[k]] = mybillingdata[j]['SI_STATUS'];
          }
          else if(headerarr[k] == "PracticeSecondaryPayerID") {
            tempitem[headerarr[k]] = mybillingdata[j]['eCW_SI_CODE'];
          }
          else if(headerarr[k] == "PracticeSecondaryPayerPatientID") {
            tempitem[headerarr[k]] = mybillingdata[j]['SI_ID'];
          }
          else{
            tempitem[headerarr[k]] = "";
          }
        }
        finalarr.push(tempitem);
      }
    }
    console.log(finalarr);
    if(finalarr.length > 0) {
      /* table id is passed over here */   
      const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(finalarr);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */
      XLSX.writeFile(wb, "ECWBillingSummary.csv");
    }
  }

  downloadBillingExcel = () => {
    let ele = document.getElementById('fourth-table-header');
    let finalarr = [];
    let headerarr = [];
    for(var i=0; i<ele.children.length; i++) {
      if(ele.children[i].innerHTML == "Billing Cycle") {
        headerarr.push(ele.children[i].innerHTML);
        headerarr.push("DTB/RB");
        headerarr.push("DTE/RE");
      }
      else {
        headerarr.push(ele.children[i].innerHTML);
      }
    }
    console.log(headerarr);
    if(this.billingdataList && this.billingdataList.length > 0) {
      var mybillingdata = this.billingdataList;
      mybillingdata.sort(function(a, b){
        var nameA=a.firstName.toLowerCase(), nameB=b.firstName.toLowerCase()
        if (nameA < nameB) //sort string ascending
            return -1 
        if (nameA > nameB)
            return 1
        return 0 //default return value (no sorting)
      })
      for(var j=0; j<mybillingdata.length; j++) {
        let tempitem = {};
        for(var k=0; k<headerarr.length; k++){
          if(headerarr[k] == "Patient") {
            tempitem[headerarr[k]] = mybillingdata[j]['firstName']+' '+mybillingdata[j]['lastName'];
          }
          else if(headerarr[k] == "DOB") {
            tempitem[headerarr[k]] = mybillingdata[j]['birthdate'];
          }
          else if(headerarr[k] == "Gender") {
            tempitem[headerarr[k]] = mybillingdata[j]['gender'];
          }
          else if(headerarr[k] == "Billing Cycle") {
            tempitem[headerarr[k]] = mybillingdata[j]['start_date']+' - '+mybillingdata[j]['end_date'] + ' ';
            if(mybillingdata[j]['callStatus'] && mybillingdata[j]['callStatus'] == "true") {
              tempitem[headerarr[k]] = tempitem[headerarr[k]] + "Review Call: YES";
            }
            if(mybillingdata[j]['callStatus'] && mybillingdata[j]['callStatus'] == "false") {
              tempitem[headerarr[k]] = tempitem[headerarr[k]] + "Review Call: NO";
            }
          }
          else if(headerarr[k] == "DTB/RB") {
            tempitem[headerarr[k]] = mybillingdata[j]['start_date'];
          }
          else if(headerarr[k] == "DTE/RE") {
            tempitem[headerarr[k]] = mybillingdata[j]['end_date'];
          }
          else if(headerarr[k] == "DD") {
            tempitem[headerarr[k]] = mybillingdata[j]['DaysUploads'];
          }
          else if(headerarr[k] == "RT") {
            tempitem[headerarr[k]] = mybillingdata[j]['ReviewTimeString'];
          }
          else if(headerarr[k] == "Devices") {
            tempitem[headerarr[k]] = mybillingdata[j]['userdevices'];
          }
          else if(headerarr[k] == "IoT") {
            if(mybillingdata[j]['hasIoT']==true) {
              tempitem[headerarr[k]] = 'Yes';
            }
            if(mybillingdata[j]['hasIoT']==false) {
              tempitem[headerarr[k]] = 'No';
            }
            for(let device of mybillingdata[j]['IoTDevices']){
              tempitem[headerarr[k]] = tempitem[headerarr[k]] + ' ' + device['display_name'];
            }
          }
          else if(headerarr[k] == "PD") {
            tempitem[headerarr[k]] = mybillingdata[j]['prim_diag'];
          }
          else if(headerarr[k] == "GSEC") {
            tempitem[headerarr[k]] = mybillingdata[j]['sec_diag'];
          }
          else if(headerarr[k] == "99453 Date") {
            tempitem[headerarr[k]] = mybillingdata[j]['eduDate'];
          }
          else if(headerarr[k] == "RC") {
            tempitem[headerarr[k]] = '';
            for(let reviewItem of mybillingdata[j]['all_reviews']){
              tempitem[headerarr[k]] = tempitem[headerarr[k]] + ' ' + reviewItem;
            }
          }
          else if(headerarr[k] == "Code") {
            tempitem[headerarr[k]] = mybillingdata[j]['Code'];
          }
          else {
            tempitem[headerarr[k]] = "";
          }
        }
        finalarr.push(tempitem);
      }
    }
    console.log(finalarr);
    if(finalarr.length > 0) {
      /* table id is passed over here */   
      const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(finalarr);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */
      XLSX.writeFile(wb, "BillingSummary.xlsx");
    }
  }

  validateBillingForm = ( billingData ) => {
    console.log(billingData);
    if(billingData.billingfromdate == undefined || billingData.billingfromdate == "") {
      alert("Please provide From Date");
    }
    else if(billingData.billingtodate == undefined || billingData.billingtodate == "") {
      alert("Please provide To Date");
    }
    else if(billingData.billingfromdate > billingData.billingtodate) {
      alert("From Date must be less than from To Date");
    }
    else {
      if(billingData.selbillingprovider == undefined || billingData.selbillingprovider == ""){
        billingData.selbillingprovider = "all";
      }
      this.screencontent.innerHTML = "";
      this.screen.style.display = 'block';
      let params = new HttpParams({
        fromObject: {
          userpull_id: localStorage.getItem('userpull_id'),
          fromdate: this.formatUTCDBDateTime(billingData.billingfromdate+" 00:00:00"),
          todate: this.formatUTCDBDateTime(billingData.billingtodate+" 23:59:59")
        }
      });
      this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/billingsummarydatasimple', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        this.screen.style.display = 'none';
        console.log(response);
        if(response.status === "success") {
          var offset = new Date().getTimezoneOffset();
          console.log(offset);
          if(offset >= 0) {
            var offsetstring = "+"+offset;
          }
          else {
            var offsetstring = ""+offset;
          }
          let responsedata = JSON.parse(response.data);
          if(responsedata.length > 0) {
            console.log(responsedata);
            this.screen.style.display = 'block';
            var responsedatalength = responsedata.length;
            this.screencontent.innerHTML = "<div><b>"+responsedatalength+"</b> data to be processed</div>";
            var counter = 0;
            var countersuccess = 0;
            var counterfail = 0;
            for (let k = 0; k < responsedata.length; k++) {
              if(responsedata[k]['birthdate']) {
                responsedata[k]['birthdate']=this.formatDateM(responsedata[k]['birthdate'].replaceAll('-','/') + " 00:00:00");
                responsedata[k]['masked_birthdate'] = this.make_number_mask(this.formatDateM(responsedata[k]['birthdate'].replaceAll('-','/') + " 00:00:00").toString(),4);
              }
              setTimeout (() => {
                let params = new HttpParams({
                  fromObject: {
                    userpull_id: localStorage.getItem('userpull_id'),
                    fromdate: this.formatUTCDBDateTime(billingData.billingfromdate+" 00:00:00"),
                    todate: this.formatUTCDBDateTime(billingData.billingtodate+" 23:59:59"),
                    patientsub: responsedata[k]['sub'],
                    numbering: k.toString(),
                    type: 'billing'
                  }
                });
                this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getsubreportdata', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
                .subscribe((responseuser: any) => {
                  // console.log(responseuser);
                  if(responseuser.status == "success") {
                    counter++;
                    countersuccess++;
                    var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
                    screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
                    screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
                    this.screencontent.innerHTML = screenContent;
                    var responseuserdata = JSON.parse(responseuser.data);
                    responsedata[responseuserdata['numbering']]['tDate'] = responseuserdata['tDate'];
                    responsedata[responseuserdata['numbering']]['rDate'] = responseuserdata['rDate'];
                    responsedata[responseuserdata['numbering']]['callStatus'] = responseuserdata['callStatus'];
                    responsedata[responseuserdata['numbering']]['DaysUploads'] = responseuserdata['DaysUploads'];
                    responsedata[responseuserdata['numbering']]['review'] = responseuserdata['review'];
                    responsedata[responseuserdata['numbering']]['ReviewTime'] = responseuserdata['ReviewTime'];
                    responsedata[responseuserdata['numbering']]['ReviewTimeString'] = responseuserdata['ReviewTimeString'];
                    responsedata[responseuserdata['numbering']]['Code'] = responseuserdata['Code'];
                    responsedata[responseuserdata['numbering']]['datatransmissiondays'] = responseuserdata['datatransmissiondays'];
                    responsedata[responseuserdata['numbering']]['minuploadrequired'] = responseuserdata['minuploadrequired'];
                    responsedata[responseuserdata['numbering']]['userdevices'] = responseuserdata['userDevices'].join(", ");
                    responsedata[responseuserdata['numbering']]['hasIoT'] = responseuserdata['hasIoT'];
                    responsedata[responseuserdata['numbering']]['IoTDevices'] = responseuserdata['IoTDevices'];
                    if(counter == responsedatalength) {
                      for (var i = 0; i < responsedata.length; i++) {
                        if(responsedata[i]['tDate'] && responsedata[i]['tDate']['StartDate'] && responsedata[i]['eduDate']) {
                          // let datediff = this.calculateDiff(responsedata[i]['tDate']['StartDate'], responsedata[i]['eduDate']);
                          let datediff = this.calculateDiff(responsedata[i]['tDate']['StartDate'], responsedata[i]['tDate']['FirstDate']);
                          responsedata[i]['datediff'] = datediff;
                          if(responsedata[i]['DaysUploads'] >= responsedata[i]['minuploadrequired'] && datediff < responsedata[i]['datatransmissiondays'] && responsedata[i]['Code'].trim() == "-") {
                            responsedata[i]['Code'] = "99453 (BDOS=EDOS="+this.formatDateM(responsedata[i]['tDate']['FirstDate'].replaceAll('-','/') + " UTC")+")";
                          }
                          else if(responsedata[i]['DaysUploads'] >= responsedata[i]['minuploadrequired'] && datediff < responsedata[i]['datatransmissiondays'] && responsedata[i]['Code'].trim() != "-") {
                            if(responsedata[i]['rDate'] && responsedata[i]['rDate']['StartDate']){
                              let codearr = responsedata[i]['Code'].split(", ");
                              let codestr = "";
                              for(var x = 0; x < codearr.length; x++) {
                                codestr = codestr + codearr[x] + " (BDOS=EDOS="+this.formatDateM(responsedata[i]['rDate']['StartDate'].replaceAll('-','/') + " UTC")+")";
                                if(x != codearr.length - 1){
                                  codestr = codestr + ", ";
                                }
                              }
                              responsedata[i]['Code'] = codestr;
                            }
                            responsedata[i]['Code'] = "99453 (BDOS=EDOS="+this.formatDateM(responsedata[i]['tDate']['FirstDate'].replaceAll('-','/') + " UTC")+"), " + responsedata[i]['Code'];
                          }
                          else if(responsedata[i]['Code'].trim() != "-" && responsedata[i]['rDate'] && responsedata[i]['rDate']['StartDate']) {
                            let codearr = responsedata[i]['Code'].split(", ");
                            let codestr = "";
                            for(var x= 0; x < codearr.length; x++) {
                              codestr = codestr + codearr[x] + " (BDOS=EDOS="+this.formatDateM(responsedata[i]['rDate']['StartDate'].replaceAll('-','/') + " UTC")+")";
                              if(x != codearr.length - 1){
                                codestr = codestr + ", ";
                              }
                            }
                            responsedata[i]['Code'] = codestr;
                          }
                        }
                        if(responsedata[i]['tDate'] && responsedata[i]['tDate']['StartDate'] && responsedata[i]['tDate']['EndDate']) {
                          responsedata[i]['start_date'] = this.formatDateM(responsedata[i]['tDate']['StartDate'].replaceAll('-','/') + " UTC");
                          responsedata[i]['end_date'] = this.formatDateM(responsedata[i]['tDate']['EndDate'].replaceAll('-','/') + " UTC");
                        }
                        else {
                          responsedata[i]['start_date'] = "";
                          responsedata[i]['end_date'] = "";
                        }
                        if(responsedata[i]['rDate'] && responsedata[i]['rDate']['StartDate'] && responsedata[i]['rDate']['EndDate']) {
                          responsedata[i]['review_start_date'] = this.formatDateM(responsedata[i]['rDate']['StartDate'].replaceAll('-','/') + " UTC");
                          responsedata[i]['review_end_date'] = this.formatDateM(responsedata[i]['rDate']['EndDate'].replaceAll('-','/') + " UTC");
                        }
                        else {
                          responsedata[i]['review_start_date'] = "";
                          responsedata[i]['review_end_date'] = "";
                        }
                        if(responsedata[i]['eduDate'] && responsedata[i]['eduDate'] != "0000-00-00 00:00:00") {
                          responsedata[i]['eduDate'] = this.formatDateM(responsedata[i]['eduDate'].replaceAll('-','/') + " UTC");
                        }
                        else {
                          responsedata[i]['eduDate'] = "";
                        }
                        // if(responsedata[i]['firstName'] == 'Albert'){
                        //   console.log(responsedata[i]);
                        // }
                        responsedata[i]['patient_sub_btoa'] = btoa(responsedata[i]['patient_sub']);
                      }
                      console.log(responsedata);
                      // this.billingdataList = responsedata;
                      this.billingdataList = responsedata.map(item=> {
                        if(item.review && item.review.indexOf('|') !== -1) {
                          let partial = item.review.split('|');
                          return {...item,
                            prim_diag:partial[0],
                            sec_diag:partial[1],
                            all_reviews : partial[2].split(',')
                          }
                        }
                        else {
                          return {...item,
                            prim_diag: '',
                            sec_diag: '',
                            all_reviews : ''
                          }
                        }
                      });
                      this.billingdataListLength = responsedata.length;
                      // console.log(this.reviewdataListLength);
                      // console.log(this.dtElements);
                      this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                        if(dtElement.dtInstance) {
                        dtElement.dtInstance.then((dtInstance: any) => {
                          if(dtInstance.table().node().id == "fourth-table") {
                            dtInstance.destroy();
                            this.dtTrigger3.next();
                          }
                        });
                        }
                        else {
                          this.dtTrigger3.next();
                        }
                      });
                      this.screencontent.innerHTML = "";
                      this.screen.style.display = 'none';
                    }
                  }
                  else {
                    if(responseuser.data) {
                      var responsetestdata = JSON.parse(responseuser.data);
                      if(responsetestdata.numbering >= 0 && responsetestdata.patientsub != ""){
                        let params = new HttpParams({
                          fromObject: {
                            userpull_id: localStorage.getItem('userpull_id'),
                            fromdate: this.formatUTCDBDateTime(billingData.billingfromdate+" 00:00:00"),
                            todate: this.formatUTCDBDateTime(billingData.billingtodate+" 23:59:59"),
                            patientsub: responsetestdata.patientsub,
                            numbering: responsetestdata.numbering,
                            type: 'billing'
                          }
                        });
                        this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getsubreportdata', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
                        .subscribe((responseuser: any) => {
                          // console.log(responseuser);
                          counter++;
                          countersuccess++;
                          var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
                          screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
                          screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
                          this.screencontent.innerHTML = screenContent;
                          var responseuserdata = JSON.parse(responseuser.data);
                          responsedata[responseuserdata['numbering']]['tDate'] = responseuserdata['tDate'];
                          responsedata[responseuserdata['numbering']]['rDate'] = responseuserdata['rDate'];
                          responsedata[responseuserdata['numbering']]['DaysUploads'] = responseuserdata['DaysUploads'];
                          responsedata[responseuserdata['numbering']]['review'] = responseuserdata['review'];
                          responsedata[responseuserdata['numbering']]['ReviewTime'] = responseuserdata['ReviewTime'];
                          responsedata[responseuserdata['numbering']]['ReviewTimeString'] = responseuserdata['ReviewTimeString'];
                          responsedata[responseuserdata['numbering']]['Code'] = responseuserdata['Code'];
                          responsedata[responseuserdata['numbering']]['datatransmissiondays'] = responseuserdata['datatransmissiondays'];
                          responsedata[responseuserdata['numbering']]['minuploadrequired'] = responseuserdata['minuploadrequired'];
                          if(counter == responsedatalength) {
                            for (var i = 0; i < responsedata.length; i++) {
                              if(responsedata[i]['tDate'] && responsedata[i]['tDate']['StartDate'] && responsedata[i]['eduDate']) {
                                // let datediff = this.calculateDiff(responsedata[i]['tDate']['StartDate'], responsedata[i]['eduDate']);
                                let datediff = this.calculateDiff(responsedata[i]['tDate']['StartDate'], responsedata[i]['tDate']['FirstDate']);
                                responsedata[i]['datediff'] = datediff;
                                if(responsedata[i]['DaysUploads'] >= responsedata[i]['minuploadrequired'] && datediff < responsedata[i]['datatransmissiondays'] && responsedata[i]['Code'].trim() == "-") {
                                  responsedata[i]['Code'] = "99453 (BDOS=EDOS="+this.formatDateM(responsedata[i]['tDate']['FirstDate'].replaceAll('-','/') + " UTC")+")";
                                }
                                else if(responsedata[i]['DaysUploads'] >= responsedata[i]['minuploadrequired'] && datediff < responsedata[i]['datatransmissiondays'] && responsedata[i]['Code'].trim() != "-") {
                                  if(responsedata[i]['rDate'] && responsedata[i]['rDate']['StartDate']){
                                    let codearr = responsedata[i]['Code'].split(", ");
                                    let codestr = "";
                                    for(var x = 0; x < codearr.length; x++) {
                                      codestr = codestr + codearr[x] + " (BDOS=EDOS="+this.formatDateM(responsedata[i]['rDate']['StartDate'].replaceAll('-','/') + " UTC")+")";
                                      if(x != codearr.length - 1){
                                        codestr = codestr + ", ";
                                      }
                                    }
                                    responsedata[i]['Code'] = codestr;
                                  }
                                  responsedata[i]['Code'] = "99453 (BDOS=EDOS="+this.formatDateM(responsedata[i]['tDate']['FirstDate'].replaceAll('-','/') + " UTC")+"), " + responsedata[i]['Code'];
                                }
                                else if(responsedata[i]['Code'].trim() != "-" && responsedata[i]['rDate'] && responsedata[i]['rDate']['StartDate']) {
                                  let codearr = responsedata[i]['Code'].split(", ");
                                  let codestr = "";
                                  for(var x= 0; x < codearr.length; x++) {
                                    codestr = codestr + codearr[x] + " (BDOS=EDOS="+this.formatDateM(responsedata[i]['rDate']['StartDate'].replaceAll('-','/') + " UTC")+")";
                                    if(x != codearr.length - 1){
                                      codestr = codestr + ", ";
                                    }
                                  }
                                  responsedata[i]['Code'] = codestr;
                                }
                              }
                              if(responsedata[i]['tDate'] && responsedata[i]['tDate']['StartDate'] && responsedata[i]['tDate']['EndDate']) {
                                responsedata[i]['start_date'] = this.formatDateM(responsedata[i]['tDate']['StartDate'].replaceAll('-','/') + " UTC");
                                responsedata[i]['end_date'] = this.formatDateM(responsedata[i]['tDate']['EndDate'].replaceAll('-','/') + " UTC");
                              }
                              else {
                                responsedata[i]['start_date'] = "";
                                responsedata[i]['end_date'] = "";
                              }
                              if(responsedata[i]['rDate'] && responsedata[i]['rDate']['StartDate'] && responsedata[i]['rDate']['EndDate']) {
                                responsedata[i]['review_start_date'] = this.formatDateM(responsedata[i]['rDate']['StartDate'] + " UTC");
                                responsedata[i]['review_end_date'] = this.formatDateM(responsedata[i]['rDate']['EndDate'] + " UTC");
                              }
                              else {
                                responsedata[i]['review_start_date'] = "";
                                responsedata[i]['review_end_date'] = "";
                              }
                              if(responsedata[i]['eduDate'] && responsedata[i]['eduDate'] != "0000-00-00 00:00:00") {
                                responsedata[i]['eduDate'] = this.formatDateM(responsedata[i]['eduDate'].replaceAll('-','/') + " UTC");
                              }
                              else {
                                responsedata[i]['eduDate'] = "";
                              }
                              // if(responsedata[i]['firstName'] == 'Albert'){
                              //   console.log(responsedata[i]);
                              // }
                              responsedata[i]['patient_sub_btoa'] = btoa(responsedata[i]['patient_sub']);
                            }
                            console.log(responsedata);
                            // this.billingdataList = responsedata;
                            this.billingdataList = responsedata.map(item=> {
                              if(item.review && item.review.indexOf('|') !== -1) {
                                let partial = item.review.split('|');
                                return {...item,
                                  prim_diag:partial[0],
                                  sec_diag:partial[1],
                                  all_reviews : partial[2].split(',')
                                }
                              }
                              else {
                                return {...item,
                                  prim_diag: '',
                                  sec_diag: '',
                                  all_reviews : ''
                                }
                              }
                            });
                            this.billingdataListLength = responsedata.length;
                            // console.log(this.reviewdataListLength);
                            // console.log(this.dtElements);
                            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                              if(dtElement.dtInstance) {
                              dtElement.dtInstance.then((dtInstance: any) => {
                                if(dtInstance.table().node().id == "fourth-table") {
                                  dtInstance.destroy();
                                  this.dtTrigger3.next();
                                }
                              });
                              }
                              else {
                                this.dtTrigger3.next();
                              }
                            });
                            this.screencontent.innerHTML = "";
                            this.screen.style.display = 'none';
                          }
                        },
                        async (error: any) => {
                          counter++;
                          counterfail++;
                          var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
                          screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
                          screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
                          this.screencontent.innerHTML = screenContent;
                          if(counter == responsedatalength) {
                            this.billingdataList = responsedata.map(item=> {
                              if(item.review && item.review.indexOf('|') !== -1) {
                                let partial = item.review.split('|');
                                return {...item,
                                  prim_diag:partial[0],
                                  sec_diag:partial[1],
                                  all_reviews : partial[2].split(',')
                                }
                              }
                              else {
                                return {...item,
                                  prim_diag: '',
                                  sec_diag: '',
                                  all_reviews : ''
                                }
                              }
                            });
                            this.billingdataListLength = responsedata.length;
                            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                              if(dtElement.dtInstance) {
                              dtElement.dtInstance.then((dtInstance: any) => {
                                if(dtInstance.table().node().id == "fourth-table") {
                                  dtInstance.destroy();
                                  this.dtTrigger3.next();
                                }
                              });
                              }
                              else {
                                this.dtTrigger3.next();
                              }
                            });
                            this.screencontent.innerHTML = "";
                            this.screen.style.display = 'none';
                          }
                        });
                      }
                    }
                    else {
                      counter++;
                      counterfail++;
                      var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
                      screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
                      screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
                      this.screencontent.innerHTML = screenContent;
                    }
                  }
                },
                async (error: any) => {
                  // console.log(error);
                  // console.log(error.url);
                  var mySubString = error.url.substring(
                    error.url.lastIndexOf("patientsub=") + 11
                  );
                  mySubString = mySubString.substring(
                    0, mySubString.indexOf("&")
                  );
                  // console.log(mySubString);
                  var myNumbering = error.url.substring(
                    error.url.lastIndexOf("numbering=") + 10
                  );
                  myNumbering = myNumbering.substring(
                    0, myNumbering.indexOf("&")
                  );
                  // console.log(myNumbering);
                  let params = new HttpParams({
                    fromObject: {
                      userpull_id: localStorage.getItem('userpull_id'),
                      fromdate: this.formatUTCDBDateTime(billingData.billingfromdate+" 00:00:00"),
                      todate: this.formatUTCDBDateTime(billingData.billingtodate+" 23:59:59"),
                      patientsub: mySubString,
                      numbering: myNumbering,
                      type: 'billing'
                    }
                  });
                  this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getsubreportdata', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
                  .subscribe((responseuser: any) => {
                    // console.log(responseuser);
                    counter++;
                    countersuccess++;
                    var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
                    screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
                    screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
                    this.screencontent.innerHTML = screenContent;
                    var responseuserdata = JSON.parse(responseuser.data);
                    responsedata[responseuserdata['numbering']]['tDate'] = responseuserdata['tDate'];
                    responsedata[responseuserdata['numbering']]['rDate'] = responseuserdata['rDate'];
                    responsedata[responseuserdata['numbering']]['DaysUploads'] = responseuserdata['DaysUploads'];
                    responsedata[responseuserdata['numbering']]['review'] = responseuserdata['review'];
                    responsedata[responseuserdata['numbering']]['ReviewTime'] = responseuserdata['ReviewTime'];
                    responsedata[responseuserdata['numbering']]['ReviewTimeString'] = responseuserdata['ReviewTimeString'];
                    responsedata[responseuserdata['numbering']]['Code'] = responseuserdata['Code'];
                    responsedata[responseuserdata['numbering']]['datatransmissiondays'] = responseuserdata['datatransmissiondays'];
                    responsedata[responseuserdata['numbering']]['minuploadrequired'] = responseuserdata['minuploadrequired'];
                    if(counter == responsedatalength) {
                      for (var i = 0; i < responsedata.length; i++) {
                        if(responsedata[i]['tDate'] && responsedata[i]['tDate']['StartDate'] && responsedata[i]['eduDate']) {
                          // let datediff = this.calculateDiff(responsedata[i]['tDate']['StartDate'], responsedata[i]['eduDate']);
                          let datediff = this.calculateDiff(responsedata[i]['tDate']['StartDate'], responsedata[i]['tDate']['FirstDate']);
                          responsedata[i]['datediff'] = datediff;
                          if(responsedata[i]['DaysUploads'] >= responsedata[i]['minuploadrequired'] && datediff < responsedata[i]['datatransmissiondays'] && responsedata[i]['Code'].trim() == "-") {
                            responsedata[i]['Code'] = "99453 (BDOS=EDOS="+this.formatDateM(responsedata[i]['tDate']['FirstDate'].replaceAll('-','/') + " UTC")+")";
                          }
                          else if(responsedata[i]['DaysUploads'] >= responsedata[i]['minuploadrequired'] && datediff < responsedata[i]['datatransmissiondays'] && responsedata[i]['Code'].trim() != "-") {
                            if(responsedata[i]['rDate'] && responsedata[i]['rDate']['StartDate']){
                              let codearr = responsedata[i]['Code'].split(", ");
                              let codestr = "";
                              for(var x = 0; x < codearr.length; x++) {
                                codestr = codestr + codearr[x] + " (BDOS=EDOS="+this.formatDateM(responsedata[i]['rDate']['StartDate'].replaceAll('-','/') + " UTC")+")";
                                if(x != codearr.length - 1){
                                  codestr = codestr + ", ";
                                }
                              }
                              responsedata[i]['Code'] = codestr;
                            }
                            responsedata[i]['Code'] = "99453 (BDOS=EDOS="+this.formatDateM(responsedata[i]['tDate']['FirstDate'].replaceAll('-','/') + " UTC")+"), " + responsedata[i]['Code'];
                          }
                          else if(responsedata[i]['Code'].trim() != "-" && responsedata[i]['rDate'] && responsedata[i]['rDate']['StartDate']) {
                            let codearr = responsedata[i]['Code'].split(", ");
                            let codestr = "";
                            for(var x= 0; x < codearr.length; x++) {
                              codestr = codestr + codearr[x] + " (BDOS=EDOS="+this.formatDateM(responsedata[i]['rDate']['StartDate'].replaceAll('-','/') + " UTC")+")";
                              if(x != codearr.length - 1){
                                codestr = codestr + ", ";
                              }
                            }
                            responsedata[i]['Code'] = codestr;
                          }
                        }
                        if(responsedata[i]['tDate'] && responsedata[i]['tDate']['StartDate'] && responsedata[i]['tDate']['EndDate']) {
                          responsedata[i]['start_date'] = this.formatDateM(responsedata[i]['tDate']['StartDate'].replaceAll('-','/') + " UTC");
                          responsedata[i]['end_date'] = this.formatDateM(responsedata[i]['tDate']['EndDate'].replaceAll('-','/') + " UTC");
                        }
                        else {
                          responsedata[i]['start_date'] = "";
                          responsedata[i]['end_date'] = "";
                        }
                        if(responsedata[i]['rDate'] && responsedata[i]['rDate']['StartDate'] && responsedata[i]['rDate']['EndDate']) {
                          responsedata[i]['review_start_date'] = this.formatDateM(responsedata[i]['rDate']['StartDate'].replaceAll('-','/') + " UTC");
                          responsedata[i]['review_end_date'] = this.formatDateM(responsedata[i]['rDate']['EndDate'].replaceAll('-','/') + " UTC");
                        }
                        else {
                          responsedata[i]['review_start_date'] = "";
                          responsedata[i]['review_end_date'] = "";
                        }
                        if(responsedata[i]['eduDate'] && responsedata[i]['eduDate'] != "0000-00-00 00:00:00") {
                          responsedata[i]['eduDate'] = this.formatDateM(responsedata[i]['eduDate'].replaceAll('-','/') + " UTC");
                        }
                        else {
                          responsedata[i]['eduDate'] = "";
                        }
                        // if(responsedata[i]['firstName'] == 'Albert'){
                        //   console.log(responsedata[i]);
                        // }
                        responsedata[i]['patient_sub_btoa'] = btoa(responsedata[i]['patient_sub']);
                      }
                      console.log(responsedata);
                      // this.billingdataList = responsedata;
                      this.billingdataList = responsedata.map(item=> {
                        if(item.review && item.review.indexOf('|') !== -1) {
                          let partial = item.review.split('|');
                          return {...item,
                            prim_diag:partial[0],
                            sec_diag:partial[1],
                            all_reviews : partial[2].split(',')
                          }
                        }
                        else {
                          return {...item,
                            prim_diag: '',
                            sec_diag: '',
                            all_reviews : ''
                          }
                        }
                      });
                      this.billingdataListLength = responsedata.length;
                      // console.log(this.reviewdataListLength);
                      // console.log(this.dtElements);
                      this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                        if(dtElement.dtInstance) {
                        dtElement.dtInstance.then((dtInstance: any) => {
                          if(dtInstance.table().node().id == "fourth-table") {
                            dtInstance.destroy();
                            this.dtTrigger3.next();
                          }
                        });
                        }
                        else {
                          this.dtTrigger3.next();
                        }
                      });
                      this.screencontent.innerHTML = "";
                      this.screen.style.display = 'none';
                    }
                  },
                  async (error: any) => {
                    counter++;
                    counterfail++;
                    var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
                    screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
                    screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
                    this.screencontent.innerHTML = screenContent;
                    if(counter == responsedatalength) {
                      this.billingdataList = responsedata.map(item=> {
                        if(item.review && item.review.indexOf('|') !== -1) {
                          let partial = item.review.split('|');
                          return {...item,
                            prim_diag:partial[0],
                            sec_diag:partial[1],
                            all_reviews : partial[2].split(',')
                          }
                        }
                        else {
                          return {...item,
                            prim_diag: '',
                            sec_diag: '',
                            all_reviews : ''
                          }
                        }
                      });
                      this.billingdataListLength = responsedata.length;
                      this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                        if(dtElement.dtInstance) {
                        dtElement.dtInstance.then((dtInstance: any) => {
                          if(dtInstance.table().node().id == "fourth-table") {
                            dtInstance.destroy();
                            this.dtTrigger3.next();
                          }
                        });
                        }
                        else {
                          this.dtTrigger3.next();
                        }
                      });
                      this.screencontent.innerHTML = "";
                      this.screen.style.display = 'none';
                    }
                  });
                });
              }, 100*(k+1));
            }
          }
          else {
            this.billingdataListLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "fourth-table") {
                  dtInstance.destroy();
                  this.dtTrigger3.next();
                }
              });
              }
              else {
                this.dtTrigger3.next();
              }
            });
          }
        }
      },
      async (error: any) => {
        // this.screen.style.display = 'none';
        // console.log(error);
        console.log(error);
        this.screencontent.innerHTML = "";
        if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
          alert("Request timeout. Please try again.");
          this.screen.style.display = 'none';
        }
        else {
          this.screen.style.display = 'none';
          console.log(error);
          await Auth.signOut({
            global: true
          });
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.router.navigateByUrl('/sign-in');
        }
      });
    }
  }

  validateReviewForm = ( reviewData ) => {
    console.log(reviewData);
    // if(reviewData.forreviewmonth == undefined || reviewData.forreviewmonth == "") {
    //   alert("Please provide Month");
    // }
    // else if(reviewData.selreviewprovider == undefined || reviewData.selreviewprovider == "") {
    //   alert("Please choose Provider All/Any");
    // }
    if(reviewData.reviewfromdate == undefined || reviewData.reviewfromdate == "") {
      alert("Please provide From Date");
    }
    else if(reviewData.reviewtodate == undefined || reviewData.reviewtodate == "") {
      alert("Please provide To Date");
    }
    else if(reviewData.reviewfromdate > reviewData.reviewtodate) {
      alert("From Date must be less than from To Date");
    }
    else {
      // var reviewDataArr = reviewData.forreviewmonth.split("-");
      // if(reviewDataArr.length != 2) {
      //   alert("Please provide Valid Month and Year in YYYY-MM format");
      // }
      // else if(reviewDataArr[0].length != 4) {
      //   alert("Please provide Valid Month and Year in YYYY-MM format");
      // }
      // else if(reviewDataArr[0] < 1900 || reviewDataArr[0] > (new Date()).getFullYear()) {
      //   alert("Please provide Valid Month and Year, not more than current year");
      // }
      // else if(reviewDataArr[1] > 12) {
      //   alert("Please provide Valid Month and Year in YYYY-MM format");
      // }
      // else if(reviewDataArr[0] == (new Date()).getFullYear() && reviewDataArr[1] > new Date().getMonth() + 1){
      //   alert("Please provide Valid Month and Year, not more than current month and year");
      // }
      // else {
        if(reviewData.selreviewprovider == undefined || reviewData.selreviewprovider == ""){
          reviewData.selreviewprovider = "all";
        }
        this.screencontent.innerHTML = "";
        this.screen.style.display = 'block';
        let params = new HttpParams({
          fromObject: {
            userpull_id: localStorage.getItem('userpull_id'),
            // month: reviewData.forreviewmonth.split("-")[1],
            // year: reviewData.forreviewmonth.split("-")[0],
            fromdate: reviewData.reviewfromdate,
            todate: reviewData.reviewtodate,
            provider: reviewData.selreviewprovider
          }
        });
        this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/reviewsummary', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          this.screen.style.display = 'none';
          console.log(response);
          if(response.status === "success") {
            var offset = new Date().getTimezoneOffset();
            console.log(offset);
            if(offset >= 0) {
              var offsetstring = "+"+offset;
            }
            else {
              var offsetstring = ""+offset;
            }
            let responsedata = JSON.parse(response.data);
            console.log(responsedata);
            for (var i = 0; i < responsedata.length; i++) {
              if(responsedata[i]['tDate'] && responsedata[i]['tDate']['StartDate'] && responsedata[i]['tDate']['EndDate']) {
                responsedata[i]['month_year'] = this.formatDateM(responsedata[i]['tDate']['StartDate'] + " 00:00:00");
                responsedata[i]['month_year2'] = this.formatDateM(responsedata[i]['tDate']['EndDate'] + " 00:00:00");
              }
              else {
                responsedata[i]['month_year'] = "";
                responsedata[i]['month_year2'] = "";
              }
            }
            this.reviewdataList = responsedata.map(item=> {
              if(item.review && item.review.indexOf('|') !== -1) {
                let partial = item.review.split('|');
                return {...item,
                  prim_diag:partial[0],
                  sec_diag:partial[1],
                  all_reviews : partial[2].split(',')
                }
              }
              else {
                return {...item,
                  prim_diag: '',
                  sec_diag: '',
                  all_reviews : ''
                }
              }
            });
            this.reviewdataListLength = responsedata.length;
            // console.log(this.reviewdataListLength);
            // console.log(this.dtElements);
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "third-table") {
                  dtInstance.destroy();
                  this.dtTrigger2.next();
                }
              });
              }
              else {
                this.dtTrigger2.next();
              }
            });
          }
        },
        async (error: any) => {
          // this.screen.style.display = 'none';
          // console.log(error);
          this.screen.style.display = 'none';
          console.log(error);
          await Auth.signOut({
            global: true
          });
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.router.navigateByUrl('/sign-in');
        });
      // }
    }
  } 

  validateSummaryForm = ( summaryData ) => {
    console.log(summaryData);
    // if(summaryData.formonth == undefined || summaryData.formonth == "") {
    //   alert("Please provide Month");
    // }
    // else if(summaryData.selprovider == undefined || summaryData.selprovider == "") {
    //   alert("Please choose Provider All/Any");
    // }
    if(summaryData.summaryfromdate == undefined || summaryData.summaryfromdate == "") {
      alert("Please provide From Date");
    }
    else if(summaryData.summarytodate == undefined || summaryData.summarytodate == "") {
      alert("Please provide To Date");
    }
    else if(summaryData.summaryfromdate > summaryData.summarytodate) {
      alert("From Date must be less than from To Date");
    }
    else {
      // var summaryDataArr = summaryData.formonth.split("-");
      // if(summaryDataArr.length != 2) {
      //   alert("Please provide Valid Month and Year in YYYY-MM format");
      // }
      // else if(summaryDataArr[0].length != 4) {
      //   alert("Please provide Valid Month and Year in YYYY-MM format");
      // }
      // else if(summaryDataArr[0] < 1900 || summaryDataArr[0] > (new Date()).getFullYear()) {
      //   alert("Please provide Valid Month and Year, not more than current year");
      // }
      // else if(summaryDataArr[1] > 12) {
      //   alert("Please provide Valid Month and Year in YYYY-MM format");
      // }
      // else if(summaryDataArr[0] == (new Date()).getFullYear() && summaryDataArr[1] > new Date().getMonth() + 1){
      //   alert("Please provide Valid Month and Year, not more than current month and year");
      // }
      // else {
        if(summaryData.selprovider == undefined || summaryData.selprovider == "") {
          summaryData.selprovider = "all";
        }
        //alert("This functionality is currently in Progress..");
        //return false;
        this.screencontent.innerHTML = "";
        this.screen.style.display = 'block';
        // let params = new HttpParams({
        //   fromObject: {
        //     userpull_id: localStorage.getItem('userpull_id'),
        //     fromdate: summaryData.formonth+"-01",
        //     todate: summaryData.formonth+"-31",
        //     selprovider: summaryData.selprovider
        //   }
        // });
        let params = new HttpParams({
          fromObject: {
            userpull_id: localStorage.getItem('userpull_id'),
            // month: summaryData.formonth.split("-")[1],
            // year: summaryData.formonth.split("-")[0],
            fromdate: summaryData.summaryfromdate,
            todate: summaryData.summarytodate,
            provider: summaryData.selprovider
          }
        });
        this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getsummaryreport', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          this.screen.style.display = 'none';
          // console.log(response);
          if(response.status === "success") {
            var offset = new Date().getTimezoneOffset();
            console.log(offset);
            if(offset >= 0) {
              var offsetstring = "+"+offset;
            }
            else {
              var offsetstring = ""+offset;
            }
            let responsedata = JSON.parse(response.data);
            // console.log(responsedata);
            for (var i = 0; i < responsedata.length; i++) {
              // responsedata[i]['start_date'] = this.formatDateM(responsedata[i]['start_date']);
              // responsedata[i]['end_date'] = this.formatDateM(responsedata[i]['end_date']);
              if(responsedata[i]['tDate'] && responsedata[i]['tDate']['StartDate'] && responsedata[i]['tDate']['EndDate']) {
                responsedata[i]['start_date'] = this.formatDateM(responsedata[i]['tDate']['StartDate'] + " 00:00:00");
                responsedata[i]['end_date'] = this.formatDateM(responsedata[i]['tDate']['EndDate'] + " 00:00:00");
              }
              else {
                responsedata[i]['start_date'] = "";
                responsedata[i]['end_date'] = "";
              }
              var providerarr = responsedata[i]['provider_name'].split(';');
              var providerstr = "";
              for(var j = 0; j < providerarr.length; j++) {
                if(providerarr[j].trim() != '') {
                  if(providerstr) {
                    providerstr = providerstr + ',' + providerarr[j].trim();
                  }
                  else {
                    providerstr = providerstr + providerarr[j].trim();
                  }
                }
              }
              responsedata[i]['provider_name'] = providerstr.split(',');
            }
            this.summarydataList = responsedata;
            this.summarydataListLength = responsedata.length;
            // console.log(this.summarydataListLength);
            // console.log(this.dtElements);
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "second-table") {
                  dtInstance.destroy();
                  this.dtTrigger1.next();
                }
              });
              }
              else {
                this.dtTrigger1.next();
              }
            });
            // if(this.dtElement && this.dtElement.dtInstance) {
            // console.log(this.dtElement.dtInstance);
            // }
            // if(this.dtElement && this.dtElement.dtInstance) {
            //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            //     dtInstance.destroy();
            //     this.dtTrigger.next();
            //   });
            // }
            // else {
            //   this.dtTrigger.next();
            // }
          }
        },
        async (error: any) => {
          // this.screen.style.display = 'none';
          // console.log(error);
          this.screen.style.display = 'none';
          console.log(error);
          await Auth.signOut({
            global: true
          });
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.router.navigateByUrl('/sign-in');
        });
      // }
    }
  }

  validateSearchForm = ( searchData ) =>{
    console.log(searchData);
    this.selectedPatientStatus = false;
    if(searchData.fromdate == undefined || searchData.fromdate == "") {
      alert("Please provide From Date");
    }
    else if(searchData.todate == undefined || searchData.todate == "") {
      alert("Please provide To Date");
    }
    // else if(searchData.devicestatus == undefined || searchData.devicestatus == "") {
    //   alert("Please choose status you are looking for");
    // }
    else if(searchData.fromdate > searchData.todate) {
      alert("From Date must be less than from To Date");
    }
    else {
      if(searchData.devicestatus == undefined || searchData.devicestatus == "") {
        searchData.devicestatus = "alert";
      }
      this.reusablesearchdata = searchData;
      this.screencontent.innerHTML = "";
      this.screen.style.display = 'block';
      let params = new HttpParams({
        fromObject: {
          userpull_id: localStorage.getItem('userpull_id'),
          fromdate: this.formatUTCDBDateTime(searchData.fromdate+" 00:00:00"),
          todate: this.formatUTCDBDateTime(searchData.todate+" 23:59:59"),
          devicestatus: searchData.devicestatus,
          havingsymptom: searchData.symptom,
          havingrisk: searchData.risk,
          token: uuidv4()
        }
      });
      this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getreportdatastatussimple1', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        this.screen.style.display = 'none';
        // console.log(response);
        if(response.status === "success") {
          var offset = new Date().getTimezoneOffset();
          console.log(offset);
          if(offset >= 0) {
            var offsetstring = "+"+offset;
          }
          else {
            var offsetstring = ""+offset;
          }
          let responsedata = JSON.parse(response.data);
          console.log(responsedata);
          this.devicedataStatus = response.devicesubstatus;
          if(responsedata.length > 0) {
            this.screen.style.display = 'block';
            var responsedatalength = responsedata.length;
            var counter = 0;
            var countersuccess = 0;
            var counterfail = 0;
            for (let k = 0; k < responsedata.length; k++) {
              setTimeout (() => {
                let params = new HttpParams({
                  fromObject: {
                    userpull_id: localStorage.getItem('userpull_id'),
                    fromdate: this.formatUTCDBDateTime(searchData.fromdate+" 00:00:00"),
                    todate: this.formatUTCDBDateTime(searchData.todate+" 23:59:59"),
                    patientsub: responsedata[k]['sub'],
                    numbering: k.toString()
                  }
                });
                if(response.devicestatus == "missing") {
                  params = new HttpParams({
                    fromObject: {
                      userpull_id: localStorage.getItem('userpull_id'),
                      fromdate: this.formatUTCDBDateTime(searchData.fromdate+" 00:00:00"),
                      todate: this.formatUTCDBDateTime(searchData.todate+" 23:59:59"),
                      patientsub: responsedata[k]['sub'],
                      devicestatus: response.devicestatus,
                      numbering: k.toString()
                    }
                  });
                }
                this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getsubreportdata', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
                .subscribe((responseuser: any) => {
                  // console.log(responseuser);
                  var responseuserdata = JSON.parse(responseuser.data);
                  counter++;
                  countersuccess++;
                  var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
                  screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
                  screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
                  this.screencontent.innerHTML = screenContent;
                  responsedata[responseuserdata['numbering']]['tDate'] = responseuserdata['tDate'];
                  responsedata[responseuserdata['numbering']]['rDate'] = responseuserdata['rDate'];
                  responsedata[responseuserdata['numbering']]['DaysUploads'] = responseuserdata['DaysUploads'];
                  responsedata[responseuserdata['numbering']]['availability'] = responseuserdata['availability'];
                  responsedata[responseuserdata['numbering']]['availabilitydetail'] = responseuserdata['availabilitydetail'];
                  if(counter == responsedatalength) {
                    for (var i = 0; i < responsedata.length; i++) {
                      if(responsedata[i]['timestamp'] && responsedata[i]['timestamp'] != "") {
                        responsedata[i]['timestamp_actual'] = responsedata[i]['timestamp'];
                        responsedata[i]['timestamp'] = this.formatTimeM(responsedata[i]['timestamp'].replaceAll('-','/') + " UTC");
                      }
                      if(responsedata[i]['created'] && responsedata[i]['created'] != "0000-00-00 00:00:00") {
                        responsedata[i]['created_actual'] = responsedata[i]['created'];
                        responsedata[i]['created'] = this.formatTimeM(responsedata[i]['created'].replaceAll('-','/') + " UTC");
                      }
                      if(responsedata[i]['reviewedon'] && responsedata[i]['reviewedon'] != "") {
                        responsedata[i]['reviewedon'] = this.formatTimeM(responsedata[i]['reviewedon'].replaceAll('-','/') + " UTC")
                      }
                      // var providerarr = [];
                      // if(responsedata[i]['provider_name'] != "") {
                      //   providerarr = responsedata[i]['provider_name'].split(';');
                      // }
                      // var providerstr = "";
                      // for(var j = 0; j < providerarr.length; j++) {
                      //   if(providerarr[j].trim() != '') {
                      //     if(providerstr) {
                      //       providerstr = providerstr + ', ' + providerarr[j].trim();
                      //     }
                      //     else {
                      //       providerstr = providerstr + providerarr[j].trim();
                      //     }
                      //   }
                      // }
                      // responsedata[i]['provider_name'] = providerstr;
                    }
                    if(response.devicestatus == 'missing') {
                      for (var i = 0; i < responsedata.length; i++) {
                        responsedata[i]['timestamp'] = "";
                        responsedata[i]['status'] = 'Missing';
                        responsedata[i]['device_name_str'] = responsedata[i]['device_name'].trim();
                      }
                    }
                    else if(response.devicestatus == 'all' || response.devicestatus == 'alert' || response.devicestatus == 'criticalalert' || response.devicestatus == 'inrange') {
                      for (var i = 0; i < responsedata.length; i++) {
                        if(responsedata[i]['out_of_range_alert']) {
                          responsedata[i]['status'] = 'Alert';
                        }
                        // console.log(responsedata[i]['alert_setting']);
                          // var settingjsonarr = responsedata[i]['alert_setting'].trim().replace(/ : /g, ':').replace(/: /g, ':').replace(/ :/g, ':').replace(/" /g, '"').split(" ");
                          var settingjson = "";
                          if(responsedata[i]['alert_setting'] && responsedata[i]['alert_setting'] != null) {
                            var settingjsonarr = responsedata[i]['alert_setting'].trim().split(" ");
                            // console.log(settingjsonarr);
                            for(var j = 0; j < settingjsonarr.length; j++) {
                              if(settingjsonarr[j].trim() != '') {
                                if(settingjson) {
                                  if(settingjsonarr[j].trim() != ":" && settingjsonarr[j].trim().slice(-1) != '"') {
                                    settingjson = settingjson + settingjsonarr[j].trim() + ',';
                                  }
                                  else {
                                    settingjson = settingjson + settingjsonarr[j].trim();
                                  }
                                }
                                else {
                                  settingjson = settingjson + settingjsonarr[j].trim();
                                }
                              }
                            }
                          }
                          if(settingjson.slice(-1) == ",") {
                            settingjson = settingjson.slice(0, -1);
                          }
                          settingjson = '{' + settingjson.replace(/After,Meal/g, 'After Meal').replace(/Normal,Test/g, 'Normal Test') + '}';
                          // console.log(settingjson);
                          var detailjson = "";
                          if(responsedata[i]['detailed_value']) {
                            var detailjsonarr = responsedata[i]['detailed_value'].trim().replace(/ : /g, ':').replace(/: /g, ':').replace(/ :/g, ':').replace(/" /g, '"').replace(/ "/g, '"').split(" ");
                            for(var j = 0; j < detailjsonarr.length; j++) {
                              if(detailjsonarr[j].trim() != '') {
                                if(detailjson) {
                                  detailjson = detailjson + ',' + detailjsonarr[j].trim();
                                }
                                else {
                                  detailjson = detailjson + detailjsonarr[j].trim();
                                }
                              }
                            }
                          }
                          var detailjson = '{' + detailjson.replace(/After,Meal/g, 'After Meal').replace(/Normal,Test/g, 'Normal Test') + '}';
                          // console.log(responsedata[i]['alert_setting']);
                          // console.log(JSON.parse(settingjson));
                          // console.log(responsedata[i]['detailed_value']);
                          // console.log(JSON.parse(detailjson));
                          if(responsedata[i]['device_name'].trim() == "BPM" || responsedata[i]['device_name'].trim() == "Oximeter" || responsedata[i]['device_name'].trim() == "Weight" || responsedata[i]['device_name'].trim() == "Blood Glucose" || responsedata[i]['device_name'].trim() == "Thermometer") {
                            responsedata[i]['alert_setting'] = JSON.parse(settingjson);
                            responsedata[i]['detailed_value'] = JSON.parse(detailjson);
                            if(responsedata[i]['detailed_value']['weight']) {
                              responsedata[i]['detailed_value']['weight'] = Math.floor(responsedata[i]['detailed_value']['weight'])+" lbs";
                            }
                            if(responsedata[i]['detailed_value']['bmi']) {
                              responsedata[i]['detailed_value']['bmi'] = Math.floor(responsedata[i]['detailed_value']['bmi']);
                            }
                          }
                          if(responsedata[i]['critical_alert'] && responsedata[i]['critical_alert']==1){
                            responsedata[i]['super_alert'] = 1;
                          }
                          if(responsedata[i]['device_name'].trim() == "BPM") {
                            responsedata[i]['device_name_str'] = "BPM (SYS/DIA/Pulse)";
                            // Attn Required : 210 => SYS =<70 120 => DIA =<30 120 => PR =<40
                            // if(responsedata[i]['detailed_value']['sysData'] >= 210 || responsedata[i]['detailed_value']['sysData'] <= 70) {
                            //   responsedata[i]['super_alert'] = 1;
                            // }
                            // if(responsedata[i]['detailed_value']['diaData'] >= 120 || responsedata[i]['detailed_value']['diaData'] <= 30) {
                            //   responsedata[i]['super_alert'] = 1;
                            // }
                            // if(responsedata[i]['detailed_value']['pulseData'] >= 120 || responsedata[i]['detailed_value']['pulseData'] <= 40) {
                            //   responsedata[i]['super_alert'] = 1;
                            // }
                          }
                          else if(responsedata[i]['device_name'].trim() == "Oximeter") {
                            responsedata[i]['device_name_str'] = "Pulse Oximeter (Spo2%/PR)";
                          }
                          else if(responsedata[i]['device_name'].trim() == "Weight") {
                            responsedata[i]['device_name_str'] = "Weight (lb./BMI)";
                          }
                          else if(responsedata[i]['device_name'].trim() == "Blood Glucose") {
                            responsedata[i]['device_name_str'] = "Blood Glucose in mg/dL (Mode)";
                          }
                          else if(responsedata[i]['device_name'].trim() == "Thermometer") {
                            responsedata[i]['device_name_str'] = "Thermometer (Temp. in Farenheit)";
                          }
                          else {
                            responsedata[i]['device_name_str'] = responsedata[i]['device_name'].trim();
                          }
                        // }
                      }
                    }
                    for (var i = 0; i < responsedata.length; i++) {
                      responsedata[i].masked_phone_number="";
                      if(responsedata[i].phone_number && responsedata[i].phone_number.length > 0){
                        var phonestr = responsedata[i].phone_number.toString();
                        responsedata[i].masked_phone_number = this.make_number_mask(phonestr);
                      }
                      if(this.has_facility){
                        for(var x=0; x<this.facility_arr.length; x++){
                          if(this.facility_arr[x]['idfacility'] == responsedata[i]['facility_id']){
                            responsedata[i]['facility'] = this.facility_arr[x]['facilitytext'];
                          }
                        }
                      }
                      if(responsedata[i]['tDate'] && responsedata[i]['tDate']['StartDate'] && responsedata[i]['tDate']['EndDate']) {
                        responsedata[i]['start_date'] = this.formatDateM(responsedata[i]['tDate']['StartDate'].replaceAll('-','/') + " UTC");
                        responsedata[i]['end_date'] = this.formatDateM(responsedata[i]['tDate']['EndDate'].replaceAll('-','/') + " UTC");
                        responsedata[i]['upload_date'] = this.formatDateM(responsedata[i]['tDate']['FirstDate'].replaceAll('-','/') + " UTC");
                      }
                      else {
                        responsedata[i]['start_date'] = "";
                        responsedata[i]['end_date'] = "";
                      }
                      if(responsedata[i]['rDate'] && responsedata[i]['rDate']['StartDate'] && responsedata[i]['rDate']['EndDate']) {
                        responsedata[i]['review_start_date'] = this.formatDateM(responsedata[i]['rDate']['StartDate'].replaceAll('-','/') + " UTC");
                        responsedata[i]['review_end_date'] = this.formatDateM(responsedata[i]['rDate']['EndDate'].replaceAll('-','/') + " UTC");
                        responsedata[i]['ReviewTime'] = responsedata[i]['rDate']['ReviewTime'];
                        responsedata[i]['ReviewTimeString'] = responsedata[i]['rDate']['ReviewTimeString'];
                      }
                      else {
                        responsedata[i]['review_start_date'] = "";
                        responsedata[i]['review_end_date'] = "";
                      }
                    }
                    console.log(responsedata);
                    this.devicedataList = responsedata;
                    this.devicedataListLength = responsedata.length;
                    // console.log(this.devicedataListLength);
                    // console.log(this.dtElements);
                    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                      if(dtElement.dtInstance) {
                      dtElement.dtInstance.then((dtInstance: any) => {
                        if(dtInstance.table().node().id == "first-table") {
                          dtInstance.destroy();
                          this.dtTrigger.next();
                        }
                      });
                      }
                      else {
                        this.dtTrigger.next();
                      }
                    });
                    // if(this.dtElement && this.dtElement.dtInstance) {
                    //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    //     dtInstance.destroy();
                    //     this.dtTrigger.next();
                    //   });
                    // }
                    // else {
                    //   this.dtTrigger.next();
                    // }
                    this.screencontent.innerHTML = "";
                    this.screen.style.display = 'none';
                  }
                },
                async (error: any) => {
                  counter++;
                  counterfail++;
                  var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
                  screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
                  screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
                  this.screencontent.innerHTML = screenContent;
                  if(counter == responsedatalength) {
                    this.devicedataList = responsedata;
                    this.devicedataListLength = responsedata.length;
                    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                      if(dtElement.dtInstance) {
                      dtElement.dtInstance.then((dtInstance: any) => {
                        if(dtInstance.table().node().id == "first-table") {
                          dtInstance.destroy();
                          this.dtTrigger.next();
                        }
                      });
                      }
                      else {
                        this.dtTrigger.next();
                      }
                    });
                    this.screencontent.innerHTML = "";
                    this.screen.style.display = 'none';
                  }
                });
              }, 100*(k+1));
            }
          }
          else {
            this.devicedataList = responsedata;
            this.devicedataListLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "first-table") {
                  dtInstance.destroy();
                  this.dtTrigger.next();
                }
              });
              }
              else {
                this.dtTrigger.next();
              }
            });
          }
        }
      },
      async (error: any) => {
        // this.screen.style.display = 'none';
        console.log(error);
        if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
          // alert("Request timeout. Please try again.");
          // this.screen.style.display = 'none';
          // await this.delay(6000);
          var root = this;
          var callcounter = 0;
          setTimeout (function requestCallback() {
            root.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getreportdatastatussimple2', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
            .subscribe((response: any) => {
              // root.screen.style.display = 'none';
              // console.log(response);
              if(response.status === "success") {
                var offset = new Date().getTimezoneOffset();
                console.log(offset);
                if(offset >= 0) {
                  var offsetstring = "+"+offset;
                }
                else {
                  var offsetstring = ""+offset;
                }
                let responsedata = JSON.parse(response.data);
                root.devicedataStatus = response.devicesubstatus;
                if(responsedata.length > 0) {
                  root.screen.style.display = 'block';
                  var responsedatalength = responsedata.length;
                  var counter = 0;
                  var countersuccess = 0;
                  var counterfail = 0;
                  for (let k = 0; k < responsedata.length; k++) {
                    setTimeout (() => {
                      let params = new HttpParams({
                        fromObject: {
                          userpull_id: localStorage.getItem('userpull_id'),
                          fromdate: root.formatUTCDBDateTime(searchData.fromdate+" 00:00:00"),
                          todate: root.formatUTCDBDateTime(searchData.todate+" 23:59:59"),
                          patientsub: responsedata[k]['sub'],
                          numbering: k.toString()
                        }
                      });
                      if(response.devicestatus == "missing") {
                        params = new HttpParams({
                          fromObject: {
                            userpull_id: localStorage.getItem('userpull_id'),
                            fromdate: root.formatUTCDBDateTime(searchData.fromdate+" 00:00:00"),
                            todate: root.formatUTCDBDateTime(searchData.todate+" 23:59:59"),
                            patientsub: responsedata[k]['sub'],
                            devicestatus: response.devicestatus,
                            numbering: k.toString()
                          }
                        });
                      }
                      root.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getsubreportdata', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
                      .subscribe((responseuser: any) => {
                        // console.log(responseuser);
                        var responseuserdata = JSON.parse(responseuser.data);
                        counter++;
                        countersuccess++;
                        var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
                        screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
                        screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
                        root.screencontent.innerHTML = screenContent;
                        responsedata[responseuserdata['numbering']]['tDate'] = responseuserdata['tDate'];
                        responsedata[responseuserdata['numbering']]['rDate'] = responseuserdata['rDate'];
                        responsedata[responseuserdata['numbering']]['DaysUploads'] = responseuserdata['DaysUploads'];
                        if(counter == responsedatalength) {
                          for (var i = 0; i < responsedata.length; i++) {
                            if(responsedata[i]['timestamp'] && responsedata[i]['timestamp'] != "") {
                              responsedata[i]['timestamp_actual'] = responsedata[i]['timestamp'];
                              responsedata[i]['timestamp'] = root.formatTimeM(responsedata[i]['timestamp'].replaceAll('-','/') + " UTC");
                            }
                            if(responsedata[i]['created'] && responsedata[i]['created'] != "0000-00-00 00:00:00") {
                              responsedata[i]['created_actual'] = responsedata[i]['created'];
                              responsedata[i]['created'] = root.formatTimeM(responsedata[i]['created'].replaceAll('-','/') + " UTC");
                            }
                            if(responsedata[i]['reviewedon'] && responsedata[i]['reviewedon'] != "") {
                              responsedata[i]['reviewedon'] = root.formatTimeM(responsedata[i]['reviewedon'].replaceAll('-','/') + " UTC")
                            }
                            // var providerarr = [];
                            // if(responsedata[i]['provider_name'] != "") {
                            //   providerarr = responsedata[i]['provider_name'].split(';');
                            // }
                            // var providerstr = "";
                            // for(var j = 0; j < providerarr.length; j++) {
                            //   if(providerarr[j].trim() != '') {
                            //     if(providerstr) {
                            //       providerstr = providerstr + ', ' + providerarr[j].trim();
                            //     }
                            //     else {
                            //       providerstr = providerstr + providerarr[j].trim();
                            //     }
                            //   }
                            // }
                            // responsedata[i]['provider_name'] = providerstr;
                          }
                          if(response.devicestatus == 'missing') {
                            for (var i = 0; i < responsedata.length; i++) {
                              responsedata[i]['timestamp'] = "";
                              responsedata[i]['status'] = 'Missing';
                              responsedata[i]['device_name_str'] = responsedata[i]['device_name'].trim();
                            }
                          }
                          else if(response.devicestatus == 'all' || response.devicestatus == 'alert' || response.devicestatus == 'criticalalert' || response.devicestatus == 'inrange') {
                            for (var i = 0; i < responsedata.length; i++) {
                              if(responsedata[i]['out_of_range_alert']) {
                                responsedata[i]['status'] = 'Alert';
                              }
                              // console.log(responsedata[i]['alert_setting']);
                                // var settingjsonarr = responsedata[i]['alert_setting'].trim().replace(/ : /g, ':').replace(/: /g, ':').replace(/ :/g, ':').replace(/" /g, '"').split(" ");
                                var settingjson = "";
                                if(responsedata[i]['alert_setting'] && responsedata[i]['alert_setting'] != null) {
                                  var settingjsonarr = responsedata[i]['alert_setting'].trim().split(" ");
                                  // console.log(settingjsonarr);
                                  for(var j = 0; j < settingjsonarr.length; j++) {
                                    if(settingjsonarr[j].trim() != '') {
                                      if(settingjson) {
                                        if(settingjsonarr[j].trim() != ":" && settingjsonarr[j].trim().slice(-1) != '"') {
                                          settingjson = settingjson + settingjsonarr[j].trim() + ',';
                                        }
                                        else {
                                          settingjson = settingjson + settingjsonarr[j].trim();
                                        }
                                      }
                                      else {
                                        settingjson = settingjson + settingjsonarr[j].trim();
                                      }
                                    }
                                  }
                                }
                                if(settingjson.slice(-1) == ",") {
                                  settingjson = settingjson.slice(0, -1);
                                }
                                settingjson = '{' + settingjson.replace(/After,Meal/g, 'After Meal').replace(/Normal,Test/g, 'Normal Test') + '}';
                                // console.log(settingjson);
                                var detailjson = "";
                                if(responsedata[i]['detailed_value']) {
                                  var detailjsonarr = responsedata[i]['detailed_value'].trim().replace(/ : /g, ':').replace(/: /g, ':').replace(/ :/g, ':').replace(/" /g, '"').replace(/ "/g, '"').split(" ");
                                  for(var j = 0; j < detailjsonarr.length; j++) {
                                    if(detailjsonarr[j].trim() != '') {
                                      if(detailjson) {
                                        detailjson = detailjson + ',' + detailjsonarr[j].trim();
                                      }
                                      else {
                                        detailjson = detailjson + detailjsonarr[j].trim();
                                      }
                                    }
                                  }
                                }
                                var detailjson = '{' + detailjson.replace(/After,Meal/g, 'After Meal').replace(/Normal,Test/g, 'Normal Test') + '}';
                                // console.log(responsedata[i]['alert_setting']);
                                // console.log(JSON.parse(settingjson));
                                // console.log(responsedata[i]['detailed_value']);
                                // console.log(JSON.parse(detailjson));
                                if(responsedata[i]['device_name'].trim() == "BPM" || responsedata[i]['device_name'].trim() == "Oximeter" || responsedata[i]['device_name'].trim() == "Weight" || responsedata[i]['device_name'].trim() == "Blood Glucose" || responsedata[i]['device_name'].trim() == "Thermometer") {
                                  responsedata[i]['alert_setting'] = JSON.parse(settingjson);
                                  responsedata[i]['detailed_value'] = JSON.parse(detailjson);
                                  if(responsedata[i]['detailed_value']['weight']) {
                                    responsedata[i]['detailed_value']['weight'] = Math.floor(responsedata[i]['detailed_value']['weight'])+" lbs";
                                  }
                                  if(responsedata[i]['detailed_value']['bmi']) {
                                    responsedata[i]['detailed_value']['bmi'] = Math.floor(responsedata[i]['detailed_value']['bmi']);
                                  }
                                }
                                if(responsedata[i]['critical_alert'] && responsedata[i]['critical_alert']==1){
                                  responsedata[i]['super_alert'] = 1;
                                }
                                if(responsedata[i]['device_name'].trim() == "BPM") {
                                  responsedata[i]['device_name_str'] = "BPM (SYS/DIA/Pulse)";
                                  // Attn Required : 210 => SYS =<70 120 => DIA =<30 120 => PR =<40
                                  // if(responsedata[i]['detailed_value']['sysData'] >= 210 || responsedata[i]['detailed_value']['sysData'] <= 70) {
                                  //   responsedata[i]['super_alert'] = 1;
                                  // }
                                  // if(responsedata[i]['detailed_value']['diaData'] >= 120 || responsedata[i]['detailed_value']['diaData'] <= 30) {
                                  //   responsedata[i]['super_alert'] = 1;
                                  // }
                                  // if(responsedata[i]['detailed_value']['pulseData'] >= 120 || responsedata[i]['detailed_value']['pulseData'] <= 40) {
                                  //   responsedata[i]['super_alert'] = 1;
                                  // }
                                }
                                else if(responsedata[i]['device_name'].trim() == "Oximeter") {
                                  responsedata[i]['device_name_str'] = "Pulse Oximeter (Spo2%/PR)";
                                }
                                else if(responsedata[i]['device_name'].trim() == "Weight") {
                                  responsedata[i]['device_name_str'] = "Weight (lb./BMI)";
                                }
                                else if(responsedata[i]['device_name'].trim() == "Blood Glucose") {
                                  responsedata[i]['device_name_str'] = "Blood Glucose in mg/dL (Mode)";
                                }
                                else if(responsedata[i]['device_name'].trim() == "Thermometer") {
                                  responsedata[i]['device_name_str'] = "Thermometer (Temp. in Farenheit)";
                                }
                                else {
                                  responsedata[i]['device_name_str'] = responsedata[i]['device_name'].trim();
                                }
                              // }
                            }
                          }
                          for (var i = 0; i < responsedata.length; i++) {
                            if(root.has_facility){
                              for(var x=0; x<root.facility_arr.length; x++){
                                if(root.facility_arr[x]['idfacility'] == responsedata[i]['facility_id']){
                                  responsedata[i]['facility'] = root.facility_arr[x]['facilitytext'];
                                }
                              }
                            }
                            if(responsedata[i]['tDate'] && responsedata[i]['tDate']['StartDate'] && responsedata[i]['tDate']['EndDate']) {
                              responsedata[i]['start_date'] = root.formatDateM(responsedata[i]['tDate']['StartDate'].replaceAll('-','/') + " UTC");
                              responsedata[i]['end_date'] = root.formatDateM(responsedata[i]['tDate']['EndDate'].replaceAll('-','/') + " UTC");
                              responsedata[i]['upload_date'] = root.formatDateM(responsedata[i]['tDate']['FirstDate'].replaceAll('-','/') + " UTC");
                            }
                            else {
                              responsedata[i]['start_date'] = "";
                              responsedata[i]['end_date'] = "";
                            }
                            if(responsedata[i]['rDate'] && responsedata[i]['rDate']['StartDate'] && responsedata[i]['rDate']['EndDate']) {
                              responsedata[i]['review_start_date'] = root.formatDateM(responsedata[i]['rDate']['StartDate'].replaceAll('-','/') + " UTC");
                              responsedata[i]['review_end_date'] = root.formatDateM(responsedata[i]['rDate']['EndDate'].replaceAll('-','/') + " UTC");
                              responsedata[i]['ReviewTime'] = responsedata[i]['rDate']['ReviewTime'];
                              responsedata[i]['ReviewTimeString'] = responsedata[i]['rDate']['ReviewTimeString'];
                            }
                            else {
                              responsedata[i]['review_start_date'] = "";
                              responsedata[i]['review_end_date'] = "";
                            }
                          }
                          console.log(responsedata);
                          root.devicedataList = responsedata;
                          root.devicedataListLength = responsedata.length;
                          // console.log(root.devicedataListLength);
                          // console.log(root.dtElements);
                          root.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                            if(dtElement.dtInstance) {
                            dtElement.dtInstance.then((dtInstance: any) => {
                              if(dtInstance.table().node().id == "first-table") {
                                dtInstance.destroy();
                                root.dtTrigger.next();
                              }
                            });
                            }
                            else {
                              root.dtTrigger.next();
                            }
                          });
                          // if(root.dtElement && root.dtElement.dtInstance) {
                          //   root.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                          //     dtInstance.destroy();
                          //     root.dtTrigger.next();
                          //   });
                          // }
                          // else {
                          //   root.dtTrigger.next();
                          // }
                          root.screencontent.innerHTML = "";
                          root.screen.style.display = 'none';
                        }
                      },
                      async (error: any) => {
                        counter++;
                        counterfail++;
                        var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
                        screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
                        screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
                        root.screencontent.innerHTML = screenContent;
                        if(counter == responsedatalength) {
                          root.devicedataList = responsedata;
                          root.devicedataListLength = responsedata.length;
                          root.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                            if(dtElement.dtInstance) {
                            dtElement.dtInstance.then((dtInstance: any) => {
                              if(dtInstance.table().node().id == "first-table") {
                                dtInstance.destroy();
                                root.dtTrigger.next();
                              }
                            });
                            }
                            else {
                              root.dtTrigger.next();
                            }
                          });
                          root.screencontent.innerHTML = "";
                          root.screen.style.display = 'none';
                        }
                      });
                    }, 100*(k+1));
                  }
                }
                else {
                  root.devicedataList = responsedata;
                  root.devicedataListLength = responsedata.length;
                  root.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                    if(dtElement.dtInstance) {
                    dtElement.dtInstance.then((dtInstance: any) => {
                      if(dtInstance.table().node().id == "first-table") {
                        dtInstance.destroy();
                        root.dtTrigger.next();
                      }
                    });
                    }
                    else {
                      root.dtTrigger.next();
                    }
                  });
                }
              }
              else {
                callcounter++;
                if(callcounter < 120) {
                  setTimeout (requestCallback, 5000);
                }
              }
            },
            async (error: any) => {
              console.log(error);
            });
          }, 5000);
        }
        else {
          await Auth.signOut({
            global: true
          });
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.router.navigateByUrl('/sign-in');
        }
      });
    }
  }

  processSMS = (formval) =>{
    let forcesms = false;
    let forcephone = false;
    if(formval.forcesms) {
      forcesms = true;
    }
    if(formval.forcephone) {
      forcephone = true;
    }

    if (formval.smstype != undefined) {
      this.smsTypeError = false;
    }else{
      this.smsTypeError = true;
    }

    if (formval.smsText !== ''){
      this.smsError = false;
    }else{
      this.smsError = true;
    }

    if (formval.smsText !== '' && formval.smstype != undefined) {
      this.smsError = false;
      let processedArr = [];
      for(var i=0; i<this.devicedataList.length; i++) {
        if(this.devicedataList[i]['selectedclass']) {
          var found = false;
          for(var j = 0; j < processedArr.length; j++) {
            if (processedArr[j]['sub'] == this.devicedataList[i]['sub']) {
                found = true;
                break;
            }
          }
          if(!found) {
            processedArr.push(this.devicedataList[i]);
          }
        }
      }
      let smsDetailsModal = document.getElementById("sms-send-ankerTag") as HTMLElement;
      smsDetailsModal.click();
      // alert("Implementation under progress...");
      if(processedArr.length > 0){
        this.smsStatus = "Running";
        var processedArrLength = processedArr.length;
        let smsDetailsModal = document.getElementById("sms-details-ankerTag") as HTMLElement;
        smsDetailsModal.click();
        this.htmlToAddSMS = "<div><b>SMS process started...</b></div>";
        this.htmlToAddSMS += "<div><b>Please wait for the 'Close' button at the end...</b></div>";
        var counter = 0;
        var countersuccess = 0;
        var counterfail = 0;
        for (let k = 0; k < processedArrLength; k++) {
          setTimeout (() => {
            console.log(this.smsTextList);
            console.log(formval);
            for(var temp of this.smsTextList){
              if (formval.smstype != 'Custom' && temp.shortname == formval.smstype){
                formval.smsText = temp['en-US'].smstext;
                if(temp[processedArr[k]['p_language']] && formval.patientlang) {
                  formval.smsText = temp[processedArr[k]['p_language']].smstext;
                }
                break;
              }
            }
            let params = {
              userpull_id: localStorage.getItem('userpull_id'),
              user_sub: localStorage.getItem('user_sub'),
              patient_sub: processedArr[k]['sub'],
              patient_name: processedArr[k]['firstName']+" "+processedArr[k]['lastName'],
              smstext: formval.smsText,
              forcesms: forcesms.toString(),
              forcephone: forcephone.toString(),
            }
            console.log(params);
            this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/initiatesms', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
              .subscribe((response: any) => {
                var responsesmsdata = JSON.parse(response.data);
                counter++;
                countersuccess++;
                if(response.status == "success") {
                  this.htmlToAddSMS += '<div class="success"><b>'+counter+' out of '+processedArrLength+'.</b> '+response.message+'</div>';
                }
                else {
                  this.htmlToAddSMS += '<div class="error"><b>'+counter+' out of '+processedArrLength+'.</b> '+response.message+'</div>';
                }
                if(counter == processedArrLength) {
                  this.htmlToAddSMS += '<div><b>Sending process completed...</b></div>';
                  this.smsStatus = "Complete";
                }
              },
              async (error: any) => {
                counter++;
                counterfail++;
                if(counter == processedArrLength) {
                  this.smsStatus = "Complete";
                }
              });
          }, 100*(k+1));
        }
      }
    }
  }

  summarySMS = () =>{
    this.patientlangArr = [];
    this.patientlang = false;
    this.showpatientlang = false;
    let getSmsClass = this;
    getSmsClass.screen.style.display = 'block';    
    this.smstype = undefined;
    this.smsText = "";
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getsmstemplate', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response)
      if (response.status === "success") {
        getSmsClass.screen.style.display = 'none';
        this.smsTextList = JSON.parse(response.data);
        this.smsTextList.push({ shortname: "Custom" })
        let patientSmsModal = document.getElementById("sms-send-ankerTag") as HTMLElement;
        patientSmsModal.click();    
      }
    },
    async (error: any) => {
      console.log(error);
    });
  }

  smstypeChange = (smstype) => {
    this.smsText = "";
    this.showpatientlang = false;
    this.patientlang = false;
    this.patientlangArr = [];
    if (smstype != "Custom"){
      for(var temp of this.smsTextList){
        if (temp.shortname == smstype){
          this.patlang = "en-US";
          this.smsText = temp['en-US'].smstext;
          this.patientlangArr.push('en-US');
          for(var i=0; i<this.devicedataList.length; i++) {
            if(this.devicedataList[i]['selectedclass']) {
              if(this.devicedataList[i]['p_language'] != 'en-US' && temp[this.devicedataList[i]['p_language']] && this.patientlangArr.indexOf(this.devicedataList[i]['p_language']) === -1) {
                this.showpatientlang = true;
                this.patientlangArr.push(this.devicedataList[i]['p_language']);
              }
            }
          }
          this.smsTypeError = false;
          this.smsError = false;
          break;
        }
      }
    }else{
      this.smsText = "";
      this.smsTypeError = false;
    }
  }

  smstextlangChange = (smslang) => {
    console.log(smslang);
    for(var temp of this.smsTextList){
      if (temp.shortname == smslang.smstype){
        this.patlang = "";
        this.smsText = "";
        if(!smslang.patientlang){
          this.patlang = "en-US";
          this.smsText = temp['en-US'].smstext;
        }
        this.smsTypeError = false;
        this.smsError = false;
        break;
      }
    }
  }

  smstextlanguageChange = (smslang) => {
    console.log(smslang);
    console.log(this.smsTextList);
    console.log(this.patlang);
    for(var temp of this.smsTextList){
      if (temp.shortname == smslang.smstype){
        this.smsText = temp['en-US'].smstext;
        if(temp[this.patlang] && smslang.patlangoption) {
          this.smsText = temp[this.patlang].smstext;
        }
        this.smsTypeError = false;
        this.smsError = false;
        break;
      }
    }
  }

  smstextChange = (smsText) => {
    if (smsText !== ''){
      this.smsError = false;
    }
  }

  onSelectDeviceFilter(selectedclass:boolean,filter:any){
    filter.selectedclass=selectedclass;
    // console.log(filter);
    this.selectedPatientStatus = true;
    if(this.devicedataList.every(filter=>!filter.selectedclass)){
      this.selectedPatientStatus = false;
    }
    if (selectedclass) {
      let selectedcounter = 0;
      this.devicedataList.forEach(filter=>{if(filter.selectedclass==true){selectedcounter++}});
      if(selectedcounter == this.devicedataList.length) {
        this.patientallselected = true;
      }
    }
    else {
      this.patientallselected = false;
    }
  }

  onSelectAllDeviceFilter(selectedall:boolean){
    this.patientallselected = selectedall;
    if(selectedall) {
      this.devicedataList.forEach(filter=>{filter.selectedclass=true});
      this.selectedPatientStatus = true;
    }
    if(!selectedall) {
      this.devicedataList.forEach(filter=>{filter.selectedclass=false});
      this.selectedPatientStatus = false;
    }
  }

  onSelectFilter(selectedclass:boolean,filter:any){
    filter.selectedclass=selectedclass;
    // console.log(filter);
    this.teleBilledStatus = 1;
    if(this.televisitdataList.every(filter=>!filter.selectedclass)){
      this.teleBilledStatus = 0;
      // console.log(filter);
      // setTimeout(()=>{
      //   this.televisitdataList.forEach(filter=>filter.selectedclass=true);
      // });
    }
    if (selectedclass) {
      let completedcounter = 0;
      let selectedcounter = 0;
      this.televisitdataList.forEach(filter=>{if(filter.status=="completed"){completedcounter++}});
      this.televisitdataList.forEach(filter=>{if(filter.selectedclass==true){selectedcounter++}});
      if(completedcounter == selectedcounter){
        this.televisitallselected = true;
      }
    }
    else {
      this.televisitallselected = false;
    }
  }

  onSelectAllFilter(selectedall:boolean){
    this.televisitallselected = selectedall;
    if(selectedall) {
      this.televisitdataList.forEach(filter=>{if(filter.status=="completed"){filter.selectedclass=true}});
      this.teleBilledStatus = 1;
    }
    if(!selectedall) {
      this.televisitdataList.forEach(filter=>{if(filter.status=="completed"){filter.selectedclass=false}});
      this.teleBilledStatus = 0;
    }
  }

  processBillTele = () =>{
    console.log(this.televisitdataList);
    console.log(this.myBillDate);
    var billDataArr = this.myBillDate.split("-");
    if(billDataArr.length != 3) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(billDataArr[0].length != 4) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(billDataArr[0] < 1900 || billDataArr[0] > (new Date()).getFullYear()) {
      alert("Please provide Valid Time, not more than current year");
    }
    else if(billDataArr[1] > 12) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(billDataArr[0] == (new Date()).getFullYear() && billDataArr[1] > new Date().getMonth() + 1){
      alert("Please provide Valid Time, not more than current month and year");
    }
    else if(billDataArr[2].split("T")[0] > 31) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(billDataArr[2].split("T")[1] && billDataArr[2].split("T")[1].split(":")[0] && billDataArr[2].split("T")[1].split(":")[0] > 23) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(billDataArr[2].split("T")[1] && billDataArr[2].split("T")[1].split(":")[1] && billDataArr[2].split("T")[1].split(":")[1] > 59) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else {
      let processedArr = [];
      for(var i=0; i<this.televisitdataList.length; i++) {
        if(this.televisitdataList[i]['selectedclass']) {
          processedArr.push(this.televisitdataList[i]);
        }
      }
      console.log(processedArr);
      this.screen.style.display = 'block';
      var responsedatalength = processedArr.length;
      this.screencontent.innerHTML = "<div><b>"+responsedatalength+"</b> data to be processed</div>";
      var counter = 0;
      var countersuccess = 0;
      var counterfail = 0;
      for (let k = 0; k < this.televisitdataList.length; k++) {
        if(this.televisitdataList[k]['selectedclass']) {
          setTimeout (() => {
            let params = {
              userpull_id: localStorage.getItem('userpull_id'),
              idtelevisit: this.televisitdataList[k].idtelevisit,
              billdate: this.formatUTCDBDateTime(this.myBillDate),
              action: 'update',
              numbering: k.toString()
            }
            console.log(params);
            this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updatetelevisitrequest', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
              .subscribe((response: any) => {
                var responseteledata = JSON.parse(response.data);
                counter++;
                countersuccess++;
                var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
                screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
                screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
                this.screencontent.innerHTML = screenContent;
                this.televisitdataList[responseteledata['numbering']]['billdate'] = this.formatUTCDBDateTime(this.myBillDate);
                this.televisitdataList[responseteledata['numbering']]['billdatestr'] = this.formatDateCustom(this.myBillDate);
                if(counter == responsedatalength) {
                  this.screencontent.innerHTML = "";
                  this.screen.style.display = 'none';
                }
              },
              async (error: any) => {
                counter++;
                counterfail++;
                var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
                screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
                screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
                this.screencontent.innerHTML = screenContent;
                if(counter == responsedatalength) {
                  this.screencontent.innerHTML = "";
                  this.screen.style.display = 'none';
                }
              });
          }, 100*(k+1));
        }
      }
    }
  }

  editTelevisit = (teledata) =>{
    console.log(teledata);
    this.screencontent.innerHTML = "";
    this.screen.style.display = 'block';
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
        provider_sub: teledata.provider_sub
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getmedicareservices', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      this.screen.style.display = 'none';
      if(response.status === "success") {
        let responsedata = JSON.parse(response.data);
        console.log(responsedata);
        this.teleDetails = "";
        let editTeleModal = document.getElementById("tele-details-ankerTag") as HTMLElement;
        editTeleModal.click();
        setTimeout (() => {
          teledata.startdatetimeEdit = this.formatTime(teledata['startdatetime'].replaceAll('-','/') + " UTC");
          teledata['durationmin'] = Number(teledata['duration'].split('.')[0]);
          teledata['durationsec'] = Number(teledata['duration'].split('.')[1]);
          teledata['medicare_options'] = responsedata;
          this.teleDetails = teledata;
        }, 500);
      }
    },
    async (error: any) => {
      this.screen.style.display = 'none';
      console.log(error);
      this.screencontent.innerHTML = "";
    });
  }

  validateTeleForm = (teleformData) =>{
    console.log(teleformData);
    console.log(this.teleDetails);
    if(teleformData.startdatetime == undefined || teleformData.startdatetime == "") {
      alert("Please provide Tele-visit Date Time.");
      return false;
    }
    if(teleformData.durationmin < 0) {
      alert("Duration minute must not be less than 0");
      return false;
    }
    if(teleformData.durationmin != null && teleformData.durationmin % 1 != 0) {
      alert("Duration minute should not be decimal number");
      return false;
    }
    if(teleformData.durationsec < 0) {
      alert("Duration second must not be less than 0");
      return false;
    }
    if(teleformData.durationsec != null && teleformData.durationsec % 1 != 0) {
      alert("Duration second should not be decimal number");
      return false;
    }
    if(teleformData.durationsec >= 60) {
      alert("Duration second must not be greater or equal to 1 minute.");
      return false;
    }
    var dursec = "";
    if(teleformData.durationsec != null) {
      dursec = teleformData.durationsec;
      if((String(teleformData.durationsec).length) == 1) {
        dursec = "0"+dursec;
      }
    }
    var durmin = "";
    if(teleformData.durationmin != null) {
      if(dursec == "") {
        durmin = teleformData.durationmin+".00";
      }
      else {
        durmin = teleformData.durationmin+"."+dursec;
      }
    }
    else if(dursec != ""){
      durmin = "0."+dursec;
    }
    teleformData.duration = Number(durmin);
    var startDataArr = teleformData.startdatetime.split("-");
    if(startDataArr.length != 3) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(startDataArr[0].length != 4) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(startDataArr[0] < 1900 || startDataArr[0] > (new Date()).getFullYear()) {
      alert("Please provide Valid Time, not more than current year");
    }
    else if(startDataArr[1] > 12) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(startDataArr[0] == (new Date()).getFullYear() && startDataArr[1] > new Date().getMonth() + 1){
      alert("Please provide Valid Time, not more than current month and year");
    }
    else if(startDataArr[2].split("T")[0] > 31) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(startDataArr[2].split("T")[1] && startDataArr[2].split("T")[1].split(":")[0] && startDataArr[2].split("T")[1].split(":")[0] > 23) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(startDataArr[2].split("T")[1] && startDataArr[2].split("T")[1].split(":")[1] && startDataArr[2].split("T")[1].split(":")[1] > 59) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else {
      this.screencontent.innerHTML = "";
      this.screen.style.display = 'block';
      let params = {
        userpull_id: localStorage.getItem('userpull_id'),
        idtelevisit: this.teleDetails.idtelevisit,
        duration: teleformData.duration,
        medicare_code: teleformData.medicare_code,
        startdatetime: this.formatUTCDBDateTime(teleformData.startdatetime),
        action: 'update'
      }
      console.log(params);
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updatetelevisitrequest', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          if(response.status == "success") {
            this.teleDetails['duration'] = String(teleformData.duration);
            this.teleDetails['medicare_code'] = teleformData.medicare_code;
            this.teleDetails['startdatetime'] = this.formatUTCDBDateTime(teleformData.startdatetime);
            this.teleDetails['startdatetimeEdit'] = this.formatTime(teleformData.startdatetime);
            this.teleDetails['datetimestr'] = this.formatDateCustom(teleformData.startdatetime);
            for(var j = 0; j < this.teleDetails['medicare_options'].length; j++) {
              if(this.teleDetails['medicare_options'][j]['code'] == teleformData.medicare_code){
                this.teleDetails['medicaredetails'] = teleformData.medicare_code + " - " + this.teleDetails['medicare_options'][j]['short_descriptor'];
                if(this.teleDetails['medicare_options'][j]['status'] != "") {
                  this.teleDetails['medicaredetails'] = this.teleDetails['medicaredetails'] + "(" + this.teleDetails['medicare_options'][j]['status'] + ")";
                }
              }
            }
            console.log(this.teleDetails);
            let teleModal = document.getElementById("tele-details-close-button") as HTMLElement;
            teleModal.click();
            alert("Tele-visit updated successfully");
          }
          else {
            alert("Could not update tele-visit. Please try again.");
          }
          this.screen.style.display = 'none';
        },
        async (error: any) => {
          console.log(error);
          alert("Could not update tele-visit. Please try again.");
          this.screen.style.display = 'none';
          this.screencontent.innerHTML = "";
        });
    }
  }
  
  validatetelevisitForm = ( telesearchData ) =>{
    console.log(telesearchData);
    this.teleBilledStatus = 0;
    if(telesearchData.televisitfromdate == undefined || telesearchData.televisitfromdate == "") {
      alert("Please provide From Date");
    }
    else if(telesearchData.televisittodate == undefined || telesearchData.televisittodate == "") {
      alert("Please provide To Date");
    }
    else if(telesearchData.televisitfromdate > telesearchData.televisittodate) {
      alert("From Date must be less than from To Date");
    }
    else {
      this.screencontent.innerHTML = "";
      this.screen.style.display = 'block';
      let params = new HttpParams({
        fromObject: {
          userpull_id: localStorage.getItem('userpull_id'),
          fromdate: this.formatUTCDBDateTime(telesearchData.televisitfromdate+" 00:00:00"),
          todate: this.formatUTCDBDateTime(telesearchData.televisittodate+" 23:59:59"),
          fromweb: 'yes',
          type: telesearchData.type
        }
      });
      this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/gettelevisitreportdata', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response);
        this.showallcheck = false;
        this.televisitallselected = false;
        this.screen.style.display = 'none';
        if(response.status === "success") {
          let responsedata = JSON.parse(response.data);
          console.log(responsedata);
          if(responsedata.length > 0) {
            for (var i = 0; i < responsedata.length; i++) {
              if(responsedata[i]['startdatetime'] && responsedata[i]['startdatetime'] != "" && responsedata[i]['startdatetime'] != "None") {
                responsedata[i]['datetimestr'] = this.formatDateCustom(responsedata[i]['startdatetime'].replaceAll('-','/') + " UTC");
              }
              else {
                responsedata[i]['datetimestr'] = this.formatDateCustom(responsedata[i]['timestamp'].replaceAll('-','/') + " UTC");
              }
              responsedata[i]['billdatestr'] = "";
              if(responsedata[i]['billdate'] != "0000-00-00 00:00:00") {
                responsedata[i]['billdatestr'] = this.formatDateCustom(responsedata[i]['billdate'].replaceAll('-','/') + " UTC");
              }
              responsedata[i]['patient_sub_btoa'] = btoa(responsedata[i]['patient_sub']);
              if(responsedata[i]['status'] == 'completed') {
                this.showallcheck = true;
              }
            }
            this.televisitdataList = responsedata;
            this.televisitdataListLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "fifth-table") {
                  dtInstance.destroy();
                  this.dtTrigger4.next();
                }
              });
              }
              else {
                this.dtTrigger4.next();
              }
            });
          }
          else {
            this.televisitdataList = responsedata;
            this.televisitdataListLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "fifth-table") {
                  dtInstance.destroy();
                  this.dtTrigger4.next();
                }
              });
              }
              else {
                this.dtTrigger4.next();
              }
            });
          }
        }
      },
      async (error: any) => {
        // this.screen.style.display = 'none';
        console.log(error);
        this.screencontent.innerHTML = "";
        if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
          alert("Request timeout. Please try again.");
          this.screen.style.display = 'none';
        }
        else {
          await Auth.signOut({
            global: true
          });
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.router.navigateByUrl('/sign-in');
        }
      });
    }
  }

  validateinventoryForm = ( inventoryForm ) =>{
    console.log(inventoryForm);
    if(inventoryForm.inventoryfromdate == undefined || inventoryForm.inventoryfromdate == "") {
      alert("Please provide From Date");
    }
    else if(inventoryForm.inventorytodate == undefined || inventoryForm.inventorytodate == "") {
      alert("Please provide To Date");
    }
    else if(inventoryForm.inventoryfromdate > inventoryForm.inventorytodate) {
      alert("From Date must be less than from To Date");
    }
    else {
      this.screencontent.innerHTML = "";
      this.screen.style.display = 'block';
      let params = new HttpParams({
        fromObject: {
          userpull_id: localStorage.getItem('userpull_id'),
          fromdate: this.formatUTCDBDateTime(inventoryForm.inventoryfromdate+" 00:00:00"),
          todate: this.formatUTCDBDateTime(inventoryForm.inventorytodate+" 23:59:59"),
          fromweb: 'yes'
        }
      });
      this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getinventoryreport', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response);
        this.screen.style.display = 'none';
        if(response.status === "success") {
          let responsedata = JSON.parse(response.data);
          console.log(responsedata);

          this.inventorydataList = [];

          if(responsedata.length > 0) {
            for(var temp of responsedata){
              var temptemp = temp;
              temptemp["date"] = this.formatDateM(temp.create_time);

              this.inventorydataList.push(temptemp);
            }
            this.inventorydataListLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "seven-table") {
                  dtInstance.destroy();
                  this.dtTrigger7.next();
                }
              });
              }
              else {
                this.dtTrigger7.next();
              }
            });
          }
          else {
            this.inventorydataList = responsedata;
            this.inventorydataListLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "seven-table") {
                  dtInstance.destroy();
                  this.dtTrigger7.next();
                }
              });
              }
              else {
                this.dtTrigger7.next();
              }
            });
          }
        }
      },
      async (error: any) => {
        // this.screen.style.display = 'none';
        console.log(error);
        this.screencontent.innerHTML = "";
        if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
          alert("Request timeout. Please try again.");
          this.screen.style.display = 'none';
        }
        else {
          await Auth.signOut({
            global: true
          });
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.router.navigateByUrl('/sign-in');
        }
      });
    }
  }

  validateinventory2Form = ( inventoryForm ) =>{
    console.log(inventoryForm);
    if(inventoryForm.inventoryfromdate == undefined || inventoryForm.inventoryfromdate == "") {
      alert("Please provide From Date");
    }
    else if(inventoryForm.inventorytodate == undefined || inventoryForm.inventorytodate == "") {
      alert("Please provide To Date");
    }
    else if(inventoryForm.inventoryfromdate > inventoryForm.inventorytodate) {
      alert("From Date must be less than from To Date");
    }
    else {
      this.screencontent.innerHTML = "";
      this.screen.style.display = 'block';
      let params = new HttpParams({
        fromObject: {
          userpull_id: localStorage.getItem('userpull_id'),
          fromdate: this.formatUTCDBDateTime(inventoryForm.inventoryfromdate+" 00:00:00"),
          todate: this.formatUTCDBDateTime(inventoryForm.inventorytodate+" 23:59:59"),
          fromweb: 'yes'
        }
      });
      this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getinventoryreport2', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response);
        this.screen.style.display = 'none';
        if(response.status === "success") {
          let responsedata = JSON.parse(response.data);
          console.log(responsedata);

          this.inventorydata2List = [];

          if(responsedata.length > 0) {
            for(var temp of responsedata){
              var temptemp = temp;
              temptemp["date"] = this.formatDateM(temp.create_time);

              this.inventorydata2List.push(temptemp);
            }
            this.inventorydata2ListLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "eight-table") {
                  dtInstance.destroy();
                  this.dtTrigger8.next();
                }
              });
              }
              else {
                this.dtTrigger8.next();
              }
            });
          }
          else {
            this.inventorydata2List = responsedata;
            this.inventorydata2ListLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "eight-table") {
                  dtInstance.destroy();
                  this.dtTrigger8.next();
                }
              });
              }
              else {
                this.dtTrigger8.next();
              }
            });
          }
        }
      },
      async (error: any) => {
        // this.screen.style.display = 'none';
        console.log(error);
        this.screencontent.innerHTML = "";
        if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
          alert("Request timeout. Please try again.");
          this.screen.style.display = 'none';
        }
        else {
          await Auth.signOut({
            global: true
          });
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.router.navigateByUrl('/sign-in');
        }
      });
    }
  }

  validaterollOutForm = ( inventoryForm ) =>{
    console.log(inventoryForm);
    if(inventoryForm.inventoryfromdate == undefined || inventoryForm.inventoryfromdate == "") {
      alert("Please provide From Date");
    }
    else if(inventoryForm.inventorytodate == undefined || inventoryForm.inventorytodate == "") {
      alert("Please provide To Date");
    }
    else if(inventoryForm.inventoryfromdate > inventoryForm.inventorytodate) {
      alert("From Date must be less than from To Date");
    }
    else {
      this.screencontent.innerHTML = "";
      this.screen.style.display = 'block';
      let params = new HttpParams({
        fromObject: {
          userpull_id: localStorage.getItem('userpull_id'),
          fromdate: this.formatUTCDBDateTime(inventoryForm.inventoryfromdate+" 00:00:00"),
          todate: this.formatUTCDBDateTime(inventoryForm.inventorytodate+" 23:59:59"),
          fromweb: 'yes'
        }
      });
      this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getrolloutreport', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response);
        this.screen.style.display = 'none';
        if(response.status === "success") {
          let responsedata = JSON.parse(response.data);
          console.log(responsedata);

          this.rollOutdata2List = [];

          if(responsedata.length > 0) {
            for(var temp of responsedata){
              var temptemp = temp;

              if(temp.create_time != "0000-00-00 00:00:00") {
                temptemp["temp_date"] = this.formatDateM(temp.create_time);
              }

              if(temp.deviceEducationDate != "0000-00-00 00:00:00") {
                temptemp["temp_EducationDate"] = this.formatDateM(temp.deviceEducationDate);
              }

              if(temp.dataTransmissionDate != "0000-00-00 00:00:00") {
                temptemp["temp_TransmissionDate"] = this.formatDateM(temp.dataTransmissionDate);
              }

              if(temp.birthdate) {
                let birthdate = temp.birthdate;
                temptemp["temp_birthdate"] = this.formatDateM(birthdate + " 00:00:00");
                temptemp['masked_temp_birthdate'] = this.make_number_mask(this.formatDateM(birthdate + " 00:00:00").toString(),4);
              }

              temptemp.masked_phone_number="";
              if(temptemp.phone_number && temptemp.phone_number.length > 0){
                var phonestr = temptemp.phone_number.toString();
                temptemp.masked_phone_number = this.make_number_mask(phonestr);
              }

              this.rollOutdata2List.push(temptemp);
            }
            this.rollOutdata2ListLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "nine-table") {
                  dtInstance.destroy();
                  this.dtTrigger9.next();
                }
              });
              }
              else {
                this.dtTrigger9.next();
              }
            });
          }
          else {
            this.rollOutdata2List = responsedata;
            this.rollOutdata2ListLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "nine-table") {
                  dtInstance.destroy();
                  this.dtTrigger9.next();
                }
              });
              }
              else {
                this.dtTrigger9.next();
              }
            });
          }
        }
      },
      async (error: any) => {
        // this.screen.style.display = 'none';
        console.log(error);
        this.screencontent.innerHTML = "";
        if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
          alert("Request timeout. Please try again.");
          this.screen.style.display = 'none';
        }
        else {
          await Auth.signOut({
            global: true
          });
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.router.navigateByUrl('/sign-in');
        }
      });
    }
  }

  validateCallForm = ( callForm ) =>{
    console.log(callForm);
    if(callForm.callfromdate == undefined || callForm.callfromdate == "") {
      alert("Please provide From Date");
    }
    else if(callForm.calltodate == undefined || callForm.calltodate == "") {
      alert("Please provide To Date");
    }
    else if(callForm.callfromdate > callForm.calltodate) {
      alert("From Date must be less than from To Date");
    }
    else {
      this.screencontent.innerHTML = "";
      this.screen.style.display = 'block';
      let params = new HttpParams({
        fromObject: {
          userpull_id: localStorage.getItem('userpull_id'),
          fromdate: this.formatUTCDBDateTime(callForm.callfromdate+" 00:00:00"),
          todate: this.formatUTCDBDateTime(callForm.calltodate+" 23:59:59")
        }
      });
      this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getcallreport', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response);
        // this.screen.style.display = 'none';
        if(response.status === "success") {
          let responsedata = JSON.parse(response.data);
          console.log(responsedata);
          let users = []
          if(localStorage.getItem('POOL-USERS')) {
            var poolusers = JSON.parse(localStorage.getItem('POOL-USERS'));
            for(var i=0; i<poolusers.length; i++){
              users.push(poolusers[i]);
            }
          }
          if(localStorage.getItem('SYS-USERS')) {
            var sysusers = JSON.parse(localStorage.getItem('SYS-USERS'));
            for(var i=0; i<sysusers.length; i++){
              users.push(sysusers[i]);
            }
          }
          this.callList = [];
          if(responsedata.length > 0) {
            var responsedatalength = responsedata.length;
            // let processedArr = [];
            // for(var i=0; i<responsedatalength; i++) {
            //   if(responsedata[i]['recordingLink']) {
            //     processedArr.push(responsedata[i]);
            //   }
            // }
            // var processedArrlength = processedArr.length;
            this.screencontent.innerHTML = "<div><b>"+responsedatalength+"</b> data to be processed</div>";
            var counter = 0;
            var countersuccess = 0;
            var counterfail = 0;
            for (let k = 0; k < responsedatalength; k++) {
              responsedata[k]['patient_sub_btoa'] = btoa(responsedata[k]['patientName']);
              responsedata[k]['create_time_str'] = this.formatTimeM(responsedata[k]['create_time'].replaceAll('-','/') + " UTC");
              responsedata[k]['patient_dob_str'] = this.formatDateM(responsedata[k]['patient_dob'].replaceAll('-','/'));
              responsedata[k]['masked_patient_dob_str'] = this.make_number_mask(this.formatDateM(responsedata[k]['patient_dob'].replaceAll('-','/')).toString(),4);
              var minutes = Math.floor(parseInt(responsedata[k]['duration']) / 60);
		          var seconds = parseInt(responsedata[k]['duration']) - minutes * 60;
              responsedata[k]['durationstr'] = String(minutes)+'.'+String(seconds);
              setTimeout (() => {
                let params = new HttpParams({
                  fromObject: {
                    userpull_id: localStorage.getItem('userpull_id'),
                    recordurl: responsedata[k]['recordingLink'],
                    numbering: k.toString()
                  }
                });
                this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getcallreportdetails', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
                .subscribe((responsedetail: any) => {
                  counter++;
                  countersuccess++;
                  var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
                  screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
                  screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
                  this.screencontent.innerHTML = screenContent;
                  if(responsedetail.data) {
                    var responsedetaildata = JSON.parse(responsedetail.data);
                    responsedata[responsedetail['numbering']]['providername'] = "";
                    if(responsedetaildata.at && responsedetaildata.at.provider_name && responsedetaildata.at.provider_name != "Null") {
                      responsedata[responsedetail['numbering']]['providername'] = responsedetaildata.at.provider_name;
                    }
                    else if(responsedetaildata['provider_name']) {
                      responsedata[responsedetail['numbering']]['providername'] = responsedetaildata['provider_name'];
                    }
                    responsedata[responsedetail['numbering']]['callref'] = "";
                    if(responsedetaildata['data_transmission_id'] && responsedetaildata['data_transmission_id'] != "None") {
                      responsedata[responsedetail['numbering']]['callref'] = "Reading Response";
                    }
                    responsedata[responsedetail['numbering']]['user'] = "";
                    for(var j = 0; j < users.length; j++) {
                      if(responsedetaildata['user_sub'] == users[j]['sub']) {
                        responsedata[responsedetail['numbering']]['user'] = users[j]['email'];
                      }
                    }
                  }
                  if(counter == responsedatalength) {
                    this.callList = responsedata;
                    this.callListLength = responsedata.length;
                    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                      if(dtElement.dtInstance) {
                      dtElement.dtInstance.then((dtInstance: any) => {
                        if(dtInstance.table().node().id == "ten-table") {
                          dtInstance.destroy();
                          this.dtTrigger10.next();
                        }
                      });
                      }
                      else {
                        this.dtTrigger10.next();
                      }
                    });
                    this.screencontent.innerHTML = "";
                    this.screen.style.display = 'none';
                  }
                },
                async (error: any) => {
                  counter++;
                  counterfail++;
                  var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
                  screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
                  screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
                  this.screencontent.innerHTML = screenContent;
                  if(counter == responsedatalength) {
                    this.callList = responsedata;
                    this.callListLength = responsedata.length;
                    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                      if(dtElement.dtInstance) {
                      dtElement.dtInstance.then((dtInstance: any) => {
                        if(dtInstance.table().node().id == "ten-table") {
                          dtInstance.destroy();
                          this.dtTrigger10.next();
                        }
                      });
                      }
                      else {
                        this.dtTrigger10.next();
                      }
                    });
                    this.screencontent.innerHTML = "";
                    this.screen.style.display = 'none';
                  }
                });
              }, 100*(k+1));
            }
          }
          else {
            this.screen.style.display = 'none';
            this.callList = responsedata;
            this.callListLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "ten-table") {
                  dtInstance.destroy();
                  this.dtTrigger10.next();
                }
              });
              }
              else {
                this.dtTrigger10.next();
              }
            });
          }
        }
      },
      async (error: any) => {
        // this.screen.style.display = 'none';
        console.log(error);
        this.screencontent.innerHTML = "";
        if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
          alert("Request timeout. Please try again.");
          this.screen.style.display = 'none';
        }
        else {
          await Auth.signOut({
            global: true
          });
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.router.navigateByUrl('/sign-in');
        }
      });
    }
  }


  validateEligibilityExceptionForm = ( inventoryForm ) =>{
    console.log(inventoryForm);
    if(inventoryForm.exceptionfromdate == undefined || inventoryForm.exceptionfromdate == "") {
      alert("Please provide From Date");
    }
    else if(inventoryForm.exceptiontodate == undefined || inventoryForm.exceptiontodate == "") {
      alert("Please provide To Date");
    }
    else if(inventoryForm.exceptionfromdate > inventoryForm.exceptiontodate) {
      alert("From Date must be less than from To Date");
    }
    else {
      this.screencontent.innerHTML = "";
      this.screen.style.display = 'block';
      let params = new HttpParams({
        fromObject: {
          userpull_id: localStorage.getItem('userpull_id'),
          fromdate: this.formatUTCDBDateTime(inventoryForm.exceptionfromdate+" 00:00:00"),
          todate: this.formatUTCDBDateTime(inventoryForm.exceptiontodate+" 23:59:59"),
          fromweb: 'yes'
        }
      });
      this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/billingeligibilityexceptionlist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response);
        this.screen.style.display = 'none';
        if(response.status === "success") {
          let responsedata = JSON.parse(response.data);
          console.log(responsedata);

          

          if(responsedata.length > 0) {
            for (var j = 0; j < responsedata.length; j++) {
              if(responsedata[j]['dob']) {
                responsedata[j]['masked_dob'] = this.make_number_mask(responsedata[j]['dob'].toString(),4);
              }
            }
            this.BillingEligibilityExceptionReportList = responsedata;
            this.BillingEligibilityExceptionReportListLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "thirteen-table") {
                  dtInstance.destroy();
                  this.dtTrigger13.next();
                }
              });
              }
              else {
                this.dtTrigger13.next();
              }
            });
          }
          else {
            this.BillingEligibilityExceptionReportList = responsedata;
            this.BillingEligibilityExceptionReportListLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "thirteen-table") {
                  dtInstance.destroy();
                  this.dtTrigger13.next();
                }
              });
              }
              else {
                this.dtTrigger13.next();
              }
            });
          }
        }
      },
      async (error: any) => {
        // this.screen.style.display = 'none';
        console.log(error);
        this.screencontent.innerHTML = "";
        if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
          alert("Request timeout. Please try again.");
          this.screen.style.display = 'none';
        }
        else {
          await Auth.signOut({
            global: true
          });
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.router.navigateByUrl('/sign-in');
        }
      });
    }
  }
  

  clickfrom = "";
  devicedeviceName = "";
  deviceEscalationItemList = [];
  deviceEscalationSettingList = [];

  viewDeviceescalation = (comefrom, deviceid, deviceName, escalation) =>{
    console.log("viewDeviceescalation")
    console.log(deviceid)
    console.log(escalation)

    var escalationIdList = [];

    for(var i of escalation){
      escalationIdList.push(i['id'])
    }

    this.clickfrom = comefrom

    this.deviceEscalationItemList = [];
    this.deviceEscalationSettingList = [];

    this.devicedeviceName = deviceName;

    this.screen.style.display = 'block';

    //Setting Popup close
    if (comefrom === 'Review'){
      let settingModal = document.getElementById("review-details-close-button") as HTMLElement;
      settingModal.click();
    }

    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      device: deviceid
    };
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getpracticedeviceglobalsettings', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
      console.log(response);
      this.screen.style.display = 'none';
      if(response.status == "success") {
        var tempescalationSettingList = JSON.parse(response.data);

        let esclationLabel = {          

          //{"bmiMax": 25, "bmiMin": 18, "wtMax": 200, "wtMin": 10}

          bmiMax: "BMI (Max)",
          bmiMin: "BMI (Min)",
          wtMax: "Weight (Max)",
          wtMin: "Weight (Min)",

          //{"diaMax": 80, "diaMin": 60, "pulseMax": 100, "pulseMin": 60, "sysMax": 85, "sysMin": 55}

          diaMax: "DIA (Max)",
          diaMin: "DIA (Min)",
          pulseMax: "PULSE (Max)",
          pulseMin: "PULSE (Min)",
          sysMax: "SYS (Max)",
          sysMin: "SYS (Min)",

          //{"tempMax": 99, "tempMin": 97}

          tempMax: "Temp (Max)",
          tempMin: "Temp (Min)",

          //{"prMax": 120, "prMin": 60, "spo2Max": 99, "spo2Min": 92}

          prMax: "PR (Max)",
          prMin: "PR (Min)",
          spo2Max: "SPO2 (Max)",
          spo2Min: "SPO2 (Min)",

          //{"glucoseAfterMealMax": 250, "glucoseAfterMealMin": 80, "glucoseFastingMax": 160, "glucoseFastingMin": 40, "glucoseNormalTestMax": 214, "glucoseNormalTestMin": 60}

          glucoseAfterMealMax: "Within 2hr. Meal (Max)",
          glucoseAfterMealMin: "Within 2hr. Meal (Min)",
          glucoseFastingMax: "Fasting (Max)",
          glucoseFastingMin: "Fasting (Min)",
          glucoseNormalTestMax: "Normal (Max)",
          glucoseNormalTestMin: "Normal (Min)"
        };

        for(var temp of tempescalationSettingList){
          console.log(temp.name)
          console.log(temp.value)

          this.deviceEscalationSettingList.push({ name : esclationLabel[temp.name], value: temp.value });
        }
        

        //List
        this.screen.style.display = 'block';
        let params = {
          userpull_id: localStorage.getItem('userpull_id'),
          device: deviceid
        };
        this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getescalation', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
          .subscribe((response: any) => {
          console.log(response);
          this.screen.style.display = 'none';
          if(response.status == "success") {
            let patientSmsModal = document.getElementById("device-escalation-ankerTag") as HTMLElement;
            patientSmsModal.click();

            let deviceEscalationItemList = JSON.parse(response.data);

            for(var tempdeviceEscalationItem of deviceEscalationItemList){
              var color = "";
              if(escalationIdList.indexOf(tempdeviceEscalationItem['id']) > -1) {
                color = "red";
              }
              tempdeviceEscalationItem['color'] = color;
              console.log(tempdeviceEscalationItem);
              this.deviceEscalationItemList.push(tempdeviceEscalationItem);
            }
          }
        },
        async (error: any) => {
          Auth.signOut();
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.screen.style.display = 'none';
          this.router.navigateByUrl('/sign-in');
        });
      }
    },
    async (error: any) => {
      Auth.signOut();
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });
    
    
  }

  deviceEscalationModalClosoButtonClick = (clickfrom) => {
    console.log("deviceEscalationModalClosoButtonClick")
    console.log(clickfrom)

    let patientSmsModal = document.getElementById("device-escalation-modal-close-button") as HTMLElement;
    patientSmsModal.click();

    if (clickfrom == 'Review'){
      this.addreview(this.escalationdeviceData)
    }    

  }

  eligibilityinfo = "Saved";
  eligibilityprimary = "";

  eligibilitysecondaryinfo = "Saved";
  eligibilitysecondary = "";

  eligibilitypart_b = "";
  eligibilityhmo = "";
  eligibilityppo = "";
  eligibilitymsp = "";
  eligibilityremaining = "";


  eligibilityisshowselectAll = 0;

  eligibilitytotalselect = 0;

  eligibilityselectAllisselect = 0;

  validateEligibilityForm = () =>{

    this.eligibilityisshowselectAll = 0;

    this.eligibilitytotalselect = 0;

    this.eligibilityselectAllisselect = 0;

    this.screencontent.innerHTML = "";
    this.screen.style.display = 'block';

    let params = {
      userpull_id: localStorage.getItem('userpull_id'),

      eligibilityinfo: this.eligibilityinfo,
      eligibilityprimary: this.eligibilityprimary,
      eligibilitysecondaryinfo: this.eligibilitysecondaryinfo,
      eligibilitysecondary: this.eligibilitysecondary,
      eligibilitypart_b: this.eligibilitypart_b,
      eligibilityhmo: this.eligibilityhmo,
      eligibilityppo: this.eligibilityppo,
      eligibilitymsp: this.eligibilitymsp,
      eligibilityremaining: this.eligibilityremaining,

      token: uuidv4()
    };

    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/eligibilityreport', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      this.screen.style.display = 'none';
      if(response.status === "success") {
        let responsedata = JSON.parse(response.data);
        console.log(responsedata);

        this.EligibilityReportList = [];

        if(responsedata.length > 0) {
          this.eligibilityisshowselectAll = 1;

          for(var temp of responsedata){
            var temptemp = temp;

            if(temp['birthdate'] && temp['birthdate'] != "0000-00-00") {
              temptemp["temp_dob"] = this.formatDateM(temp['birthdate'].replaceAll('-','/'));
              temptemp['masked_temp_dob'] = this.make_number_mask(this.formatDateM(temp['birthdate'].replaceAll('-','/')).toString(),4);
            }
            else{
              temptemp["temp_dob"] = "";
            }

            temptemp['patient_sub_btoa'] = btoa(temp['sub']);

            temptemp['checked'] = false;

            this.EligibilityReportList.push(temptemp);
          }
          this.EligibilityReportListLength = responsedata.length;
          this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            if(dtElement.dtInstance) {
            dtElement.dtInstance.then((dtInstance: any) => {
              if(dtInstance.table().node().id == "eleven-table") {
                dtInstance.destroy();
                this.dtTrigger11.next();
              }
            });
            }
            else {
              this.dtTrigger11.next();
            }
          });
        }
        else {
          this.EligibilityReportList = responsedata;
          this.EligibilityReportListLength = responsedata.length;
          this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            if(dtElement.dtInstance) {
            dtElement.dtInstance.then((dtInstance: any) => {
              if(dtInstance.table().node().id == "eleven-table") {
                dtInstance.destroy();
                this.dtTrigger11.next();
              }
            });
            }
            else {
              this.dtTrigger11.next();
            }
          });
        }
      }
    },
    async (error: any) => {
      // this.screen.style.display = 'none';
      console.log(error);
      this.screencontent.innerHTML = "";
      if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
        // alert("Request timeout. Please try again.");
        // this.screen.style.display = 'none';
        var root = this;
        var callcounter = 0;
        setTimeout (function requestCallback() {
          root.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/eligibilityreport2', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
          .subscribe((response: any) => {
            console.log(response);
            
            if(response.status === "success") {
              root.screen.style.display = 'none';
              
              let responsedata = JSON.parse(response.data);
              console.log(responsedata);
      
              root.EligibilityReportList = [];
      
              if(responsedata.length > 0) {
                root.eligibilityisshowselectAll = 1;
      
                for(var temp of responsedata){
                  var temptemp = temp;
      
                  if(temp['birthdate'] && temp['birthdate'] != "0000-00-00") {
                    temptemp["temp_dob"] = root.formatDateM(temp['birthdate'].replaceAll('-','/'));
                    temptemp['masked_temp_dob'] = root.make_number_mask(root.formatDateM(temp['birthdate'].replaceAll('-','/')).toString(),4);
                  }
                  else{
                    temptemp["temp_dob"] = "";
                  }
      
                  temptemp['patient_sub_btoa'] = btoa(temp['sub']);
      
                  temptemp['checked'] = false;
      
                  root.EligibilityReportList.push(temptemp);
                }
                root.EligibilityReportListLength = responsedata.length;
                root.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                  if(dtElement.dtInstance) {
                  dtElement.dtInstance.then((dtInstance: any) => {
                    if(dtInstance.table().node().id == "eleven-table") {
                      dtInstance.destroy();
                      root.dtTrigger11.next();
                    }
                  });
                  }
                  else {
                    root.dtTrigger11.next();
                  }
                });
              }
              else {
                root.EligibilityReportList = responsedata;
                root.EligibilityReportListLength = responsedata.length;
                root.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                  if(dtElement.dtInstance) {
                  dtElement.dtInstance.then((dtInstance: any) => {
                    if(dtInstance.table().node().id == "eleven-table") {
                      dtInstance.destroy();
                      root.dtTrigger11.next();
                    }
                  });
                  }
                  else {
                    root.dtTrigger11.next();
                  }
                });
              }
            }
            else {
              callcounter++;
              if(callcounter < 50) {
                setTimeout (requestCallback, 5000);
              }
              else {
                alert("Request timeout. Please try again.");
                root.screen.style.display = 'none';
              }
            }
          },
          async (error: any) => {
            console.log(error);
          });
        }, 5000);
      }
      else {
        await Auth.signOut({
          global: true
        });
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        this.router.navigateByUrl('/sign-in');
      }
    });
  }

  formatDateMEligibility = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [month, day, year].join('-');
  }

  EligibilitySelectChange = (item, isChecked) => {
    console.log("EligibilitySelectChange");
    console.log(item);
    console.log(isChecked);

    if (isChecked) {
      let index = this.EligibilityReportList.indexOf(item);
      item["checked"] = true;
      this.EligibilityReportList[index] = item;

      this.eligibilitytotalselect = this.eligibilitytotalselect + 1;
    } else {
      let index = this.EligibilityReportList.indexOf(item);
      item["checked"] = false;
      this.EligibilityReportList[index] = item;

      this.eligibilitytotalselect = this.eligibilitytotalselect - 1;
    }

    if (this.eligibilitytotalselect == this.EligibilityReportList.length){
      this.eligibilityselectAllisselect = 1;
    }else{
      this.eligibilityselectAllisselect = 0;
    }

    
  }

  EligibilitySelectAllChange = (isChecked) => {
    console.log("EligibilitySelectAllChange");
    console.log(isChecked);

    if (isChecked) {
      for(var temp of this.EligibilityReportList){
        let index = this.EligibilityReportList.indexOf(temp);
        temp["checked"] = true;
        this.EligibilityReportList[index] = temp;
      }
      this.eligibilitytotalselect = this.EligibilityReportList.length;
      this.eligibilityselectAllisselect = 1;
    }else{
      for(var temp of this.EligibilityReportList){
        let index = this.EligibilityReportList.indexOf(temp);
        temp["checked"] = false;
        this.EligibilityReportList[index] = temp;
      }
      this.eligibilitytotalselect = 0;
      this.eligibilityselectAllisselect = 0;
    }
  }

  EligibilityRunAllEligibility = (type) =>{
    console.log("EligibilityRunAllEligibility");
    console.log(type);

    

    var tempList = [];

    if (type == 'Eligibility'){
      for (var eligibility of this.EligibilityReportList) {
        if (eligibility.checked == true){
          tempList.push(eligibility)
        }
      }
    }else if(type == 'Billing_Eligibility'){
      for (var eligibility of this.BillingEligibilityReportList) {
        if (eligibility.checked == true){
          tempList.push(eligibility)
        }
      }
    }else if(type == 'NewBilling_Eligibility'){
      for (var eligibility of this.newbillingdataList) {
        if (eligibility.checked == true){
          tempList.push(eligibility)
        }
      }
    }
    

    if (tempList.length == 0){
      alert("please select atleast one patient");
    }else if (tempList.length > 0){
      console.log("all ok");

      let usersub = ""

      if (localStorage.getItem('USER-DETAILS')) {
        let userDetails = JSON.parse(localStorage.getItem('USER-DETAILS'));

        if(userDetails.sub){
          usersub = userDetails.sub;
        }
      }

      let params = {
        userpull_id: localStorage.getItem('userpull_id'),
        user_type: "user",
        user_id: usersub,
        doS_Date: this.formatDateMEligibility(this.thisDate),
        patients: tempList
      };

      console.log("EligibilityRunAllEligibility params", params);

      // return;
      this.screen.style.display = 'block';

      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/runalleligibility', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe( async (response: any) => {
        this.screen.style.display = 'none';
        console.log(response);
        if(response.status == "success") {
          alert(" Run Eligibility successfully");
        }
      },
      async (error: any) => {
        Auth.signOut();
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        this.screen.style.display = 'none';
        this.router.navigateByUrl('/sign-in');
      });
    }
  }

  resetEligibilitySearchForm = () => {
    console.log("resetEligibilitySearchForm");

    this.eligibilityinfo = "Saved";
    this.eligibilityprimary = "";

    this.eligibilitysecondaryinfo = "Saved";
    this.eligibilitysecondary = "";

    this.eligibilitypart_b = "";
    this.eligibilityhmo = "";
    this.eligibilityppo = "";
    this.eligibilitymsp = "";
    this.eligibilityremaining = "";
  }


  // Billing Eligibility

  mybillingeligibilityCloseDate: any;

  billingeligibilityinfo = "Saved";
  billingeligibilityprimary = "Active";

  billingeligibilitysecondaryinfo = "Saved";
  billingeligibilitysecondary = "Active";

  billingeligibilitypart_b = "Active";
  billingeligibilityhmo = "Inactive";
  billingeligibilityppo = "Inactive";
  billingeligibilitymsp = "Inactive";
  billingeligibilityremaining = "Zero";


  billingeligibilityisshowselectAll = 0;

  billingeligibilitytotalselect = 0;

  billingeligibilityselectAllisselect = 0;

  validateNewBillingEligibilityForm = ( newBillingData ) => {

    this.billingeligibilityisshowselectAll = 0;

    this.billingeligibilitytotalselect = 0;

    this.billingeligibilityselectAllisselect = 0;

    console.log(newBillingData);
    if(!newBillingData.cyclestatus) {
      newBillingData.cyclestatus = "current";
    }
    if(!newBillingData.batchcycle) {
      newBillingData.batchcyclestr = "all";
    }
    else{
      newBillingData.batchcyclestr = newBillingData.batchcycle.join(",")
    }
    console.log(newBillingData);
    
    this.screencontent.innerHTML = "";
    this.screen.style.display = 'block';
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
        cyclestatus: newBillingData.cyclestatus,
        batchcycle: newBillingData.batchcyclestr,
        token: uuidv4()
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/batchcyclepatients', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      this.screen.style.display = 'none';
      if(response.status === "success") {
        let responsedata = JSON.parse(response.data);
        console.log(responsedata);

        
        if(responsedata.length > 0) {
          for (var i = 0; i < responsedata.length; i++) {
            responsedata[i]['patient_sub_btoa'] = btoa(responsedata[i]['PatientSub']);
            if(responsedata[i]['BillingBatchDate'] != null) {
              responsedata[i]['BillingBatchDateStr'] = this.formatDateM(responsedata[i]['BillingBatchDate'].replaceAll('-','/'));
            }
            if(responsedata[i]['CycleStartDate'] != null) {
              responsedata[i]['CycleStartDateStr'] = this.formatDateM(responsedata[i]['CycleStartDate'].replaceAll('-','/'));
              responsedata[i]['CycleStartDateStrDB'] = this.formatDate(responsedata[i]['CycleStartDate']);
            }
            if(responsedata[i]['CycleEndDate'] != null) {
              responsedata[i]['CycleEndDateStr'] = this.formatDateM(responsedata[i]['CycleEndDate'].replaceAll('-','/'));
              responsedata[i]['CycleEndDateStrDB'] = this.formatDate(responsedata[i]['CycleEndDate']);
            }
            if(responsedata[i]['eduDate'] && responsedata[i]['eduDate'] != "0000-00-00 00:00:00") {
              responsedata[i]['eduDateStr'] = this.formatDateM(responsedata[i]['eduDate'].replaceAll('-','/') + " UTC");
            }
            else {
              responsedata[i]['eduDateStr'] = "";
            }
            if(responsedata[i]['DOB'] && responsedata[i]['DOB'] != "0000-00-00") {
              responsedata[i]['DOBstr'] = this.formatDateM(responsedata[i]['DOB'].replaceAll('-','/'));
              responsedata[i]['masked_DOBstr'] = this.make_number_mask(this.formatDateM(responsedata[i]['DOB'].replaceAll('-','/') + " 00:00:00").toString(),4);
            }
            else{
              responsedata[i]['DOBstr'] = "";
            }
            if(responsedata[i]['DTB'] && responsedata[i]['DTB'] != "0000-00-00 00:00:00") {
              responsedata[i]['DTBstr'] = this.formatDateM(responsedata[i]['DTB'].replaceAll('-','/'));
            }
            else{
              responsedata[i]['DTBstr'] = "";
            }
            if(responsedata[i]['DTE'] && responsedata[i]['DTE'] != "0000-00-00 00:00:00") {
              responsedata[i]['DTEstr'] = this.formatDateM(responsedata[i]['DTE'].replaceAll('-','/'));
            }
            else{
              responsedata[i]['DTEstr'] = "";
            }
            if(responsedata[i]['BD'] && responsedata[i]['BD'] != "0000-00-00" && responsedata[i]['BD'] != null) {
              responsedata[i]['BDstr'] = this.formatDateM(responsedata[i]['BD'].replaceAll('-','/'));
            }
            else{
              responsedata[i]['BDstr'] = "";
            }
          }


          this.BillngEligibilitySecondCall(responsedata);


          
        }
        else {
          this.BillingEligibilityReportList = responsedata;
          this.BillingEligibilityReportListLength = responsedata.length;
          this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            if(dtElement.dtInstance) {
            dtElement.dtInstance.then((dtInstance: any) => {
              if(dtInstance.table().node().id == "Twelve-table") {
                dtInstance.destroy();
                this.dtTrigger12.next();
              }
            });
            }
            else {
              this.dtTrigger12.next();
            }
          });
        }
      }
    },
    async (error: any) => {
      // this.screen.style.display = 'none';
      console.log(error);
      this.screencontent.innerHTML = "";
      if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
        // alert("Request timeout. Please try again.");
        // this.screen.style.display = 'none';
        var root = this;
        var callcounter = 0;
        setTimeout (function requestCallback() {
          root.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/batchcyclepatients2', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
          .subscribe((response: any) => {
            console.log(response);
            if(response.status === "success") {
              root.screen.style.display = 'none';
              let responsedata = JSON.parse(response.data);
              console.log(responsedata);

              if(responsedata.length > 0) {
                for (var i = 0; i < responsedata.length; i++) {
                  responsedata[i]['patient_sub_btoa'] = btoa(responsedata[i]['PatientSub']);
                  if(responsedata[i]['BillingBatchDate'] != null) {
                    responsedata[i]['BillingBatchDateStr'] = root.formatDateM(responsedata[i]['BillingBatchDate'].replaceAll('-','/'));
                  }
                  if(responsedata[i]['CycleStartDate'] != null) {
                    responsedata[i]['CycleStartDateStr'] = root.formatDateM(responsedata[i]['CycleStartDate'].replaceAll('-','/'));
                    responsedata[i]['CycleStartDateStrDB'] = root.formatDate(responsedata[i]['CycleStartDate']);
                  }
                  if(responsedata[i]['CycleEndDate'] != null) {
                    responsedata[i]['CycleEndDateStr'] = root.formatDateM(responsedata[i]['CycleEndDate'].replaceAll('-','/'));
                    responsedata[i]['CycleEndDateStrDB'] = root.formatDate(responsedata[i]['CycleEndDate']);
                  }
                  if(responsedata[i]['eduDate'] && responsedata[i]['eduDate'] != "0000-00-00 00:00:00") {
                    responsedata[i]['eduDateStr'] = root.formatDateM(responsedata[i]['eduDate'].replaceAll('-','/') + " UTC");
                  }
                  else {
                    responsedata[i]['eduDateStr'] = "";
                  }
                  if(responsedata[i]['DOB'] && responsedata[i]['DOB'] != "0000-00-00") {
                    responsedata[i]['DOBstr'] = root.formatDateM(responsedata[i]['DOB'].replaceAll('-','/'));
                    responsedata[i]['masked_DOBstr'] = this.make_number_mask(this.formatDateM(responsedata[i]['DOB'].replaceAll('-','/') + " 00:00:00").toString(),4);
                  }
                  else{
                    responsedata[i]['DOBstr'] = "";
                  }
                  if(responsedata[i]['DTB'] && responsedata[i]['DTB'] != "0000-00-00 00:00:00") {
                    responsedata[i]['DTBstr'] = root.formatDateM(responsedata[i]['DTB'].replaceAll('-','/'));
                  }
                  else{
                    responsedata[i]['DTBstr'] = "";
                  }
                  if(responsedata[i]['DTE'] && responsedata[i]['DTE'] != "0000-00-00 00:00:00") {
                    responsedata[i]['DTEstr'] = root.formatDateM(responsedata[i]['DTE'].replaceAll('-','/'));
                  }
                  else{
                    responsedata[i]['DTEstr'] = "";
                  }
                  if(responsedata[i]['BD'] && responsedata[i]['BD'] != "0000-00-00" && responsedata[i]['BD'] != null) {
                    responsedata[i]['BDstr'] = root.formatDateM(responsedata[i]['BD'].replaceAll('-','/'));
                  }
                  else{
                    responsedata[i]['BDstr'] = "";
                  }
                }
      
      
                root.BillngEligibilitySecondCall(responsedata);
      
      
                
              }
              else {
                root.BillingEligibilityReportList = responsedata;
                root.BillingEligibilityReportListLength = responsedata.length;
                root.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                  if(dtElement.dtInstance) {
                  dtElement.dtInstance.then((dtInstance: any) => {
                    if(dtInstance.table().node().id == "Twelve-table") {
                      dtInstance.destroy();
                      root.dtTrigger12.next();
                    }
                  });
                  }
                  else {
                    root.dtTrigger12.next();
                  }
                });
              }
              
            }
            else {
              callcounter++;
              if(callcounter < 50) {
                setTimeout (requestCallback, 5000);
              }
              else {
                alert("Request timeout. Please try again.");
                root.screen.style.display = 'none';
              }
            }
          },
          async (error: any) => {
            console.log(error);
          });
        }, 5000);
      }
      else {
        await Auth.signOut({
          global: true
        });
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        this.router.navigateByUrl('/sign-in');
      }
    });
  }

  BillngEligibilitySecondCall = (responsedata) => {
    var patientList = [];


    for(var temp of responsedata){
      patientList.push(temp.PatientSub);
    }

    this.screen.style.display = 'block';

    let params = {
      userpull_id: localStorage.getItem('userpull_id'),

      eligibilityinfo: this.billingeligibilityinfo,
      eligibilityprimary: this.billingeligibilityprimary,
      eligibilitysecondaryinfo: this.billingeligibilitysecondaryinfo,
      eligibilitysecondary: this.billingeligibilitysecondary,
      eligibilitypart_b: this.billingeligibilitypart_b,
      eligibilityhmo: this.billingeligibilityhmo,
      eligibilityppo: this.billingeligibilityppo,
      eligibilitymsp: this.billingeligibilitymsp,
      eligibilityremaining: this.billingeligibilityremaining,

      patientsub: patientList,
      token: uuidv4()

    };

    var TempBillingData = [];
    
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/eligibilityreport', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response2: any) => {
      console.log(response2);
      this.screen.style.display = 'none';
      if(response2.status === "success") {
        let responsedata2 = JSON.parse(response2.data);
        console.log(responsedata2);
        

        for(var temp of responsedata){
          let obj = responsedata2.find(o => o.sub === temp.PatientSub);
          if(obj){
            var merged = Object.assign(temp, obj);

            merged["checked"] = false;
            merged["iot"] = "";
            merged["devices"] = "";
            merged["education_location"] = "";

            TempBillingData.push(merged)
          }
        }

        if (TempBillingData.length > 0){
          this.BillngEligibilityThirdCall(TempBillingData);
        }else{
          this.BillingEligibilityReportList = TempBillingData;
          this.BillingEligibilityReportListLength = TempBillingData.length;
          this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            if(dtElement.dtInstance) {
            dtElement.dtInstance.then((dtInstance: any) => {
              if(dtInstance.table().node().id == "Twelve-table") {
                dtInstance.destroy();
                this.dtTrigger12.next();
              }
            });
            }
            else {
              this.dtTrigger12.next();
            }
          });
        }             
      }
    },
    async (error: any) => {
      // this.screen.style.display = 'none';
      console.log(error);
      this.screencontent.innerHTML = "";
      if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
        // alert("Request timeout. Please try again.");
        // this.screen.style.display = 'none';
        var root = this;
        var callcounter = 0;
        setTimeout (function requestCallback() {
          root.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/eligibilityreport2', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
          .subscribe((response2: any) => {
            console.log(response2);
            if(response2.status === "success") {
              let responsedata2 = JSON.parse(response2.data);
              console.log(responsedata2);
              
      
              for(var temp of responsedata){
                let obj = responsedata2.find(o => o.sub === temp.PatientSub);
                if(obj){
                  var merged = Object.assign(temp, obj);
      
                  merged["checked"] = false;
                  merged["iot"] = "";
                  merged["devices"] = "";
                  merged["education_location"] = "";
      
                  TempBillingData.push(merged)
                }
              }
      
              if (TempBillingData.length > 0){
                root.BillngEligibilityThirdCall(TempBillingData);
              }else{
                root.BillingEligibilityReportList = TempBillingData;
                root.BillingEligibilityReportListLength = TempBillingData.length;
                root.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                  if(dtElement.dtInstance) {
                  dtElement.dtInstance.then((dtInstance: any) => {
                    if(dtInstance.table().node().id == "Twelve-table") {
                      dtInstance.destroy();
                      root.dtTrigger12.next();
                    }
                  });
                  }
                  else {
                    root.dtTrigger12.next();
                  }
                });
              }             
            }
            else {
              callcounter++;
              if(callcounter < 50) {
                setTimeout (requestCallback, 5000);
              }
              else {
                alert("Request timeout. Please try again.");
                root.screen.style.display = 'none';
              }
            }
          },
          async (error: any) => {
            console.log(error);
          });
        }, 5000);
      }
      else {
        await Auth.signOut({
          global: true
        });
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        this.router.navigateByUrl('/sign-in');
      }
    });
  }

  BillngEligibilityThirdCall = (TempBillingData) => {
    this.billingeligibilityisshowselectAll = 1;

    var responsedatalength = TempBillingData.length;

    this.screen.style.display = 'block';
    this.screencontent.innerHTML = "<div><b>"+responsedatalength+"</b> data to be processed</div>";
    var counter = 0;
    var countersuccess = 0;
    var counterfail = 0;

    for (let k = 0; k < responsedatalength; k++) {
      setTimeout (() => {
        let params = new HttpParams({
          fromObject: {
            userpull_id: localStorage.getItem('userpull_id'),
            sub: TempBillingData[k]['sub']
          }
        });
        this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getbillingeligibityreport3', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((responsedetail: any) => {
          
          counter++;
          countersuccess++;
          var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
          screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
          screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
          this.screencontent.innerHTML = screenContent;

          var responsedetaildata = JSON.parse(responsedetail.data);
          console.log(responsedetaildata);

          TempBillingData[k]["iot"] = responsedetaildata[0]['iot'];
          TempBillingData[k]["devices"] = responsedetaildata[0]['devices'];
          TempBillingData[k]["education_location"] = responsedetaildata[0]['education_location'];

          if(counter == responsedatalength) {
            //data table
            console.log("Final", TempBillingData)

            this.BillingEligibilityReportList = TempBillingData;
            this.BillingEligibilityReportListLength = TempBillingData.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "Twelve-table") {
                  dtInstance.destroy();
                  this.dtTrigger12.next();
                }
              });
              }
              else {
                this.dtTrigger12.next();
              }
            });
            this.screencontent.innerHTML = "";
            this.screen.style.display = 'none';
          }
        },
        async (error: any) => {
          counter++;
          counterfail++;
          var screenContent = "<div><b>"+counter+"</b> out of <b>"+responsedatalength+"</b> data processed</div>";
          screenContent += "<div><b>Success:</b> "+countersuccess+"</div>";
          screenContent += "<div><b>Fail:</b> "+counterfail+"</div>";
          this.screencontent.innerHTML = screenContent;
          if(counter == responsedatalength) {
            //data table
            this.BillingEligibilityReportList = TempBillingData;
            this.BillingEligibilityReportListLength = TempBillingData.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "Twelve-table") {
                  dtInstance.destroy();
                  this.dtTrigger12.next();
                }
              });
              }
              else {
                this.dtTrigger12.next();
              }
            });
            this.screencontent.innerHTML = "";
            this.screen.style.display = 'none';
          }
        });
      }, 100*(k+1));
    }

  }

  billingeEligibilitySelectAllChange = (isChecked) => {
    console.log("billingeEligibilitySelectAllChange");
    console.log(isChecked);

    if (isChecked) {
      for(var temp of this.BillingEligibilityReportList){
        let index = this.BillingEligibilityReportList.indexOf(temp);
        temp["checked"] = true;
        this.BillingEligibilityReportList[index] = temp;
      }
      this.eligibilitytotalselect = this.BillingEligibilityReportList.length;
      this.eligibilityselectAllisselect = 1;
    }else{
      for(var temp of this.BillingEligibilityReportList){
        let index = this.BillingEligibilityReportList.indexOf(temp);
        temp["checked"] = false;
        this.BillingEligibilityReportList[index] = temp;
      }
      this.billingeligibilitytotalselect = 0;
      this.billingeligibilityselectAllisselect = 0;
    }
  }


  billingEligibilitySelectChange = (item, isChecked) => {
    console.log("billingEligibilitySelectChange");
    console.log(item);
    console.log(isChecked);

    if (isChecked) {
      let index = this.BillingEligibilityReportList.indexOf(item);
      item["checked"] = true;
      this.BillingEligibilityReportList[index] = item;

      this.billingeligibilitytotalselect = this.billingeligibilitytotalselect + 1;
    } else {
      let index = this.BillingEligibilityReportList.indexOf(item);
      item["checked"] = false;
      this.BillingEligibilityReportList[index] = item;

      this.billingeligibilitytotalselect = this.billingeligibilitytotalselect - 1;
    }

    if (this.billingeligibilitytotalselect == this.BillingEligibilityReportList.length){
      this.billingeligibilityselectAllisselect = 1;
    }else{
      this.billingeligibilityselectAllisselect = 0;
    }

    
  } 

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
}
