import { Component, OnInit, OnDestroy, AfterViewInit, QueryList, ViewChildren, ViewChild} from '@angular/core';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'; // For Ajax Call //
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userGroup: string = '';

  processing: HTMLElement = document.getElementById('cover') as HTMLElement;
  screen: HTMLElement = document.getElementById('screen') as HTMLElement;
  logedInUserName : string = "";
  logedInUserDetails : any = "";
  logedInUserManagePermission : string = "no";
  userlist : any = "";
  billingconfig : any;
  managinguser = "no";
  providerlist = [];
  selectedprovider = "";
  usereditmode: boolean = false;
  usereditdata : any;

  twilio_num = "";

  inventorymyTime: any;
  
  userPassformInvalids:boolean = false;
  userPassformInvalidMsgs:boolean = false;

  stockItemList = [];
  stockItemListLength = 0;

  stockAddCount = 0;

  stockItemReorderList = [];

  @ViewChildren(DataTableDirective)
  dtElements: QueryList<any>;

  dtOptions: any = {};

  dtTrigger: Subject<HeaderComponent> = new Subject();
  dtTrigger1: Subject<HeaderComponent> = new Subject();

  allPullList = [];
  currentUserPull = "";
  currentUserPullName = "";


  constructor( private router: Router, private httpClient: HttpClient ) { 
    let authData = JSON.parse(localStorage.getItem('AUTH'));
    this.userGroup = authData.group;

    let todaydate = new Date();
    this.inventorymyTime = this.formatTime(todaydate);

    if (localStorage.getItem('MANAGE-USER') && localStorage.getItem('MANAGE-USER') == "yes") {
      this.logedInUserManagePermission = "yes";
    }
  	if (localStorage.getItem('USER-DETAILS')) {
  		this.logedInUserDetails = JSON.parse(localStorage.getItem('USER-DETAILS'));
  		console.log(this.logedInUserDetails);
  		if(this.logedInUserDetails.given_name){
  			this.logedInUserName = this.logedInUserDetails.given_name;
      }
      else if(this.logedInUserDetails.email){
  			this.logedInUserName = this.logedInUserDetails.email;
  		}
  		else{
  			this.logedInUserName = (this.logedInUserDetails.name)?this.logedInUserDetails.name:'';
  		}
  	}
    if(this.userGroup != 'Provider'){
      if (localStorage.getItem('ALL-POOL')) {
        this.allPullList = JSON.parse(localStorage.getItem('ALL-POOL'));
      }
      if (localStorage.getItem('userpull_id')) {
        this.currentUserPull = localStorage.getItem('userpull_id');
      }
      if(this.currentUserPull != '' && this.allPullList.length > 0){
        for(var pull of this.allPullList){
          if(pull.id == this.currentUserPull){
            this.currentUserPullName = pull.name;
          }
        }
      }
    }
  }

  ngOnInit() {
    this.dtOptions = {
      // Declare the use of the extension in the dom parameter
      // dom: 'lBfrtip',
      dom: '<"col-sm-2"B><"col-sm-5"l><"col-sm-5"f>rt<"col-sm-5"i><"col-sm-7"p>',
      // Configure the buttons
      buttons: [
        // 'colvis',
        // 'copy',
        // 'print',
        'excel'
      ],
      stateSave: true
    };
    this.dtTrigger.next();
    this.dtTrigger1.next();
    // console.log(localStorage);
    // console.log(sessionStorage);
  }

  onSignOut = async () =>{
    this.processing.style.display = 'block';
    await Auth.signOut();
    this.processing.style.display = 'none';
    localStorage.removeItem('AUTH');
    localStorage.removeItem('USER-DETAILS');
    localStorage.removeItem('AUTH-LIFE');
    localStorage.removeItem('AUTH-PROFILE-PIC');
    //this.router.navigateByUrl('/sign-in');

  }

  formatTime = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours(),
      minutes = d.getMinutes();
      var strhour = ''+ hours;
      var strminute = '' + minutes;
      if(hours < 10){
        strhour = '0' + hours;
      }
      if(minutes < 10){
        strminute = '0' + minutes;
      }

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  deleteUser = (userData = "")=>{
    console.log(userData);
    if(confirm("Do you want to delete the User?")) {
      let params = {
        sub: userData['sub']
      };
      console.log(params);
      let userClass = this;
      userClass.screen.style.display = 'block';
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/deleteuser', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          console.log(response);
          userClass.screen.style.display = 'none';
          if(response.status == "success") {
            alert("User deleted successfully");
            location.reload();
          }
          else {
            alert(response.message);
          }
        },
        async (error: any) => {
          Auth.signOut();
                  localStorage.removeItem('AUTH');
                  localStorage.removeItem('AUTH-LIFE');
                  localStorage.removeItem('AUTH-PROFILE-PIC');
                  localStorage.removeItem('USER-DETAILS');
                  userClass.screen.style.display = 'none';
                  userClass.router.navigateByUrl('/sign-in');
        });
    }
  }

  listusers = (userdata = "")=>{
    console.log(userdata);
    let userClass = this;
    userClass.screen.style.display = 'block';
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id')
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/webuserlist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      userClass.screen.style.display = 'none';
      if (response.status === "success") {
        let responsedata = JSON.parse(response.data);
        // if (responsedata.length > 0) {
          let listuser = [];
          for(var j=0; j<responsedata.length; j++){
            if(responsedata[j]['email'] && responsedata[j]['email'] != this.logedInUserDetails.email) {
              listuser.push(responsedata[j]);
            }
          }
          this.userlist = listuser;
          let listuserModal = document.getElementById("list-user-ankerTag") as HTMLElement;
          listuserModal.click();
        // }
      }
    },
    async (error: any) => {
      userClass.screen.style.display = 'none';
      console.log(error);
      alert("Some error occurred");
    });
  }

  closeuserCreateForm = () =>{
    if(this.usereditmode) {
      this.usereditmode = false;
      this.listusers();
    }
  }

  createEditUser = (userdata = "") =>{
    console.log(userdata);
    this.managinguser = "no";
    this.usereditdata = "";
    this.usereditmode = false;
    if(userdata['custom:manage_users']){
      this.usereditmode = true;
      this.usereditdata = userdata;
      this.managinguser = userdata['custom:manage_users'];
    }
    let userClass = this;
    userClass.screen.style.display = 'block';
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id')
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/webuserlist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      if (response.status === "success") {
        this.selectedprovider= "";
        let responsedata = JSON.parse(response.data);
        // if (responsedata.length > 0) {
          let listprovideruser = [];
          for(var j=0; j<responsedata.length; j++){
            if(responsedata[j]['custom:provider_sub']) {
              if(userdata['custom:provider_sub']) {
                this.selectedprovider = userdata['custom:provider_sub'];
                if(userdata['custom:provider_sub'] != responsedata[j]['custom:provider_sub']) {
                  listprovideruser.push(responsedata[j]['custom:provider_sub']);
                }
              }
              else {
                listprovideruser.push(responsedata[j]['custom:provider_sub']);
              }
            }
          }
          console.log(listprovideruser);
          let params = new HttpParams({
            fromObject: {
              userpull_id: localStorage.getItem('userpull_id'),
              group: 'Provider'
            }
          });
          this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/userlist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
          .subscribe((response: any) => {
            userClass.screen.style.display = 'none';
            if (response.status === "success") {
              if(this.usereditmode) {
                let listuserModal = document.getElementById("list-user-ankerTag") as HTMLElement;
                listuserModal.click();
              }
              let responsedata = JSON.parse(response.data);
              console.log(responsedata);
              let listprovider = [];
              if (responsedata.length > 0) {
                listprovider.push({"sub":"", "name":"Select Provider"});
                for(var j=0; j<responsedata.length; j++){
                  if(listprovideruser.indexOf(responsedata[j]['sub']) < 0){
                    listprovider.push({"sub":responsedata[j]['sub'], "name":responsedata[j]['firstName']+' '+responsedata[j]['lastName']+' ('+responsedata[j]['email']+')'});
                  }
                }
              }
              console.log(listprovider);
              this.providerlist = listprovider;
              let usercreateeditModal = document.getElementById("user-create-edit-ankerTag") as HTMLElement;
              usercreateeditModal.click();
            }
          },
          async (error: any) => {
            userClass.screen.style.display = 'none';
            console.log(error);
            alert("Some error occurred");
          });
        // }
      }
    },
    async (error: any) => {
      userClass.screen.style.display = 'none';
      console.log(error);
      alert("Some error occurred");
    });
  }

  validateuserCreateForm = (userData) =>{
    console.log(this.usereditmode);
    console.log(userData);
    let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
    let userClass = this;
    if(!this.usereditmode && (!userData['user_email'] || typeof userData['user_email'] === 'undefined' || !userData['user_email'].match(emailPattern))){
      alert("Please provide valid email");
    }
    else if(!this.usereditmode && userData['user_password'] && userData['user_password'] != '' && !userData['user_password'].match(passwordPattern)) {
      alert("Please provide valid password");
    }
    else {
      let params = {
        userpull_id: localStorage.getItem('userpull_id')
      };
      if(this.usereditmode && this.usereditdata) {
        params['user_sub'] = this.usereditdata.sub;
      }
      if(userData['user_email']) {
        params['email'] = userData['user_email'];
      }
      if(userData['user_password'] && userData['user_password'] != '') {
        params['password'] = userData['user_password'];
      }
      if(userData['manageuser'] && userData['manageuser'] != '' && (userData['manageuser'] == 'yes' || userData['manageuser'] == 'no')) {
        params['manageuser'] = userData['manageuser'];
      }
      params['provider_sub'] = "";
      if(userData['provider']) {
        params['provider_sub'] = userData['provider'];
      }
      console.log(params);
      // return false;
      userClass.screen.style.display = 'block';
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/adduser', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          console.log(response);
          userClass.screen.style.display = 'none';
          if(response.status == "success") {
            // alert("User added successfully");
            alert(response.message);
            // location.reload();
            let usercreateeditModal = document.getElementById("user-create-edit-ankerTag") as HTMLElement;
            usercreateeditModal.click();
            if(this.usereditmode) {
              this.usereditmode = false;
            }
            this.listusers();
          }
          else {
            alert(response.message);
          }
        },
        async (error: any) => {
          Auth.signOut();
                  localStorage.removeItem('AUTH');
                  localStorage.removeItem('AUTH-LIFE');
                  localStorage.removeItem('AUTH-PROFILE-PIC');
                  localStorage.removeItem('USER-DETAILS');
                  userClass.screen.style.display = 'none';
                  userClass.router.navigateByUrl('/sign-in');
        });
    }
  }

  validateuserPasswordForm = ( passwordData ) =>{
    let userDetails = JSON.parse(localStorage.getItem('USER-DETAILS'));
    let PasswordClass = this;
    PasswordClass.userPassformInvalids = false;
    PasswordClass.userPassformInvalidMsgs = false;
    if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(passwordData.user_new_password)) {
      if(passwordData.user_new_password == passwordData.user_confirm_password) {
        console.log(passwordData);
        PasswordClass.screen.style.display = 'block';
    
        // let params = new HttpParams({
        //   fromObject: {
        //     userpull_id: localStorage.getItem('userpull_id'),
        //     sub: passwordData.sub,
        //     password: passwordData.user_new_password
        //   }
        // });
        let params = {
          userpull_id: "us-east-1_8whlhJLl8",
          sub: userDetails.sub,
          password: passwordData.user_new_password
        };
        this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/changepassword', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          console.log(response);
          PasswordClass.screen.style.display = 'none';
          if(response.status == "success") {
            alert("Password changed successful");
            location.reload();
          }
          else {
            alert("Password could not be changed. Please try again.");
          }
        },
        async (error: any) => {
          Auth.signOut();
                  localStorage.removeItem('AUTH');
                  localStorage.removeItem('AUTH-LIFE');
                  localStorage.removeItem('AUTH-PROFILE-PIC');
                  localStorage.removeItem('USER-DETAILS');
                  PasswordClass.screen.style.display = 'none';
                  PasswordClass.router.navigateByUrl('/sign-in');
        });
      }
      else {
        console.log('Error');
        PasswordClass.userPassformInvalids = true;
        PasswordClass.userPassformInvalidMsgs = false;
      }
    }
    else {
      console.log('Error');
      PasswordClass.userPassformInvalidMsgs = true;
      PasswordClass.userPassformInvalids = false;
    }
  }

  managebilling = (userdata = "")=>{
    console.log(userdata);
    let userClass = this;
    userClass.screen.style.display = 'block';
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id')
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getbillingconfig', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      userClass.screen.style.display = 'none';
      if (response.status === "success") {
        let responsedata = JSON.parse(response.data);
        console.log(responsedata);
        let responsedataArr = [];
        let montharr = {"1":"January", "2":"February", "3":"March", "4":"April", "5":"May", "6":"June", "7":"July", "8":"August", "9":"September", "10":"October", "11":"November", "12":"December"};
        for (let key in responsedata) {
          responsedataArr[Number(key) - 1] = {};
          if(responsedata[key]['active'] == "yes") {
            responsedataArr[Number(key) - 1]['active'] = true;
          }
          else if(responsedata[key]['active'] == "no") {
            responsedataArr[Number(key) - 1]['active'] = false;
          }
          responsedataArr[Number(key) - 1]['day'] = parseInt(responsedata[key]['day'], 10);
          responsedataArr[Number(key) - 1]['transmission'] = parseInt(responsedata[key]['transmission'], 10);
          responsedataArr[Number(key) - 1]['minupload'] = parseInt(responsedata[key]['minupload'], 10);
          responsedataArr[Number(key) - 1]['month'] = montharr[key];
          responsedataArr[Number(key) - 1]['monthkey'] = key;
        }
        console.log(responsedataArr);
        this.billingconfig = responsedataArr;
        let billModal = document.getElementById("bill-config-ankerTag") as HTMLElement;
        billModal.click();
      }
    },
    async (error: any) => {
      userClass.screen.style.display = 'none';
      console.log(error);
      alert("Some error occurred");
    });
  }

  validateBillingConfigForm = ( configData ) =>{
    console.log(configData);
    let processdata = {};
    let configClass = this;
    for (let key in configData) {
      let keyfirst = key.split("_");
      if(!processdata[keyfirst[0]]) {
        processdata[keyfirst[0]] = {};
      }
      processdata[keyfirst[0]][keyfirst[1]] = configData[key];
    }
    for(let pkey in processdata) {
      processdata[pkey]['day'] = parseInt(processdata[pkey]['day'], 10);
      processdata[pkey]['transmission'] = parseInt(processdata[pkey]['transmission'], 10);
      processdata[pkey]['minupload'] = parseInt(processdata[pkey]['minupload'], 10);
      if(processdata[pkey]['active']) {
        processdata[pkey]['active'] = "yes";
      }
      else {
        processdata[pkey]['active'] = "no";
      }
    }
    console.log(processdata);
    configClass.screen.style.display = 'block';
    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      processdata: JSON.stringify(processdata)
    };
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/savebillingconfig', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      configClass.screen.style.display = 'none';
      if(response.status == "success") {
        alert("Billing Configuration updated successfully");
        location.reload();
      }
      else {
        alert("Billing Configuration could not be updated. Please try again.");
      }
    },
    async (error: any) => {
      Auth.signOut();
              localStorage.removeItem('AUTH');
              localStorage.removeItem('AUTH-LIFE');
              localStorage.removeItem('AUTH-PROFILE-PIC');
              localStorage.removeItem('USER-DETAILS');
              configClass.screen.style.display = 'none';
              configClass.router.navigateByUrl('/sign-in');
    });
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
    this.dtTrigger1.unsubscribe();
  }

  addStockButton = (userdata = "")=>{
    console.log("addStockButton")
    console.log(userdata);

    this.stockItemList = [];

    let userClass = this;
    userClass.screen.style.display = 'block';

    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getstock', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response)
      userClass.screen.style.display = 'none';
      if (response.status === "success") {

        this.stockItemReorderList = JSON.parse(response.reOrderList)


        let tempstockItemList = JSON.parse(response.data);

        for(var tempstockItem of tempstockItemList){
          var temptempstockItem = tempstockItem;
          temptempstockItem["date_" + tempstockItem.id] = this.inventorymyTime;
          temptempstockItem["date"] = "date_" + tempstockItem.id;

          temptempstockItem["inventory_type_" + tempstockItem.id] = "";
          temptempstockItem["inventory_type"] = "inventory_type_" + tempstockItem.id;

          temptempstockItem["quantity_" + tempstockItem.id] = "";
          temptempstockItem["quantity"] = "quantity_" + tempstockItem.id;



          temptempstockItem["reorder_min_" + tempstockItem.id] = tempstockItem.minQuantity;
          temptempstockItem["reorder_min"] = "reorder_min_" + tempstockItem.id;

          temptempstockItem["reorder_order_" + tempstockItem.id] = tempstockItem.orderQuantity;
          temptempstockItem["reorder_order"] = "reorder_order_" + tempstockItem.id;

          

          this.stockItemList.push(temptempstockItem);
        }

        console.log(this.stockItemList);

        let patientInventoryModal = document.getElementById("add-stocks-ankerTag") as HTMLElement;
        patientInventoryModal.click();

        this.stockItemListLength = this.stockItemList.length;
        this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
          if(dtElement.dtInstance) {
          dtElement.dtInstance.then((dtInstance: any) => {
            if(dtInstance.table().node().id == "stock-table") {
              dtInstance.destroy();
              this.dtTrigger.next();
            }
          });
          }
          else {
            this.dtTrigger.next();
          }
        });
                
      }
    },
    async (error: any) => {
      await Auth.signOut({
        global: true
      });
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });

    
  } 
  
  addStocksModalClosoButtonClick = () => {
    console.log("addStocksModalClosoButtonClick");

    this.stockItemList = [];

    this.stockItemListLength = this.stockItemList.length;
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      if(dtElement.dtInstance) {
      dtElement.dtInstance.then((dtInstance: any) => {
        if(dtInstance.table().node().id == "stock-table") {
          dtInstance.destroy();
          this.dtTrigger.next();
        }
      });
      }
      else {
        this.dtTrigger.next();
      }
    });

    let patientInventoryModal = document.getElementById("add-stocks-inventory-modal-close-button") as HTMLElement;
    patientInventoryModal.click();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.dtTrigger1.next();
  }

  addStockQuantity = (type, id, stockItem) => {
    console.log(type)
    console.log(id)

    if (type == 'add') {
      $('#add-stocks').find('#add_'+id).hide();
      $('#add-stocks').find('#remove_'+id).hide();
      $('#add-stocks').find('#reorder_'+id).hide();

      $('#add-stocks').find('#not_add_'+id).show();
      $('#add-stocks').find('#not_remove_'+id).hide();
      $('#add-stocks').find('#not_reorder_'+id).hide();

      $('#add-stocks').find('#text_'+id).css("background", "#80e0a2");      
      $('#add-stocks').find('#text_'+id).show();

      let index = this.stockItemList.indexOf(stockItem);
      stockItem["date_" + id] = this.inventorymyTime;
      stockItem["quantity_" + id] = "";
      stockItem["inventory_type_" + id] = "Add";
      this.stockItemList[index] = stockItem;

      $('#add-stocks').find('#date_'+id).show();


      $('#add-stocks').find('#reorder_min_'+id).hide();
      $('#add-stocks').find('#reorder_order_'+id).hide();


      $('#add-stocks').find('#cros_'+id).show();
      
      this.stockAddCount = this.stockAddCount + 1;
    }else if (type == 'remove') {
      $('#add-stocks').find('#add_'+id).hide();
      $('#add-stocks').find('#remove_'+id).hide();
      $('#add-stocks').find('#reorder_'+id).hide();

      $('#add-stocks').find('#not_add_'+id).hide();
      $('#add-stocks').find('#not_remove_'+id).show();
      $('#add-stocks').find('#not_reorder_'+id).hide();

      $('#add-stocks').find('#text_'+id).css("background", "#e897aa");      
      $('#add-stocks').find('#text_'+id).show();      

      let index = this.stockItemList.indexOf(stockItem);
      stockItem["date_" + id] = this.inventorymyTime;
      stockItem["quantity_" + id] = "";
      stockItem["inventory_type_" + id] = "Remove";
      this.stockItemList[index] = stockItem;

      $('#add-stocks').find('#date_'+id).show();

      $('#add-stocks').find('#reorder_min_'+id).hide();
      $('#add-stocks').find('#reorder_order_'+id).hide();

      $('#add-stocks').find('#cros_'+id).show();

      this.stockAddCount = this.stockAddCount + 1;
    }else if (type == 'reorder') {
      $('#add-stocks').find('#add_'+id).hide();
      $('#add-stocks').find('#remove_'+id).hide();
      $('#add-stocks').find('#reorder_'+id).hide();

      $('#add-stocks').find('#not_add_'+id).hide();
      $('#add-stocks').find('#not_remove_'+id).hide();
      $('#add-stocks').find('#not_reorder_'+id).show();

      $('#add-stocks').find('#text_'+id).css("background", "#e897aa");      
      $('#add-stocks').find('#text_'+id).hide();      

      let index = this.stockItemList.indexOf(stockItem);
      stockItem["date_" + id] = this.inventorymyTime;
      stockItem["quantity_" + id] = "";
      stockItem["inventory_type_" + id] = "Reorder";
      this.stockItemList[index] = stockItem;

      $('#add-stocks').find('#date_'+id).hide();

      $('#add-stocks').find('#reorder_min_'+id).show();
      $('#add-stocks').find('#reorder_order_'+id).show();

      $('#add-stocks').find('#cros_'+id).show();

      this.stockAddCount = this.stockAddCount + 1;
    }else if (type == 'cros') {
      $('#add-stocks').find('#add_'+id).show();
      $('#add-stocks').find('#remove_'+id).show();
      $('#add-stocks').find('#reorder_'+id).show();

      $('#add-stocks').find('#not_add_'+id).hide();
      $('#add-stocks').find('#not_remove_'+id).hide();
      $('#add-stocks').find('#not_reorder_'+id).hide();

      let index = this.stockItemList.indexOf(stockItem);
      stockItem["date_" + id] = this.inventorymyTime;
      stockItem["quantity_" + id] = "";
      stockItem["inventory_type_" + id] = "";
      stockItem["reorder_min_" + id] = stockItem.minQuantity;
      stockItem["reorder_order_" + id] = stockItem.orderQuantity;
      this.stockItemList[index] = stockItem;

      $('#add-stocks').find('#text_'+id).hide();

      $('#add-stocks').find('#date_'+id).hide();

      $('#add-stocks').find('#reorder_min_'+id).hide();
      $('#add-stocks').find('#reorder_order_'+id).hide();

      $('#add-stocks').find('#cros_'+id).hide();

      this.stockAddCount = this.stockAddCount - 1;
    }    
  }


  addStockSubmitButtonClick = (addStockForm) => {
    console.log("addStockSubmitButtonClick")
    console.log(addStockForm)

    let itemparams = [];
    let addStockFormError = false;

    for (let stockItem of this.stockItemList) {      
      if (addStockForm["inventory_type_" + stockItem.id] === "Add"){
        if (addStockForm["quantity_" + stockItem.id] > 0 && addStockForm["quantity_" + stockItem.id] !== undefined){
          let tempitemparam = {};

          tempitemparam["item_id"] = stockItem.id;
          tempitemparam["quantity"] = addStockForm["quantity_" + stockItem.id];
          tempitemparam["inventory_type"] = addStockForm["inventory_type_" + stockItem.id];
          tempitemparam["date"] = addStockForm["date_" + stockItem.id];

          itemparams.push(tempitemparam);
        }else{
          addStockFormError = true;
          alert("Enter " + stockItem.name + " Add quantity");
        }
      }else if (addStockForm["inventory_type_" + stockItem.id] === "Remove"){
        if (addStockForm["quantity_" + stockItem.id] > 0 && addStockForm["quantity_" + stockItem.id] !== undefined){
          if (addStockForm["quantity_" + stockItem.id] <= stockItem.stock){
            let tempitemparam = {};

            tempitemparam["item_id"] = stockItem.id;
            tempitemparam["quantity"] = addStockForm["quantity_" + stockItem.id];
            tempitemparam["inventory_type"] = addStockForm["inventory_type_" + stockItem.id];
            tempitemparam["date"] = addStockForm["date_" + stockItem.id];

            itemparams.push(tempitemparam);
          }else{
            addStockFormError = true;
            alert(stockItem.name + " quantity less then stock");
          }
        }else{
          addStockFormError = true;
          alert("Enter " + stockItem.name + " Remove quantity");
        }
      }else if (addStockForm["inventory_type_" + stockItem.id] === "Reorder"){
        if (addStockForm["reorder_min_" + stockItem.id] > 0 && addStockForm["reorder_order_" + stockItem.id] > 0){
          let tempitemparam = {};

          tempitemparam["item_id"] = stockItem.id;
          tempitemparam["inventory_type"] = addStockForm["inventory_type_" + stockItem.id];
          tempitemparam["reorder_min"] = addStockForm["reorder_min_" + stockItem.id];
          tempitemparam["reorder_order"] = addStockForm["reorder_order_" + stockItem.id];

          itemparams.push(tempitemparam);
        }else{
          addStockFormError = true;
          if ((addStockForm["reorder_min_" + stockItem.id] === 0 || addStockForm["reorder_min_" + stockItem.id] === null) && (addStockForm["reorder_order_" + stockItem.id] === 0 || addStockForm["reorder_order_" + stockItem.id] === null)){
            alert("Enter " + stockItem.name + " Minimum Quantity & Order Quantity");
          }else if (addStockForm["reorder_min_" + stockItem.id] === 0 || addStockForm["reorder_min_" + stockItem.id] === null ){
            alert("Enter " + stockItem.name + " Minimum Quantity");
          }else if (addStockForm["reorder_order_" + stockItem.id] === 0 || addStockForm["reorder_order_" + stockItem.id] === null){
            alert("Enter " + stockItem.name + " Order Quantity")
          }
        }
      }   
    }

    console.log("itemparams", itemparams)

    if(addStockFormError == true){
      itemparams = [];
    }
    
    if ( itemparams.length > 0){

      let usersub = ""

      if (localStorage.getItem('USER-DETAILS')) {
        let userDetails = JSON.parse(localStorage.getItem('USER-DETAILS'));

        if(userDetails.sub){
          usersub = userDetails.sub;
        }
      }

      let params = {
        userpull_id: localStorage.getItem('userpull_id'),
        user_type: "user",
        user_id: usersub,
        items: itemparams,
      };


      console.log("final call params", params);

      let userClass = this;
      userClass.screen.style.display = 'block';

      
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addstock', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        userClass.screen.style.display = 'none';
        console.log(response)
        if (response.status === "success") {
          this.addStocksModalClosoButtonClick();

          this.addStockButton();
        }else{
          alert(response.message);
        }
      },
      async (error: any) => {
        userClass.screen.style.display = 'none';
        console.log(error);
        alert("Could not add stock. Please try again.");
        userClass.screen.style.display = 'none';
      });
    }

  }


  downloadStockReorderExcelButtonClick = () => {
    console.log("downloadexcel");
    if (this.stockItemReorderList.length > 0){
      /* table id is passed over here */   
      let element = document.getElementById('stock-table'); 
      const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(this.stockItemReorderList);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */
      XLSX.writeFile(wb, "ReOrderItem.xlsx");
    }else{
      alert("All Items In-Hand Quantity is grater than reorder minimum Quantity");
    }
  }

  downloadPatientInactiveItemExcelButtonClick = () => {
    console.log("downloadPatientInactiveItemExcelButtonClick");


    this.screen.style.display = 'block';
    let params = {
      userpull_id: localStorage.getItem('userpull_id')
    };
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getpatientinactivedevicelist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
      console.log(response);
      this.screen.style.display = 'none';
      if(response.status == "success") {
        var itemlist = JSON.parse(response.data);

        if (itemlist.length > 0){
          /* table id is passed over here */   
          let element = document.getElementById('stock-table'); 
          const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(itemlist);
    
          /* generate workbook and add the worksheet */
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
          /* save to file */
          XLSX.writeFile(wb, "InactiveItemList.xlsx");
        }else{
          alert("no Inactive Items at this time");
        }
      }
    },
    async (error: any) => {
      Auth.signOut();
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });

    
  }

  
  escalationDeviceList = [{id: 3, name: "Weight"},{ id: 4, name: "BPM" }, { id: 6, name: "Thermometer" }, { id: 7, name: "Oximeter" }, { id: 12, name: "Blood Glucose" }];
  escalationSettingList = [];
  escalationSettingParamList = [];
  escalationItemList = [];

  escalationDevice = 0;
  
  escalationParameterError = false;
  escalationValueError = false;
  escalationTextError = false;

  escalationValueErrorText = "";

  escalationNewType = "";

  EditescalationId = 0;

  escalationForm: any;

  escalationFormDevice: any;

  escalationParamDisabled = 0;

  addEscalationButtonClick = (escalationtype, tempescalationdevice) => {
    console.log("addEscalationButtonClick");
    console.log(escalationtype);
    console.log(tempescalationdevice);

    this.escalationSettingList = [];
    this.escalationSettingParamList = [];
    this.escalationItemList = [];

    this.escalationDevice = 0;

    
    this.escalationParameterError = false;
    this.escalationValueError = false;
    this.escalationTextError = false;

    this.escalationNewType = "";

    this.escalationFormDevice = "";
    setTimeout (() => {
      let reviewDataFinal = {};
      reviewDataFinal['device'] = 0;
      this.escalationFormDevice = reviewDataFinal;
    }, 500);

    let patientEscalationModal = document.getElementById("add-escalation-ankerTag") as HTMLElement;
    patientEscalationModal.click();

    if (escalationtype != "First" && tempescalationdevice > 0) {
      console.log("escalationdevice trigger", tempescalationdevice);
      this.escalationDevice = parseInt(tempescalationdevice);

      setTimeout (() => {
        let reviewDataFinal = {};
        reviewDataFinal['device'] = tempescalationdevice;
        this.escalationFormDevice = reviewDataFinal;
      }, 500);

      this.escalationdeviceChange(tempescalationdevice);
    }
  }

  escalationdeviceChange = (device) => {
    console.log("escalationdeviceChange");
    console.log(device);

    this.escalationDevice = device;

    this.escalationParameterError = false;
    this.escalationValueError = false;
    this.escalationTextError = false;

    this.escalationNewType = "";

    this.escalationSettingParamList = [];

    var tempescalationSettingParamList = [];

    this.escalationSettingList = [];

    this.screen.style.display = 'block';
    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      device: device
    };
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getpracticedeviceglobalsettings', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
      console.log(response);
      this.screen.style.display = 'none';
      if(response.status == "success") {
        var tempescalationSettingList = JSON.parse(response.data);

        let esclationLabel = {          

          //{"bmiMax": 25, "bmiMin": 18, "wtMax": 200, "wtMin": 10}

          bmiMax: "BMI (Max)",
          bmiMin: "BMI (Min)",
          wtMax: "Weight (Max)",
          wtMin: "Weight (Min)",

          //{"diaMax": 80, "diaMin": 60, "pulseMax": 100, "pulseMin": 60, "sysMax": 85, "sysMin": 55}

          diaMax: "DIA (Max)",
          diaMin: "DIA (Min)",
          pulseMax: "PULSE (Max)",
          pulseMin: "PULSE (Min)",
          sysMax: "SYS (Max)",
          sysMin: "SYS (Min)",

          //{"tempMax": 99, "tempMin": 97}

          tempMax: "Temp (Max)",
          tempMin: "Temp (Min)",

          //{"prMax": 120, "prMin": 60, "spo2Max": 99, "spo2Min": 92}

          prMax: "PR (Max)",
          prMin: "PR (Min)",
          spo2Max: "SPO2 (Max)",
          spo2Min: "SPO2 (Min)",

          //{"glucoseAfterMealMax": 250, "glucoseAfterMealMin": 80, "glucoseFastingMax": 160, "glucoseFastingMin": 40, "glucoseNormalTestMax": 214, "glucoseNormalTestMin": 60}

          glucoseAfterMealMax: "Within 2hr. Meal (Max)",
          glucoseAfterMealMin: "Within 2hr. Meal (Min)",
          glucoseFastingMax: "Fasting (Max)",
          glucoseFastingMin: "Fasting (Min)",
          glucoseNormalTestMax: "Normal (Max)",
          glucoseNormalTestMin: "Normal (Min)"
        };

        for(var temp of tempescalationSettingList){
          this.escalationSettingList.push({ name : esclationLabel[temp.name], value: temp.value });
        }

        let uniqparam = [];

        for(var temp of tempescalationSettingList){
          if(uniqparam.indexOf(temp.name.slice(0, temp.name.length - 3)) === -1) {
            uniqparam.push(temp.name.slice(0, temp.name.length - 3));

            tempescalationSettingParamList.push({ id : temp.name.slice(0, temp.name.length - 3), name: temp.name.slice(0, temp.name.length - 3)});
          }
        }

        if (device == 3){
          console.log("Weight");
          tempescalationSettingParamList = [];

          tempescalationSettingParamList.push({ id : "Plus", name: "Plus" });
          tempescalationSettingParamList.push({ id : "Minus", name: "Minus" });
        }

        if (device == 4){
          tempescalationSettingParamList.push({ id : "arrhythmia", name: "arrhythmia" });
        }

        let esclationParamLabel = {
          
          Plus: "Above Threshold",
          Minus: "Below Threshold",

          dia: "DIA",
          pulse: "PULSE",
          sys: "SYS",
          arrhythmia: "Arrhythmia",

          temp: "Temp",

          pr: "PR",
          spo2: "SPO2",

          glucoseAfterMeal: "Within 2hr. Meal",
          glucoseFasting: "Fasting",
          glucoseNormalTest: "Normal"

        };

        for(var temp of tempescalationSettingParamList){
          this.escalationSettingParamList.push({ id : temp.id, name: esclationParamLabel[temp.name] });
        }

        //List
        this.screen.style.display = 'block';
        let params = {
          userpull_id: localStorage.getItem('userpull_id'),
          device: device
        };
        this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getescalation', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
          .subscribe((response: any) => {
          console.log(response);
          this.screen.style.display = 'none';
          if(response.status == "success") {
            this.escalationItemList = JSON.parse(response.data);
          }
        },
        async (error: any) => {
          Auth.signOut();
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.screen.style.display = 'none';
          this.router.navigateByUrl('/sign-in');
        });
      }
    },
    async (error: any) => {
      Auth.signOut();
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });
  }

  addNewEscalation = async () => {
    console.log("addNewEscalation");
    
    this.escalationForm = "";
    setTimeout (() => {
      let reviewDataFinal = {};
      reviewDataFinal['device_param'] = "";
      reviewDataFinal['device_escalation_val'] = "";
      reviewDataFinal['escalation_text'] = "";
      this.escalationForm = reviewDataFinal;
    }, 500);

    this.screen.style.display = 'block';
    await this.delay(500);
    this.screen.style.display = 'none';
    this.escalationNewType = "Add";
  }

  escalationParamChange = (paramTemp) => {
    console.log("escalationParamChange");
    console.log(paramTemp);
    if (paramTemp == 'arrhythmia'){
      this.escalationParamDisabled = 1;
      setTimeout (() => {
        let reviewDataFinal = {};
        reviewDataFinal['device_param'] = paramTemp;
        reviewDataFinal['device_escalation_val'] = 1;
        reviewDataFinal['escalation_text'] = '';
        this.escalationForm = reviewDataFinal;
      }, 500);
    }else{
      this.escalationParamDisabled = 0;
      setTimeout (() => {
        let reviewDataFinal = {};
        reviewDataFinal['device_param'] = paramTemp;
        reviewDataFinal['device_escalation_val'] = '';
        reviewDataFinal['escalation_text'] = '';
        this.escalationForm = reviewDataFinal;
      }, 500);
    }
    this.escalationParameterError = false;
  }

  escalationValueChange = (escalationValue, form) => {
    console.log("escalationValueChange");
    console.log(escalationValue);

    if (escalationValue > 0){
      this.escalationValueError = false;
    }else{
      this.escalationValueError = true;
      this.escalationValueErrorText = "Escalation Value is required.";
    }
  }

  escalationTextChange = (text) => {
    console.log("escalationTextChange");
    console.log(text);

    if (text !== ''){
      this.escalationTextError = false;
    }else{
      this.escalationTextError = true;
    }
  }

  addUpdateEscalationFormSubmitButtonClick = (form, escalationNewType) => {
    console.log("addEscalationFormSubmitButtonClick");
    console.log(form);
    console.log(escalationNewType);

    if (form.escalationParam !== '' && form.escalationParam !== undefined){
      this.escalationParameterError = false;
    }else{
      this.escalationParameterError = true;
    }

    if (form.escalationvalue > 0){
      

      //In Min Max
      console.log(form.escalationParam);
      var min: number;
      var max: number;
      for (var tempsetteingval of this.escalationSettingList){
        console.log(tempsetteingval)
        if (tempsetteingval.name == form.escalationParam + "Min"){
          console.log(tempsetteingval.value)
          min = tempsetteingval.value
        }
        if (tempsetteingval.name == form.escalationParam + "Max"){
          console.log(tempsetteingval.value)
          max = tempsetteingval.value
        }
      }
      if (min & max){
        if(form.escalationvalue > max || form.escalationvalue < min){
          this.escalationValueError = false;
        }else{
          this.escalationValueError = true;
          this.escalationValueErrorText = "Escalation Value is greater than max and less than min.";
        }
      }
    }else{
      this.escalationValueError = true;
      this.escalationValueErrorText = "Escalation Value is required.";
    }

    if (form.escalationtext !== '' && form.escalationtext !== undefined){
      this.escalationTextError = false;
    }else{
      this.escalationTextError = true;
    }

    if (this.escalationParameterError == false && this.escalationValueError == false && this.escalationTextError == false){
      console.log("Final call");

      var addUpdateescalationParams = {};
      var addupdateUrl = "";
      var addupdateMassage = "";  

      if (escalationNewType == 'Add'){
        addupdateUrl = "https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addescalation";
        addupdateMassage = "Add escalation successfully";
        var deviceparam = "";
        for(var temp of this.escalationSettingParamList){
          if (temp.id == form.escalationParam){
            deviceparam = temp.name;
          }
        }
        addUpdateescalationParams = {
          device: form.escalationDevice,
          escalationparam: form.escalationParam,
          escalationvalue: form.escalationvalue,
          escalationtext: form.escalationtext,
          userpull_id: localStorage.getItem('userpull_id'),
          escalationparam2: deviceparam
        };
      }else if (escalationNewType == 'Edit'){
        addupdateUrl = "https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updateescalation";
        addupdateMassage = "Update escalation successfully";
        addUpdateescalationParams = {
          escalation_id: this.EditescalationId,
          device: form.escalationDevice,
          escalationparam: form.escalationParam,
          escalationvalue: form.escalationvalue,
          escalationtext: form.escalationtext,
          userpull_id: localStorage.getItem('userpull_id')
        };
      }

      if (addupdateUrl != ''){

        console.log("Final call Param", addUpdateescalationParams);     

        this.screen.style.display = 'block';

        this.httpClient.post(addupdateUrl, addUpdateescalationParams, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe( async (response: any) => {
          console.log("response");
          console.log(response)
          this.screen.style.display = 'none';
          if (response.status === "success") {
            alert(addupdateMassage);
            let patientEscalationModal = document.getElementById("add-escalation-modal-close-button") as HTMLElement;
            patientEscalationModal.click();
            this.screen.style.display = 'block';
            await this.delay(500);
            this.addEscalationButtonClick('Second', parseInt(form.escalationDevice));
          }else{
            alert(response.message);
          }
        },
        async (error: any) => {
          console.log(error);
          alert("Could not save escalation. Please try again.");
          this.screen.style.display = 'none';
        });
      }

    }
  }

  escalationEdit = async (escalation) => {
    console.log("escalationEdit");
    console.log(escalation);

    this.escalationForm = "";

    this.escalationParameterError = false;
    this.escalationValueError = false;
    this.escalationTextError = false;

    this.EditescalationId = escalation.id;

    setTimeout (() => {
      let reviewDataFinal = {};
      reviewDataFinal['device_param'] = escalation.device_param;
      reviewDataFinal['device_escalation_val'] = escalation.device_escalation_val;
      reviewDataFinal['escalation_text'] = escalation.escalation_text;
      this.escalationForm = reviewDataFinal;
    }, 500);

    if (escalation.device_param == 'arrhythmia'){
      this.escalationParamDisabled = 1;
    }else{
      this.escalationParamDisabled = 0;
    }

    this.screen.style.display = 'block';
    await this.delay(500);
    this.screen.style.display = 'none';
    this.escalationNewType = "Edit";
  }

  escalationDelete = (escalation) => {
    console.log("escalationDelete");
    console.log(escalation);

    var confirmMsg = "Do you want to delete this escalation?";
    if(confirm(confirmMsg)) {
      console.log("confirm delete");
      this.screen.style.display = 'block';

      let addescalationParams = {
        escalation_id: escalation.id,
        userpull_id: localStorage.getItem('userpull_id')
      };

      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/deleteescalation', addescalationParams, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe( async (response: any) => {
        console.log("response");
        console.log(response)
        this.screen.style.display = 'none';
        if (response.status === "success") {
          alert("Escalation delete successfully");
          let patientEscalationModal = document.getElementById("add-escalation-modal-close-button") as HTMLElement;
          patientEscalationModal.click();
          this.screen.style.display = 'block';
          await this.delay(500);
          this.addEscalationButtonClick('Second', parseInt(escalation.item_id));
        }else{
          alert(response.message);
        }
      },
      async (error: any) => {
        console.log(error);
        alert("Could not delete escalation. Please try again.");
        this.screen.style.display = 'none';
      });
    }
  }

  smsTemplateList = [];
  languageList = [];

  smsTemplateAddType = "";

  smsShortNameError = false;
  smsTextError = false;

  EditsmsTemplateId = [];

  smsTemplateForm:any;

  smsTemplateButtonClick = () => {
    console.log("smsTemplateButtonClick");

    this.smsTemplateList = [];
    this.languageList = [];

    this.smsTemplateAddType = "";

    this.smsShortNameError = false;
    this.smsTextError = false;

    this.screen.style.display = 'block';
    let params = {
      userpull_id: localStorage.getItem('userpull_id')
    };
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getsmstemplate', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
      console.log(response);
      this.screen.style.display = 'none';
      if(response.status == "success") {
        this.smsTemplateList = JSON.parse(response.data);
        console.log(this.smsTemplateList);
        // this.languageList
        if(localStorage.getItem('LanguageList')) {
          let langList = JSON.parse(localStorage.getItem('LanguageList'));
          for(var j=0; j<langList.length; j++) {
            var langval = langList[j]['language_value'];
            if(langval.indexOf("(") > 0) {
              langList[j]['language_value'] = langval.substring(0, langval.indexOf("(")-1);
            }
          }
          this.languageList = langList;
          console.log(this.languageList);
        }
        let patientSmsModal = document.getElementById("sms-template-ankerTag") as HTMLElement;
        patientSmsModal.click();
      }
    },
    async (error: any) => {
      Auth.signOut();
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });
  }

  addNewSMSTemplate = async () => {
    console.log("addNewEscalation");

    this.smsTemplateForm = "";
    setTimeout (() => {
      let reviewDataFinal = {};
      reviewDataFinal['shortName'] = "";
      for (var i=0; i<this.languageList.length; i++){
        reviewDataFinal['Text_'+this.languageList[i]['language_code']] = "";
      }
      // reviewDataFinal['text'] = "";
      this.smsTemplateForm = reviewDataFinal;
    }, 500);

    this.screen.style.display = 'block';
    await this.delay(500);
    this.screen.style.display = 'none';
    this.smsTemplateAddType = "Add";
  }

  smsShortNameChange = (tempVal) => {
    console.log("smsShortNameChange");
    console.log(tempVal);

    if (tempVal !== ''){
      this.smsShortNameError = false;
    }else{
      this.smsShortNameError = true;
    }
  }

  smsTextChange = (tempVal) => {
    console.log("smsTextChange");
    console.log(tempVal);

    if (tempVal !== ''){
      this.smsTextError = false;
    }else{
      this.smsTextError = true;
    }
  }


  smsTemplateEdit = async (template) => {
    console.log("smsTemplateEdit");
    console.log(template);

    this.smsShortNameError = false;
    this.smsTextError = false;
    
    this.EditsmsTemplateId = [];

    setTimeout (() => {
      let reviewDataFinal = {};
      reviewDataFinal['shortName'] = template.shortname;
      for (var i=0; i<this.languageList.length; i++){
        if(template[this.languageList[i]['language_code']]) {
          this.EditsmsTemplateId.push(template[this.languageList[i]['language_code']]['id']);
          reviewDataFinal['Text_'+this.languageList[i]['language_code']] = template[this.languageList[i]['language_code']]['smstext'];
        }
        else {
          reviewDataFinal['Text_'+this.languageList[i]['language_code']] = "";
        }
      }
      // reviewDataFinal['text'] = template.smstext;
      console.log(reviewDataFinal);
      this.smsTemplateForm = reviewDataFinal;
    }, 500);

    this.screen.style.display = 'block';
    await this.delay(500);
    this.screen.style.display = 'none';

    this.smsTemplateAddType = "Edit";
  }

  smsFormSubmitButtonClick = (form, smsTemplateAddType) => {
    console.log("smsFormSubmitButtonClick");
    console.log(form);
    console.log(smsTemplateAddType)
    
    if (form.shortname !== '' && form.shortname !== undefined){
      this.smsShortNameError = false;
    }else{
      this.smsShortNameError = true;
    }

    // if (form.text !== '' && form.text !== undefined){
    //   this.smsTextError = false;
    // }else{
    //   this.smsTextError = true;
    // }
    
    if (form['text_en-US'] !== '' && form['text_en-US'] !== undefined){
      this.smsTextError = false;
    }else{
      this.smsTextError = true;
    }

    if (this.smsShortNameError == false && this.smsTextError == false){
      var formtext = {};
      for (var i=0; i<this.languageList.length; i++){
        if('text_'+this.languageList[i]['language_code'] in form){
          formtext[this.languageList[i]['language_code']] = form['text_'+this.languageList[i]['language_code']]
        }
      }
      console.log(formtext);
      
      var addupdateParams = {};
      var addupdateUrl = "";
      var addupdateMassage = "";  

      // if (smsTemplateAddType == 'Add'){
      //   addupdateUrl = "https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addsmstemplate";
      //   addupdateMassage = "Add template successfully";
      //   addupdateParams = {
      //     shortname: form.shortname,
      //     text: form.text,
      //     userpull_id: localStorage.getItem('userpull_id')
      //   };
      // }else if (smsTemplateAddType == 'Edit'){
      //   addupdateUrl = "https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updatesmstemplate";
      //   addupdateMassage = "Update template successfully";
      //   addupdateParams = {
      //     template_id: this.EditsmsTemplateId,
      //     shortname: form.shortname,
      //     text: form.text,
      //     userpull_id: localStorage.getItem('userpull_id')
      //   };
      // }
      if (smsTemplateAddType == 'Add' || smsTemplateAddType == 'Edit'){
        addupdateUrl = "https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addeditsmstemplate";
        addupdateParams = {
          shortname: form.shortname,
          text: formtext,
          userpull_id: localStorage.getItem('userpull_id')
        };
        addupdateMassage = "Add template successfully";
        if (smsTemplateAddType == 'Edit'){
          addupdateMassage = "Update template successfully";
          addupdateParams['template_id'] = this.EditsmsTemplateId;
        }
      }

      if (addupdateUrl != ''){

        console.log("Final call Param", addupdateParams);     
        // return false;
        this.screen.style.display = 'block';

        this.httpClient.post(addupdateUrl, addupdateParams, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe( async (response: any) => {
          console.log("response");
          console.log(response)
          this.screen.style.display = 'none';
          if (response.status === "success") {
            alert(addupdateMassage);
            let patientSmsModal = document.getElementById("sms-template-modal-close-button") as HTMLElement;
            patientSmsModal.click();
            this.screen.style.display = 'block';
            await this.delay(500);
            this.smsTemplateButtonClick();
          }else{
            alert(response.message);
          }
        },
        async (error: any) => {
          console.log(error);
          alert("Could not save template. Please try again.");
          this.screen.style.display = 'none';
        });
      }

    }
  }

  smsTemplateDelete = (template) => {
    console.log("smsTemplateDelete");
    console.log(template);

    var deletesmsTemplateId = [];
    var formtext = {};
    for (var i=0; i<this.languageList.length; i++){
      if(template[this.languageList[i]['language_code']]) {
        deletesmsTemplateId.push(template[this.languageList[i]['language_code']]['id']);
        formtext[this.languageList[i]['language_code']] = "";
      }
    }
    console.log(deletesmsTemplateId);
    console.log(formtext);

    var confirmMsg = "Do you want to delete this template?";
    if(confirm(confirmMsg)) {
      console.log("confirm delete");
      this.screen.style.display = 'block';
      
      // let deleteParams = {
      //   template_id: template.id,
      //   userpull_id: localStorage.getItem('userpull_id')
      // };

      let deleteParams = {
        shortname: template.shortname,
        text: formtext,
        template_id: deletesmsTemplateId,
        userpull_id: localStorage.getItem('userpull_id')
      };

      // this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/deletesmstemplate', deleteParams, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addeditsmstemplate', deleteParams, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe( async (response: any) => {
        console.log("response");
        console.log(response)
        this.screen.style.display = 'none';
        if (response.status === "success") {
          alert("Template delete successfully");
          let patientSmsModal = document.getElementById("sms-template-modal-close-button") as HTMLElement;
          patientSmsModal.click();
          this.screen.style.display = 'block';
          await this.delay(500);
          this.smsTemplateButtonClick();
        }else{
          alert(response.message);
        }
      },
      async (error: any) => {
        console.log(error);
        alert("Could not delete Template. Please try again.");
        this.screen.style.display = 'none';
      });
    }
  }


  //Review Template

  reviewTemplateList = [];

  reviewTemplateAddType = "";

  reviewShortNameError = false;
  reviewTextError = false;

  EditreviewTemplateId = 0;

  reviewTemplateForm:any;

  reviewTemplateButtonClick = () => {
    console.log("reviewTemplateButtonClick");

    this.reviewTemplateList = [];

    this.reviewTemplateAddType = "";

    this.reviewShortNameError = false;
    this.reviewTextError = false;

    this.screen.style.display = 'block';
    let params = {
      userpull_id: localStorage.getItem('userpull_id')
    };
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getreviewtemplatelist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
      console.log(response);
      this.screen.style.display = 'none';
      if(response.status == "success") {
        this.reviewTemplateList = JSON.parse(response.data);

        //Save Session Storage
        sessionStorage.setItem('reviewtemplate', response.data);

        let patientReviewModal = document.getElementById("review-template-ankerTag") as HTMLElement;
        patientReviewModal.click();
      }
    },
    async (error: any) => {
      Auth.signOut();
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });
  }

  addNewReviewTemplate = async () => {
    console.log("addNewEscalation");

    this.reviewTemplateForm = "";
    setTimeout (() => {
      let reviewDataFinal = {};
      reviewDataFinal['shortName'] = "";
      reviewDataFinal['text'] = "";
      this.reviewTemplateForm = reviewDataFinal;
    }, 500);

    this.screen.style.display = 'block';
    await this.delay(500);
    this.screen.style.display = 'none';
    this.reviewTemplateAddType = "Add";
  }

  reviewShortNameChange = (tempVal) => {
    console.log("reviewShortNameChange");
    console.log(tempVal);

    if (tempVal !== ''){
      this.reviewShortNameError = false;
    }else{
      this.reviewShortNameError = true;
    }
  }

  reviewTextChange = (tempVal) => {
    console.log("reviewTextChange");
    console.log(tempVal);

    if (tempVal !== ''){
      this.reviewTextError = false;
    }else{
      this.reviewTextError = true;
    }
  }


  reviewTemplateEdit = async (template) => {
    console.log("reviewTemplateEdit");
    console.log(template);

    this.reviewShortNameError = false;
    this.reviewTextError = false;
    
    this.EditreviewTemplateId = template.id;

    setTimeout (() => {
      let reviewDataFinal = {};
      reviewDataFinal['shortName'] = template.shortname;
      reviewDataFinal['text'] = template.template_text;
      this.reviewTemplateForm = reviewDataFinal;
    }, 500);

    this.screen.style.display = 'block';
    await this.delay(500);
    this.screen.style.display = 'none';

    this.reviewTemplateAddType = "Edit";
  }

  reviewFormSubmitButtonClick = (form, reviewTemplateAddType) => {
    console.log("reviewFormSubmitButtonClick");
    console.log(form);
    console.log(reviewTemplateAddType)

    if (form.shortname !== '' && form.shortname !== undefined){
      this.reviewShortNameError = false;
    }else{
      this.reviewShortNameError = true;
    }

    if (form.text !== '' && form.text !== undefined){
      this.reviewTextError = false;
    }else{
      this.reviewTextError = true;
    }

    if (this.reviewShortNameError == false && this.reviewTextError == false){
      console.log("Final call");

      var addupdateParams = {};
      var addupdateUrl = "";
      var addupdateMassage = "";  

      if (reviewTemplateAddType == 'Add'){
        addupdateUrl = "https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addreviewtemplate";
        addupdateMassage = "Add template successfully";
        addupdateParams = {
          shortname: form.shortname,
          text: form.text,
          userpull_id: localStorage.getItem('userpull_id')
        };
      }else if (reviewTemplateAddType == 'Edit'){
        addupdateUrl = "https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updatereviewtemplate";
        addupdateMassage = "Update template successfully";
        addupdateParams = {
          template_id: this.EditreviewTemplateId,
          shortname: form.shortname,
          text: form.text,
          userpull_id: localStorage.getItem('userpull_id')
        };
      }

      if (addupdateUrl != ''){

        console.log("Final call Param", addupdateParams);     

        this.screen.style.display = 'block';

        this.httpClient.post(addupdateUrl, addupdateParams, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe( async (response: any) => {
          console.log("response");
          console.log(response)
          this.screen.style.display = 'none';
          if (response.status === "success") {
            alert(addupdateMassage);
            let patientReviewModal = document.getElementById("review-template-modal-close-button") as HTMLElement;
            patientReviewModal.click();
            this.screen.style.display = 'block';
            await this.delay(500);
            this.reviewTemplateButtonClick();
          }else{
            alert(response.message);
          }
        },
        async (error: any) => {
          console.log(error);
          alert("Could not save template. Please try again.");
          this.screen.style.display = 'none';
        });
      }

    }
  }

  reviewTemplateDelete = (template) => {
    console.log("reviewTemplateDelete");
    console.log(template);

    var confirmMsg = "Do you want to delete this template?";
    if(confirm(confirmMsg)) {
      console.log("confirm delete");
      this.screen.style.display = 'block';

      let deleteParams = {
        template_id: template.id,
        userpull_id: localStorage.getItem('userpull_id')
      };

      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/deletereviewtemplate', deleteParams, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe( async (response: any) => {
        console.log("response");
        console.log(response)
        this.screen.style.display = 'none';
        if (response.status === "success") {
          alert("Template delete successfully");
          let patientReviewModal = document.getElementById("review-template-modal-close-button") as HTMLElement;
          patientReviewModal.click();
          this.screen.style.display = 'block';
          await this.delay(500);
          this.reviewTemplateButtonClick();
        }else{
          alert(response.message);
        }
      },
      async (error: any) => {
        console.log(error);
        alert("Could not delete Template. Please try again.");
        this.screen.style.display = 'none';
      });
    }
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  getTwilioNum = () => {
    this.screen.style.display = 'block';
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id')
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/gettwilionum', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      this.screen.style.display = 'none';
      if (response.status === "success") {
        let responsedata = JSON.parse(response.data);
        console.log(responsedata);
        this.twilio_num = "";
        let callerModal = document.getElementById("caller-config-ankerTag") as HTMLElement;
        callerModal.click();
        setTimeout (() => {
          this.twilio_num = responsedata['twilio_number'];
        }, 500);
      }
      else {
        alert("Could not find Caller ID");
        this.screen.style.display = 'none';
      }
    },
    async (error: any) => {
      alert("Some error occurred. Please try again.");
      this.screen.style.display = 'none';
    });
  }

  validateTwilioNumForm = (callerData) => {
    console.log(callerData);
    this.screen.style.display = 'block';

    let saveParams = {
      twilio_number: callerData.twilio_number,
      userpull_id: localStorage.getItem('userpull_id')
    };

    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/savetwilionum', saveParams, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe( async (response: any) => {
      console.log(response);
      this.screen.style.display = 'none';
      if(response.status == "success") {
        alert("saved successfully");
        location.reload();
      }
      else {
        console.log(response);
      }
    },
    async (error: any) => {
      console.log(error);
      alert("Could not delete Template. Please try again.");
      this.screen.style.display = 'none';
    });
  }

  PayerList = [];
  PayerListLength = 0;

  CheckedPayer = [];

  payerListButtonClick = () => {
    console.log("payerListButtonClick");

    this.PayerList = []
    this.CheckedPayer = []

    this.screen.style.display = 'block';

    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/geteligibilityallpayerlist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      if (response.status === "success") {
        this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getpracticeeligibilitypayerlist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((responsepractice: any) => {
        this.screen.style.display = 'none';      
          if (responsepractice.status === "success") {
            let Practice = JSON.parse(responsepractice.data);

            this.CheckedPayer = Practice['payercode'];

            var PayerList = JSON.parse(response.data);

            for (var payer of PayerList){
              var checked = false;
              var temppayer = payer;
              const index: number = this.CheckedPayer.indexOf(payer['payerCode']);
              if (index !== -1) {
                checked = true;
              }
              temppayer["checked"] = checked;
              this.PayerList.push(temppayer);
            }

            let patientPayerModal = document.getElementById("add-payer-ankerTag") as HTMLElement;
            patientPayerModal.click();

            
            this.PayerListLength = this.PayerList.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "payer-table") {
                  dtInstance.destroy();
                  this.dtTrigger1.next();
                }
              });
              }
              else {
                this.dtTrigger1.next();
              }
            });
            

          }
        },
        async (error: any) => {
          console.log(error);
          this.screen.style.display = 'none';
        });
      }
    },
    async (error: any) => {
      console.log(error);
      this.screen.style.display = 'none';
    });
  }

  addPayerModalClosoButtonClick = () => {
    console.log("addPayerModalClosoButtonClick");

    this.PayerList = [];

    this.PayerListLength = this.PayerList.length;
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      if(dtElement.dtInstance) {
      dtElement.dtInstance.then((dtInstance: any) => {
        if(dtInstance.table().node().id == "payer-table") {
          dtInstance.destroy();
          this.dtTrigger1.next();
        }
      });
      }
      else {
        this.dtTrigger1.next();
      }
    });

    let patientPayerModal = document.getElementById("add-payer-modal-close-button") as HTMLElement;
    patientPayerModal.click();
  }

  PayerCheckonChange(code: string, isChecked: boolean) {
    console.log("PayerCheckonChange");
    console.log(code);
    console.log(isChecked);

    // const emailFormArray = <FormArray>this.myForm.controls.useremail;

    if (isChecked) {
      this.CheckedPayer.push(code);
    } else {
      const index: number = this.CheckedPayer.indexOf(code);
      if (index !== -1) {
          this.CheckedPayer.splice(index, 1);
      }
    }
  }

  addPayerSubmitButtonClick = (from) => {
    console.log("addPayerSubmitButtonClick");
    console.log(this.CheckedPayer)

    if (this.CheckedPayer.length > 0){

      this.screen.style.display = 'block';

      let deleteParams = {
        payercode: this.CheckedPayer.toString(),
        userpull_id: localStorage.getItem('userpull_id')
      };

      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addpracticeeligibilitypayerlist', deleteParams, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe( async (response: any) => {
        console.log("response");
        console.log(response)
        this.screen.style.display = 'none';
        if (response.status === "success") {
          alert("payer add successfully");
          
        }else{
          alert(response.message);
        }
      },
      async (error: any) => {
        console.log(error);
        alert("Could not delete Template. Please try again.");
        this.screen.style.display = 'none';
      });
    }else{
      alert("select at least one payer");
    }
  }

  gotoPracticeClick = (pull) => {
    this.screen.style.display = 'block';
    console.log("gotoPracticeClick");

    localStorage.setItem('userpull_id', pull.id);
    localStorage.setItem('userpull', JSON.stringify(pull));
    // location.reload();

    sessionStorage.setItem('reviewtemplate', '');

    let params:any;

    params = new HttpParams({
      fromObject: {
        userpull_id: pull.id
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getreviewtemplatelist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((responsetemp: any) => {
      if (responsetemp.status === "success") {
        sessionStorage.setItem('reviewtemplate', responsetemp.data);
        this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/webuserlist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          if (response.status === "success") {
            localStorage.setItem('SYS-USERS', response.sysdata);
            localStorage.setItem('POOL-USERS', response.data);
            if(response.pubnubdata) {
              sessionStorage.setItem('pubnubcred', response.pubnubdata);
            }
            if(response.LanguageList) {
              localStorage.setItem('LanguageList', response.LanguageList);
              // this.router.navigateByUrl('/dashboard');
              location.reload();
            }
          }
        },
        async (error: any) => {
          console.log(error);
        });
      }
    },
    async (error: any) => {
      console.log(error);
    });

  }

}
