import { AfterViewInit, Component, OnInit, ViewChild, ViewChildren, ElementRef, QueryList, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { Auth } from 'aws-amplify';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThrowStmt } from '@angular/compiler';
import { ltLocale } from 'ngx-bootstrap';
import { UploadService } from '../services/upload.service';
import { ChatgptService } from '../services/chatgpt.service';
import { isObject } from 'util';
import {v4 as uuidv4} from 'uuid';
import { Console } from 'console';
import { DatePipe } from '@angular/common';

declare var $: any;
declare const Twilio: any;

@Component({
  selector: 'app-patient-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PatientDetailComponent implements AfterViewInit, OnInit {
  @ViewChild('uploadPatient', {static: false})
  myInputVariable: ElementRef;

  upmessage: string = "";

  userGroup: string = '';
  subId: any;
  thisDate: any;
  activeStatus: boolean = false;

  showfacilities: boolean = false;
  facilities: any;
  facilityid: any;
  facilitytext: any;

  interventionarr: any;
  interventiondataarr: any;
  interventionDeviceText: any;
  interventionPreText: any;
  
  reviewtemplatearr: any;
  selreviewtemplate: any;
  reviewtext: any;

  htmlToAdd: any;
  syncStatus: any;
  syncedon: any;

  deviceReading: any;
  primdiag: any;
  primdiagStr: any;
  eduloc: any;

  timezoneStr: any;
  languageStr: any;
  smsallowed: number = 0;
  usergender: any;

  processing: HTMLElement = document.getElementById('cover') as HTMLElement;
  screen: HTMLElement = document.getElementById('screen') as HTMLElement;

  cpass: any;

  reviewTransmission: any;
  addReviewFormMode: any;
  addReviewFormProvider: any;

  statusFormStatus: any;
  
  userDetails: any;
  monthDetails:any;
  myMonth: any;
  myTime: any;
  myEduDate: any;
  providerlist: any;
  languagelist: any;
  languageArr: any;

  eduDetails: any;
  editEduForm: any;

  revDetails: any;

  settingDetails: any;

  currentDuration: any;
  previousDuration: any; 
  
  formInvalids:boolean = false;
  formInvalidMsgs:boolean = false;

  patientEditMode:boolean = false;

  consentdateInvalid: boolean = false;
  consentdateError: string = '';
  edudateInvalid: boolean = false;
  edudateError: string = '';
  transdateInvalid: boolean = false;
  transdateError: string = '';

  givenNameInvalid: boolean = false;
  givenNameError: string = '';

  familyNameInvalid: boolean = false;
  familyNameError: string = '';

  phoneNumberInvalid: boolean = false;
  phoneNumberError: string = ''; 

  facetimeInvalid: boolean = false;
  facetimeError: string = ''; 

  smsNumberInvalid: boolean = false;
  smsNumberError: string = ''; 
  
  birthdateInvalid: boolean = false;
  birthdateError: string = ''; 

  addressInvalid: boolean = false;
  addressError: string = '';

  genderInvalid: boolean = false;
  genderError: string = '';

  heightInvalid: boolean = false;
  heightError: string = '';

  countryInvalid: boolean = false;
  countryError: string = '';

  timezoneInvalid: boolean = false;
  timezoneError: string = '';

  languageInvalid: boolean = false;
  languageError: string = '';

  hubDetails: any;

  patientStatuses = []
  patientCurrentStatus: boolean = false;
  patientCurrentStatusMessage: string = '';

  alertSettingDeviceCount = 0;

  //Graph
  isPatientDeviceLineChartData: boolean = false;
  isPatientGlocoseLineChartData: boolean = false;

  patientLineChartStr = ""

  allGraphDevice = []
  allUploadHistory = []

  patientLineChartData: any = [];
  patientLinechartLabels: any = [];
  patientLinechartColors = [];
  patientLinechartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: false
        }
      }],
      xAxes:[{
        ticks:{
            display: true,
            autoSkip: true,
            maxTicksLimit: 10
        }
    }]
    }
  };

  patientLineFAChartData: any = [];
  patientLineFAchartLabels: any = [];
  patientLineFAchartColors = [];

  patientLineAMChartData: any = [];
  patientLineAMchartLabels: any = [];
  patientLineAMchartColors = [];

  patientLineNTChartData: any = [];
  patientLineNTchartLabels: any = [];
  patientLineNTchartColors = [];

  // Call
  // twilioEndPoint = "https://9plv7qb61c.execute-api.us-east-1.amazonaws.com";
  twilioEndPoint = "https://3xcxz84v68.execute-api.us-east-1.amazonaws.com";
  // twilioEndPointApiKey = "Jn8LkmFieL9uzY8NIIXnB5Q9t5MNBYJz7Sqztw4R";
  twilioEndPointApiKey = "2pdKyWDlxE6KxzVGV8Dsq5cDxcTVuWhZ3k5TCmYe";
  // miradoreEndPoint = "https://es7lryw4v3.execute-api.us-east-1.amazonaws.com";
  miradoreEndPoint = "https://vnguhgav37.execute-api.us-east-1.amazonaws.com";
  // miradoreEndPointApiKey = "prHxSL9JEX2DTNjANOKzi98Rg8fAe5Ot5f10sKqa";


  // twilioEndPoint = "https://x551vb9x3m.execute-api.us-east-1.amazonaws.com";

  miradoreEndPointApiKey = this.twilioEndPointApiKey;
  twilioNumber = "";
  twilioConstantNumber = "+14083518666";
  isTwilioEnabled = false;
  clickCallisDisabled = false;
  endButtonShow = false;
  phonecall = true;
  hubcall = true;
  twliotimer = null;  

  counter: number;
  timerRef;
  running: boolean = false;
  pagehours = 0;
  pageminutes = 0;
  pageseconds = 0;

  historyList: any;

  smsText:String = "";
  smsTextList = [];
  smstype: any;
  smsError = false;
  smsTypeError = false;

  showpatientlang = false;
  patientlang = false;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  @ViewChildren(DataTableDirective)
  dtElements: QueryList<any>;

  dtSystemAdmin: DataTables.Settings = {};
  dtTrigger: Subject<PatientDetailComponent> = new Subject();

  dtOptions: any = {};
  dtOptionsGraph: any = {};

  patientStockHistory = [];

  mySubscription: any;

  ZohoTicketDate: any;

  modules: any;

  module_RPM = false;
  module_CCM = false;
  module_TCM = false;
  module_APT = false;
  
  constructor( private route: ActivatedRoute, private locationservice: Location, private router: Router, private httpClient: HttpClient, private uploadService: UploadService, private chatgptService: ChatgptService, private datePipe: DatePipe) {
    let authData = JSON.parse(localStorage.getItem('AUTH'));
    this.userGroup = authData.group;
    let todaydate = new Date();
    this.myMonth = this.formatMonth(todaydate);
    this.myTime = this.formatTime(todaydate);
    this.thisDate = this.formatDate(todaydate);
    this.ZohoTicketDate = this.formatDate(todaydate);
    //////// Get Current Month And Past Month Date //////////////////
    this.setMonthDetails();
    this.statusFormStatus = "vacation";
    
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  setMonthDetails = () => {

    this.monthDetails = {
        currentMonthFirstDate:'',
        currentMonthLastDate:'',
        pastMonthFirstDate:'',
        pastMonthLastDate:'',
        currentMonthName:'',
        pastMonthName:'',
        currentYear:''
    };


    let date = new Date();

    let currentMonthfirstDay    = new Date(date.getFullYear(), date.getMonth(), 1);
    let currentMonthlastDay     = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let pastMonthfirstDay       = new Date(date.getFullYear(), date.getMonth()-1, 1);
    let pastMonthLastDay        = new Date(date.getFullYear(), date.getMonth()-1 + 1, 0);

    this.monthDetails.currentMonthFirstDate  = this.splitAndReplace(currentMonthfirstDay.toString());
    this.monthDetails.currentMonthLastDate   = this.splitAndReplace(currentMonthlastDay.toString());
    this.monthDetails.pastMonthFirstDate     = this.splitAndReplace(pastMonthfirstDay.toString());
    this.monthDetails.pastMonthLastDate      = this.splitAndReplace(pastMonthLastDay.toString());

    this.monthDetails.currentMonthName       = this.splitAndReplace(currentMonthlastDay.toString(),'Month');
    this.monthDetails.pastMonthName          = this.splitAndReplace(pastMonthLastDay.toString(),'Month');

    this.monthDetails.currentYear             = date.getFullYear();
  }

  splitAndReplace = (SplitDate,Month='') =>{
    let res = SplitDate.split(" ");
    if(Month!="")
    {
      return res[1];  
    }
    return res[2].replace(/^0/, '');
  }

  formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  formatMonth = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month].join('-');
  }

  formatTime = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours(),
      minutes = d.getMinutes();
      var strhour = ''+ hours;
      var strminute = '' + minutes;
      if(hours < 10){
        strhour = '0' + hours;
      }
      if(minutes < 10){
        strminute = '0' + minutes;
      }

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-')+'T'+strhour+":"+strminute;
  }

  formatTimeM = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hour = d.getHours(),
      minute = d.getMinutes();
    var time = "AM";
    if(hour >= 12) {
      time = "PM";
      if(hour > 12) {
        hour = hour - 12;
      }
    }
    var strhour = ''+ hour;
    if(hour == 0) {
      strhour = '12';
    }
    else if(hour < 10){
      strhour = '0' + hour;
    }
    var strminute = '' + minute;
    if(minute < 10){
      strminute = '0' + minute;
    }

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [month, day, year].join('/')+' '+strhour+":"+strminute+" "+time;
  }

  formatUTCDBDateTime = (date) => {
    var d = new Date(date),
      month = '' + (d.getUTCMonth() + 1),
      day = '' + d.getUTCDate(),
      year = d.getUTCFullYear(),
      hours = d.getUTCHours(),
      minutes = d.getUTCMinutes(),
      seconds = d.getUTCSeconds();
      var strhour = ''+ hours;
      var strminute = '' + minutes;
      var strsecond = '' + seconds;
      if(hours < 10){
        strhour = '0' + hours;
      }
      if(minutes < 10){
        strminute = '0' + minutes;
      }
      if(seconds < 10){
        strsecond = '0' + seconds;
      }
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-')+" "+strhour+":"+strminute+":"+strsecond;
  }

  formatDBDateTime = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours(),
      minutes = d.getMinutes(),
      seconds = d.getSeconds();
      var strhour = ''+ hours;
      var strminute = '' + minutes;
      var strsecond = '' + seconds;
      if(hours < 10){
        strhour = '0' + hours;
      }
      if(minutes < 10){
        strminute = '0' + minutes;
      }
      if(seconds < 10){
        strsecond = '0' + seconds;
      }
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-')+" "+strhour+":"+strminute+":"+strsecond;
  }

  formatDateTime = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours(),
      minutes = d.getMinutes(),
      seconds = d.getSeconds();
      var strhour = ''+ hours;
      var strminute = '' + minutes;
      if(hours < 10){
        strhour = '0' + hours;
      }
      if(minutes < 10){
        strminute = '0' + minutes;
      }
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-')+"T"+strhour+":"+strminute;
  }

  formatDateM = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [month, day, year].join('-');
  }

  formatDateMDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  formatDateMSlash = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    //console.log(d.toString());
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [month, day, year].join('/');
  }

  formatDateCustom = (date) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    const days = [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat'
    ]
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hour = d.getHours(),
      minute = d.getMinutes();
    
    var time = "AM";
    if(hour >= 12) {
      time = "PM";
      if(hour > 12) {
        hour = hour - 12;
      }
    }
    var strhour = ''+ hour;
    if(hour == 0) {
      strhour = '12';
    }
    else if(hour < 10){
      strhour = '0' + hour;
    }
    var strminute = '' + minute;
    if(minute < 10){
      strminute = '0' + minute;
    }

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    // return [year, month, day].join('-');
    return strhour+":"+strminute+" "+time+" "+days[d.getDay()]+" "+day+" "+months[d.getMonth()]+" "+year;
  }

  formatDateCustom2 = (date) => {
    var d = new Date(date);
    var newdate = this.datePipe.transform(d,"MM-dd-yyyy HH:MM");
    return newdate
  }

  formatUTCDateCustom = (date) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    const days = [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat'
    ]
    var d = new Date(date),
      month = '' + (d.getUTCMonth() + 1),
      day = '' + d.getUTCDate(),
      year = d.getUTCFullYear(),
      hour = d.getUTCHours(),
      minute = d.getUTCMinutes();
    
    var time = "AM";
    if(hour >= 12) {
      time = "PM";
      if(hour > 12) {
        hour = hour - 12;
      }
    }
    var strhour = ''+ hour;
    if(hour == 0) {
      strhour = '12';
    }
    else if(hour < 10){
      strhour = '0' + hour;
    }
    var strminute = '' + minute;
    if(minute < 10){
      strminute = '0' + minute;
    }

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    // return [year, month, day].join('-');
    return strhour+":"+strminute+" "+time+" "+days[d.getUTCDay()]+" "+day+" "+months[d.getUTCMonth()]+" "+year;
  }

  ngOnInit() {
    this.getGlobalConfig();

    this.dtOptions = {
      // Declare the use of the extension in the dom parameter
      // dom: 'lBfrtip',
      dom: '<"col-sm-2"B><"col-sm-5"l><"col-sm-5"f>rt<"col-sm-5"i><"col-sm-7"p>',
      // Configure the buttons
      buttons: [
        // 'colvis',
        // 'copy',
        // 'print',
        'excel'
      ],
      stateSave: true,
      stateSaveCallback: function(settings,data) {
        sessionStorage.setItem('DataTables_' + settings.sInstance, JSON.stringify(data));
      },
      stateLoadCallback: function(settings) {
        return JSON.parse(sessionStorage.getItem('DataTables_' + settings.sInstance ))
      }
    };

    this.dtOptionsGraph = {
      // Declare the use of the extension in the dom parameter
      columnDefs: [{ targets: 0, type: 'date' }],
      dom: '<"col-sm-2"B><"col-sm-5"l><"col-sm-5"f>rt<"col-sm-5"i><"col-sm-7"p>',
      "order": [[ 0, 'desc' ]],
      // Configure the buttons
      buttons: ['excel'],
      stateSave: true
    };

    this.dtTrigger.next();

    this.patientLineChartData = [{ 'data': [], 'label': '', 'fill': false, 'lineTension': 0 }];
    this.patientLineFAChartData = [{ 'data': [], 'label': '', 'fill': false, 'lineTension': 0 }];
    this.patientLineAMChartData = [{ 'data': [], 'label': '', 'fill': false, 'lineTension': 0 }];
    this.patientLineNTChartData = [{ 'data': [], 'label': '', 'fill': false, 'lineTension': 0 }];

    //this.deviceReading = {'diaMax': 'DIA Max', 'diaMin': 'DIA Min', 'pulseMax': 'Pulse Max', 'pulseMin': 'Pulse Min', 'sysMax': 'SYS Max', 'sysMin': 'SYS Min', 'bmiMax': 'BMI Max', 'bmiMin': 'BMI Min', 'wtPercentageMax': 'Weight Max', 'wtPercentageMin': 'Weight Min', 'prMax': 'PR Max', 'prMin': 'PR Min', 'spo2Max': 'SPO2 Max', 'spo2Min': 'SPO2 Min', 'tempMax': 'Temp. Max', 'tempMin': 'Temp. Min', 'glucoseAfterMealMax':'After Meal Max', 'glucoseAfterMealMin': 'After Meal Min', 'glucoseFastingMax': 'Fasting Max', 'glucoseFastingMin': 'Fasting Min', 'glucoseNormalTestMax': 'Normal Max', 'glucoseNormalTestMin': 'Normal Min'}
    this.deviceReading = {'diaMax': 'DIA Max', 'diaMin': 'DIA Min', 'pulseMax': 'Pulse Max', 'pulseMin': 'Pulse Min', 'sysMax': 'SYS Max', 'sysMin': 'SYS Min', 'wtzdiffer': 'Weight Differ', 'bmiMax': 'BMI Max', 'bmiMin': 'BMI Min', 'wtMax': 'Weight Max', 'wtMin': 'Weight Min', 'prMax': 'PR Max', 'prMin': 'PR Min', 'spo2Max': 'SPO2 Max', 'spo2Min': 'SPO2 Min', 'tempMax': 'Temp. Max', 'tempMin': 'Temp. Min', 'glucoseAfterMealMax':'After Meal Max', 'glucoseAfterMealMin': 'After Meal Min', 'glucoseFastingMax': 'Fasting Max', 'glucoseFastingMin': 'Fasting Min', 'glucoseNormalTestMax': 'Normal Max', 'glucoseNormalTestMin': 'Normal Min'}
    this.screen.style.display = 'block';
    this.subId = atob(this.route.snapshot.paramMap.get('sub'));
    const params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
        patient_sub: this.subId
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/rdssync', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => { 
      let params = new HttpParams({
        fromObject: {
          userpull_id: localStorage.getItem('userpull_id'),
          orientation: 'simple'
        }
      });
      this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getintervention', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        if (response.status === "success") {
          this.interventiondataarr = JSON.parse(response.data);
        }
        console.log(this.interventiondataarr);
        this.getPatient(this);
        this.getPatientStockHistory(this);
        this.getPatientCareplan(this);
      },
      async (error: any) => {
        await Auth.signOut({
          global: true
        });
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        this.screen.style.display = 'none';
        this.router.navigateByUrl('/sign-in');
      });
    },
    async (error: any) => {
      Auth.signOut();
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });
  }

  getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
  }

  aiSupport(userparam={}, transparam={}) {
    console.log(userparam);
    console.log(transparam);
    let param="";
    this.prompt=param;
    let supportModal = document.getElementById("ai-ankerTag") as HTMLElement;
    supportModal.click();
    
    setTimeout (() => {
      if(transparam['UploadHistory'] && transparam['UploadHistory'].length > 0){
        if('gender' in userparam){
          param += userparam['gender']+" ";
        }
        param += "patient ";
        if('custom-height' in userparam){
          param += "with height "+userparam['custom-height']+" ";
        }
        if('birthdate' in userparam){
          // param += "having date of birth "+userparam['birthdate']+"(mm/dd/yyyy) ";
          param += "having age "+this.getAge(userparam['birthdate'])+" years ";
        }
        
        if(this.primdiagStr.length > 0){
          param +="having "+ this.primdiagStr.join(',');
        }
        
        param +="  had below readings.\n";
        for(var j=0; j<transparam['UploadHistory'].length; j++){
          param += "\n"+String(j+1)+".\n";
          param += "Date Time: "+transparam['UploadHistory'][j]['timestamp']+"\n";
          param += "Device: "+transparam['UploadHistory'][j]['device_name_str']+"\n";
          param += "Normal Range:\n";
          if(transparam['UploadHistory'][j].device_name.trim() == 'BPM' && transparam['devicesetting'] && transparam['devicesetting']['BPM']){
            param += "SYS : ";
            if(transparam['UploadHistory'][j].alert_setting.sysMin){
              param += transparam['UploadHistory'][j].alert_setting.sysMin;
            }
            if(transparam['UploadHistory'][j].alert_setting.sysMin && transparam['UploadHistory'][j].alert_setting.sysMax){
              param += " - ";
            }
            if(transparam['UploadHistory'][j].alert_setting.sysMax){
              param += transparam['UploadHistory'][j].alert_setting.sysMax;
            }
            if(transparam['UploadHistory'][j].alert_setting.sysMin == null){
              param += transparam['devicesetting']['BPM'].sysMin;
            }
            if(transparam['UploadHistory'][j].alert_setting.sysMin == null && transparam['UploadHistory'][j].alert_setting.sysMax == null){
              param += " - ";
            }
            if(transparam['UploadHistory'][j].alert_setting.sysMax == null){
              param += transparam['devicesetting']['BPM'].sysMax;
            }
            param +="\n";
            param += "DIA : ";
            if(transparam['UploadHistory'][j].alert_setting.diaMin){
              param += transparam['UploadHistory'][j].alert_setting.diaMin;
            }
            if(transparam['UploadHistory'][j].alert_setting.diaMin && transparam['UploadHistory'][j].alert_setting.diaMax){
              param += " - ";
            }
            if(transparam['UploadHistory'][j].alert_setting.diaMax){
              param += transparam['UploadHistory'][j].alert_setting.diaMax;
            }
            if(transparam['UploadHistory'][j].alert_setting.diaMin == null){
              param += transparam['devicesetting']['BPM'].diaMin;
            }
            if(transparam['UploadHistory'][j].alert_setting.diaMin == null && transparam['UploadHistory'][j].alert_setting.diaMax == null){
              param += " - ";
            }
            if(transparam['UploadHistory'][j].alert_setting.diaMax == null){
              param += transparam['devicesetting']['BPM'].diaMax;
            }
            param +="\n";
            param += "Pulse : ";
            if(transparam['UploadHistory'][j].alert_setting.pulseMin){
              param += transparam['UploadHistory'][j].alert_setting.pulseMin;
            }
            if(transparam['UploadHistory'][j].alert_setting.pulseMin && transparam['UploadHistory'][j].alert_setting.pulseMax){
              param += " - ";
            }
            if(transparam['UploadHistory'][j].alert_setting.pulseMax){
              param += transparam['UploadHistory'][j].alert_setting.pulseMax;
            }
            if(transparam['UploadHistory'][j].alert_setting.pulseMin == null){
              param += transparam['devicesetting']['BPM'].pulseMin;
            }
            if(transparam['UploadHistory'][j].alert_setting.pulseMin == null && transparam['UploadHistory'][j].alert_setting.pulseMax == null){
              param += " - ";
            }
            if(transparam['UploadHistory'][j].alert_setting.pulseMax == null){
              param += transparam['devicesetting']['BPM'].pulseMax;
            }
            param +="\n";
          }
          if(transparam['UploadHistory'][j].device_name.trim() == 'Oximeter' && transparam['devicesetting'] && transparam['devicesetting']['Oximeter']){
            param += "SPO2 : ";
            if(transparam['UploadHistory'][j].alert_setting.spo2Min){
              param += transparam['UploadHistory'][j].alert_setting.spo2Min+"%";
            }
            if(transparam['UploadHistory'][j].alert_setting.spo2Min && transparam['UploadHistory'][j].alert_setting.spo2Max){
              param += " - ";
            }
            if(transparam['UploadHistory'][j].alert_setting.spo2Max){
              param += transparam['UploadHistory'][j].alert_setting.spo2Max+"%";
            }
            if(transparam['UploadHistory'][j].alert_setting.spo2Min == null){
              param += transparam['devicesetting']['Oximeter'].spo2Min+"%";
            }
            if(transparam['UploadHistory'][j].alert_setting.spo2Min == null && transparam['UploadHistory'][j].alert_setting.spo2Max == null){
              param += " - ";
            }
            if(transparam['UploadHistory'][j].alert_setting.spo2Max == null){
              param += transparam['devicesetting']['Oximeter'].spo2Max+"%";
            }
            param +="\n";
            param += "PR : ";
            if(transparam['UploadHistory'][j].alert_setting.prMin){
              param += transparam['UploadHistory'][j].alert_setting.prMin;
            }
            if(transparam['UploadHistory'][j].alert_setting.prMin && transparam['UploadHistory'][j].alert_setting.prMax){
              param += " - ";
            }
            if(transparam['UploadHistory'][j].alert_setting.prMax){
              param += transparam['UploadHistory'][j].alert_setting.prMax;
            }
            if(transparam['UploadHistory'][j].alert_setting.prMin == null){
              param += transparam['devicesetting']['Oximeter'].prMin;
            }
            if(transparam['UploadHistory'][j].alert_setting.prMin == null && transparam['UploadHistory'][j].alert_setting.prMax == null){
              param += " - ";
            }
            if(transparam['UploadHistory'][j].alert_setting.prMax == null){
              param += transparam['devicesetting']['Oximeter'].prMax;
            }
            param +="\n";
          }
          if(transparam['UploadHistory'][j].device_name.trim() == 'Weight' && transparam['devicesetting'] && transparam['devicesetting']['Weight']){
            param += "Weight";
            if(transparam['UploadHistory'][j].alert_setting.wtPercentageMin && transparam['UploadHistory'][j].alert_setting.wtPercentageMax){
              param += "%";
            }
            param +=" : ";
            if(transparam['UploadHistory'][j].alert_setting.wtPercentageMin){
              param += transparam['UploadHistory'][j].alert_setting.wtPercentageMin;
            }
            if(transparam['UploadHistory'][j].alert_setting.wtPercentageMin && transparam['UploadHistory'][j].alert_setting.wtPercentageMax){
              param += " - ";
            }
            if(transparam['UploadHistory'][j].alert_setting.wtPercentageMax){
              param += transparam['UploadHistory'][j].alert_setting.wtPercentageMax;
            }
            if(!transparam['UploadHistory'][j].alert_setting.wtPercentageMin && !transparam['UploadHistory'][j].alert_setting.wtPercentageMax && transparam['UploadHistory'][j].alert_setting.wtMin && transparam['UploadHistory'][j].alert_setting.wtMax){
              param += transparam['UploadHistory'][j].alert_setting.wtMin;
              param += " - ";
              param += transparam['UploadHistory'][j].alert_setting.wtMax;
            }
            if(transparam['UploadHistory'][j].alert_setting.wtPercentageMin == null && transparam['UploadHistory'][j].alert_setting.wtPercentageMax == null){
              param += transparam['devicesetting']['Weight'].wtPercentageMin;
              param += " - ";
              param += transparam['devicesetting']['Weight'].wtPercentageMax;
            }
            param +="\n";
            param += "BMI : ";
            if(transparam['UploadHistory'][j].alert_setting.bmiMin){
              param += transparam['UploadHistory'][j].alert_setting.bmiMin;
            }
            if(transparam['UploadHistory'][j].alert_setting.bmiMin && transparam['UploadHistory'][j].alert_setting.bmiMax){
              param += " - ";
            }
            if(transparam['UploadHistory'][j].alert_setting.bmiMax){
              param += transparam['UploadHistory'][j].alert_setting.bmiMax;
            }
            if(transparam['UploadHistory'][j].alert_setting.bmiMin == null){
              param += transparam['devicesetting']['Weight'].bmiMin;
            }
            if(transparam['UploadHistory'][j].alert_setting.bmiMin == null && transparam['UploadHistory'][j].alert_setting.bmiMax == null){
              param += " - ";
            }
            if(transparam['UploadHistory'][j].alert_setting.bmiMax == null){
              param += transparam['devicesetting']['Weight'].bmiMax;
            }
            param +="\n";
          }
          if(transparam['UploadHistory'][j].device_name.trim() == 'Blood Glucose' && transparam['devicesetting'] && transparam['devicesetting']['Blood Glucose']){
            param += "Normal : ";
            if(transparam['UploadHistory'][j].alert_setting.glucoseNormalTestMin){
              param += transparam['UploadHistory'][j].alert_setting.glucoseNormalTestMin;
            }
            if(transparam['UploadHistory'][j].alert_setting.glucoseNormalTestMin && transparam['UploadHistory'][j].alert_setting.glucoseNormalTestMax){
              param += " - ";
            }
            if(transparam['UploadHistory'][j].alert_setting.glucoseNormalTestMax){
              param += transparam['UploadHistory'][j].alert_setting.glucoseNormalTestMax;
            }
            if(transparam['UploadHistory'][j].alert_setting.glucoseNormalTestMin == null){
              param += transparam['devicesetting']['Blood Glucose'].glucoseNormalTestMin;
            }
            if(transparam['UploadHistory'][j].alert_setting.glucoseNormalTestMin == null && transparam['UploadHistory'][j].alert_setting.glucoseNormalTestMax == null){
              param += " - ";
            }
            if(transparam['UploadHistory'][j].alert_setting.glucoseNormalTestMax == null){
              param += transparam['devicesetting']['Blood Glucose'].glucoseNormalTestMax;
            }
            param +="\n";
            param += "Fasting : ";
            if(transparam['UploadHistory'][j].alert_setting.glucoseFastingMin){
              param += transparam['UploadHistory'][j].alert_setting.glucoseFastingMin;
            }
            if(transparam['UploadHistory'][j].alert_setting.glucoseFastingMin && transparam['UploadHistory'][j].alert_setting.glucoseFastingMax){
              param += " - ";
            }
            if(transparam['UploadHistory'][j].alert_setting.glucoseFastingMax){
              param += transparam['UploadHistory'][j].alert_setting.glucoseFastingMax;
            }
            if(transparam['UploadHistory'][j].alert_setting.glucoseFastingMin == null){
              param += transparam['devicesetting']['Blood Glucose'].glucoseFastingMin;
            }
            if(transparam['UploadHistory'][j].alert_setting.glucoseFastingMin == null && transparam['UploadHistory'][j].alert_setting.glucoseFastingMax == null){
              param += " - ";
            }
            if(transparam['UploadHistory'][j].alert_setting.glucoseFastingMax == null){
              param += transparam['devicesetting']['Blood Glucose'].glucoseFastingMax;
            }
            param +="\n";
            param += "After Meal : ";
            if(transparam['UploadHistory'][j].alert_setting.glucoseAfterMealMin){
              param += transparam['UploadHistory'][j].alert_setting.glucoseAfterMealMin;
            }
            if(transparam['UploadHistory'][j].alert_setting.glucoseAfterMealMin && transparam['UploadHistory'][j].alert_setting.glucoseAfterMealMax){
              param += " - ";
            }
            if(transparam['UploadHistory'][j].alert_setting.glucoseAfterMealMax){
              param += transparam['UploadHistory'][j].alert_setting.glucoseAfterMealMax;
            }
            if(transparam['UploadHistory'][j].alert_setting.glucoseAfterMealMin == null){
              param += transparam['devicesetting']['Blood Glucose'].glucoseAfterMealMin;
            }
            if(transparam['UploadHistory'][j].alert_setting.glucoseAfterMealMin == null && transparam['UploadHistory'][j].alert_setting.glucoseAfterMealMax == null){
              param += " - ";
            }
            if(transparam['UploadHistory'][j].alert_setting.glucoseAfterMealMax == null){
              param += transparam['devicesetting']['Blood Glucose'].glucoseAfterMealMax;
            }
            param +="\n";
          }
          if(transparam['UploadHistory'][j].device_name.trim() == 'Thermometer' && transparam['devicesetting'] && transparam['devicesetting']['Thermometer']){
            param += "Temperature : ";
            if(transparam['UploadHistory'][j].alert_setting.tempMin){
              param += transparam['UploadHistory'][j].alert_setting.tempMin;
            }
            if(transparam['UploadHistory'][j].alert_setting.tempMin && transparam['UploadHistory'][j].alert_setting.tempMax){
              param += " - ";
            }
            if(transparam['UploadHistory'][j].alert_setting.tempMax){
              param += transparam['UploadHistory'][j].alert_setting.tempMax;
            }
            if(transparam['UploadHistory'][j].alert_setting.tempMin == null){
              param += transparam['devicesetting']['Thermometer'].tempMin;
            }
            if(transparam['UploadHistory'][j].alert_setting.tempMin == null && transparam['UploadHistory'][j].alert_setting.tempMax == null){
              param += " - ";
            }
            if(transparam['UploadHistory'][j].alert_setting.tempMax == null){
              param += transparam['devicesetting']['Thermometer'].tempMax;
            }
            param +="\n";
          }
          param += "Readings: \n";
          if(transparam['UploadHistory'][j].device_name.trim() == 'BPM' && transparam['UploadHistory'][j].detailed_value.sysData && transparam['UploadHistory'][j].detailed_value.diaData && transparam['UploadHistory'][j].detailed_value.pulseData){
            param += transparam['UploadHistory'][j].detailed_value.sysData + " / " + transparam['UploadHistory'][j].detailed_value.diaData + " / " + transparam['UploadHistory'][j].detailed_value.pulseData;
          }
          if(transparam['UploadHistory'][j].device_name.trim() == 'Oximeter' && transparam['UploadHistory'][j].detailed_value.spo2 && transparam['UploadHistory'][j].detailed_value.pr){
            param += transparam['UploadHistory'][j].detailed_value.spo2 + "% / " + transparam['UploadHistory'][j].detailed_value.pr;
          }
          if(transparam['UploadHistory'][j].device_name.trim() == 'Weight' && transparam['UploadHistory'][j].detailed_value.weight && transparam['UploadHistory'][j].detailed_value.bmi){
            param += transparam['UploadHistory'][j].detailed_value.weight + " / " + transparam['UploadHistory'][j].detailed_value.bmi;
          }
          if(transparam['UploadHistory'][j].device_name.trim() == 'Blood Glucose' && transparam['UploadHistory'][j].detailed_value.bloodGlucose && transparam['UploadHistory'][j].detailed_value.type){
            param += transparam['UploadHistory'][j].detailed_value.bloodGlucose + "mg/dL (" + transparam['UploadHistory'][j].detailed_value.type +")";
          }
          if(transparam['UploadHistory'][j].device_name.trim() == 'Thermometer' && transparam['UploadHistory'][j].detailed_value.temperature){
            param += transparam['UploadHistory'][j].detailed_value.temperature;
          }
          if(transparam['UploadHistory'][j].super_alert && transparam['UploadHistory'][j].super_alert=='1'){
            param += " [CRITICAL ALERT]"
          }
          else if(transparam['UploadHistory'][j].out_of_range_alert == '1'){
            param += " [ALERT]"
          }
          if(transparam['UploadHistory'][j].device_name.trim() == 'BPM' && transparam['UploadHistory'][j].detailed_value.sysData && transparam['UploadHistory'][j].detailed_value.diaData && transparam['UploadHistory'][j].detailed_value.pulseData){
            if(transparam['UploadHistory'][j].detailed_value.arrhythmia && transparam['UploadHistory'][j].detailed_value.arrhythmia == '1'){
              param += "\nArrhythmia (Irregular Heartbeat detected)"
            }
          }
          if(transparam['UploadHistory'][j].symptoms){
            param += "\nSymptoms: \n";
            console.log(transparam['UploadHistory'][j].symptoms);
            for(var symptoms of transparam['UploadHistory'][j].symptoms){
              if(symptoms.symptom && symptoms.symptom.fatigue){
                param += "Fatigue and weakness - ";
                if(symptoms.symptom.fatigue == 'mild') {
                  param += "Mild: Feeling slightly tired after engaging in high-intensity activities.";
                }
                if(symptoms.symptom.fatigue == 'moderate') {
                  param += "Moderate: Feeling tired or weak after normal daily activities.";
                }
                if(symptoms.symptom.fatigue == 'severe') {
                  param += "Severe: Feeling tired or weak all the time, even with little to no activity.";
                }
                param += "\n";
              }
              if(symptoms.symptom && symptoms.symptom.urine){
                param += "Changes in Urination - ";
                if(symptoms.symptom.urine == 'mild') {
                  param += "Mild: Slightly increased frequency or urgency of urination, especially at night.";
                }
                if(symptoms.symptom.urine == 'moderate') {
                  param += "Moderate: Noticeably increased frequency or urgency; urine may be foamy or bubbly.Moderate: Feeling tired or weak after normal daily activities.";
                }
                if(symptoms.symptom.urine == 'severe') {
                  param += "Severe: Frequent urination at night; urine may contain blood or have a very dark color.";
                }
                param += "\n";
              }
              if(symptoms.symptom && symptoms.symptom.swell){
                param += "Swelling in Ankles, Feet, and Legs (Edema) - ";
                if(symptoms.symptom.swell == 'mild') {
                  param += "Mild: Slight swelling that does not interfere with activity.";
                }
                if(symptoms.symptom.swell == 'moderate') {
                  param += "Moderate: Noticeable swelling that causes discomfort and may limit daily activities.";
                }
                if(symptoms.symptom.swell == 'severe') {
                  param += "Severe: Significant swelling that is uncomfortable or painful, limits mobility, and severely interferes with daily activities.";
                }
                param += "\n";
              }
              if(symptoms.symptom && symptoms.symptom.nausea){
                param += "Swelling in Ankles, Feet, and Legs (Edema) - ";
                if(symptoms.symptom.nausea == 'mild') {
                  param += "Mild: Occasional episodes of nausea but not enough to interfere with eating or daily activities.";
                }
                if(symptoms.symptom.nausea == 'moderate') {
                  param += "Moderate: Frequent episodes of nausea that occasionally lead to vomiting; can interfere with the ability to eat and maintain nutrition.";
                }
                if(symptoms.symptom.nausea == 'severe') {
                  param += "Severe: Persistent or frequent vomiting that interferes with the ability to eat and maintain nutrition; medical intervention may be needed.";
                }
                param += "\n";
              }
              if(symptoms.symptom && symptoms.symptom.appetite){
                param += "Loss of Appetite - ";
                if(symptoms.symptom.appetite == 'mild') {
                  param += "Mild: Occasional lack of interest in food but overall nutrition is maintained.";
                }
                if(symptoms.symptom.appetite == 'moderate') {
                  param += "Moderate: Frequently not feeling hungry; starting to impact overall nutrition.";
                }
                if(symptoms.symptom.appetite == 'severe') {
                  param += "Severe: Consistent lack of interest in food leading to weight loss and malnutrition.";
                }
                param += "\n";
              }
              if(symptoms.symptom && symptoms.symptom.taste){
                param += "Changes in Taste or Bad Breath - ";
                if(symptoms.symptom.taste == 'mild') {
                  param += "Mild: Subtle changes in taste or slightly bad breath.";
                }
                if(symptoms.symptom.taste == 'moderate') {
                  param += "Moderate: Noticeable changes in taste, particularly a metallic taste in the mouth; bad breath despite good oral hygiene.";
                }
                if(symptoms.symptom.taste == 'severe') {
                  param += "Severe: Significant changes in taste leading to food aversion; persistent bad breath.";
                }
                param += "\n";
              }
              if(symptoms.symptom && symptoms.symptom.concentrate){
                param += "Difficulty Concentrating - ";
                if(symptoms.symptom.concentrate == 'mild') {
                  param += "Mild: Occasional difficulty focusing on complex tasks.";
                }
                if(symptoms.symptom.concentrate == 'moderate') {
                  param += "Moderate: Frequent difficulty concentrating; may impact work or daily activities.";
                }
                if(symptoms.symptom.concentrate == 'severe') {
                  param += "Severe: Consistent difficulty concentrating, even on simple tasks; significantly impacts work or daily activities.";
                }
                param += "\n";
              }
              if(symptoms.symptom && symptoms.symptom.faint){
                param += "Fainting (Syncope) or Lightheadedness - ";
                if(symptoms.symptom.faint == 'mild') {
                  param += "Mild: Rare episodes of feeling faint or lightheaded, does not interfere with normal activities.";
                }
                if(symptoms.symptom.faint == 'moderate') {
                  param += "Moderate: Frequent episodes of feeling faint or lightheaded, moderately impacts normal activities.";
                }
                if(symptoms.symptom.faint == 'severe') {
                  param += "Severe: Regular episodes of fainting or severe lightheadedness significantly interferes with normal activities.";
                }
                param += "\n";
              }
              if(symptoms.symptom && symptoms.symptom.cold){
                param += "Feeling Cold - ";
                if(symptoms.symptom.cold == 'mild') {
                  param += "Mild: Occasional feelings of being cold that do not significantly interfere with daily activities.";
                }
                if(symptoms.symptom.cold == 'moderate') {
                  param += "Moderate: Frequent feelings of being cold that moderately impact daily activities.";
                }
                if(symptoms.symptom.cold == 'severe') {
                  param += "Severe: Constant or near-constant feelings of being cold that significantly interfere with daily activities.";
                }
                param += "\n";
              }
              if(symptoms.symptom.pain){
                param += "Chest Pain or Discomfort - ";
                if(symptoms.symptom.pain == 'mild') {
                  param += "Mild: Occasional, brief episodes of chest pain that do not interfere with normal activities.";
                }
                if(symptoms.symptom.pain == 'moderate') {
                  param += "Moderate: Frequent episodes of chest pain that moderately impact normal activities.";
                }
                if(symptoms.symptom.pain == 'severe') {
                  param += "Severe: Constant or near-constant chest pain that significantly interferes with normal activities.";
                }
                param += "\n";
              }
              if(symptoms.symptom && symptoms.symptom.breate){
                param += "Shortness of Breath (Dyspnea) - ";
                if(symptoms.symptom.breate == 'mild') {
                  param += "Mild: Shortness of breath during vigorous physical activities.";
                }
                if(symptoms.symptom.breate == 'moderate') {
                  param += "Moderate: Shortness of breath during normal physical activities.";
                }
                if(symptoms.symptom.breate == 'severe') {
                  param += "Severe: Shortness of breath at rest.";
                }
                param += "\n";
              }
              if(symptoms.symptom && symptoms.symptom.palpitaion){
                param += "Palpitations - ";
                if(symptoms.symptom.palpitaion == 'mild') {
                  param += "Mild: Infrequent, brief episodes of palpitations that do not interfere with normal activities.";
                }
                if(symptoms.symptom.palpitaion == 'moderate') {
                  param += "Moderate: Frequent episodes of palpitations that moderately impact normal activities.";
                }
                if(symptoms.symptom.palpitaion == 'severe') {
                  param += "Severe: Constant or near-constant palpitations that significantly interfere with normal activities.";
                }
                param += "\n";
              }
              if(symptoms.symptom && symptoms.symptom.fever){
                param += "Fever (elevated body temperature, typically above 100.4°F) - ";
                if(symptoms.symptom.fever == 'mild') {
                  param += "Mild: Low-grade fever (up to 100.4°F) that comes and goes.";
                }
                if(symptoms.symptom.fever == 'moderate') {
                  param += "Moderate: Persistent low-grade fever or occasional higher fever (up to 102°F).";
                }
                if(symptoms.symptom.fever == 'severe') {
                  param += "Severe: High fever (above 102°F) or a fever with chills and sweating.";
                }
                param += "\n";
              }
            }
          }
          param += "\n";
        }
        param += "\n";
        // param += "Based on the above data - summarize ranges, average, SD values, Patient communication message about these readings, information for physician.";
        // param += "Based on the above data - summarize ranges, average, SD values, calculate trends, calculate approximate patient health risk, Patient communication message, and provider communication message.";
        param += "Based on the above data - summarize readings max, min, average, and  detail insights, patient check-in questions, summary for physician. Provide check-in script for nurse with motivation for life-style management. Suggest a simple workout and diet plan."
      }
      this.prompt=param;
    }, 500);
  }

  prompt: string;
  response: string = '';
  onSupportSubmit(): void {
    if(!this.prompt){
      alert("Prompt should not be blank");
    }
    else {
      this.response = "<div class='promptparent'><div class='promptblock'>"+this.prompt+"</div></div>" + this.response;
      let aiClass = this;
      aiClass.screen.style.display = 'block';
      let token = uuidv4();
      this.chatgptService.getResponse(this.prompt, token).subscribe((dataobj) => {
        let data = {}
        if(dataobj.statusCode == "200") {
          data= JSON.parse(dataobj.body);
        }
        if('response' in data){
          this.response = "<div class='responseparent'><div class='responseblock'>"+data['response']+"</div></div>" + this.response;
        }
        else if(dataobj.errorMessage){
          this.response = "<div class='responseparent'><div class='responseblock'>"+dataobj.errorMessage+"</div></div>" + this.response;
        }
        aiClass.screen.style.display = 'none';
        // this.chatgptService.storeRequestResponse(this.prompt, this.response).subscribe();
      },
      async (error: any) => {
        console.log(error);
        if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
          let params = {
            prompt: aiClass.prompt,
            token: token
          };
          var root = this;
          var callcounter = 0;
          setTimeout (function requestCallback() {
            root.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/chatgpt2', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
            .subscribe((response: any) => {
              console.log(response);
              if(response.status === "success") {
                let responsedata = JSON.parse(response.data);
                console.log(responsedata);
                if('response' in responsedata){
                  root.response = "<div class='responseparent'><div class='responseblock'>"+responsedata['response']+"</div></div>" + root.response;
                }
                root.screen.style.display = 'none';
              }
              else {
                callcounter++;
                if(callcounter < 50) {
                  setTimeout (requestCallback, 5000);
                }
                else {
                  alert("Request timeout. Please try again.");
                  root.screen.style.display = 'none';
                }
              }
            },
            async (error: any) => {
              console.log(error);
            });
          }, 5000);
        }
        else {
          aiClass.screen.style.display = 'none';
        }
      });
    }
  }

  selectedFiles: FileList;
  upload() {
    const file = this.selectedFiles.item(0);
    if(file.type && file.type == "application/pdf") {
      this.upmessage = "uploading....";
      this.uploadService.uploadFile(file, 'instaprofileuploads', localStorage.getItem('userpull_id')+"/"+this.subId.replaceAll('-','_')+'/uploadprofile/', 'usrfile.pdf');
      this.myInputVariable.nativeElement.value = "";
      this.upmessage = "";
    }
    else {
      alert("Please choose PDF file only");
      this.myInputVariable.nativeElement.value = "";
      this.upmessage = "";
    }
  }
    
  selectFile(event) {
    this.upmessage = "";
    this.selectedFiles = event.target.files;
  }

  reviewTransmissionEsclation:any;
  reviewTransmissionData:any;
  // isreview

  rcchange = (deviceReview) => {
    console.log(deviceReview);
    var ctext = "";
    if(deviceReview.review_call){
      ctext = "Are you sure you want to mark this review as a non-review call?";
      var rc = 0;
    }
    else{
      ctext = "Are you sure you want to mark this review as a review call?";
      var rc = 1;
    }
    if(confirm(ctext)) {
      console.log("update review");
      let addreviewClass = this;
      addreviewClass.screen.style.display = 'block';
      let params = {
        userpull_id: localStorage.getItem('userpull_id'),
        user_sub: localStorage.getItem('user_sub'),
        user_group: this.userGroup,
        patient_sub: deviceReview.patient_sub.replaceAll('_','-'),
        provider_sub: deviceReview.provider_sub.replaceAll('_','-'),
        mode: deviceReview.mode,
        duration: (deviceReview.duration)?deviceReview.duration:'',
        timestamp: this.formatUTCDBDateTime(deviceReview.timestampdb),
        rpmreview_id: deviceReview.rpmreview_id,
        review: deviceReview.review,
        review_call: rc.toString()
      };
      console.log(params);
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addreview', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          if(response.status == "success") {
            // addreviewClass.getPatient(addreviewClass);
            for(var j=0; j<this.userDetails.TransmissionDate.length; j++){
              if(this.userDetails.TransmissionDate[j]['ReviewPeriod'] && this.userDetails.TransmissionDate[j]['ReviewPeriod']['Review']){
                let callstatus = "false";
                for(var k=0; k<this.userDetails.TransmissionDate[j]['ReviewPeriod']['Review'].length; k++){
                  if(this.userDetails.TransmissionDate[j]['ReviewPeriod']['Review'][k]['rpmreview_id'] == deviceReview.rpmreview_id){
                    console.log(rc);
                    this.userDetails.TransmissionDate[j]['ReviewPeriod']['Review'][k]['review_call'] = rc;
                  }
                  if(this.userDetails.TransmissionDate[j]['ReviewPeriod']['Review'][k]['review_call'] == 1){
                    callstatus = 'true';
                  }
                }
                this.userDetails.TransmissionDate[j]['callStatus']=callstatus;
              }
            }
            alert("Review updated successfully");
          }
          else {
            alert("Could not update review. Please try again.");
          }
          addreviewClass.screen.style.display = 'none';
        },
        async (error: any) => {
          console.log(error);
          alert("Could not update review. Please try again.");
          addreviewClass.screen.style.display = 'none';
        });
    }
  }

  make_number_mask = (num, masklength=7) =>{
    var numstr = num.toString();
    if(this.userGroup == "Superadmin"){
      return numstr;
    }
    var masked_num = numstr;
    if(numstr.length > masklength){
      masked_num = numstr.substr(0,numstr.length-masklength)+Array(masklength+1).join('x');
    }
    return masked_num;
  }

  getPatient = (PatientClass) =>{
    this.reviewTransmission = "";
    this.interventionarr = [];
    this.reviewtemplatearr = [];
    this.selreviewtemplate = "0";
    this.reviewtext = "";
    this.cpass = "false";

    this.reviewTransmissionEsclation = [];
    this.reviewTransmissionData = {};

    if(sessionStorage.getItem('cpass') && sessionStorage.getItem('cpass') == "true") {
      this.cpass = "true";
      sessionStorage.removeItem("cpass");
    }
    if(sessionStorage.getItem('reviewTransmissionSub') == this.subId) {
      if(sessionStorage.getItem('reviewTransmission') != null) {
        this.reviewTransmission = sessionStorage.getItem('reviewTransmission');
        if(sessionStorage.getItem('intervention_arr') != null) {
          // console.log(sessionStorage.getItem('intervention_arr'));
          var interventionfullarr = JSON.parse(sessionStorage.getItem('intervention_arr'));
          this.interventionarr = [];
          for(var i=0; i<interventionfullarr.length; i++) {
            if(interventionfullarr[i]['is_devicetext'] == '1'){
              this.interventionDeviceText = interventionfullarr[i]['question_provider'];
            }
            else if(interventionfullarr[i]['is_pretext'] == '1'){
              this.interventionPreText = interventionfullarr[i]['question_provider'];
            }
            else {
              var tempobj = {
                'idintervention': interventionfullarr[i]['idintervention'],
                'question_provider': interventionfullarr[i]['question_provider']
              }
              this.interventionarr.push(tempobj);
            }
          }
          // console.log(this.interventionarr);
        }

        if(sessionStorage.getItem('reviewTransmissionEsclation') != null) {
          var reviewTransmissionEsclation = JSON.parse(sessionStorage.getItem('reviewTransmissionEsclation'));
          console.log("reviewTransmissionEsclation", reviewTransmissionEsclation);

          this.reviewTransmissionEsclation = reviewTransmissionEsclation;
        }

        if(sessionStorage.getItem('reviewTransmissionData') != null) {
          var reviewTransmissionData = JSON.parse(sessionStorage.getItem('reviewTransmissionData'));
          console.log("reviewTransmissionData", reviewTransmissionData);

          this.reviewTransmissionData = reviewTransmissionData;
        }
      }
    }
    else if(sessionStorage.getItem('reviewTransmissionSub')) {
      sessionStorage.removeItem("reviewTransmissionSub");
      if(sessionStorage.getItem('reviewTransmission')) {
        sessionStorage.removeItem("reviewTransmission");
      }
      if(sessionStorage.getItem('intervention_arr')) {
        sessionStorage.removeItem("intervention_arr");
      }
    }
    console.log(this.reviewTransmissionData);
    PatientClass.screen.style.display = 'block';
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
        group: 'Patient',
        sub: this.subId,
        token: uuidv4()
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getuserdetail', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      if (response.status === "success") {
        let params = new HttpParams({
          fromObject: {
            userpull_id: localStorage.getItem('userpull_id'),
            patient_sub: this.subId,
            sub: this.subId
          }
        });

        //EligibilitY Api Call Start
        this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getpatienteligibility', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((responsepatient: any) => {
          if (responsepatient.status === "success") {
            this.PatientEligibilty = JSON.parse(responsepatient.data);
            if (this.PatientEligibilty.length > 0){
              this.PatientIsAllEligibility = 1;
            }

            for(var elegibility of this.PatientEligibilty){
              if (elegibility['type'] == 'Primary') {
                this.primarypayerCode = elegibility['payercode'];
                this.primarymemberID = elegibility['memberid'];
    
                var tempdata = JSON.parse(elegibility['data']);
    
                var splitted = tempdata['rundate'].split("-");
                this.primaryrundate = splitted[2]+"-"+splitted[0]+"-"+splitted[1];
    
                this.primarystatus = tempdata['status'];
                this.primaryremainingamount = tempdata['remainingamount'];
    
                this.primary_partb = tempdata['partb'];
                this.primary_ppo = tempdata['ppo'];
                this.primary_hmo = tempdata['hmo'];
                this.primary_msp = tempdata['msp'];
                this.primary_pdf_url = elegibility['pdfurl'];
                this.primary_r_pdf_url = elegibility['recursive_pdfurl'];
              }else if (elegibility['type'] == 'Secondary') {
                this.secondarypayerCode = elegibility['payercode'];
                this.secondarymemberID = elegibility['memberid'];
    
                var tempdata = JSON.parse(elegibility['data']);
    
                var splitted = tempdata['rundate'].split("-");
                this.secondaryrundate = splitted[2]+"-"+splitted[0]+"-"+splitted[1];
    
                this.secondarystatus = tempdata['status'];
                this.secondaryremainingamount = tempdata['remainingamount'];
    
                this.secondary_partb = tempdata['partb'];
                this.secondary_ppo = tempdata['ppo'];
                this.secondary_hmo = tempdata['hmo'];
                this.secondary_msp = tempdata['msp'];
                this.secondary_pdf_url = elegibility['pdfurl'];
                this.secondary_r_pdf_url = elegibility['recursive_pdfurl'];
              }
            }

          }
        },
        async (error: any) => {
          console.log(error);
        });
        //EligibilitY Api Call End

        
        this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/patientstatusfetch', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((responsestatus: any) => {
          console.log(responsestatus);
          if (responsestatus.status === "success") {
            let responsestatusdata = JSON.parse(responsestatus.data);
            if (responsestatusdata.length > 0) {
              for(var z=0; z < responsestatusdata.length; z++) {
                responsestatusdata[z]['from_date_updated'] = this.formatDateMSlash(responsestatusdata[z]['from_date'].replaceAll('-','/') + " UTC");
                responsestatusdata[z]['to_date_updated'] = this.formatDateMSlash(responsestatusdata[z]['to_date'].replaceAll('-','/') + " UTC");
                var d1 = new Date();
                var d2 = new Date( d1.getUTCFullYear(), d1.getUTCMonth(), d1.getUTCDate(), d1.getUTCHours(), d1.getUTCMinutes(), d1.getUTCSeconds() );
                // console.log(this.formatDBDateTime(d2.getTime()));
                // console.log(this.formatDBDateTime(Date.parse(responsestatusdata[z]['from_date'])));
                // console.log(this.formatDBDateTime(Date.parse(responsestatusdata[z]['to_date'])));
                if(Date.parse(responsestatusdata[z]['from_date']) < d2.getTime() && Date.parse(responsestatusdata[z]['to_date']) > d2.getTime() && responsestatusdata[z]['active_status'] == 1) {
                  this.patientCurrentStatus = true;
                  this.patientCurrentStatusMessage = "Not available from "+responsestatusdata[z]['from_date_updated']+" to "+responsestatusdata[z]['to_date_updated']+" for "+responsestatusdata[z]['reason'];
                }
                // console.log(responsestatusdata[z]);
                this.patientStatuses.push(responsestatusdata[z]);
              }
            }
          }
        },
        async (error: any) => {
          console.log(error);
        });
        this.hubDetails = {};
        this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/patienthubdetails', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((responsedetail: any) => {
          console.log(responsedetail);
          if (responsedetail.status === "success") {
            let responsedetaildata = JSON.parse(responsedetail.data);
            if (responsedetaildata['DeviceID'] && responsedetaildata['Sname']) {
              this.hubDetails = responsedetaildata;
              console.log(responsedetaildata);
            }
          }
        },
        async (error: any) => {
          console.log(error);
        });
        this.pageTimerButtonClick();
        var offset = new Date().getTimezoneOffset();
        if(offset >= 0) {
          var offsetstring = "+"+offset;
        }
        else {
          var offsetstring = ""+offset;
        }
        let responsedata = JSON.parse(response.data);
        console.log(responsedata);
        PatientClass.providerlist = JSON.parse(response.ProviderList);
        console.log(PatientClass.providerlist);
        PatientClass.languagelist = JSON.parse(response.LanguageList);
        console.log(PatientClass.languagelist);
        this.languageArr = {};
        for (var l = 0; l < PatientClass.languagelist.length; l++) {
          this.languageArr[PatientClass.languagelist[l]['language_code']] = PatientClass.languagelist[l]['language_value'];
        }
        console.log(this.languageArr);
        if (responsedata.length > 0) {
          for (var j = 0; j < responsedata.length; j++) {
            if(responsedata[j]['dataTransmissionDate'] != '0000-00-00 00:00:00' && responsedata[j]['assign'] != ""){
              responsedata[j]['patientStatus'] = "Active";
            }
            else if(responsedata[j]['assign'] == ""){
              responsedata[j]['patientStatus'] = "Inactive";
            }
            else if(responsedata[j]['consent'] == 1 && responsedata[j]['dataTransmissionDate'] == '0000-00-00 00:00:00'){
              responsedata[j]['patientStatus'] = "Consented";
            }
            else if(responsedata[j]['consent'] == 0 && responsedata[j]['dataTransmissionDate'] == '0000-00-00 00:00:00' && responsedata[j]['assign'] != ""){
              responsedata[j]['patientStatus'] = "Registered";
            }
            if((responsedata[j]['ActiveStatus'] && responsedata[j]['ActiveStatus'] == 'true') || (responsedata[j]['patientStatus'] == 'Active') || (responsedata[j]['consent'] == 1 && responsedata[j]['assign'] != "")) {
              this.activeStatus = true;
            }
            this.smsallowed = 0;
            if(responsedata[j]['isSmsAllowed']) {
              this.smsallowed = Number(responsedata[j]['isSmsAllowed']);
            }
            this.usergender = "Male";
            if(responsedata[j]['gender']) {
              let str = responsedata[j]['gender'];
              responsedata[j]['gender'] = str.charAt(0).toUpperCase() + str.slice(1);
              this.usergender = responsedata[j]['gender'];
            }
            this.timezoneStr="";
            if(responsedata[j]['p_timezone']) {
              this.timezoneStr = responsedata[j]['p_timezone'];
            }
            this.languageStr="";
            if(responsedata[j]['p_language']) {
              this.languageStr = responsedata[j]['p_language'];
            }
            this.eduloc = "";
            if(responsedata[j]['Diagnosis']['education_location'] != null) {
              this.eduloc = responsedata[j]['Diagnosis']['education_location'];
            }
            this.primdiag = [];
            this.primdiagStr = [];
            for(let z = 0; z < responsedata[j]['Diagnosis']['primary_diagnosis'].length; z++) {
              if(responsedata[j]['Diagnosis']['primary_diagnosis'][z]['check'] == 'true') {
                this.primdiag.push(responsedata[j]['Diagnosis']['primary_diagnosis'][z]['id'])
                this.primdiagStr.push(responsedata[j]['Diagnosis']['primary_diagnosis'][z]['diagnosis_type'])
              }
            }
            this.modules = responsedata[j]['modules'].split(',');
            if (this.modules.indexOf('RPM') > -1){
              this.module_RPM = true;
            }
            if (this.modules.indexOf('CCM') > -1){
              this.module_CCM = true;
            }
            if (this.modules.indexOf('TCM') > -1){
              this.module_TCM = true;
            }
            if (this.modules.indexOf('APT') > -1){
              this.module_APT = true;
            }
            responsedata[j]['UnAssignProviderList'] = [];
            for(var x=0; x<PatientClass.providerlist.length; x++) {
              let counter = 0;
              if(responsedata[j]['AssignProviderList']) {
                for(var y=0; y<responsedata[j]['AssignProviderList'].length; y++) {
                  if(PatientClass.providerlist[x]['sub'] == responsedata[j]['AssignProviderList'][y]['sub']) {
                    counter++;
                  }
                }
              }
              if(counter == 0) {
                responsedata[j]['UnAssignProviderList'].push(PatientClass.providerlist[x]);
              }
            }
            if(responsedata[j]['height']) {
              responsedata[j]['custom-height-feet'] = Math.floor(responsedata[j]['height'] / 12);
              responsedata[j]['custom-height-inches'] = responsedata[j]['height'] % 12;
              responsedata[j]['custom-height'] = responsedata[j]['custom-height-feet'] + " Feet " + responsedata[j]['custom-height-inches'] + " Inches";
            }
            if(responsedata[j]['birthdate']) {
              responsedata[j]['birthdate']=this.formatDateMSlash(responsedata[j]['birthdate'].replaceAll('-','/') + " 00:00:00");
              responsedata[j]['birthdate_date']=this.formatDateMDate(responsedata[j]['birthdate'].replaceAll('-','/') + " 00:00:00");
              responsedata[j]['masked_birthdate'] = this.make_number_mask(this.formatDateMSlash(responsedata[j]['birthdate'].replaceAll('-','/') + " 00:00:00").toString(),4);
            }
            responsedata[j]['consentdate'] = "";
            responsedata[j]['consentdate_date'] = "";
            if(responsedata[j]['consentDate'] && responsedata[j]['consentDate'] != "0000-00-00 00:00:00") {
              responsedata[j]['consentdate'] = this.formatDateMSlash(responsedata[j]['consentDate'].replaceAll('-','/') + " UTC");
              responsedata[j]['consentdate_date'] = this.formatDateMDate(responsedata[j]['consentDate'].replaceAll('-','/') + " UTC");
            }
            responsedata[j]['edudate'] = "";
            responsedata[j]['edudate_date'] = "";
            if(responsedata[j]['deviceEducationDate'] && responsedata[j]['deviceEducationDate'] != "0000-00-00 00:00:00") {
              responsedata[j]['edudate'] = this.formatDateMSlash(responsedata[j]['deviceEducationDate'].replaceAll('-','/') + " UTC");
              responsedata[j]['edudate_date'] = this.formatDateMDate(responsedata[j]['deviceEducationDate'].replaceAll('-','/') + " UTC");
            }
            responsedata[j]['transdate'] = "";
            responsedata[j]['transdate_date'] = "";
            if(responsedata[j]['dataTransmissionDate'] && responsedata[j]['dataTransmissionDate'] != "0000-00-00 00:00:00") {
              responsedata[j]['transdate'] = this.formatDateMSlash(responsedata[j]['dataTransmissionDate'].replaceAll('-','/') + " UTC");
              responsedata[j]['transdate_date'] = this.formatDateMDate(responsedata[j]['dataTransmissionDate'].replaceAll('-','/') + " UTC");
            }
            if(responsedata[j].DeviceEducation) {
              for (var i = 0; i < responsedata[j].DeviceEducation.length; i++) {
                responsedata[j].DeviceEducation[i]['timestamp'] = this.formatDateM(responsedata[j].DeviceEducation[i]['timestamp'].replaceAll('-','/') + " UTC");
              }
            }
            if(responsedata[j].TransmissionDate) {
              responsedata[j].devicesetting = [];
              responsedata[j].TransmissionDateArr = responsedata[j].TransmissionDate;
              responsedata[j].TransmissionDate = [];
              responsedata[j].UploadHistoryPart = responsedata[j].UploadHistory;
              responsedata[j].UploadHistory = [];
              responsedata[j].historyspecificPart = responsedata[j].historyspecific;
              responsedata[j].historyspecific = [];
              responsedata[j].ReviewPeriodPart = responsedata[j].ReviewPeriod;
              responsedata[j].ReviewPeriod = [];
              responsedata[j].callStatusPart = responsedata[j].callStatus;
              responsedata[j].callStatus = [];
              responsedata[j].AlertsPart = responsedata[j].Alerts;
              responsedata[j].Alerts = [];
              responsedata[j].InRangePart = responsedata[j].InRange;
              responsedata[j].InRange = [];
              responsedata[j].DevicesUploadCountPart = responsedata[j].DevicesUploadCount;
              responsedata[j].DevicesUploadCount = [];
              responsedata[j].DevicesUploadsPart = responsedata[j].DevicesUploads;
              responsedata[j].DevicesUploads = [];
              responsedata[j].DevicesPart = responsedata[j].Devices;
              responsedata[j].Devices = [];
              responsedata = this.partDataProcess(responsedata);
            }
            if(responsedata[j].Review && responsedata[j].Review.Current_Month_Detail) {
              responsedata[j].Review.Current_Month_Detail = responsedata[j].Review.Current_Month_Detail['startmonth']+"/"+responsedata[j].Review.Current_Month_Detail['startdate']+"/"+responsedata[j].Review.Current_Month_Detail['startyear']+" to "+responsedata[j].Review.Current_Month_Detail['endmonth']+"/"+responsedata[j].Review.Current_Month_Detail['enddate']+"/"+responsedata[j].Review.Current_Month_Detail['endyear'];
            }
            if(responsedata[j].Review && responsedata[j].Review.Current_Month) {
              this.currentDuration = 0;
              for (var i = 0; i < responsedata[j].Review.Current_Month.length; i++) {
                this.currentDuration = this.currentDuration + Number(responsedata[j].Review.Current_Month[i].duration);
                responsedata[j].Review.Current_Month[i].timestamp = this.formatDateCustom(responsedata[j].Review.Current_Month[i].timestamp);
              }
              responsedata[j].Review.Current_Month_Duration = this.currentDuration;
            }
            if(responsedata[j].Review && responsedata[j].Review.Previous_Month_Detail) {
              responsedata[j].Review.Previous_Month_Detail = this.formatDateMSlash(responsedata[j].Review.Previous_Month_Detail['startyear']+"-"+responsedata[j].Review.Previous_Month_Detail['startmonth']+"-"+responsedata[j].Review.Previous_Month_Detail['startdate'])+" to "+this.formatDateMSlash(responsedata[j].Review.Previous_Month_Detail['endyear']+"-"+responsedata[j].Review.Previous_Month_Detail['endmonth']+"-"+responsedata[j].Review.Previous_Month_Detail['enddate']);
            }
            if(responsedata[j].Review && responsedata[j].Review.Previous_Month) {
              this.previousDuration = 0;
              for (var i = 0; i < responsedata[j].Review.Previous_Month.length; i++) {
                this.previousDuration = this.previousDuration + Number(responsedata[j].Review.Previous_Month[i].duration);
                responsedata[j].Review.Previous_Month[i].timestamp = this.formatDateCustom(responsedata[j].Review.Previous_Month[i].timestamp);
              }
              responsedata[j].Review.Previous_Month_Duration = this.previousDuration;
            }
            responsedata[j]['sub_btoa'] = btoa(responsedata[j]['sub']);
          }
          if(responsedata[0]['Facilities']) {
            this.showfacilities = true;
            this.facilities = responsedata[0]['Facilities'];
            this.facilityid = "0";
            for(var i=0; i<this.facilities.length; i++){
              if(this.facilities[i]['check'] == true) {
                this.facilitytext = this.facilities[i]['facilitytext'];
                this.facilityid = this.facilities[i]['idfacility'];
              }
            }
          }
          // console.log(this.facilityid);
          PatientClass.userDetails = responsedata[0];
          if(PatientClass.userDetails.reporting_provider){
            this.Reporting_provider = PatientClass.userDetails.reporting_provider
          }
          if(PatientClass.userDetails.Review && PatientClass.userDetails.Review.Current_Month) {
            PatientClass.userDetails.currentReviews = PatientClass.userDetails.Review.Current_Month; 
          }
          if(PatientClass.userDetails.Review && PatientClass.userDetails.Review.Previous_Month) {
            PatientClass.userDetails.pastMonthReviews = PatientClass.userDetails.Review.Previous_Month;
          }
          PatientClass.userDetails.masked_phone_number="";
          if(PatientClass.userDetails.phone_number && PatientClass.userDetails.phone_number.length > 0){
            var phonestr = PatientClass.userDetails.phone_number.toString();
            PatientClass.userDetails.masked_phone_number = this.make_number_mask(phonestr);
          }
          PatientClass.userDetails.masked_phoneNoSms="";
          if(PatientClass.userDetails.phoneNoSms && PatientClass.userDetails.phoneNoSms.length > 0){
            var smsphonestr = PatientClass.userDetails.phoneNoSms.toString();
            PatientClass.userDetails.masked_phoneNoSms = this.make_number_mask(smsphonestr);
          }
          PatientClass.screen.style.display = 'none';

          this.reviewtemplatearr = [];
          this.selreviewtemplate = "0";
          this.reviewtext = "";
          var tempobj = {
            'id': '0',
            'shortname': 'Custom',
            'template_text': ''
          }
          this.reviewtemplatearr.push(tempobj);
          if(sessionStorage.getItem('reviewtemplate') != null) {
            var reviewtemplateobj = JSON.parse(sessionStorage.getItem('reviewtemplate'));
            for(var i=0; i<reviewtemplateobj.length; i++) {
              this.reviewtemplatearr.push(reviewtemplateobj[i]);
            }
          }

          if(sessionStorage.getItem('Review_Mode') == null) {
            this.addReviewFormMode = 'datareview';
          }
          else if(!this.reviewTransmission){
            this.addReviewFormMode = sessionStorage.getItem('Review_Mode');
          }
          else{
            this.addReviewFormMode = 'datareview';
          }
          
          if(sessionStorage.getItem('Review_Provider') == null) {
            this.addReviewFormProvider = '';
          }
          else {
            var provmatch = false;
            for(var z=0; z<this.providerlist.length; z++){
              if(this.providerlist[z]['sub'] == sessionStorage.getItem('Review_Provider')){
                provmatch = true;
              }
            }
            if(provmatch) {
              this.addReviewFormProvider = sessionStorage.getItem('Review_Provider');
            }
            else {
              this.addReviewFormProvider = '';
            }
          }

          if(this.reviewTransmission){
            setTimeout (() => {
              document.getElementById("in_add_review").scrollIntoView();
            }, 500);
          }

          if(this.cpass && this.cpass == "true"){
            setTimeout (() => {
              document.getElementById("collapse1").scrollIntoView();
            }, 500);
          }

          this.allGraphUpdate();
        }
        else {
          alert("Could not find Patient Details");
          PatientClass.router.navigateByUrl('/patient');
        }
      }
      else {
        alert("Could not find Patient Details");
        PatientClass.router.navigateByUrl('/patient');
      }
    },
    async (error: any) => {
      if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
        var root = this;
        var callcounter = 0;
        setTimeout (function requestCallback() {
          root.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getuserdetail2', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
          .subscribe((response: any) => {
            root.screen.style.display = 'none';
            // console.log(response);
            if (response.status === "success") {
              let params = new HttpParams({
                fromObject: {
                  userpull_id: localStorage.getItem('userpull_id'),
                  patient_sub: root.subId,
                  sub: root.subId
                }
              });
      
              //EligibilitY Api Call Start
              root.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getpatienteligibility', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
              .subscribe((responsepatient: any) => {
                if (responsepatient.status === "success") {
                  root.PatientEligibilty = JSON.parse(responsepatient.data);
                  if (root.PatientEligibilty.length > 0){
                    root.PatientIsAllEligibility = 1;
                  }
      
                  for(var elegibility of root.PatientEligibilty){
                    if (elegibility['type'] == 'Primary') {
                      root.primarypayerCode = elegibility['payercode'];
                      root.primarymemberID = elegibility['memberid'];
          
                      var tempdata = JSON.parse(elegibility['data']);
          
                      var splitted = tempdata['rundate'].split("-");
                      root.primaryrundate = splitted[2]+"-"+splitted[0]+"-"+splitted[1];
          
                      root.primarystatus = tempdata['status'];
                      root.primaryremainingamount = tempdata['remainingamount'];
          
                      root.primary_partb = tempdata['partb'];
                      root.primary_ppo = tempdata['ppo'];
                      root.primary_hmo = tempdata['hmo'];
                      root.primary_msp = tempdata['msp'];
                      root.primary_pdf_url = elegibility['pdfurl'];
                      root.primary_r_pdf_url = elegibility['recursive_pdfurl'];
                    }else if (elegibility['type'] == 'Secondary') {
                      root.secondarypayerCode = elegibility['payercode'];
                      root.secondarymemberID = elegibility['memberid'];
          
                      var tempdata = JSON.parse(elegibility['data']);
          
                      var splitted = tempdata['rundate'].split("-");
                      root.secondaryrundate = splitted[2]+"-"+splitted[0]+"-"+splitted[1];
          
                      root.secondarystatus = tempdata['status'];
                      root.secondaryremainingamount = tempdata['remainingamount'];
          
                      root.secondary_partb = tempdata['partb'];
                      root.secondary_ppo = tempdata['ppo'];
                      root.secondary_hmo = tempdata['hmo'];
                      root.secondary_msp = tempdata['msp'];
                      root.secondary_pdf_url = elegibility['pdfurl'];
                      root.secondary_r_pdf_url = elegibility['recursive_pdfurl'];
                    }
                  }
      
                }
              },
              async (error: any) => {
                console.log(error);
              });
              //EligibilitY Api Call End
      
              
              root.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/patientstatusfetch', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
              .subscribe((responsestatus: any) => {
                console.log(responsestatus);
                if (responsestatus.status === "success") {
                  let responsestatusdata = JSON.parse(responsestatus.data);
                  if (responsestatusdata.length > 0) {
                    for(var z=0; z < responsestatusdata.length; z++) {
                      responsestatusdata[z]['from_date_updated'] = root.formatDateMSlash(responsestatusdata[z]['from_date'].replaceAll('-','/') + " UTC");
                      responsestatusdata[z]['to_date_updated'] = root.formatDateMSlash(responsestatusdata[z]['to_date'].replaceAll('-','/') + " UTC");
                      var d1 = new Date();
                      var d2 = new Date( d1.getUTCFullYear(), d1.getUTCMonth(), d1.getUTCDate(), d1.getUTCHours(), d1.getUTCMinutes(), d1.getUTCSeconds() );
                      // console.log(root.formatDBDateTime(d2.getTime()));
                      // console.log(root.formatDBDateTime(Date.parse(responsestatusdata[z]['from_date'])));
                      // console.log(root.formatDBDateTime(Date.parse(responsestatusdata[z]['to_date'])));
                      if(Date.parse(responsestatusdata[z]['from_date']) < d2.getTime() && Date.parse(responsestatusdata[z]['to_date']) > d2.getTime() && responsestatusdata[z]['active_status'] == 1) {
                        root.patientCurrentStatus = true;
                        root.patientCurrentStatusMessage = "Not available from "+responsestatusdata[z]['from_date_updated']+" to "+responsestatusdata[z]['to_date_updated']+" for "+responsestatusdata[z]['reason'];
                      }
                      // console.log(responsestatusdata[z]);
                      root.patientStatuses.push(responsestatusdata[z]);
                    }
                  }
                }
              },
              async (error: any) => {
                console.log(error);
              });
              root.hubDetails = {};
              root.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/patienthubdetails', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
              .subscribe((responsedetail: any) => {
                console.log(responsedetail);
                if (responsedetail.status === "success") {
                  let responsedetaildata = JSON.parse(responsedetail.data);
                  if (responsedetaildata['DeviceID'] && responsedetaildata['Sname']) {
                    root.hubDetails = responsedetaildata;
                    console.log(responsedetaildata);
                  }
                }
              },
              async (error: any) => {
                console.log(error);
              });
              root.pageTimerButtonClick();
              var offset = new Date().getTimezoneOffset();
              if(offset >= 0) {
                var offsetstring = "+"+offset;
              }
              else {
                var offsetstring = ""+offset;
              }
              let responsedata = JSON.parse(response.data);
              console.log(responsedata);
              PatientClass.providerlist = JSON.parse(response.ProviderList);
              console.log(PatientClass.providerlist);
              PatientClass.languagelist = JSON.parse(response.LanguageList);
              console.log(PatientClass.languagelist);
              root.languageArr = {};
              for (var l = 0; l < PatientClass.languagelist.length; l++) {
                root.languageArr[PatientClass.languagelist[l]['language_code']] = PatientClass.languagelist[l]['language_value'];
              }
              console.log(root.languageArr);
              if (responsedata.length > 0) {
                for (var j = 0; j < responsedata.length; j++) {
                  if(responsedata[j]['dataTransmissionDate'] != '0000-00-00 00:00:00' && responsedata[j]['assign'] != ""){
                    responsedata[j]['patientStatus'] = "Active";
                  }
                  else if(responsedata[j]['assign'] == ""){
                    responsedata[j]['patientStatus'] = "Inactive";
                  }
                  else if(responsedata[j]['consent'] == 1 && responsedata[j]['dataTransmissionDate'] == '0000-00-00 00:00:00'){
                    responsedata[j]['patientStatus'] = "Consented";
                  }
                  else if(responsedata[j]['consent'] == 0 && responsedata[j]['dataTransmissionDate'] == '0000-00-00 00:00:00' && responsedata[j]['assign'] != ""){
                    responsedata[j]['patientStatus'] = "Registered";
                  }
                  if((responsedata[j]['ActiveStatus'] && responsedata[j]['ActiveStatus'] == 'true') || (responsedata[j]['patientStatus'] == 'Active') || (responsedata[j]['consent'] == 1 && responsedata[j]['assign'] != "")) {
                    root.activeStatus = true;
                  }
                  root.smsallowed = 0;
                  if(responsedata[j]['isSmsAllowed']) {
                    root.smsallowed = Number(responsedata[j]['isSmsAllowed']);
                  }
                  root.usergender = "Male";
                  if(responsedata[j]['gender']) {
                    let str = responsedata[j]['gender'];
                    responsedata[j]['gender'] = str.charAt(0).toUpperCase() + str.slice(1);
                    root.usergender = responsedata[j]['gender'];
                  }
                  root.timezoneStr="";
                  if(responsedata[j]['p_timezone']) {
                    root.timezoneStr = responsedata[j]['p_timezone'];
                  }
                  root.languageStr="";
                  if(responsedata[j]['p_language']) {
                    root.languageStr = responsedata[j]['p_language'];
                  }
                  root.eduloc = "";
                  if(responsedata[j]['Diagnosis']['education_location'] != null) {
                    root.eduloc = responsedata[j]['Diagnosis']['education_location'];
                  }
                  root.primdiag = [];
                  root.primdiagStr = [];
                  for(let z = 0; z < responsedata[j]['Diagnosis']['primary_diagnosis'].length; z++) {
                    if(responsedata[j]['Diagnosis']['primary_diagnosis'][z]['check'] == 'true') {
                      root.primdiag.push(responsedata[j]['Diagnosis']['primary_diagnosis'][z]['id'])
                      root.primdiagStr.push(responsedata[j]['Diagnosis']['primary_diagnosis'][z]['diagnosis_type'])
                    }
                  }
                  root.modules = responsedata[j]['modules'].split(',');
                  if (root.modules.indexOf('RPM') > -1){
                    root.module_RPM = true;
                  }
                  if (root.modules.indexOf('CCM') > -1){
                    root.module_CCM = true;
                  }
                  if (root.modules.indexOf('TCM') > -1){
                    root.module_TCM = true;
                  }
                  if (root.modules.indexOf('APT') > -1){
                    root.module_APT = true;
                  }
                  responsedata[j]['UnAssignProviderList'] = [];
                  for(var x=0; x<PatientClass.providerlist.length; x++) {
                    let counter = 0;
                    if(responsedata[j]['AssignProviderList']) {
                      for(var y=0; y<responsedata[j]['AssignProviderList'].length; y++) {
                        if(PatientClass.providerlist[x]['sub'] == responsedata[j]['AssignProviderList'][y]['sub']) {
                          counter++;
                        }
                      }
                    }
                    if(counter == 0) {
                      responsedata[j]['UnAssignProviderList'].push(PatientClass.providerlist[x]);
                    }
                  }
                  if(responsedata[j]['height']) {
                    responsedata[j]['custom-height-feet'] = Math.floor(responsedata[j]['height'] / 12);
                    responsedata[j]['custom-height-inches'] = responsedata[j]['height'] % 12;
                    responsedata[j]['custom-height'] = responsedata[j]['custom-height-feet'] + " Feet " + responsedata[j]['custom-height-inches'] + " Inches";
                  }
                  if(responsedata[j]['birthdate']) {
                    responsedata[j]['birthdate']=root.formatDateMSlash(responsedata[j]['birthdate'].replaceAll('-','/') + " 00:00:00");
                    responsedata[j]['birthdate_date']=root.formatDateMDate(responsedata[j]['birthdate'].replaceAll('-','/') + " 00:00:00");
                    responsedata[j]['masked_birthdate'] = root.make_number_mask(root.formatDateMSlash(responsedata[j]['birthdate'].replaceAll('-','/') + " 00:00:00").toString(),4);
                  }
                  responsedata[j]['consentdate'] = "";
                  responsedata[j]['consentdate_date'] = "";
                  if(responsedata[j]['consentDate'] && responsedata[j]['consentDate'] != "0000-00-00 00:00:00") {
                    responsedata[j]['consentdate'] = root.formatDateMSlash(responsedata[j]['consentDate'].replaceAll('-','/') + " UTC");
                    responsedata[j]['consentdate_date'] = root.formatDateMDate(responsedata[j]['consentDate'].replaceAll('-','/') + " UTC");
                  }
                  responsedata[j]['edudate'] = "";
                  responsedata[j]['edudate_date'] = "";
                  if(responsedata[j]['deviceEducationDate'] && responsedata[j]['deviceEducationDate'] != "0000-00-00 00:00:00") {
                    responsedata[j]['edudate'] = root.formatDateMSlash(responsedata[j]['deviceEducationDate'].replaceAll('-','/') + " UTC");
                    responsedata[j]['edudate_date'] = root.formatDateMDate(responsedata[j]['deviceEducationDate'].replaceAll('-','/') + " UTC");
                  }
                  responsedata[j]['transdate'] = "";
                  responsedata[j]['transdate_date'] = "";
                  if(responsedata[j]['dataTransmissionDate'] && responsedata[j]['dataTransmissionDate'] != "0000-00-00 00:00:00") {
                    responsedata[j]['transdate'] = root.formatDateMSlash(responsedata[j]['dataTransmissionDate'].replaceAll('-','/') + " UTC");
                    responsedata[j]['transdate_date'] = root.formatDateMDate(responsedata[j]['dataTransmissionDate'].replaceAll('-','/') + " UTC");
                  }
                  if(responsedata[j].DeviceEducation) {
                    for (var i = 0; i < responsedata[j].DeviceEducation.length; i++) {
                      responsedata[j].DeviceEducation[i]['timestamp'] = root.formatDateM(responsedata[j].DeviceEducation[i]['timestamp'].replaceAll('-','/') + " UTC");
                    }
                  }
                  if(responsedata[j].TransmissionDate) {
                    responsedata[j].devicesetting = [];
                    responsedata[j].TransmissionDateArr = responsedata[j].TransmissionDate;
                    responsedata[j].TransmissionDate = [];
                    responsedata[j].UploadHistoryPart = responsedata[j].UploadHistory;
                    responsedata[j].UploadHistory = [];
                    responsedata[j].historyspecificPart = responsedata[j].historyspecific;
                    responsedata[j].historyspecific = [];
                    responsedata[j].ReviewPeriodPart = responsedata[j].ReviewPeriod;
                    responsedata[j].ReviewPeriod = [];
                    responsedata[j].callStatusPart = responsedata[j].callStatus;
                    responsedata[j].callStatus = [];
                    responsedata[j].AlertsPart = responsedata[j].Alerts;
                    responsedata[j].Alerts = [];
                    responsedata[j].InRangePart = responsedata[j].InRange;
                    responsedata[j].InRange = [];
                    responsedata[j].DevicesUploadCountPart = responsedata[j].DevicesUploadCount;
                    responsedata[j].DevicesUploadCount = [];
                    responsedata[j].DevicesUploadsPart = responsedata[j].DevicesUploads;
                    responsedata[j].DevicesUploads = [];
                    responsedata[j].DevicesPart = responsedata[j].Devices;
                    responsedata[j].Devices = [];
                    responsedata = root.partDataProcess(responsedata);
                  }
                  if(responsedata[j].Review && responsedata[j].Review.Current_Month_Detail) {
                    responsedata[j].Review.Current_Month_Detail = responsedata[j].Review.Current_Month_Detail['startmonth']+"/"+responsedata[j].Review.Current_Month_Detail['startdate']+"/"+responsedata[j].Review.Current_Month_Detail['startyear']+" to "+responsedata[j].Review.Current_Month_Detail['endmonth']+"/"+responsedata[j].Review.Current_Month_Detail['enddate']+"/"+responsedata[j].Review.Current_Month_Detail['endyear'];
                  }
                  if(responsedata[j].Review && responsedata[j].Review.Current_Month) {
                    root.currentDuration = 0;
                    for (var i = 0; i < responsedata[j].Review.Current_Month.length; i++) {
                      root.currentDuration = root.currentDuration + Number(responsedata[j].Review.Current_Month[i].duration);
                      responsedata[j].Review.Current_Month[i].timestamp = root.formatDateCustom(responsedata[j].Review.Current_Month[i].timestamp);
                    }
                    responsedata[j].Review.Current_Month_Duration = root.currentDuration;
                  }
                  if(responsedata[j].Review && responsedata[j].Review.Previous_Month_Detail) {
                    responsedata[j].Review.Previous_Month_Detail = root.formatDateMSlash(responsedata[j].Review.Previous_Month_Detail['startyear']+"-"+responsedata[j].Review.Previous_Month_Detail['startmonth']+"-"+responsedata[j].Review.Previous_Month_Detail['startdate'])+" to "+root.formatDateMSlash(responsedata[j].Review.Previous_Month_Detail['endyear']+"-"+responsedata[j].Review.Previous_Month_Detail['endmonth']+"-"+responsedata[j].Review.Previous_Month_Detail['enddate']);
                  }
                  if(responsedata[j].Review && responsedata[j].Review.Previous_Month) {
                    root.previousDuration = 0;
                    for (var i = 0; i < responsedata[j].Review.Previous_Month.length; i++) {
                      root.previousDuration = root.previousDuration + Number(responsedata[j].Review.Previous_Month[i].duration);
                      responsedata[j].Review.Previous_Month[i].timestamp = root.formatDateCustom(responsedata[j].Review.Previous_Month[i].timestamp);
                    }
                    responsedata[j].Review.Previous_Month_Duration = root.previousDuration;
                  }
                  responsedata[j]['sub_btoa'] = btoa(responsedata[j]['sub']);
                }
                if(responsedata[0]['Facilities']) {
                  root.showfacilities = true;
                  root.facilities = responsedata[0]['Facilities'];
                  root.facilityid = "0";
                  for(var i=0; i<root.facilities.length; i++){
                    if(root.facilities[i]['check'] == true) {
                      root.facilitytext = root.facilities[i]['facilitytext'];
                      root.facilityid = root.facilities[i]['idfacility'];
                    }
                  }
                }
                // console.log(root.facilityid);
                PatientClass.userDetails = responsedata[0];
                if(PatientClass.userDetails.reporting_provider){
                  root.Reporting_provider = PatientClass.userDetails.reporting_provider
                }
                if(PatientClass.userDetails.Review && PatientClass.userDetails.Review.Current_Month) {
                  PatientClass.userDetails.currentReviews = PatientClass.userDetails.Review.Current_Month; 
                }
                if(PatientClass.userDetails.Review && PatientClass.userDetails.Review.Previous_Month) {
                  PatientClass.userDetails.pastMonthReviews = PatientClass.userDetails.Review.Previous_Month;
                }
                
                PatientClass.userDetails.masked_phone_number="";
                if(PatientClass.userDetails.phone_number && PatientClass.userDetails.phone_number.length > 0){
                  var phonestr = PatientClass.userDetails.phone_number.toString();
                  PatientClass.userDetails.masked_phone_number = this.make_number_mask(phonestr);
                }
                PatientClass.userDetails.masked_phoneNoSms="";
                if(PatientClass.userDetails.phoneNoSms && PatientClass.userDetails.phoneNoSms.length > 0){
                  var smsphonestr = PatientClass.userDetails.phoneNoSms.toString();
                  PatientClass.userDetails.masked_phoneNoSms = this.make_number_mask(smsphonestr);
                }
                PatientClass.screen.style.display = 'none';
      
                root.reviewtemplatearr = [];
                root.selreviewtemplate = "0";
                root.reviewtext = "";
                var tempobj = {
                  'id': '0',
                  'shortname': 'Custom',
                  'template_text': ''
                }
                root.reviewtemplatearr.push(tempobj);
                if(sessionStorage.getItem('reviewtemplate') != null) {
                  var reviewtemplateobj = JSON.parse(sessionStorage.getItem('reviewtemplate'));
                  for(var i=0; i<reviewtemplateobj.length; i++) {
                    root.reviewtemplatearr.push(reviewtemplateobj[i]);
                  }
                }
      
                if(sessionStorage.getItem('Review_Mode') == null) {
                  root.addReviewFormMode = 'datareview';
                }
                else if(!root.reviewTransmission){
                  root.addReviewFormMode = sessionStorage.getItem('Review_Mode');
                }
                else{
                  root.addReviewFormMode = 'datareview';
                }
                
                if(sessionStorage.getItem('Review_Provider') == null) {
                  root.addReviewFormProvider = '';
                }
                else {
                  var provmatch = false;
                  for(var z=0; z<root.providerlist.length; z++){
                    if(root.providerlist[z]['sub'] == sessionStorage.getItem('Review_Provider')){
                      provmatch = true;
                    }
                  }
                  if(provmatch) {
                    root.addReviewFormProvider = sessionStorage.getItem('Review_Provider');
                  }
                  else {
                    root.addReviewFormProvider = '';
                  }
                }
      
                if(root.reviewTransmission){
                  setTimeout (() => {
                    document.getElementById("in_add_review").scrollIntoView();
                  }, 500);
                }
      
                if(root.cpass && root.cpass == "true"){
                  setTimeout (() => {
                    document.getElementById("collapse1").scrollIntoView();
                  }, 500);
                }
      
                root.allGraphUpdate();
              }
              else {
                alert("Could not find Patient Details");
                PatientClass.router.navigateByUrl('/patient');
              }
            }
            else {
              callcounter++;
              if(callcounter < 120) {
                setTimeout (requestCallback, 5000);
              }
            }
          },
          async (error: any) => {
            console.log(error);
          });
        }, 5000);

      }else{
        Auth.signOut();
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        PatientClass.screen.style.display = 'none';
        PatientClass.router.navigateByUrl('/sign-in');
      }      
    });
  }

  getNext = () => {
    this.screen.style.display = 'block';
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
        group: 'Patient',
        sub: this.subId,
        page: this.userDetails.nextpage
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getuserdetail', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      if (response.status === "success") {
        let responsedatapart = JSON.parse(response.data);
        console.log(responsedatapart);
        responsedatapart[0].UploadHistoryPart = responsedatapart[0].UploadHistory;
        responsedatapart[0].historyspecificPart = responsedatapart[0].historyspecific;
        responsedatapart[0].ReviewPeriodPart = responsedatapart[0].ReviewPeriod;
        responsedatapart[0].callStatusPart = responsedatapart[0].callStatus;
        responsedatapart[0].AlertsPart = responsedatapart[0].Alerts;
        responsedatapart[0].InRangePart = responsedatapart[0].InRange;
        responsedatapart[0].DevicesUploadCountPart = responsedatapart[0].DevicesUploadCount;
        responsedatapart[0].DevicesUploadsPart = responsedatapart[0].DevicesUploads;
        responsedatapart[0].DevicesPart = responsedatapart[0].Devices;
        responsedatapart[0]['TransmissionDate'] = this.userDetails.TransmissionDate;
        responsedatapart[0]['UploadHistory'] = this.userDetails.UploadHistory;
        responsedatapart[0]['historyspecific'] = this.userDetails.historyspecific;
        responsedatapart[0]['devicesetting'] = this.userDetails.devicesetting;
        responsedatapart[0]['ReviewPeriod'] = this.userDetails.ReviewPeriod;
        responsedatapart[0]['callStatus'] = this.userDetails.callStatus;
        responsedatapart[0]['Alerts'] = this.userDetails.Alerts;
        responsedatapart[0]['InRange'] = this.userDetails.InRange;
        responsedatapart[0]['DevicesUploadCount'] = this.userDetails.DevicesUploadCount;
        responsedatapart[0]['DevicesUploads'] = this.userDetails.DevicesUploads;
        responsedatapart[0]['Devices'] = this.userDetails.Devices;
        this.partDataProcess(responsedatapart);
        this.userDetails.nextpage = responsedatapart[0].nextpage;
        this.allGraphUpdate();
      }
      this.screen.style.display = 'none';
    },
    async (error: any) => {
      console.log(error);
    })
  }

  partDataProcess = (responsedata) => {
    let j = 0;
    let startk = responsedata[j].TransmissionDate.length;
    responsedata[j].devicesetting = [];
    if(responsedata[j].PartDate) {
      for (var z = 0; z < responsedata[j].PartDate.length; z++) {
        responsedata[j].TransmissionDate.push(responsedata[j].PartDate[z]);
        responsedata[j].UploadHistory.push(responsedata[j].UploadHistoryPart[z]);
        responsedata[j].historyspecific.push(responsedata[j].historyspecificPart[z]);
        responsedata[j].ReviewPeriod.push(responsedata[j].ReviewPeriodPart[z]);
        responsedata[j].callStatus.push(responsedata[j].callStatusPart[z]);
        responsedata[j].Alerts.push(responsedata[j].AlertsPart[z]);
        responsedata[j].InRange.push(responsedata[j].InRangePart[z]);
        responsedata[j].DevicesUploadCount.push(responsedata[j].DevicesUploadCountPart[z]);
        responsedata[j].DevicesUploads.push(responsedata[j].DevicesUploadsPart[z]);
        responsedata[j].Devices.push(responsedata[j].DevicesPart[z]);
      }
    }
    for (var k = startk; k < responsedata[j].TransmissionDate.length; k++) {
      if(responsedata[j].ReviewPeriod && responsedata[j].ReviewPeriod[k]) {
        // if(responsedata[j].ReviewPeriod[0].Review[0].review) {
        //   console.log(responsedata[j].ReviewPeriod[0].Review[0].review);
        // }
        // console.log(responsedata[j].ReviewPeriod[k].StartDate);
        // console.log(responsedata[j].ReviewPeriod[k].EndDate);
        responsedata[j].ReviewPeriod[k].StartDate = this.formatDateMSlash(responsedata[j].ReviewPeriod[k].StartDate.replaceAll('-','/') + " UTC");
        responsedata[j].ReviewPeriod[k].EndDate = this.formatDateMSlash(responsedata[j].ReviewPeriod[k].EndDate.replaceAll('-','/') + " UTC");
        for(var i=0; i<responsedata[j].ReviewPeriod[k].Review.length; i++) {
          responsedata[j].ReviewPeriod[k].Review[i].timestampdb = this.formatDBDateTime(responsedata[j].ReviewPeriod[k].Review[i].timestamp.replaceAll('-','/') + " UTC");
          responsedata[j].ReviewPeriod[k].Review[i].timestamp = this.formatDateCustom(responsedata[j].ReviewPeriod[k].Review[i].timestamp.replaceAll('-','/') + " UTC");
          // responsedata[j].ReviewPeriod[k].Review[i].timestamp = this.formatDateMSlash(responsedata[j].ReviewPeriod[k].Review[i].timestamp);
          if(responsedata[j].ReviewPeriod[k].Review[i].review){
            // console.log(responsedata[j].ReviewPeriod[k].Review[i].review);
            responsedata[j].ReviewPeriod[k].Review[i].review = responsedata[j].ReviewPeriod[k].Review[i].review;
          }
          if(responsedata[j].ReviewPeriod[k].Review[i].symptoms && responsedata[j].ReviewPeriod[k].Review[i].symptoms != "") {
            responsedata[j].ReviewPeriod[k].Review[i].symptoms = JSON.parse(responsedata[j].ReviewPeriod[k].Review[i].symptoms);
          }
          if(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail){
            if(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['timestamp']) {
              responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['timestamp'] = this.formatDateCustom(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['timestamp'].replaceAll('-','/') + " UTC");
            }
            var relsettingjson = "";
            if(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['alert_setting'] && responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['alert_setting'] != null) {
              if(isObject(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['alert_setting'])){
                responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['alert_setting'] = JSON.stringify(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['alert_setting']);
              }
              var relsettingjsonarr = (responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['alert_setting']).trim().replace(/{/g, '').replace(/}/g, '').split(" ");
              for(var l = 0; l < relsettingjsonarr.length; l++) {
                if(relsettingjsonarr[l].trim() != '') {
                  if(relsettingjson) {
                    if(relsettingjsonarr[l].trim() != ":" && relsettingjsonarr[l].trim().slice(-1) != '"') {
                      relsettingjson = relsettingjson + relsettingjsonarr[l].trim() + ',';
                    }
                    else {
                      relsettingjson = relsettingjson + relsettingjsonarr[l].trim();
                    }
                  }
                  else {
                    relsettingjson = relsettingjson + relsettingjsonarr[l].trim();
                  }
                }
              }
            }
            if(relsettingjson.slice(-1) == ",") {
              relsettingjson = relsettingjson.slice(0, -1);
            }
            relsettingjson = '{' + relsettingjson.replace(/After,Meal/g, 'After Meal').replace(/Normal,Test/g, 'Normal Test') + '}';
            var reldetailjson = "";
            if(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['detailed_value']) {
              if(isObject(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['detailed_value'])){
                responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['detailed_value'] = JSON.stringify(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['detailed_value']);
              }
              var reldetailjsonarr = (responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['detailed_value']).toString().trim().replace(/ : /g, ':').replace(/ :/g, ':').replace(/: /g, ':').replace(/" /g, '"').replace(/ "/g, '"').replace(/{/g, '').replace(/}/g, '').split(" ");
              for(var l = 0; l < reldetailjsonarr.length; l++) {
                if(reldetailjsonarr[l].trim() != '') {
                  if(reldetailjson) {
                    reldetailjson = reldetailjson + ',' + reldetailjsonarr[l].trim();
                  }
                  else {
                    reldetailjson = reldetailjson + reldetailjsonarr[l].trim();
                  }
                }
              }
            }
            // console.log(reldetailjson);
            var reldetailjson = '{' + reldetailjson.replace(/After,Meal/g, 'After Meal').replace(/Normal,Test/g, 'Normal Test') + '}';
            if(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name'] && (responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name'].trim() == "BPM" || responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name'].trim() == "Oximeter" || responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name'].trim() == "Weight" || responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name'].trim() == "Blood Glucose" || responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name'].trim() == "Thermometer")) {
              responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['alert_setting'] = JSON.parse(relsettingjson);
              // if(!(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name'].trim() in responsedata[j].devicesetting[k]) && relsettingjson != '{}') {
              //   responsedata[j].devicesetting[k][responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name'].trim()] = JSON.parse(relsettingjson);
              // }
              responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['detailed_value'] = JSON.parse(reldetailjson);
              if(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['detailed_value']['weight']) {
                responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['detailed_value']['weight'] = Math.floor(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['detailed_value']['weight'])+" lbs";
              }
              if(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['detailed_value']['bmi']) {
                responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['detailed_value']['bmi'] = Math.floor(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['detailed_value']['bmi']);
              }
            }
            if(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['critical_alert'] && responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['critical_alert'] == 1) {
              responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['super_alert'] = responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['critical_alert'];
            }
            if(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name'] && responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name'].trim() == "BPM") {
              responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name_str'] = "BPM (SYS/DIA/Pulse)";
              // Attn Required : 210 => SYS =<70 120 => DIA =<30 120 => PR =<40
              // if(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['detailed_value']['sysData'] >= 210 || responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['detailed_value']['sysData'] <= 70) {
              //   responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['super_alert'] = 1;
              // }
              // if(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['detailed_value']['diaData'] >= 120 || responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['detailed_value']['diaData'] <= 30) {
              //   responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['super_alert'] = 1;
              // }
              // if(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['detailed_value']['pulseData'] >= 120 || responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['detailed_value']['pulseData'] <= 40) {
              //   responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['super_alert'] = 1;
              // }
            }
            else if(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name'] && responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name'].trim() == "Oximeter") {
              responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name_str'] = "Pulse Oximeter (Spo2%/PR)";
            }
            else if(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name'] && responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name'].trim() == "Weight") {
              responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name_str'] = "Weight (lb./BMI)";
            }
            else if(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name'] && responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name'].trim() == "Blood Glucose") {
              responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name_str'] = "Blood Glucose in mg/dL (Mode)";
            }
            else if(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name'] && responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name'].trim() == "Thermometer") {
              responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name_str'] = "Thermometer (Temp. in Farenheit)";
            }
            else if(responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name']) {
              responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name_str'] = responsedata[j].ReviewPeriod[k].Review[i].transmission_detail['device_name'].trim();
            }
          }
        }
        responsedata[j].TransmissionDate[k].ReviewPeriod = responsedata[j].ReviewPeriod[k];
      }
      if(responsedata[j].TransmissionDate[k].StartDate && responsedata[j].TransmissionDate[k].EndDate) {
        // console.log(responsedata[j].TransmissionDate[k].StartDate);
        // console.log(responsedata[j].TransmissionDate[k].StartDate + " UTC");
        // console.log(this.formatDateMSlash(responsedata[j].TransmissionDate[k].StartDate + " UTC"));
        // console.log(responsedata[j].TransmissionDate[k].EndDate);
        // console.log(responsedata[j].TransmissionDate[k].EndDate + " UTC");
        // console.log(this.formatDateMSlash(responsedata[j].TransmissionDate[k].EndDate + " UTC"));
        responsedata[j].TransmissionDate[k].StartDateUTC = responsedata[j].TransmissionDate[k].StartDate.replaceAll('-','/');
        responsedata[j].TransmissionDate[k].EndDateUTC = responsedata[j].TransmissionDate[k].EndDate.replaceAll('-','/');
        responsedata[j].TransmissionDate[k].StartDateActual = this.formatTime(responsedata[j].TransmissionDate[k].StartDate.replaceAll('-','/') + " UTC");
        responsedata[j].TransmissionDate[k].EndDateActual = this.formatTime(responsedata[j].TransmissionDate[k].EndDate.replaceAll('-','/') + " UTC");
        responsedata[j].TransmissionDate[k].StartDate = this.formatDateMSlash(responsedata[j].TransmissionDate[k].StartDate.replaceAll('-','/') + " UTC");
        responsedata[j].TransmissionDate[k].EndDate = this.formatDateMSlash(responsedata[j].TransmissionDate[k].EndDate.replaceAll('-','/') + " UTC");
        responsedata[j].TransmissionDate[k].StartDateStr = responsedata[j].TransmissionDate[k].StartDate.replace('/','').replace('/','');
      }
      if(responsedata[j].Alerts && responsedata[j].Alerts[k]) {
        for (var i = 0; i < responsedata[j].Alerts[k].length; i++) {
          // if(responsedata[j].Alerts[k][i]) {  // && responsedata[j].Alerts[k][i].length > 0
          //   responsedata[j].Alerts[k][i]['timestamp'] = this.formatDateM(responsedata[j].Alerts[k][i]['timestamp']);
          //   // var settingjsonarr = responsedata[j].Alerts[k][i]['alert_setting'].trim().replace(/ : /g, ':').replace(/ :/g, ':').replace(/: /g, ':').replace(/" /g, '"').replace(/ "/g, '"').split(" ");
          //   var settingjson = "";
          //   if(responsedata[j].Alerts[k][i]['alert_setting'] && responsedata[j].Alerts[k][i]['alert_setting'] != null) {
          //     var settingjsonarr = responsedata[j].Alerts[k][i]['alert_setting'].trim().split(" ");
          //     for(var l = 0; l < settingjsonarr.length; l++) {
          //       if(settingjsonarr[l].trim() != '') {
          //         if(settingjson) {
          //           if(settingjsonarr[l].trim() != ":" && settingjsonarr[l].trim().slice(-1) != '"') {
          //             settingjson = settingjson + settingjsonarr[l].trim() + ',';
          //           }
          //           else {
          //             settingjson = settingjson + settingjsonarr[l].trim();
          //           }
          //         }
          //         else {
          //           settingjson = settingjson + settingjsonarr[l].trim();
          //         }
          //       }
          //     }
          //   }
          //   if(settingjson.slice(-1) == ",") {
          //     settingjson = settingjson.slice(0, -1);
          //   }
          //   settingjson = '{' + settingjson.replace(/After,Meal/g, 'After Meal').replace(/Normal,Test/g, 'Normal Test') + '}';
          //   var detailjson = "";
          //   if(responsedata[j].Alerts[k][i]['detailed_value']) {
          //     var detailjsonarr = responsedata[j].Alerts[k][i]['detailed_value'].trim().replace(/ : /g, ':').replace(/ :/g, ':').replace(/: /g, ':').replace(/" /g, '"').replace(/ "/g, '"').split(" ");
          //     for(var l = 0; l < detailjsonarr.length; l++) {
          //       if(detailjsonarr[l].trim() != '') {
          //         if(detailjson) {
          //           detailjson = detailjson + ',' + detailjsonarr[l].trim();
          //         }
          //         else {
          //           detailjson = detailjson + detailjsonarr[l].trim();
          //         }
          //       }
          //     }
          //   }
          //   var detailjson = '{' + detailjson.replace(/After,Meal/g, 'After Meal').replace(/Normal,Test/g, 'Normal Test') + '}';
          //   if(responsedata[j].Alerts[k][i]['device_name'].trim() == "BPM" || responsedata[j].Alerts[k][i]['device_name'].trim() == "Oximeter" || responsedata[j].Alerts[k][i]['device_name'].trim() == "Weight" || responsedata[j].Alerts[k][i]['device_name'].trim() == "Blood Glucose" || responsedata[j].Alerts[k][i]['device_name'].trim() == "Thermometer") {
          //     responsedata[j].Alerts[k][i]['alert_setting'] = JSON.parse(settingjson);
          //     responsedata[j].Alerts[k][i]['detailed_value'] = JSON.parse(detailjson);
          //     if(responsedata[j].Alerts[k][i]['detailed_value']['weight']) {
          //       responsedata[j].Alerts[k][i]['detailed_value']['weight'] = Math.floor(responsedata[j].Alerts[k][i]['detailed_value']['weight'])+" lbs";
          //     }
          //     if(responsedata[j].Alerts[k][i]['detailed_value']['bmi']) {
          //       responsedata[j].Alerts[k][i]['detailed_value']['bmi'] = Math.floor(responsedata[j].Alerts[k][i]['detailed_value']['bmi']);
          //     }
          //   }
          //   if(responsedata[j].Alerts[k][i]['device_name'].trim() == "BPM") {
          //     responsedata[j].Alerts[k][i]['device_name_str'] = "BPM (SYS/DIA/Pulse)";
          //   }
          //   else if(responsedata[j].Alerts[k][i]['device_name'].trim() == "Oximeter") {
          //     responsedata[j].Alerts[k][i]['device_name_str'] = "Pulse Oximeter (Spo2%/PR)";
          //   }
          //   else if(responsedata[j].Alerts[k][i]['device_name'].trim() == "Weight") {
          //     responsedata[j].Alerts[k][i]['device_name_str'] = "Weight (lb./BMI)";
          //   }
          //   else if(responsedata[j].Alerts[k][i]['device_name'].trim() == "Blood Glucose") {
          //     responsedata[j].Alerts[k][i]['device_name_str'] = "Blood Glucose in mg/dL (Mode)";
          //   }
          //   else if(responsedata[j].Alerts[k][i]['device_name'].trim() == "Thermometer") {
          //     responsedata[j].Alerts[k][i]['device_name_str'] = "Thermometer (Temp. in Farenheit)";
          //   }
          //   else {
          //     responsedata[j].Alerts[k][i]['device_name_str'] = responsedata[j].Alerts[k][i]['device_name'].trim();
          //   }
          // }
        }
        responsedata[j].TransmissionDate[k].Alerts = responsedata[j].Alerts[k];
      }
      if(responsedata[j].callStatus && responsedata[j].callStatus[k]) {
        responsedata[j].TransmissionDate[k].callStatus = responsedata[j].callStatus[k];
      }
      if(responsedata[j].DevicesUploadCount && responsedata[j].DevicesUploadCount[k]) {
        responsedata[j].TransmissionDate[k].DevicesUploadCount = responsedata[j].DevicesUploadCount[k].toString();
      }
      if(responsedata[j].DevicesUploads && responsedata[j].DevicesUploads[k]) {
        responsedata[j].TransmissionDate[k].DevicesUploads = responsedata[j].DevicesUploads[k];
      }
      if(responsedata[j].Devices && responsedata[j].Devices[k]) {
        responsedata[j].TransmissionDate[k].Devices = responsedata[j].Devices[k];
      }
      if(responsedata[j].InRange && responsedata[j].InRange[k]) {
        responsedata[j].TransmissionDate[k].InRange = responsedata[j].InRange[k].length;
      }
      if(responsedata[j].historyspecific && responsedata[j].historyspecific[k]) {
        responsedata[j].TransmissionDate[k].historyspecific = responsedata[j].historyspecific[k];
      }
      if(responsedata[j].UploadHistory && responsedata[j].UploadHistory[k]) {
        responsedata[j].devicesetting[k] = {};
        for (var i = 0; i < responsedata[j].UploadHistory[k].length; i++) {
          if(responsedata[j].UploadHistory[k][i]) {  // && responsedata[j].UploadHistory[k][i].length > 0
            responsedata[j].UploadHistory[k][i]['timestamp_new'] = this.formatDateCustom2(responsedata[j].UploadHistory[k][i]['timestamp'].replaceAll('-','/') + " UTC");
            responsedata[j].UploadHistory[k][i]['timestamp'] = this.formatDateCustom(responsedata[j].UploadHistory[k][i]['timestamp'].replaceAll('-','/') + " UTC");
            responsedata[j].UploadHistory[k][i]['extended_comments'] = "";
            if(responsedata[j].UploadHistory[k][i]['ext_alert_comments'] != null && responsedata[j].UploadHistory[k][i]['ext_alert_comments'] != ''){
              var commentarr = responsedata[j].UploadHistory[k][i]['ext_alert_comments'].trim().split(",");
              for(var m = 0; m < commentarr.length; m++) {
                // if(m==0){
                //   responsedata[j].UploadHistory[k][i]['extended_comments'] = this.interventiondataarr[commentarr[m]];
                // }
                // else {
                  responsedata[j].UploadHistory[k][i]['extended_comments'] = responsedata[j].UploadHistory[k][i]['extended_comments']+"<div>"+(Number(m)+1).toString()+". "+this.interventiondataarr[commentarr[m]]+"</div>";
                // }
              }
            }
            // var settingjsonarr = responsedata[j].UploadHistory[k][i]['alert_setting'].trim().replace(/ : /g, ':').replace(/ :/g, ':').replace(/: /g, ':').replace(/" /g, '"').replace(/ "/g, '"').split(" ");
            var settingjson = "";
            if(responsedata[j].UploadHistory[k][i]['alert_setting'] && responsedata[j].UploadHistory[k][i]['alert_setting'] != null) {
              if(isObject(responsedata[j].UploadHistory[k][i]['alert_setting'])){
                responsedata[j].UploadHistory[k][i]['alert_setting'] = JSON.stringify(responsedata[j].UploadHistory[k][i]['alert_setting']);
              }
              var settingjsonarr = (responsedata[j].UploadHistory[k][i]['alert_setting']).trim().replace(/{/g, '').replace(/}/g, '').split(" ");
              for(var l = 0; l < settingjsonarr.length; l++) {
                if(settingjsonarr[l].trim() != '') {
                  if(settingjson) {
                    if(settingjsonarr[l].trim() != ":" && settingjsonarr[l].trim().slice(-1) != '"') {
                      settingjson = settingjson + settingjsonarr[l].trim() + ',';
                    }
                    else {
                      settingjson = settingjson + settingjsonarr[l].trim();
                    }
                  }
                  else {
                    settingjson = settingjson + settingjsonarr[l].trim();
                  }
                }
              }
            }
            if(settingjson.slice(-1) == ",") {
              settingjson = settingjson.slice(0, -1);
            }
            settingjson = '{' + settingjson.replace(/After,Meal/g, 'After Meal').replace(/Normal,Test/g, 'Normal Test') + '}';
            var detailjson = "";
            if(responsedata[j].UploadHistory[k][i]['detailed_value']) {
              if(isObject(responsedata[j].UploadHistory[k][i]['detailed_value'])){
                responsedata[j].UploadHistory[k][i]['detailed_value'] = JSON.stringify(responsedata[j].UploadHistory[k][i]['detailed_value']);
              }
              var detailjsonarr = (responsedata[j].UploadHistory[k][i]['detailed_value']).toString().trim().replace(/ : /g, ':').replace(/ :/g, ':').replace(/: /g, ':').replace(/" /g, '"').replace(/ "/g, '"').replace(/{/g, '').replace(/}/g, '').split(" ");
              for(var l = 0; l < detailjsonarr.length; l++) {
                if(detailjsonarr[l].trim() != '') {
                  if(detailjson) {
                    detailjson = detailjson + ',' + detailjsonarr[l].trim();
                  }
                  else {
                    detailjson = detailjson + detailjsonarr[l].trim();
                  }
                }
              }
            }
            // console.log(detailjson);
            var detailjson = '{' + detailjson.replace(/After,Meal/g, 'After Meal').replace(/Normal,Test/g, 'Normal Test') + '}';
            if(responsedata[j].UploadHistory[k][i]['device_name'].trim() == "BPM" || responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Oximeter" || responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Weight" || responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Blood Glucose" || responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Thermometer") {
              responsedata[j].UploadHistory[k][i]['alert_setting'] = JSON.parse(settingjson);
              if(!(responsedata[j].UploadHistory[k][i]['device_name'].trim() in responsedata[j].devicesetting[k]) && settingjson != '{}') {
                responsedata[j].devicesetting[k][responsedata[j].UploadHistory[k][i]['device_name'].trim()] = JSON.parse(settingjson);
              }
              responsedata[j].UploadHistory[k][i]['detailed_value'] = JSON.parse(detailjson);
              if(responsedata[j].UploadHistory[k][i]['detailed_value']['weight']) {
                responsedata[j].UploadHistory[k][i]['detailed_value']['weight'] = Math.floor(responsedata[j].UploadHistory[k][i]['detailed_value']['weight'])+" lbs";
              }
              if(responsedata[j].UploadHistory[k][i]['detailed_value']['bmi']) {
                responsedata[j].UploadHistory[k][i]['detailed_value']['bmi'] = Math.floor(responsedata[j].UploadHistory[k][i]['detailed_value']['bmi']);
              }
            }
            if(responsedata[j].UploadHistory[k][i]['critical_alert'] && responsedata[j].UploadHistory[k][i]['critical_alert'] == 1) {
              responsedata[j].UploadHistory[k][i]['super_alert'] = 1;
            }
            if(responsedata[j].UploadHistory[k][i]['device_name'].trim() == "BPM") {
              responsedata[j].UploadHistory[k][i]['device_name_str'] = "BPM (SYS/DIA/Pulse)";
              // Attn Required : 210 => SYS =<70 120 => DIA =<30 120 => PR =<40
              // if(responsedata[j].UploadHistory[k][i]['detailed_value']['sysData'] >= 210 || responsedata[j].UploadHistory[k][i]['detailed_value']['sysData'] <= 70) {
              //   responsedata[j].UploadHistory[k][i]['super_alert'] = 1;
              // }
              // if(responsedata[j].UploadHistory[k][i]['detailed_value']['diaData'] >= 120 || responsedata[j].UploadHistory[k][i]['detailed_value']['diaData'] <= 30) {
              //   responsedata[j].UploadHistory[k][i]['super_alert'] = 1;
              // }
              // if(responsedata[j].UploadHistory[k][i]['detailed_value']['pulseData'] >= 120 || responsedata[j].UploadHistory[k][i]['detailed_value']['pulseData'] <= 40) {
              //   responsedata[j].UploadHistory[k][i]['super_alert'] = 1;
              // }
            }
            else if(responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Oximeter") {
              responsedata[j].UploadHistory[k][i]['device_name_str'] = "Pulse Oximeter (Spo2%/PR)";
            }
            else if(responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Weight") {
              responsedata[j].UploadHistory[k][i]['device_name_str'] = "Weight (lb./BMI)";
            }
            else if(responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Blood Glucose") {
              responsedata[j].UploadHistory[k][i]['device_name_str'] = "Blood Glucose in mg/dL (Mode)";
            }
            else if(responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Thermometer") {
              responsedata[j].UploadHistory[k][i]['device_name_str'] = "Thermometer (Temp. in Farenheit)";
            }
            else {
              responsedata[j].UploadHistory[k][i]['device_name_str'] = responsedata[j].UploadHistory[k][i]['device_name'].trim();
            }
            if(responsedata[j].UploadHistory[k][i]['reviewdetails'] && responsedata[j].UploadHistory[k][i]['reviewdetails'].length > 0) {
              for(var l = 0; l < responsedata[j].UploadHistory[k][i]['reviewdetails'].length; l++) {
                responsedata[j].UploadHistory[k][i]['reviewdetails'][l]['revon'] = this.formatTimeM(responsedata[j].UploadHistory[k][i]['reviewdetails'][l]['reviewedon'].replaceAll('-','/') + " UTC");
              }
            }
            if(responsedata[j].UploadHistory[k][i].symptoms && responsedata[j].UploadHistory[k][i].symptoms.length > 0) {
              for(var l = 0; l < responsedata[j].UploadHistory[k][i]['symptoms'].length; l++) {
                if(responsedata[j].UploadHistory[k][i]['symptoms'][l]['symptom']){
                  responsedata[j].UploadHistory[k][i].symptoms[l]['symptom'] = JSON.parse(responsedata[j].UploadHistory[k][i]['symptoms'][l]['symptom']);
                }
              }
            }
          }
        }
        responsedata[j].TransmissionDate[k].devicesetting = responsedata[j].devicesetting[k];
        responsedata[j].TransmissionDate[k].UploadHistory = responsedata[j].UploadHistory[k];

        //A1C
        let faData = [];
        let amData = [];
        let ntData = [];

        for(var UploadHis of responsedata[j].UploadHistory[k]){        
          if (UploadHis.device_name.trim() == "Blood Glucose") {
            if (UploadHis.detailed_value.bloodGlucose && UploadHis.detailed_value.type.trim() == 'Fasting' ){
              faData.push(UploadHis.detailed_value.bloodGlucose);
            }else if (UploadHis.detailed_value.bloodGlucose && UploadHis.detailed_value.type.trim() == 'After Meal' ){
              amData.push(UploadHis.detailed_value.bloodGlucose);
            }else if (UploadHis.detailed_value.bloodGlucose && UploadHis.detailed_value.type.trim() == 'Normal Test' ){
              ntData.push(UploadHis.detailed_value.bloodGlucose);
            }
          }
        }

        let faDatatotalval = 0;
        let faDataA1c = "";
        for (var str of faData){
          faDatatotalval += Number(str);
        }
        let faDataa1c = ((46.7 + (faDatatotalval/faData.length))/28.7);
        if (faDataa1c > 0){
          faDataA1c = faDataa1c.toFixed(2);
        }




        let amDatatotalval = 0;
        let amDataA1c = "";
        for (var str of amData){
          amDatatotalval += Number(str);
        }
        let amDataa1c = ((46.7 + (amDatatotalval/amData.length))/28.7);
        if (amDataa1c > 0){
          amDataA1c = amDataa1c.toFixed(2);
        }



        let ntDatatotalval = 0;
        let ntDataA1c = "";
        for (var str of ntData){
          ntDatatotalval += Number(str);
        }
        let ntDataa1c = ((46.7 + (ntDatatotalval/ntData.length))/28.7);
        if (ntDataa1c > 0){
          ntDataA1c = ntDataa1c.toFixed(2);
        }

        responsedata[j].TransmissionDate[k].A1C = {faData: faDataA1c, amData: amDataA1c, ntData: ntDataA1c};
      }
    }
    return responsedata;
  }

  allGraphUpdate = () =>{
    // Start ALL Graph

    let tempUploadHistory = []
    let tempdevice = []
    let tempSettingDevice = []
    if(this.userDetails.DevicesAlertSettings) {
      tempSettingDevice = Object.keys(this.userDetails.DevicesAlertSettings);
    }
    this.alertSettingDeviceCount = this.userDetails.getuserDevices.length;

    if(this.userDetails.UploadHistory) {
      for (var UploadHis of this.userDetails.UploadHistory){
        tempUploadHistory = tempUploadHistory.concat(UploadHis)
      }
    }

    for (var tempUploadHis of tempUploadHistory) {
      tempdevice.push(tempUploadHis.device_name.trim())
    }
    
    let unique = tempdevice.filter((v, i, a) => a.indexOf(v) === i);

    var allGraphDevice = unique.filter(function(v) {
      return tempSettingDevice.indexOf(v) > -1;
    })

    this.allGraphDevice = allGraphDevice;
    this.allUploadHistory = tempUploadHistory;
  }

  syncTransmission = (subDetails) => {
    var confirmMsg = "Do you want to sync data transmission of this user?"
    if(confirm(confirmMsg)) {
      var patientListNow = [subDetails];
      console.log(patientListNow);
      var patientlength = patientListNow.length;
      let syncDetailsModal = document.getElementById("sync-details-ankerTag") as HTMLElement;
      syncDetailsModal.click();
      this.htmlToAdd = "<div><b>Syncing process started...</b></div>";
      this.htmlToAdd += "<div><b>Please wait for the 'Close' button at the end...</b></div>";
      this.syncStatus = "Running";
      var counter = 0;
      for(var k = 0; k < patientListNow.length; k++) {
        var patientDetail = patientListNow[k];
        let params = {
          userpull_id: localStorage.getItem('userpull_id'),
          sub: patientDetail['sub'],
          subname: patientDetail['firstName']+" "+patientDetail['lastName'],
          userauth: localStorage.getItem('AUTH-TOKEN')
        };
        this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/synctransmission', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
          .subscribe((response: any) => {
            counter++;
            this.htmlToAdd += '<div>';
            this.htmlToAdd += '<b>'+counter+' out of '+patientlength+'.</b>';
            if(response.status == "success") {
              this.htmlToAdd += '<span class="success" style="color:#3a7b3c;"> Transmission syncing successful for '+response.subname+'</span>';
            }
            else {
              this.htmlToAdd += '<span class="error" style="color:#f00;"> Could not sync transmission for '+response.subname+'</span>';
            }
            this.htmlToAdd += "</div>";
            if(counter == patientlength) {
              this.htmlToAdd += '<div><b>Syncing process completed...</b></div>';
              this.syncStatus = "Complete";
            }
        });
      }
    }
  }

  syncIOTTransmission = (subDetails) => {
    var confirmMsg = "Do you want to sync data transmission of this user?"
    if(confirm(confirmMsg)) {
      var patientListNow = [subDetails];
      console.log(patientListNow);
      var patientlength = patientListNow.length;
      let syncDetailsModal = document.getElementById("sync-details-ankerTag") as HTMLElement;
      syncDetailsModal.click();
      this.htmlToAdd = "<div><b>Syncing process started...</b></div>";
      this.htmlToAdd += "<div><b>Please wait for the 'Close' button at the end...</b></div>";
      this.syncStatus = "Running";
      var counter = 0;
      var successcounter = 0;
      var errorcounter = 0;
      for(var k = 0; k < patientListNow.length; k++) {
        var patientDetail = patientListNow[k];
        let params = {
          userpull_id: localStorage.getItem('userpull_id'),
          sub: patientDetail['sub'],
          userauth: localStorage.getItem('AUTH-TOKEN')
        };
        this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/iotsync', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
          .subscribe((response: any) => {
            counter++;
            this.htmlToAdd += '<div>';
            this.htmlToAdd += '<b>'+counter+' out of '+patientlength+'.</b>';
            if(response.status == "success") {
              successcounter++;
              // this.htmlToAdd += '<span class="success" style="color:#3a7b3c;"> Transmission syncing successful for '+response.subname+'</span>';
            }
            else {
              errorcounter++;
              // this.htmlToAdd += '<span class="error" style="color:#f00;"> Could not sync transmission for '+response.subname+'</span>';
            }
            this.htmlToAdd += "</div>";
            this.htmlToAdd += '<div><b>Success:</b> '+successcounter+', <b>Error:</b> '+errorcounter;
            if(counter == patientlength) {
              this.htmlToAdd += '<div><b>Syncing process completed...</b></div>';
              this.syncStatus = "Complete";
            }
        });
      }
    }
  }

  validatePatientUpdateForm= ( patientData ) =>{
    var tempmodules = [];
    if(this.module_RPM){
      tempmodules.push('RPM')
    }
    if(this.module_CCM){
      tempmodules.push('CCM')
    }
    if(this.module_TCM){
      tempmodules.push('TCM')
    }
    if(this.module_APT){
      tempmodules.push('APT')
    }

    delete patientData['module_RPM'];
    delete patientData['module_CCM'];
    delete patientData['module_TCM'];
    delete patientData['module_APT'];

    patientData['user_primary_diagnosis'] = "";
    if(patientData['user_primary_diagnosis_arr']) {
      patientData['user_primary_diagnosis'] = patientData['user_primary_diagnosis_arr'].join();
    }
    delete patientData['user_primary_diagnosis_arr'];
    let PatientClass = this;
    PatientClass.screen.style.display = 'block';

    // if( !patientData.consentdate || typeof patientData.consentdate === 'undefined' || patientData.consentdate==''){
    //   this.consentdateInvalid   = true;
    //   this.consentdateError     = 'Choose Consent Date.'; 
    //   PatientClass.screen.style.display = 'none';
    //   return false;
    // }else{
    //   this.consentdateInvalid   = false;
    //   this.consentdateError    = ''; 
    // }
    // if( !patientData.edudate || typeof patientData.edudate === 'undefined' || patientData.edudate==''){
    //   this.edudateInvalid   = true;
    //   this.edudateError     = 'Choose Patient Education Date.'; 
    //   PatientClass.screen.style.display = 'none';
    //   return false;
    // }else{
    //   this.edudateInvalid   = false;
    //   this.edudateError    = ''; 
    // }
    // if( !patientData.transdate || typeof patientData.transdate === 'undefined' || patientData.transdate==''){
    //   this.transdateInvalid   = true;
    //   this.transdateError     = 'Choose Transmission Date.'; 
    //   PatientClass.screen.style.display = 'none';
    //   return false;
    // }else{
    //   this.transdateInvalid   = false;
    //   this.transdateError    = ''; 
    // }
    
    let nameAlphabets = /^[A-Za-z]+$/;
    let nameAlphabetsNumeric = /^[A-Za-z0-9 ]+$/;

    if(!patientData.given_name || typeof patientData.given_name === 'undefined' || !patientData.given_name.match(nameAlphabetsNumeric)){
      this.givenNameInvalid = true;
      this.givenNameError   = 'Invalid First name.';
      PatientClass.screen.style.display = 'none';
      return false;
    }else{
       this.givenNameInvalid = false;
       this.givenNameError   = '';
    }

    if(!patientData.family_name || typeof patientData.family_name === 'undefined' || !patientData.family_name.match(nameAlphabetsNumeric)){
      this.familyNameInvalid = true;
      this.familyNameError   = 'Invalid Last name.';
      PatientClass.screen.style.display = 'none';
      return false;
    }else{
     this.familyNameInvalid = false;
     this.familyNameError   = '';
    }
    
    let phone_numberPattern = /^[+]\d+$/;                ///// Checking for +Number /////
    if(!patientData.phone_number || typeof patientData.phone_number === 'undefined' || !patientData.phone_number.match(phone_numberPattern)){
      this.phoneNumberInvalid = true;
      this.phoneNumberError   = 'Invalid Phone number.';
      PatientClass.screen.style.display = 'none';
      return false;
    }else{
      this.phoneNumberInvalid = false;
      this.phoneNumberError   = '';
    }

    let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if(patientData['custom-phoneNo2'] && patientData['custom-phoneNo2'] != '' && !patientData['custom-phoneNo2'].match(phone_numberPattern) && !patientData['custom-phoneNo2'].match(emailPattern)){
      this.facetimeInvalid = true;
      this.facetimeError   = 'Invalid FaceTime Phone number.';
      PatientClass.screen.style.display = 'none';
      return false;
    }else{
      this.facetimeInvalid = false;
      this.facetimeError   = '';
    }
    
    if( !patientData.birthdate || typeof patientData.birthdate === 'undefined' || patientData.birthdate==''){
      this.birthdateInvalid   = true;
      this.birthdateError     = 'Choose Birthdate.'; 
      PatientClass.screen.style.display = 'none';
      return false;
    }else{
      this.birthdateInvalid   = false;
      this.birthdateError    = ''; 
    }

    if(!patientData.gender || typeof patientData.gender === 'undefined'){
      this.genderInvalid     = true;
      this.genderError       = 'Select Gender.';
      PatientClass.screen.style.display = 'none';
      return false;
    }else{
      this.genderInvalid      = false;
      this.genderError        = '';
    }

    if(!patientData['custom-height-feet'] || typeof patientData['custom-height-feet'] === 'undefined' || patientData['custom-height-feet']=="" || patientData['custom-height-feet']<1){
      this.heightInvalid     = true;
      this.heightError       = 'Invalid Height.';
      PatientClass.screen.style.display = 'none';
      return false;
    }else{
      this.heightInvalid     = false;
      this.heightError       = '';
    }
    if(typeof patientData['custom-height-inches'] === 'undefined' || Number(patientData['custom-height-inches'])<0){
      this.heightInvalid     = true;
      this.heightError       = 'Invalid Height.';
      PatientClass.screen.style.display = 'none';
      return false;
    }else{
      this.heightInvalid     = false;
      this.heightError       = '';
    }

    if(!patientData.address || typeof patientData.address === 'undefined' || patientData.address==''){
      this.addressInvalid     = true;
      this.addressError       = 'Invalid Address.';
      PatientClass.screen.style.display = 'none';
      return false;
    }else{
      this.addressInvalid     = false;
      this.addressError       = '';
    }

    if(patientData['custom-phoneNoSms'] && patientData['custom-phoneNoSms'] != '' && !patientData['custom-phoneNoSms'].match(phone_numberPattern)){
      this.smsNumberInvalid = true;
      this.smsNumberError   = 'Invalid SMS Phone number.';
      PatientClass.screen.style.display = 'none';
      return false;
    }else{
      this.smsNumberInvalid = false;
      this.smsNumberError   = '';
    }

    if(!patientData['custom-p_timezone'] || typeof patientData['custom-p_timezone'] === 'undefined'){
      this.timezoneInvalid     = true;
      this.timezoneError       = 'Select Timezone.';
      PatientClass.screen.style.display = 'none';
      return false;
    }else{
      this.timezoneInvalid      = false;
      this.timezoneError        = '';
    }

    if(!patientData['custom-p_language'] || typeof patientData['custom-p_language'] === 'undefined'){
      this.languageInvalid     = true;
      this.languageError       = 'Select Language.';
      PatientClass.screen.style.display = 'none';
      return false;
    }else{
      this.languageInvalid      = false;
      this.languageError        = '';
    }

    let userData = [];
    let custom_height = 0;
    let sms_allowed = 0;
    for(let k in patientData){
      let Key = k.replace("-", ":");
      let key_value = (patientData[k])?patientData[k].toString():'';
      if(Key == "birthdate"){
        let date_value = patientData['birthdate'];
        let con_date = date_value.split("-");
        //let Date_format = con_date[2]+'-'+con_date[1]+'-'+con_date[0];
        let Date_format = con_date[1]+'-'+con_date[2]+'-'+con_date[0];
        userData.push({Name:Key,Value:Date_format});
      }
      else if(Key == "consentdate") {
        let date_value = patientData['consentdate'];
        let formatted_date = "";
        if(date_value!="") {
          let con_date = date_value.split("-");
          let Date_format = con_date[1]+'/'+con_date[2]+'/'+con_date[0]+' 00:00:00';
          formatted_date = this.formatUTCDateCustom(Date_format);
          userData.push({Name:"custom:consent",Value:"1"})
        }
        else {
          userData.push({Name:"custom:consent",Value:"0"})
        }
        userData.push({Name:"custom:consentDate",Value:formatted_date})
      }	
      else if(Key == "edudate") {
        let date_value = patientData['edudate'];
        let formatted_date = "";
        if(date_value!="") {
          let con_date = date_value.split("-");
          let Date_format = con_date[1]+'/'+con_date[2]+'/'+con_date[0]+' 00:00:00';
          formatted_date = this.formatUTCDateCustom(Date_format);
        }
        userData.push({Name:"custom:deviceEducationDate",Value:formatted_date})
      }
      else if(Key == "transdate") {
        let date_value = patientData['transdate'];
        let formatted_date = "";
        if(date_value!="") {
          let con_date = date_value.split("-");
          let Date_format = con_date[1]+'/'+con_date[2]+'/'+con_date[0]+' 00:00:00';
          formatted_date = this.formatUTCDateCustom(Date_format);
        }
        userData.push({Name:"custom:dataTransmissionDate",Value:formatted_date})
      }
      else if(Key == "custom:height-feet" || Key == "custom:height-inches") {
        if(Key == "custom:height-feet") {
          custom_height = custom_height + (Number(key_value)*12);
        }
        else {
          custom_height = custom_height + (Number(key_value));
        }
      }
      else if(Key == "custom:isSmsAllowed") {
        sms_allowed = Number(key_value);
      }
      else if(Key == "facility") {
        let facility_id = Number(key_value);
        userData.push({Name:Key,Value:facility_id.toString()});
      }
      else{
        userData.push({Name:Key,Value:key_value});
      }
    }
    userData.push({Name:"custom:height",Value: custom_height.toString()});
    userData.push({Name:"custom:isSmsAllowed",Value: sms_allowed.toString()});
    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      group: "Patient",
      sub: this.subId
    };
    
    for(var k = 0; k < userData.length; k++) {
      params[userData[k]['Name']] = userData[k]['Value'];
    }

    params['modules'] = tempmodules;

    console.log("yyyyyyyy", params);
    // return
    
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updateuser', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        if(response.status == "success") {
          alert("Patient profile updated successfully");
          location.reload();
        }
        else {
          PatientClass.screen.style.display = 'none';
          alert("Patient profile could not be updated");
        }
      },
      async (error: any) => {
        console.log(error);
        PatientClass.screen.style.display = 'none';
        alert("Patient profile could not be updated. Please try again.");
      });

  }

  unassignProvider = (data, provider, providername) => {
    console.log(data)
    console.log(provider)
    console.log(providername)

    var allconfirmation = false;

    if(provider != 'all' && provider == data.reporting_provider){
      alert("This is reporting Provider not unenroll from patient");
    }else{
      if(confirm("Are you sure you would like to unenroll "+data.firstName+" "+data.lastName+" from "+providername+"?")) {
        allconfirmation = true;
        if((provider == 'all') || (data.AssignProviderList.length == 1)){
          allconfirmation = false;
          if(confirm("Please note, this will make the patient inactive and transmission cycle will be recalculated from the next reading. Do you want to continue?")) {
            allconfirmation = true;
          }
        }      
      }
    }

    

    if(allconfirmation == true){
      console.log("all ok api calll");
      let unassignClass = this;
        unassignClass.screen.style.display = 'block';
        let params = {
          userpull_id: localStorage.getItem('userpull_id'),
          sub: data.sub,
          provider: provider
        };
        this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/unenrollprovider', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          if(response.status == "success") {
            alert("Provider unenrolled successfully");
            location.reload();
          }
          else {
            alert("Could not unenroll provider");
          }
          unassignClass.screen.style.display = 'none';
        },
        async (error: any) => {
          console.log(error);
          alert("Could not unenroll provider");
          unassignClass.screen.style.display = 'none';
          location.reload();
        });
    }
  }

  Reporting_provider = "";


  RereportingProviderChange = ( user, sub, name ) =>{
    console.log("RereportingProviderChange")
    console.log(this.Reporting_provider)
    console.log(sub)
    console.log(name)

    console.log(this.userDetails)

    if(confirm("Are you sure you would like to "+user.firstName+" "+user.lastName+"'s Reporting Provider "+name+"?")) {
      this.screen.style.display = 'block';
      let params = {
        userpull_id: localStorage.getItem('userpull_id'),
        group: "Patient",
        sub: this.userDetails.sub,
        given_name: this.userDetails.firstName,
        reporting_provider: sub
      };
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updateuser', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          if(response.status == "success") {
            alert("Reporting Provider updated");
            location.reload();
          }
          else {
            alert("Reporting Provider could not be updated. Please try again.");
            location.reload();
          }
          this.screen.style.display = 'none';
        },
        async (error: any) => {
          console.log(error);
          alert("Reporting Provider could not be updated. Please try again.");
          this.screen.style.display = 'none';
        });
    }else{
      setTimeout (() => {
        this.Reporting_provider = this.userDetails.reporting_provider;
      }, 500);
    }

    
  }

  validateAssignPatientForm = ( assignData ) =>{
    if(!assignData.provider) {
      alert("Please choose Provider");
      return false;
    }
    if(assignData.provider.length == 0) {
      alert("Please choose atleast one Provider");
      return false;
    }
    let providerlist = "";
    for(let i=0; i<assignData.provider.length; i++){
      if(providerlist) {
        providerlist = providerlist + "," + assignData.provider[i];
      }
      else {
        providerlist = providerlist + assignData.provider[i];
      }
    }
    let assignClass = this;
    assignClass.screen.style.display = 'block';
    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      sub: assignData.sub,
      provider: providerlist
    };
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/enrollprovider', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        if(response.status == "success") {
          alert("Provider assigned successfully");
          location.reload();
        }
        else {
          alert("Could not assign provider");
        }
        assignClass.screen.style.display = 'none';
      },
      async (error: any) => {
        console.log(error);
        alert("Could not assign provider");
        assignClass.screen.style.display = 'none';
        location.reload();
      });
  }

  validateMRNForm = ( mrnData ) =>{
    let MrnClass = this;
    // if(mrnData.mrn) {
      MrnClass.screen.style.display = 'block';
      let params = {
        userpull_id: localStorage.getItem('userpull_id'),
        group: "Patient",
        sub: (mrnData.sub)?mrnData.sub:'',
        "custom:mrn1": mrnData.mrn
      };
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updateuser', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          if(response.status == "success") {
            alert("MRN2 updated");
          }
          else {
            alert("MRN2 could not be updated. Please try again.");
            location.reload();
          }
          MrnClass.screen.style.display = 'none';
        },
        async (error: any) => {
          console.log(error);
          alert("MRN2 could not be updated. Please try again.");
          MrnClass.screen.style.display = 'none';
        });
    // }
    // else {
    //   alert("Please provide MRN");
    // }
  }

  validatePasswordForm = ( passwordData ) =>{
    let PasswordClass = this;
    PasswordClass.formInvalids = false;
    PasswordClass.formInvalidMsgs = false;
    if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(passwordData.new_password)) {
      if(passwordData.new_password == passwordData.confirm_password) {
        PasswordClass.screen.style.display = 'block';
    
        // let params = new HttpParams({
        //   fromObject: {
        //     userpull_id: localStorage.getItem('userpull_id'),
        //     sub: passwordData.sub,
        //     password: passwordData.new_password
        //   }
        // });
        let params = {
          userpull_id: localStorage.getItem('userpull_id'),
          sub: passwordData.sub,
          password: passwordData.new_password
        };
        this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/changepassword', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          if(response.status == "success") {
            alert("Password changed successfully.");
            location.reload();
          }
          else {
            alert("Password could not be changed. Please try again.");
          }
          PasswordClass.screen.style.display = 'none';
        },
        async (error: any) => {
          Auth.signOut();
                  localStorage.removeItem('AUTH');
                  localStorage.removeItem('AUTH-LIFE');
                  localStorage.removeItem('AUTH-PROFILE-PIC');
                  localStorage.removeItem('USER-DETAILS');
                  PasswordClass.screen.style.display = 'none';
                  PasswordClass.router.navigateByUrl('/sign-in');
        });
      }
      else {
        PasswordClass.formInvalids = true;
        PasswordClass.formInvalidMsgs = false;
      }
    }
    else {
      PasswordClass.formInvalidMsgs = true;
      PasswordClass.formInvalids = false;
    }
  }

  reviewtemplateChange = (temptype) => {
    console.log(temptype);
    if (temptype == 0){
      this.reviewtext = "";
    }
    else {
      for(var temp of this.reviewtemplatearr){
        if (temp.id == temptype){
          this.reviewtext = temp.template_text;
          break;
        }
      }
    }
  }


  validateAddCarePlanForm = ( formData ) =>{
    console.log("validateAddCarePlanForm");
    console.log(formData);
    if(formData.patient_sub == undefined || formData.patient_sub.trim() == "") {
      alert("Some error occurred. Please refresh the page and try again.");
      return false;
    }
    if(formData.medication_adherence == undefined || formData.medication_adherence.trim() == "") {
      alert("Please Enter Medication Adherence.");
      return false;
    }
    if(formData.lifestyle_modification == undefined || formData.lifestyle_modification.trim() == "") {
      alert("Please Enter Lifestyle Modification.");
      return false;
    }
    if(formData.care_coordination == undefined || formData.care_coordination.trim() == "") {
      alert("Please Enter Care Coordination.");
      return false;
    }
    if(formData.technical_issue == undefined || formData.technical_issue.trim() == "") {
      alert("Please Enter Technical Issue.");
      return false;
    }
    if(formData.feedback == undefined || formData.feedback.trim() == "") {
      alert("Please Enter Feedback.");
      return false;
    }
    if(formData.outcome == undefined || formData.outcome.trim() == "") {
      alert("Please Enter Outcome.");
      return false;
    }
    if(formData.next_review_scheduled == undefined || formData.next_review_scheduled.trim() == "") {
      alert("Please Enter Next Review Scheduled.");
      return false;
    }
    if(formData.evaluation_to_be_measured == undefined || formData.evaluation_to_be_measured.trim() == "") {
      alert("Please Enter Evaluation to be measured.");
      return false;
    }

    let params = {};
    params = {
      userpull_id: localStorage.getItem('userpull_id'),
      user_sub: localStorage.getItem('user_sub'),
      user_group: this.userGroup,
      patient_sub: formData.patient_sub,
      medication_adherence: formData.medication_adherence,
      lifestyle_modification: formData.lifestyle_modification,
      care_coordination: formData.care_coordination,
      technical_issue: formData.technical_issue,
      feedback: formData.feedback,
      outcome: formData.outcome,
      next_review_scheduled: formData.next_review_scheduled,
      evaluation_to_be_measured: formData.evaluation_to_be_measured
    };

    this.screen.style.display = 'block';
    
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addcareplan', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      if(response.status == "success") {
        alert("Careplan added successfully");
        location.reload();
      }
      else if(response.message){
        alert(response.message);
      }
      else {
        alert("Could not add Careplan. Please try again.");
      }
      this.screen.style.display = 'none';
    },
    async (error: any) => {
      console.log(error);
      alert("Could not add Careplan. Please try again.");
      this.screen.style.display = 'none';
    });

  }

  careplanEditDetails : any;

  CarePlanEditButtonClick = (careplan = "") =>{
    console.log(careplan);
    this.careplanEditDetails = "";
    let editRevModal = document.getElementById("edit-careplan-ankerTag") as HTMLElement;
    editRevModal.click();

    setTimeout (() => {
      let careplanDataFinal = {};
      careplanDataFinal['patient_sub'] = careplan['patient_sub'];
      careplanDataFinal['id'] = careplan['id'];

      careplanDataFinal['care_coordination'] = careplan['care_coordination'];
      careplanDataFinal['evaluation_to_be_measured'] = careplan['evaluation_to_be_measured'];
      careplanDataFinal['feedback'] = careplan['feedback'];
      careplanDataFinal['lifestyle_modification'] = careplan['lifestyle_modification'];
      careplanDataFinal['medication_adherence'] = careplan['medication_adherence'];
      careplanDataFinal['next_review_scheduled'] = careplan['next_review_scheduled'];
      careplanDataFinal['outcome'] = careplan['outcome'];
      careplanDataFinal['technical_issue'] = careplan['technical_issue'];

      this.careplanEditDetails = careplanDataFinal;
    }, 500);
  }

  validateEditCareplanForm = (formData) =>{
    console.log("validateEditCareplanForm");
    console.log(formData);

    if(formData.patient_sub == undefined || formData.patient_sub.trim() == "") {
      alert("Some error occurred. Please refresh the page and try again.");
      return false;
    }
    if(formData.medication_adherence == undefined || formData.medication_adherence.trim() == "") {
      alert("Please Enter Medication Adherence.");
      return false;
    }
    if(formData.lifestyle_modification == undefined || formData.lifestyle_modification.trim() == "") {
      alert("Please Enter Lifestyle Modification.");
      return false;
    }
    if(formData.care_coordination == undefined || formData.care_coordination.trim() == "") {
      alert("Please Enter Care Coordination.");
      return false;
    }
    if(formData.technical_issue == undefined || formData.technical_issue.trim() == "") {
      alert("Please Enter Technical Issue.");
      return false;
    }
    if(formData.feedback == undefined || formData.feedback.trim() == "") {
      alert("Please Enter Feedback.");
      return false;
    }
    if(formData.outcome == undefined || formData.outcome.trim() == "") {
      alert("Please Enter Outcome.");
      return false;
    }
    if(formData.next_review_scheduled == undefined || formData.next_review_scheduled.trim() == "") {
      alert("Please Enter Next Review Scheduled.");
      return false;
    }
    if(formData.evaluation_to_be_measured == undefined || formData.evaluation_to_be_measured.trim() == "") {
      alert("Please Enter Evaluation to be measured.");
      return false;
    }

    let params = {};
    params = {
      userpull_id: localStorage.getItem('userpull_id'),
      patient_sub: formData.patient_sub,
      id: formData.id,
      medication_adherence: formData.medication_adherence,
      lifestyle_modification: formData.lifestyle_modification,
      care_coordination: formData.care_coordination,
      technical_issue: formData.technical_issue,
      feedback: formData.feedback,
      outcome: formData.outcome,
      next_review_scheduled: formData.next_review_scheduled,
      evaluation_to_be_measured: formData.evaluation_to_be_measured
    };

    this.screen.style.display = 'block';
    
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/editcareplan', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      if(response.status == "success") {
        alert("Careplan edit successfully");
        location.reload();
      }
      else if(response.message){
        alert(response.message);
      }
      else {
        alert("Could not edit Careplan. Please try again.");
      }
      this.screen.style.display = 'none';
    },
    async (error: any) => {
      console.log(error);
      alert("Could not edit Careplan. Please try again.");
      this.screen.style.display = 'none';
    });
  }

  CarePlanDeleteButtonClick = (patientcareplan) =>{
    console.log("CarePlanDeleteButtonClick");
    console.log(patientcareplan);
    if(confirm("Do you want to delete this month Careplan?")) {
      this.screen.style.display = 'block';
      let params = {
        userpull_id: localStorage.getItem('userpull_id'),
        patient_sub: patientcareplan['patient_sub'],
        id: patientcareplan['id']
      };
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/deletecareplan', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          if(response.status == "success") {
            alert("Careplan deleted successfully");
            location.reload();
          }
          else if(response.message){
            alert(response.message);
          }
          else {
            alert("Could not delete Careplan");
          }
          this.screen.style.display = 'none';
        },
        async (error: any) => {
          console.log(error);
          alert("Could not delete Careplan");
          this.screen.style.display = 'none';
        });
    }
  }

  validateAddReviewForm = ( addreviewData ) =>{
    // console.log(addreviewData);
    let symptomsarr = ['fatigue','urine','swell','nausea','appetite','taste','concentrate','faint','cold','pain','breate','palpitaion','fever','others'];
    let symptoms = {}
    for(var symptomtype of symptomsarr) {
      if(symptomtype in addreviewData) {
        if(addreviewData[symptomtype]){
          symptoms[symptomtype] = addreviewData[symptomtype];
        }
      }
    }
    // console.log(symptoms);
    // return false;
    var interventionData = "";
    if(addreviewData.patient_sub == undefined || addreviewData.patient_sub.trim() == "") {
      alert("Some error occurred. Please refresh the page and try again.");
      return false;
    }
    if(addreviewData.provider_sub == undefined || addreviewData.provider_sub.trim() == "") {
      alert("Please choose Provider.");
      return false;
    }
    if(addreviewData.mode == undefined || addreviewData.mode.trim() == "") {
      alert("Please choose mode.");
      return false;
    }
    /*if(addreviewData.duration == undefined || addreviewData.duration == "") {
      alert("Please provide duration.");
      return false;
    }
    if(addreviewData.duration < 1) {
      alert("Duration must be greater or equal to 1 minute.");
      return false;
    }*/
    if(addreviewData.durationmin < 0) {
      alert("Duration minute must not be less than 0");
      return false;
    }
    if(addreviewData.durationmin != null && addreviewData.durationmin % 1 != 0) {
      alert("Duration minute should not be decimal number");
      return false;
    }
    if(addreviewData.durationsec < 0) {
      alert("Duration second must not be less than 0");
      return false;
    }
    if(addreviewData.durationsec != null && addreviewData.durationsec % 1 != 0) {
      alert("Duration second should not be decimal number");
      return false;
    }
    if(addreviewData.durationsec >= 60) {
      alert("Duration second must not be greater or equal to 1 minute.");
      return false;
    }
    var dursec = "";
    if(addreviewData.durationsec != null) {
      dursec = addreviewData.durationsec;
      if((String(addreviewData.durationsec).length) == 1) {
        dursec = "0"+dursec;
      }
    }
    var durmin = "";
    if(addreviewData.durationmin != null) {
      if(dursec == "") {
        durmin = addreviewData.durationmin+".00";
      }
      else {
        durmin = addreviewData.durationmin+"."+dursec;
      }
    }
    else if(dursec != ""){
      durmin = "0."+dursec;
    }
    addreviewData.duration = Number(durmin);
    if(addreviewData.reviewtime == undefined || addreviewData.reviewtime == "") {
      alert("Please provide Review Time.");
      return false;
    }
    if(addreviewData.review == undefined || addreviewData.review.trim() == "") {
      alert("Please provide review text.");
      return false;
    }
    var reviewDataArr = addreviewData.reviewtime.split("-");
    if(reviewDataArr.length != 3) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(reviewDataArr[0].length != 4) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(reviewDataArr[0] < 1900 || reviewDataArr[0] > (new Date()).getFullYear()) {
      alert("Please provide Valid Time, not more than current year");
    }
    else if(reviewDataArr[1] > 12) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(reviewDataArr[0] == (new Date()).getFullYear() && reviewDataArr[1] > new Date().getMonth() + 1){
      alert("Please provide Valid Time, not more than current month and year");
    }
    else if(reviewDataArr[2].split("T")[0] > 31) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(reviewDataArr[2].split("T")[1] && reviewDataArr[2].split("T")[1].split(":")[0] && reviewDataArr[2].split("T")[1].split(":")[0] > 23) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(reviewDataArr[2].split("T")[1] && reviewDataArr[2].split("T")[1].split(":")[1] && reviewDataArr[2].split("T")[1].split(":")[1] > 59) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else {
      if(addreviewData.intervention && addreviewData.intervention.length > 0){
        interventionData = addreviewData.intervention.join();
      }
      sessionStorage.setItem('Review_Mode', addreviewData.mode);
      sessionStorage.setItem('Review_Provider', addreviewData.provider_sub);
      let addreviewClass = this;
      addreviewClass.screen.style.display = 'block';
      let params = {};
      params = {
        userpull_id: localStorage.getItem('userpull_id'),
        user_sub: localStorage.getItem('user_sub'),
        user_group: this.userGroup,
        patient_sub: addreviewData.patient_sub,
        provider_sub: addreviewData.provider_sub,
        mode: addreviewData.mode,
        duration: (addreviewData.duration)?addreviewData.duration:'',
        intervention_ids: interventionData,
        // month: addreviewData.forreviewmonth.split("-")[1],
        // year: addreviewData.forreviewmonth.split("-")[0],
        timestamp: this.formatUTCDBDateTime(addreviewData.reviewtime),
        review: addreviewData.review,
        userauth: localStorage.getItem('AUTH-TOKEN'),
        symptoms: JSON.stringify(symptoms),
        escalated_intervention: addreviewData.escalated_intervention
      };
      if(this.reviewTransmission) {
        params['transmission_id'] = this.reviewTransmission;
      }
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addrpmreview', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        if(response.status == "success") {
          alert("Review added successfully");
          location.reload();
        }
        else if(response.message){
          alert(response.message);
        }
        else {
          alert("Could not add review. Please try again.");
        }
        addreviewClass.screen.style.display = 'none';
      },
      async (error: any) => {
        console.log(error);
        alert("Could not add review. Please try again.");
        addreviewClass.screen.style.display = 'none';
      });
    }
  }

  validateEditReviewForm = ( addreviewData ) =>{
    if(addreviewData.rpmreview_id == undefined || addreviewData.rpmreview_id == "") {
      alert("Some error occurred. Please reload the page and try again.");
      return false;
    }
    if(addreviewData.patient_sub == undefined || addreviewData.patient_sub.trim() == "") {
      alert("Some error occurred. Please refresh the page and try again.");
      return false;
    }
    if(addreviewData.provider_sub == undefined || addreviewData.provider_sub.trim() == "") {
      alert("Please choose Provider.");
      return false;
    }
    if(addreviewData.mode == undefined || addreviewData.mode.trim() == "") {
      alert("Please choose mode.");
      return false;
    }
    /*if(addreviewData.duration == undefined || addreviewData.duration == "") {
      alert("Please provide duration.");
      return false;
    }
    if(addreviewData.duration < 1) {
      alert("Duration must be greater or equal to 1 minute.");
      return false;
    }*/
    if(addreviewData.durationmin < 0) {
      alert("Duration minute must not be less than 0");
      return false;
    }
    if(addreviewData.durationmin != null && addreviewData.durationmin % 1 != 0) {
      alert("Duration minute should not be decimal number");
      return false;
    }
    if(addreviewData.durationsec < 0) {
      alert("Duration second must not be less than 0");
      return false;
    }
    if(addreviewData.durationsec != null && addreviewData.durationsec % 1 != 0) {
      alert("Duration second should not be decimal number");
      return false;
    }
    if(addreviewData.durationsec >= 60) {
      alert("Duration second must not be greater or equal to 1 minute.");
      return false;
    }
    var dursec = "";
    if(addreviewData.durationsec != null) {
      dursec = addreviewData.durationsec;
      if((String(addreviewData.durationsec).length) == 1) {
        dursec = "0"+dursec;
      }
    }
    var durmin = "";
    if(addreviewData.durationmin != null) {
      if(dursec == "") {
        durmin = addreviewData.durationmin+".00";
      }
      else {
        durmin = addreviewData.durationmin+"."+dursec;
      }
    }
    else if(dursec != ""){
      durmin = "0."+dursec;
    }
    addreviewData.duration = Number(durmin);
    if(addreviewData.reviewtime == undefined || addreviewData.reviewtime == "") {
      alert("Please provide Review Time.");
      return false;
    }
    if(addreviewData.review == undefined || addreviewData.review.trim() == "") {
      alert("Please provide review text.");
      return false;
    }
    var reviewDataArr = addreviewData.reviewtime.split("-");
    if(reviewDataArr.length != 3) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(reviewDataArr[0].length != 4) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(reviewDataArr[0] < 1900 || reviewDataArr[0] > (new Date()).getFullYear()) {
      alert("Please provide Valid Time, not more than current year");
    }
    else if(reviewDataArr[1] > 12) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(reviewDataArr[0] == (new Date()).getFullYear() && reviewDataArr[1] > new Date().getMonth() + 1){
      alert("Please provide Valid Time, not more than current month and year");
    }
    else if(reviewDataArr[2].split("T")[0] > 31) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(reviewDataArr[2].split("T")[1] && reviewDataArr[2].split("T")[1].split(":")[0] && reviewDataArr[2].split("T")[1].split(":")[0] > 23) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(reviewDataArr[2].split("T")[1] && reviewDataArr[2].split("T")[1].split(":")[1] && reviewDataArr[2].split("T")[1].split(":")[1] > 59) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else {
      let addreviewClass = this;
      addreviewClass.screen.style.display = 'block';
      let params = {
        userpull_id: localStorage.getItem('userpull_id'),
        user_sub: localStorage.getItem('user_sub'),
        user_group: this.userGroup,
        patient_sub: addreviewData.patient_sub,
        provider_sub: addreviewData.provider_sub,
        mode: addreviewData.mode,
        duration: (addreviewData.duration)?addreviewData.duration:'',
        // month: addreviewData.forreviewmonth.split("-")[1],
        // year: addreviewData.forreviewmonth.split("-")[0],
        timestamp: this.formatUTCDBDateTime(addreviewData.reviewtime),
        rpmreview_id: addreviewData.rpmreview_id,
        review: addreviewData.review,
        escalated_intervention: addreviewData.escalated_intervention
      };
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addreview', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        if(response.status == "success") {
          alert("Review updated successfully");
          location.reload();
        }
        else {
          alert("Could not update review. Please try again.");
        }
        addreviewClass.screen.style.display = 'none';
      },
      async (error: any) => {
        console.log(error);
        alert("Could not update review. Please try again.");
        addreviewClass.screen.style.display = 'none';
      });
    }
  }

  editReview = (reviewData = "") =>{
    console.log(reviewData);
    this.revDetails = "";
    let editRevModal = document.getElementById("edit-review-ankerTag") as HTMLElement;
    editRevModal.click();
    setTimeout (() => {
      let reviewDataFinal = {};
      reviewDataFinal['patient_sub'] = reviewData['patient_sub'].trim().split('_').join('-');
      reviewDataFinal['provider_sub'] = reviewData['provider_sub'].trim().split('_').join('-');
      reviewDataFinal['mode'] = reviewData['mode'].trim();
      reviewDataFinal['timestamp'] = this.formatDateTime(reviewData['timestampdb']);
      reviewDataFinal['duration'] = reviewData['duration'];
      reviewDataFinal['durationmin'] = Number(reviewData['duration'].split('.')[0]);
      reviewDataFinal['durationsec'] = Number(reviewData['duration'].split('.')[1]);
      reviewDataFinal['rpmreview_id'] = reviewData['rpmreview_id'];
      reviewDataFinal['review'] = reviewData['review'];
      reviewDataFinal['hastrans'] = false;
      if(reviewData['transmission_detail'] && reviewData['transmission_detail']['data_transmission_id']){
        reviewDataFinal['hastrans'] = true;
      }
      reviewDataFinal['escalated_intervention'] = false;
      if(reviewData['escalated_intervention']){
        reviewDataFinal['escalated_intervention'] = true;
      }
      this.revDetails = reviewDataFinal;
    }, 500);
  }

  validateReviewForm = ( reviewData ) =>{
    // return false;
    let reviewClass = this;
    reviewClass.screen.style.display = 'block';
    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      user_sub: localStorage.getItem('user_sub'),
      user_group: this.userGroup,
      rpmreview_id: reviewData.rpmreview_id,
      review: reviewData.review
    };
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addreview', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        if(response.status == "success") {
          alert("Review added successfully");
          location.reload();
        }
        else if(response.message){
          alert(response.message);
        }
        else {
          alert("Could not add review. Please try again.");
        }
        reviewClass.screen.style.display = 'none';
      },
      async (error: any) => {
        console.log(error);
        alert("Could not add review. Please try again.");
        reviewClass.screen.style.display = 'none';
      });
  }

  validatedeviceEduForm = ( deviceEduData ) =>{
    // if(deviceEduData.device_education_id == undefined || deviceEduData.device_education_id.trim() == "") {
    //   alert("Some error occurred. Please refresh the page and try again.");
    //   return false;
    // }
    // if(deviceEduData.primary_diagnosis == undefined || deviceEduData.primary_diagnosis.trim() == "") {
    //   alert("Please Provide Primary Diagnosis.");
    //   return false;
    // }
    // if(deviceEduData.secondary_diagnosis == undefined || deviceEduData.secondary_diagnosis.trim() == "") {
    //   alert("Please Provide Primary Diagnosis.");
    //   return false;
    // }
    let deviceEduClass = this;
    deviceEduClass.screen.style.display = 'block';
      let params = {
        userpull_id: localStorage.getItem('userpull_id'),
        device_education_id: deviceEduData.device_education_id,
        primary_diagnosis: deviceEduData.primary_diagnosis,
        secondary_diagnosis: deviceEduData.secondary_diagnosis
      };
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/adddiagnosis', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          deviceEduClass.screen.style.display = 'none';
          if(response.status == "success") {
            alert("Updated successfully");
          }
          else {
            alert("Could not update. Please try again.");
          }
        },
        async (error: any) => {
          console.log(error);
          alert("Could not update. Please try again.");
          deviceEduClass.screen.style.display = 'none';
        });
  }

  editEducation = (deviceEduData = "") =>{
    this.eduDetails = deviceEduData;
    this.myEduDate = "";
    this.editEduForm = {
      "device_education_id":deviceEduData['device_education_id'],
      "edudate":this.formatDateMDate(deviceEduData['timestamp'])
    };
  	let editEduModal = document.getElementById("edit-education-ankerTag") as HTMLElement;
    editEduModal.click();
    // let educationModal: HTMLElement = document.getElementById('education-details') as HTMLElement;
    // educationModal.click();
    setTimeout (() => {
      if(deviceEduData['timestamp']) {
        this.myEduDate = this.formatDateMDate(deviceEduData['timestamp']);
      }
      // editEduModal.click();
    }, 500);
  }

  validateEduForm= ( deviceEduData ) =>{
    if( !deviceEduData.edudate || typeof deviceEduData.edudate === 'undefined' || deviceEduData.edudate==''){
      alert("Please choose Device Education Date");
    }
    else {
      if(deviceEduData && deviceEduData['device_education_id']) {
        let updateEducationClass = this;
        updateEducationClass.screen.style.display = 'block';
        let params = {
          userpull_id: localStorage.getItem('userpull_id'),
          edu_id: deviceEduData['device_education_id'],
          timestamp: this.formatUTCDBDateTime(deviceEduData.edudate+" 00:00:00")
        };
        this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updateeducation', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          if(response.status == "success") {
            alert("Device Education updated successfully");
            location.reload();
          }
          else {
            alert("Could not update device education");
          }
          updateEducationClass.screen.style.display = 'none';
        },
        async (error: any) => {
          console.log(error);
          alert("Could not update education");
          updateEducationClass.screen.style.display = 'none';
          location.reload();
        });
      }
    }
  }

  deleteEducation = (deviceEduData = "") =>{
    if(deviceEduData && deviceEduData['device_education_id']) {
      if(confirm("Do you want to delete the Device Education Diagnosis captured on "+deviceEduData['timestamp']+" in "+deviceEduData['devices'].trim()+" (For "+deviceEduData['location'].trim()+")?")) {
        let deleteEducationClass = this;
        deleteEducationClass.screen.style.display = 'block';
        let params = {
          userpull_id: localStorage.getItem('userpull_id'),
          edu_id: deviceEduData['device_education_id'],
          isdeleted: 1
        };
        this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updateeducation', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          if(response.status == "success") {
            alert("Device Education deleted successfully");
            location.reload();
          }
          else {
            alert("Could not delete education");
          }
          deleteEducationClass.screen.style.display = 'none';
        },
        async (error: any) => {
          console.log(error);
          alert("Could not delete education");
          deleteEducationClass.screen.style.display = 'none';
          location.reload();
        });
      }
    }
  }

  deleteSymptom = (symptomid) =>{
    console.log(symptomid);
    if(confirm("Do you want to delete the symptom?")) {
      let deleteSymptomClass = this;
      deleteSymptomClass.screen.style.display = 'block';
      let params = {
        userpull_id: localStorage.getItem('userpull_id'),
        symptom_id: symptomid
      };
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/deletesymptom', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          if(response.status == "success") {
            // let symp = document.getElementsByClassName("symp_"+symptomid) as HTMLCollection;
            // console.log(symp);
            // for(var i=0; i<symp.length; i++){
            //   let sympP = symp[i].parentElement.parentElement;
            //   console.log(sympP.textContent);
            //   // sympI.remove();
            //   symp[i].textContent = "";
            //   if(sympP.textContent.trim() == "Symptoms:"){
            //     sympP.textContent = "";
            //   }
            // }
            alert("Symptom deleted successfully");
            location.reload();
          }
          else if(response.message){
            alert(response.message);
          }
          else {
            alert("Could not delete symptom");
          }
          deleteSymptomClass.screen.style.display = 'none';
        },
        async (error: any) => {
          console.log(error);
          alert("Could not delete review");
          deleteSymptomClass.screen.style.display = 'none';
          location.reload();
        });
    }
  }

  deleteReview = (reviewData, allReview) =>{
    console.log(reviewData);
    console.log(allReview);
    if(confirm("Do you want to delete the review?")) {
      let deleteReviewClass = this;
      deleteReviewClass.screen.style.display = 'block';
      let params = {
        userpull_id: localStorage.getItem('userpull_id'),
        rpmreview_id: reviewData['rpmreview_id'],
        user_sub: localStorage.getItem('user_sub')
      };
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/deletereview', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          if(response.status == "success") {
            alert("Review deleted successfully");
            location.reload();
          }
          else if(response.message){
            alert(response.message);
          }
          else {
            alert("Could not delete review");
          }
          deleteReviewClass.screen.style.display = 'none';
        },
        async (error: any) => {
          console.log(error);
          alert("Could not delete review");
          deleteReviewClass.screen.style.display = 'none';
          location.reload();
        });
    }
  }

  resetReviewForm = (form: NgForm) =>{
    // let patientSub = form.form.value.patient_sub;
    // form.resetForm();
    form.controls['provider_sub'].reset();
    form.controls['mode'].reset();
    form.controls['duration'].reset();
    form.controls['review'].reset();
    // form.form.value.patient_sub = patientSub;
  }

  getSettings = (userdata) => {
    console.log(userdata);
    this.settingDetails = "";
    this.screen.style.display = 'block';
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
        sub: userdata.sub
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getsettings', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      this.screen.style.display = 'none';
      if (response.status === "success") {
        let responsedata = JSON.parse(response.data);
        for(let i=0; i<responsedata[0]['Settings'].length; i++) {
          responsedata[0]['Settings'][i]['global_settings_arr'] = JSON.parse(responsedata[0]['Settings'][i]['global_settings']);
          responsedata[0]['Settings'][i]['merge_settings_arr']={};
          for (let key of Object.keys(responsedata[0]['Settings'][i]['global_settings_arr'])) {
            responsedata[0]['Settings'][i]['merge_settings_arr'][key]=responsedata[0]['Settings'][i]['global_settings_arr'][key];
          }
          if(responsedata[0]['Settings'][i]['alert_settings']) {
            responsedata[0]['Settings'][i]['alert_settings_arr'] = JSON.parse(responsedata[0]['Settings'][i]['alert_settings'].replace(', "isActive": true','').replace(', "isActive": false',''));
            if(responsedata[0]['Settings'][i]['device_id'] == null || responsedata[0]['Settings'][i]['device_id'] == "None"){
              responsedata[0]['Settings'][i]['device_id'] = "";
            }
            for (let key of Object.keys(responsedata[0]['Settings'][i]['global_settings_arr'])) {
              if(responsedata[0]['Settings'][i]['alert_settings_arr'][key]){
                responsedata[0]['Settings'][i]['merge_settings_arr'][key]=responsedata[0]['Settings'][i]['alert_settings_arr'][key];
              }
            }
          }
          else{
            responsedata[0]['Settings'][i]['device_id'] = "";
            responsedata[0]['Settings'][i]['enabled'] = 0;
          }
        }
        this.settingDetails = responsedata[0]['Settings'];
        console.log(this.settingDetails);
        let settingsModal = document.getElementById("settings-ankerTag") as HTMLElement;
        settingsModal.click();
      }
      else {
        alert("Could not find Settings");
        this.screen.style.display = 'none';
      }
    },
    async (error: any) => {
      alert("Some error occurred. Please try again.");
      this.screen.style.display = 'none';
    });
  }

  validateSettingForm= ( settingsData ) =>{
    // console.log(this.subId);
    // console.log(settingsData);
    // return false;
    let careplan = {};
    let devicealert = [];
    let devicealertsettings = [];
    let keyparsearr = ['device_id','device_type_id','enabled'];
    for (let key of Object.keys(settingsData)) {
      let keyarr = key.split("-");
      if(keyarr[0] != 'care_plan'){
        if(!devicealert[keyarr[0]]) {
          devicealert[keyarr[0]] = {};
          devicealert[keyarr[0]]['device_type_id'] = keyarr[1];
          devicealert[keyarr[0]]['alert_settings'] = {};
        }
        if(keyparsearr.indexOf(keyarr[2]) > -1) {
          devicealert[keyarr[0]][keyarr[2]] = settingsData[key];
        }
        else {
          devicealert[keyarr[0]]['alert_settings'][keyarr[2]] = settingsData[key];
        }
      }
      else{
        careplan[keyarr[1]] = settingsData[key];
      }
    }
    // console.log(devicealert);
    // console.log(careplan);
    for (let devicekey of Object.keys(devicealert)) {
      devicealertsettings.push(devicealert[devicekey]);
    }
    // console.log(devicealertsettings);
    // return false;
    let settingClass = this;
    settingClass.screen.style.display = 'block';
    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      sub: this.subId,
      fromweb: 'yes',
      device_alert_settings: devicealertsettings,
      care_plan: careplan,
      user_provider_sub: localStorage.getItem('user_sub'),
      userauth: localStorage.getItem('AUTH-TOKEN')
    };
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/savealertsettings', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      if(response.status == "success") {
        alert("Alert Settings updated successfully");
        location.reload();
      }
      else {
        alert("Could not update alert settings");
      }
      settingClass.screen.style.display = 'none';
    },
    async (error: any) => {
      console.log(error);
      alert("Could not update settings");
      settingClass.screen.style.display = 'none';
      // location.reload();
    });
  }

  goBack(): void {
    this.locationservice.back();
  }

  reloadpage(): void {
    if(confirm("Unsaved changes will be lost. Do you want to continue?")) {
      location.reload();
    }
  }

  Graphlistdata = [];

  patientgraphclick = (graphType, devicedata, device_name, DevicesAlertSettings, tempUploadHistory) => {
    console.log(graphType)
    console.log(devicedata)
    console.log(tempUploadHistory)
    console.log(DevicesAlertSettings)

    this.isPatientGlocoseLineChartData = false;
    this.isPatientDeviceLineChartData = false;

    this.Graphlistdata = []

    this.patientLineChartData = [{ 'data': [], 'label': '', 'fill': false, 'lineTension': 0 }];
    this.patientLinechartColors = []
    this.patientLinechartLabels = []

    this.patientLineFAChartData = [{ 'data': [], 'label': '', 'fill': false, 'lineTension': 0 }];
    this.patientLineFAchartColors = []
    this.patientLineFAchartLabels = []

    this.patientLineAMChartData = [{ 'data': [], 'label': '', 'fill': false, 'lineTension': 0 }];
    this.patientLineAMchartColors = []
    this.patientLineAMchartLabels = []

    this.patientLineNTChartData = [{ 'data': [], 'label': '', 'fill': false, 'lineTension': 0 }];
    this.patientLineNTchartColors = []
    this.patientLineNTchartLabels = []



    let tempGraphDevicedata = []

    if (graphType == 'AllGraph') {
      tempGraphDevicedata = devicedata;
      this.patientLineChartStr = device_name + "  All Data";

      if (tempUploadHistory.length > 0){
        this.patientLineChartStr = device_name + ": ( " + tempUploadHistory[tempUploadHistory.length - 1].StartDate + " - " + tempUploadHistory[0].EndDate + " )";
      }      

    }else if (graphType == 'Graph'){
      tempGraphDevicedata = devicedata.UploadHistory;
      this.patientLineChartStr = device_name + ": ( " + devicedata.StartDate + " - " + devicedata.EndDate + " )";
    }    

    if (device_name == "BPM"){
      let diaData = [];
      let pulseData = [];
      let sysData = [];

      let diaMin = [];
      let pulseMin = [];
      let sysMin = [];

      let diaMax = [];
      let pulseMax = [];
      let sysMax = [];

      let marList = [];

      var diaMinVal: number = DevicesAlertSettings.BPM.diaMin;
      var pulseMinVal: number = DevicesAlertSettings.BPM.pulseMin;
      var sysMinVal: number = DevicesAlertSettings.BPM.sysMin;

      var diaMaxVal: number = DevicesAlertSettings.BPM.diaMax;
      var pulseMaxVal: number = DevicesAlertSettings.BPM.pulseMax;
      var sysMaxVal: number = DevicesAlertSettings.BPM.sysMax;

      let labels = [];

      for(var UploadHis of tempGraphDevicedata){        
        if (UploadHis.device_name.trim() == device_name) {

          this.Graphlistdata.push(UploadHis);

          var dia: number;
          var pulse: number;
          var sys: number;

          if (UploadHis.detailed_value.diaData){
            dia = UploadHis.detailed_value.diaData
          }

          if (UploadHis.detailed_value.pulseData){
            pulse = UploadHis.detailed_value.pulseData
          }

          if (UploadHis.detailed_value.sysData){
            sys = UploadHis.detailed_value.sysData
          }

          if (dia && pulse && sys){
            diaData.push(dia)
            pulseData.push(pulse)
            sysData.push(sys)

            diaMin.push(diaMinVal)
            pulseMin.push(pulseMinVal)
            sysMin.push(sysMinVal)

            diaMax.push(diaMaxVal)
            pulseMax.push(pulseMaxVal)
            sysMax.push(sysMaxVal)

            let marVal = ((sys + (2 * dia))/3).toFixed(2);

            marList.push(marVal)

            labels.push(UploadHis.timestamp.split(" ").slice(3, 6).join(" "))
          }
        }
      }

      if (diaData.length == 1) {
        diaData.push(diaData[0])
        pulseData.push(pulseData[0])
        sysData.push(sysData[0])

        diaMin.push(diaMin[0])
        pulseMin.push(pulseMin[0])
        sysMin.push(sysMin[0])

        diaMax.push(diaMax[0])
        pulseMax.push(pulseMax[0])
        sysMax.push(sysMax[0])

        marList.push(marList[0])

        labels.push(labels[0])
      }

      if (diaData.length > 0) {
      
        //DATA      
        this.patientLineChartData =   [{ 'data':  diaData, 'label': 'DIA', 'fill': true, 'lineTension': 0 }];
        this.patientLineChartData.push({ 'data': pulseData, 'label': 'PULSE', 'fill': true, 'lineTension': 0 });
        this.patientLineChartData.push({ 'data': sysData, 'label': 'SYS', 'fill': true, 'lineTension': 0 });

        this.patientLineChartData.push({ 'data': diaMin, 'label': 'DIA MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });
        this.patientLineChartData.push({ 'data': pulseMin, 'label': 'PULSE MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });
        this.patientLineChartData.push({ 'data': sysMin, 'label': 'SYS MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        this.patientLineChartData.push({ 'data': diaMax, 'label': 'DIA MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });
        this.patientLineChartData.push({ 'data': pulseMax, 'label': 'PULSE MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });
        this.patientLineChartData.push({ 'data': sysMax, 'label': 'SYS MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1 , hidden: true});

        this.patientLineChartData.push({ 'data': marList, 'label': 'MAP (mean arterial pressure)', 'fill': false, 'lineTension': 0, 'borderWidth': 1 , hidden: true});

        //COLOR
        this.patientLinechartColors.push({ borderColor: 'rgba(0, 188, 158)', backgroundColor: 'rgba(167, 204, 213, .6)' })        
        this.patientLinechartColors.push({ borderColor: 'rgba(51, 51, 51)', backgroundColor: 'rgba(235, 187, 164, .6)' })
        this.patientLinechartColors.push({ borderColor: 'rgba(0, 156, 215)', backgroundColor: 'rgba(200, 230, 245, .6)' })
        

        this.patientLinechartColors.push({ borderColor: 'rgba(10, 198, 10)' })
        this.patientLinechartColors.push({ borderColor: 'rgba(38, 41, 51)' })
        this.patientLinechartColors.push({ borderColor: 'rgba(10, 10, 100)' })

        this.patientLinechartColors.push({ borderColor: 'rgba(10, 198, 10)' })
        this.patientLinechartColors.push({ borderColor: 'rgba(38, 41, 51)' })
        this.patientLinechartColors.push({ borderColor: 'rgba(10, 10, 100)' })

        this.patientLinechartColors.push({ borderColor: 'red' })

        this.patientLinechartLabels = labels
      }
      
      console.log(diaData)
      console.log(pulseData)
      console.log(sysData)
      console.log(labels)
    }else if (device_name == "Weight"){
      let bmiData = [];
      let weightData = [];

      let bmiMin = [];
      let weightMin = [];

      let bmiMax = [];
      let weightMax = [];

      var bmiMinVal: number = DevicesAlertSettings.Weight.bmiMin;
      var weightMinVal: number = DevicesAlertSettings.Weight.wtMin;

      var bmiMaxVal: number = DevicesAlertSettings.Weight.bmiMax;
      var weightMaxVal: number = DevicesAlertSettings.Weight.wtMax;

      let labels = [];

      for(var UploadHis of tempGraphDevicedata){        
        if (UploadHis.device_name.trim() == device_name) {
          this.Graphlistdata.push(UploadHis);

          var bmi: number;
          var weight: number;

          if (UploadHis.detailed_value.bmi){
            bmi = UploadHis.detailed_value.bmi
          }

          if (UploadHis.detailed_value.weight){
            weight = +UploadHis.detailed_value.weight.split(" ")[0]
          }

          if (bmi && weight){
            bmiData.push(bmi)
            weightData.push(weight)

            bmiMin.push(bmiMinVal)
            weightMin.push(weightMinVal)

            bmiMax.push(bmiMaxVal)
            weightMax.push(weightMaxVal)

            labels.push(UploadHis.timestamp.split(" ").slice(3, 6).join(" "))
          }
        }
      }

      if (bmiData.length == 1) {
        bmiData.push(bmiData[0])
        weightData.push(weightData[0])

        bmiMin.push(bmiMin[0])
        weightMin.push(weightMin[0])

        bmiMax.push(bmiMax[0])
        weightMax.push(weightMax[0])

        labels.push(labels[0])
      }

      if (bmiData.length > 0) {
      
        //DATA      
        this.patientLineChartData =   [];
        // this.patientLineChartData.push({ 'data':  bmiData, 'label': 'BMI', 'fill': true, 'lineTension': 0 });
        this.patientLineChartData.push({ 'data': weightData, 'label': 'Weight', 'fill': true, 'lineTension': 0 });

        // this.patientLineChartData.push({ 'data': bmiMin, 'label': 'BMI MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });
        this.patientLineChartData.push({ 'data': weightMin, 'label': 'Weight MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        // this.patientLineChartData.push({ 'data': bmiMax, 'label': 'BMI MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });
        this.patientLineChartData.push({ 'data': weightMax, 'label': 'Weight MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        //COLOR
        this.patientLinechartColors.push({ borderColor: 'rgba(0, 188, 158)', backgroundColor: 'rgba(167, 204, 213, .6)' })        
        this.patientLinechartColors.push({ borderColor: 'rgba(51, 51, 51)', backgroundColor: 'rgba(184, 224, 235, .6)' })
        

        this.patientLinechartColors.push({ borderColor: 'rgba(10, 198, 10)' })
        this.patientLinechartColors.push({ borderColor: 'rgba(38, 41, 51)' })

        this.patientLinechartColors.push({ borderColor: 'rgba(10, 198, 10)' })
        this.patientLinechartColors.push({ borderColor: 'rgba(38, 41, 51)' })

        this.patientLinechartLabels = labels
      }
      
      console.log(bmiData)
      console.log(weightData)
      console.log(labels)


    }else if (device_name == "Oximeter"){
      let prData = [];
      let spo2Data = [];

      let prMin = [];
      let spo2Min = [];

      let prMax = [];
      let spo2Max = [];

      var prMinVal: number = DevicesAlertSettings.Oximeter.prMin;
      var spo2MinVal: number = DevicesAlertSettings.Oximeter.spo2Min;

      var prMaxVal: number = DevicesAlertSettings.Oximeter.prMax;
      var spo2MaxVal: number = DevicesAlertSettings.Oximeter.spo2Max;

      let labels = [];

      for(var UploadHis of tempGraphDevicedata){        
        if (UploadHis.device_name.trim() == device_name) {
          this.Graphlistdata.push(UploadHis);

          var pr: number;
          var spo2: number;

          if (UploadHis.detailed_value.pr){
            pr = +UploadHis.detailed_value.pr
          }

          if (UploadHis.detailed_value.spo2){
            spo2 = +UploadHis.detailed_value.spo2
          }

          if (pr && spo2){
            prData.push(pr)
            spo2Data.push(spo2)

            prMin.push(prMinVal)
            spo2Min.push(spo2MinVal)

            prMax.push(prMaxVal)
            spo2Max.push(spo2MaxVal)

            labels.push(UploadHis.timestamp.split(" ").slice(3, 6).join(" "))
          }
        }
      }

      if (prData.length == 1) {
        prData.push(prData[0])
        spo2Data.push(spo2Data[0])

        prMin.push(prMin[0])
        spo2Min.push(spo2Min[0])

        prMax.push(prMax[0])
        spo2Max.push(spo2Max[0])

        labels.push(labels[0])
      }

      if (prData.length > 0) {
      
        //DATA      
        this.patientLineChartData =   [{ 'data':  prData, 'label': 'PR', 'fill': true, 'lineTension': 0 }];
        this.patientLineChartData.push({ 'data': spo2Data, 'label': 'SPO2', 'fill': true, 'lineTension': 0 });

        this.patientLineChartData.push({ 'data': prMin, 'label': 'PR MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });
        this.patientLineChartData.push({ 'data': spo2Min, 'label': 'SPO2 MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        this.patientLineChartData.push({ 'data': prMax, 'label': 'PR MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });
        this.patientLineChartData.push({ 'data': spo2Max, 'label': 'SPO2 MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        //COLOR
        this.patientLinechartColors.push({ borderColor: 'rgba(0, 188, 158)', backgroundColor: 'rgba(167, 204, 213, .6)' })        
        this.patientLinechartColors.push({ borderColor: 'rgba(51, 51, 51)', backgroundColor: 'rgba(184, 224, 235, .6)' })
        

        this.patientLinechartColors.push({ borderColor: 'rgba(10, 198, 10)' })
        this.patientLinechartColors.push({ borderColor: 'rgba(38, 41, 51)' })

        this.patientLinechartColors.push({ borderColor: 'rgba(10, 198, 10)' })
        this.patientLinechartColors.push({ borderColor: 'rgba(38, 41, 51)' })

        this.patientLinechartLabels = labels
      }
      
      console.log(prData)
      console.log(spo2Data)
      console.log(labels)



    }else if (device_name == "Thermometer"){
      let temperatureData = [];

      let temperatureMin = [];

      let temperatureMax = [];

      var temperatureMinVal: number = DevicesAlertSettings.Thermometer.tempMin;

      var temperatureMaxVal: number = DevicesAlertSettings.Thermometer.tempMax;

      let labels = [];

      for(var UploadHis of tempGraphDevicedata){        
        if (UploadHis.device_name.trim() == device_name) {
          this.Graphlistdata.push(UploadHis);

          var temperature: number;

          if (UploadHis.detailed_value.temperature){
            temperature = UploadHis.detailed_value.temperature
          }

          

          if (temperature){
            temperatureData.push(temperature)

            temperatureMin.push(temperatureMinVal)

            temperatureMax.push(temperatureMaxVal)

            labels.push(UploadHis.timestamp.split(" ").slice(3, 6).join(" "))
          }
        }
      }

      if (temperatureData.length == 1) {
        temperatureData.push(temperatureData[0])

        temperatureMin.push(temperatureMin[0])

        temperatureMax.push(temperatureMax[0])

        labels.push(labels[0])
      }

      if (temperatureData.length > 0) {
      
        //DATA      
        this.patientLineChartData =   [{ 'data':  temperatureData, 'label': 'temperature', 'fill': true, 'lineTension': 0 }];

        this.patientLineChartData.push({ 'data': temperatureMin, 'label': 'temperature MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        this.patientLineChartData.push({ 'data': temperatureMax, 'label': 'temperature MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        //COLOR
        this.patientLinechartColors.push({ borderColor: 'rgba(0, 188, 158)', backgroundColor: 'rgba(167, 204, 213, .6)' })         

        this.patientLinechartColors.push({ borderColor: 'rgba(10, 198, 10)' })

        this.patientLinechartColors.push({ borderColor: 'rgba(10, 198, 10)' })

        this.patientLinechartLabels = labels
      }
      
      console.log(temperatureData)
      console.log(labels)

    }else if (device_name == "Blood Glucose"){
      let faData = [];
      let amData = [];
      let ntData = [];

      let faMin = [];
      let amMin = [];
      let ntMin = [];

      let faMax = [];
      let amMax = [];
      let ntMax = [];

      let faa1c = [];
      let ama1c = [];
      let nta1c = [];

      let falabels = [];
      let amlabels = [];
      let ntlabels = [];

      var faMinVal: number = DevicesAlertSettings['Blood Glucose']['glucoseFastingMin'];
      var amMinVal: number = DevicesAlertSettings['Blood Glucose']['glucoseAfterMealMin'];
      var ntMinVal: number = DevicesAlertSettings['Blood Glucose']['glucoseNormalTestMin'];

      var faMaxVal: number = DevicesAlertSettings['Blood Glucose']['glucoseFastingMax'];
      var amMaxVal: number = DevicesAlertSettings['Blood Glucose']['glucoseAfterMealMax'];
      var ntMaxVal: number = DevicesAlertSettings['Blood Glucose']['glucoseNormalTestMax'];

      var faa1cVal: number = 0;
      var ama1cVal: number = 0;
      var nta1cVal: number = 0;

      var fatotalVal: number = 0;
      var amtotalVal: number = 0;
      var nttotalVal: number = 0;

      for(var UploadHis of tempGraphDevicedata){        
        if (UploadHis.device_name.trim() == device_name) {
          this.Graphlistdata.push(UploadHis);

          console.log(UploadHis.detailed_value)

          if (UploadHis.detailed_value.bloodGlucose && UploadHis.detailed_value.type.trim() == 'Fasting' ){
            faData.push(UploadHis.detailed_value.bloodGlucose)

            fatotalVal += Number(UploadHis.detailed_value.bloodGlucose);
            faa1cVal = Number(((46.7 + (fatotalVal/faData.length))/28.7).toFixed(2));

            faMin.push(faMinVal)

            faMax.push(faMaxVal)

            faa1c.push(faa1cVal)

            falabels.push(UploadHis.timestamp.split(" ").slice(3, 6).join(" "))

          }else if (UploadHis.detailed_value.bloodGlucose && UploadHis.detailed_value.type.trim() == 'After Meal' ){
            amData.push(UploadHis.detailed_value.bloodGlucose)

            amtotalVal += Number(UploadHis.detailed_value.bloodGlucose);
            ama1cVal = Number(((46.7 + (amtotalVal/amData.length))/28.7).toFixed(2));

            amMin.push(amMinVal)

            amMax.push(amMaxVal)

            ama1c.push(ama1cVal)

            amlabels.push(UploadHis.timestamp.split(" ").slice(3, 6).join(" "))
          }else if (UploadHis.detailed_value.bloodGlucose && UploadHis.detailed_value.type.trim() == 'Normal Test' ){
            ntData.push(UploadHis.detailed_value.bloodGlucose)

            nttotalVal += Number(UploadHis.detailed_value.bloodGlucose);
            nta1cVal = Number(((46.7 + (nttotalVal/ntData.length))/28.7).toFixed(2));

            ntMin.push(ntMinVal)

            ntMax.push(ntMaxVal)

            nta1c.push(nta1cVal)

            ntlabels.push(UploadHis.timestamp.split(" ").slice(3, 6).join(" "))
          }


          
        }
      }      

      if (faData.length == 1) {
        faData.push(faData[0])

        faMin.push(faMin[0])

        faMax.push(faMax[0])

        faa1c.push(faa1c[0])

        falabels.push(falabels[0])
      }

      if (amData.length == 1) {
        amData.push(amData[0])

        amMin.push(amMin[0])

        amMax.push(amMax[0])
        
        ama1c.push(ama1c[0])
        
        amlabels.push(amlabels[0])
      }

      if (ntData.length == 1) {
        ntData.push(ntData[0])
        
        ntMin.push(ntMin[0])
        
        ntMax.push(ntMax[0])

        nta1c.push(nta1c[0])
        
        ntlabels.push(ntlabels[0])
      }

      if (faData.length > 0) {
      
        //DATA      
        this.patientLineFAChartData =   [{ 'data':  faData, 'label': 'Fasting', 'fill': true, 'lineTension': 0, 'borderWidth': 1, hidden: true }];

        this.patientLineFAChartData.push({ 'data': faMin, 'label': 'Fasting MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        this.patientLineFAChartData.push({ 'data': faMax, 'label': 'Fasting MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        this.patientLineFAChartData.push({ 'data': faa1c, 'label': 'Fasting A1C', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: false });

        //COLOR
        this.patientLineFAchartColors.push({ borderColor: 'rgba(0, 188, 158)', backgroundColor: 'rgba(167, 204, 213, .6)' }) 

        this.patientLineFAchartColors.push({ borderColor: 'rgba(10, 198, 10)' })

        this.patientLineFAchartColors.push({ borderColor: 'rgba(10, 198, 10)' })

        this.patientLineFAchartColors.push({ borderColor: 'red' })

        this.patientLineFAchartLabels = falabels
      }

      if (amData.length > 0) {
      
        //DATA 
        this.patientLineAMChartData =   [{ 'data': amData, 'label': 'After Meal', 'fill': true, 'lineTension': 0, 'borderWidth': 1, hidden: true }];
        
        this.patientLineAMChartData.push({ 'data': amMin, 'label': 'After Meal MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });
        
        this.patientLineAMChartData.push({ 'data': amMax, 'label': 'After Meal MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        this.patientLineAMChartData.push({ 'data': ama1c, 'label': 'After Meal A1C', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: false });

        //COLOR     
        this.patientLineAMchartColors.push({ borderColor: 'rgba(51, 51, 51)', backgroundColor: 'rgba(164, 224, 235, .6)' })

        this.patientLineAMchartColors.push({ borderColor: 'rgba(38, 41, 51)' })
        
        this.patientLineAMchartColors.push({ borderColor: 'rgba(38, 41, 51)' })

        this.patientLineAMchartColors.push({ borderColor: 'red' })        

        this.patientLineAMchartLabels = amlabels
      }

      if (ntData.length > 0) {
      
        //DATA 
        this.patientLineNTChartData =   [{ 'data': ntData, 'label': 'Normal Test', 'fill': true, 'lineTension': 0, 'borderWidth': 1, hidden: true }];
        
        this.patientLineNTChartData.push({ 'data': ntMin, 'label': 'Normal Test MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });
        
        this.patientLineNTChartData.push({ 'data': ntMax, 'label': 'Normal Test MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        this.patientLineNTChartData.push({ 'data': nta1c, 'label': 'Normal Test A1C', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: false });

        //COLOR
        this.patientLineNTchartColors.push({ borderColor: 'rgba(0, 156, 215)', backgroundColor: 'rgba(200, 230, 245, .6)' })
        
        this.patientLineNTchartColors.push({ borderColor: 'rgba(10, 10, 100)' })
        
        this.patientLineNTchartColors.push({ borderColor: 'rgba(10, 10, 100)' })

        this.patientLineNTchartColors.push({ borderColor: 'red' })

        this.patientLineNTchartLabels = ntlabels
      }

      console.log("Fasting")      
      console.log(faData)
      console.log(faa1c)
      console.log(falabels)

      console.log("After Meal")
      console.log(amData)
      console.log(amlabels)

      console.log("Normal Test")
      console.log(ntData)
      console.log(ntlabels)
    }
    
    console.log("Graphlistdata", this.Graphlistdata)

    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      if(dtElement.dtInstance) {
      dtElement.dtInstance.then((dtInstance: any) => {
        if(dtInstance.table().node().id == "graph-table") {
          dtInstance.destroy();
          this.dtTrigger.next();
        }
      });
      }
      else {
        this.dtTrigger.next();
      }
    });

    if (device_name == "Blood Glucose") {
      this.isPatientGlocoseLineChartData = true;
      this.isPatientDeviceLineChartData = false;

     
    }else {
      this.isPatientDeviceLineChartData = true;
      this.isPatientGlocoseLineChartData = false;
    }


    let patientGraphModal = document.getElementById("patient-graph-ankerTag") as HTMLElement;
    patientGraphModal.click();

  }

  openWindow = (hubDetails) => {
    // window.open("https://online.miradore.com/"+hubDetails.Sname+"/MDCloud/Device/"+hubDetails.DeviceID, "_blank");
    window.open("https://online.miradore.com/"+hubDetails.Sname+"/Device/"+hubDetails.DeviceID, "_blank");
  }

  roomName = uuidv4();

  twillioRecordButton = true;

  callNumbers = [];
  // callNumbers = [{phone: "+555552222222", status: "Ring", callsid: "CAf6bac30172197afb0367cfbf2b13cb5c"}]

  twillioaddnumberadd = false;

  twillioaddnumber:string = "";

  twlioConfarencetimer: any;

  twlioSecondtimer: any;

  SecondCallSid:string = "";

  SecondCallStatus:string = "";

  twlioConfarenceCallStatusList = ["queued", "initiated", "ringing", "in-progress"];


  twiliocalllistAddbuttonClick = (val) => {
    console.log("twiliocalllistAddbuttonClick")
    console.log(val)

    this.twillioaddnumberadd = true;
    this.twillioaddnumber = "";

  }
  twiliocalllistAddNumberbuttonClick = (val) => {
    console.log("twiliocalllistAddNumberbuttonClick")
    console.log(val)

    let phone_numberPattern = /^[+]\d+$/;                ///// Checking for +Number /////
    if(!this.twillioaddnumber || typeof this.twillioaddnumber === 'undefined' || !this.twillioaddnumber.match(phone_numberPattern)){
      alert("Invalid Phone number.")
    }else{
      this.twillioaddnumberadd = false;
      let params = {
        roomName: this.roomName,
        fromNumber: this.twilioConstantNumber,
        numbers: [this.twillioaddnumber]
      };

      let tempNumber = this.twillioaddnumber;
      this.httpClient.post(this.twilioEndPoint + '/stage/twilio/conferenceCall', params, { headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) })
        .subscribe( async (response: any) => {
          console.log(response);
          var addNumber = response;
          console.log(addNumber['callSids'][0])

          this.callNumbers.push({phone: tempNumber.toString(), status: "", callsid: addNumber['callSids'][0]});
          
        },
        async (error: any) => {
          console.log(error);
        });
      this.twillioaddnumber = "";
    }

    

  }

  

  callConfarenceWatcher = async () => {
    this.twlioConfarencetimer = setInterval(async () => {
      // console.log("callConfarenceWatcher")

      if (this.callNumbers.length > 0){
        for(let callNumber of this.callNumbers){

          var tempcallNumber = callNumber;

          let index = this.callNumbers.indexOf(callNumber);

          const params = new HttpParams({
            fromObject: {
              callsid: callNumber.callsid
            }
          });


          let response: any = await this.httpClient.get(this.twilioEndPoint + '/stage/twilio/CallStatus', { params, headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) }).toPromise();

          console.log(response);
          console.log(response['callStatus']['status']);

          tempcallNumber["status"] = response['callStatus']['status'];

          this.callNumbers[index] = tempcallNumber;
    
          await this.delay(100);

          if(this.twlioConfarenceCallStatusList.indexOf(response['callStatus']['status']) == -1){
            this.callNumbers.splice(index, 1);
          }

        }
      }
      
    }, 2000);
  }

  secondcallWatcher = async () => {
    this.twlioSecondtimer = setInterval(async () => {
      console.log("secondcallWatcher")

      const params = new HttpParams({
        fromObject: {
          callsid: this.SecondCallSid
        }
      });

      let response: any = await this.httpClient.get(this.twilioEndPoint + '/stage/twilio/CallStatus', { params, headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) }).toPromise();

      console.log(response);
      console.log(response['callStatus']['status']);

      this.SecondCallStatus = response['callStatus']['status'];
      
    }, 1000);
  }

  twiliocalllistdeletebuttonClick = (val) => {
    console.log("twiliocalllistdeletebuttonClick")
    console.log(val)

    const index: number = this.callNumbers.indexOf(val);
    this.callNumbers.splice(index, 1);
    if (index !== -1) {
        
        let params = {
          roomName: this.roomName,
          entity: val.phone
        };
        this.httpClient.post(this.twilioEndPoint + '/stage/twilio/RemovePersonFromConference', params, { headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) })
          .subscribe( async (response: any) => {
            console.log(response);
            
          },
          async (error: any) => {
            console.log(error);
          });
    }
  }

  twilliocallcheckValue = (val) =>{
    console.log("twilliocallcheckValue");
    console.log(this.twillioRecordButton);

    var isPaused = true;

    if(this.twillioRecordButton == true){
      isPaused = false;
    }else if(this.twillioRecordButton == false){
      isPaused = true;
    }

    let params = {
      roomName: this.roomName,
      isPaused: isPaused
    };
    this.httpClient.post(this.twilioEndPoint + '/stage/twilio/RecordingOnAndOff', params, { headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) })
      .subscribe( async (response: any) => {
        console.log(response);
      },
      async (error: any) => {
        console.log(error);
      });

  }

  getSecondCallSid = () => {
    console.log("getSecondCallSid");
    const params = new HttpParams({
      fromObject: {
        roomName: this.roomName
      }
    });
    this.httpClient.get(this.twilioEndPoint + '/stage/twilio/SecondCallSid', { params, headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) })
    .subscribe((response: any) => { 
      console.log(response);
      this.SecondCallSid = response['callsid']
      this.secondcallWatcher();
    },
    async (error: any) => {
      console.log(error);
    });
  }

  patienttwiliohubcallButtonClick =  async (hubDetails, userDetails) => {
    console.log("twilio hub call Button Pressed");
    console.log(hubDetails);
    console.log(userDetails);
    if (this.clickCallisDisabled == false) {
      this.clickCallisDisabled = true;

      let hubParams = {
        OrgId: hubDetails.OrgId,
        Pemail: hubDetails.Pemail
      }
      this.httpClient.post(this.miradoreEndPoint + '/stage/kiosk/status', hubParams, { headers: new HttpHeaders({ 'x-api-key': this.miradoreEndPointApiKey }) })
      .subscribe( async (response: any) => {
        console.log(response);
        if (response.messages && response.messages === "Success") {
          let resdata = response.data;
          if(resdata.status == 1){
            this.clickCallisDisabled = false;
            if(confirm("Do you want to unlock the hub?")) {
              this.httpClient.put(this.miradoreEndPoint + '/stage/kiosk/unlock', {'kioskUrlRes': resdata.kioskUrlRes}, { headers: new HttpHeaders({ 'x-api-key': this.miradoreEndPointApiKey }) })
              .subscribe( async (reslock: any) => {
                console.log(reslock);
              },
              async (error: any) => {
                console.log(error);
              });
            }
          }
          else {
            let patientNumber = userDetails.sub.replaceAll("-", "_");
            let practice = localStorage.getItem('userpull_id'); //user pull id
            let patientName = userDetails.sub; //patient sub
            let reason = "reason";   
            let tt = new Date();
            console.log(tt.getFullYear());
            // let roomName = tt.getFullYear().toString()+tt.getMonth().toString()+tt.getDate().toString()+tt.getHours().toString()+tt.getMinutes().toString()+tt.getSeconds().toString()+tt.getMilliseconds().toString()+'-'+patientNumber.replace('+','');
            let roomName = this.roomName;
            console.log(roomName);
            
            var callbackUrl = this.twilioEndPoint + "/stage/twilio/callbackUrl?patientNumber=" + patientNumber +"&twilioNumber=" + this.twilioConstantNumber + "&fromNumber=" + this.twilioNumber + "&practice=" + practice + "&patientName=" + patientName + "&reason=" + reason + "&user_info="+localStorage.getItem('user_sub')+"&roomName=" + roomName;
            if(this.reviewTransmission) {
              callbackUrl = callbackUrl + "&transmission_id=" + this.reviewTransmission;
            }
            console.log(callbackUrl)

            let tokenParams = {
              twilioNumber: this.twilioConstantNumber,
              fromNumber: this.twilioNumber,
              patientNumber: patientNumber,
              callbackUrl: encodeURI(callbackUrl),
              roomName: roomName
            };

            this.httpClient.post(this.twilioEndPoint + '/stage/twilio/call/token3', tokenParams, { headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) })
            .subscribe( async (response: any) => {
              console.log("token call Sucess");
              console.log(response.token);

              var tokenparam = {
                fakeLocalDTMF: true,
              };

              Twilio.Device.setup(response.token, tokenparam);
              this.phonecall = false;
              this.endButtonShow = true;
              console.log("device setup");
              await this.delay(3000);
              console.log("call setup");
              var callParams = {
                // phoneNumber: this.twilioConstantNumber,
                phoneNumber: patientNumber,
                recording : true,
                practice: practice,
                patientName: patientName,
                reason : reason,
                roomName: roomName,
                isWeb: true,
                fromNumber: this.twilioNumber,
                twilioNumber: this.twilioConstantNumber, //'+14083518666' // twilio number
                user_info: localStorage.getItem('user_sub')
              };
              Twilio.Device.connect(callParams);
              
              await this.delay(3000);

              this.callWatcher()
              
            },
            async (error: any) => {
              console.log(error);
            });
          }
        }
      },
      async (error: any) => {
        console.log(error);
      });
    }
  }

  patienttwiliocallButtonClick =  async (userDetails) => {
    console.log("twilio call Button Pressed")

    console.log(this.reviewTransmission)
    console.log(this.reviewTransmissionData)
    // return

    var subtype = "Check-In";

    if(this.reviewTransmission) {
      if(this.reviewTransmissionData.out_of_range_alert){
        subtype = "Alert"
      }
      if(this.reviewTransmissionData.critical_alert){
        subtype = "Critical-Alert"
      }
    }

    console.log(subtype)

    // return

    if (this.clickCallisDisabled == false) {
      console.log("twilio call")

      this.clickCallisDisabled = true;

      let patientNumber = userDetails.phone_number;    
      let practice = localStorage.getItem('userpull_id'); //user pull id
      let patientName = userDetails.sub; //patient sub
      let reason = "reason";   
      let tt = new Date();
      console.log(tt.getFullYear());
      // let roomName = tt.getFullYear().toString()+tt.getMonth().toString()+tt.getDate().toString()+tt.getHours().toString()+tt.getMinutes().toString()+tt.getSeconds().toString()+tt.getMilliseconds().toString()+'-'+patientNumber.replace('+','');
      let roomName = this.roomName;
      console.log(roomName);
      
      var callbackUrl = this.twilioEndPoint + "/stage/twilio/callbackUrl?patientNumber=" + patientNumber +"&twilioNumber=" + this.twilioConstantNumber + "&fromNumber=" + this.twilioNumber + "&practice=" + practice + "&patientName=" + patientName + "&reason=" + reason + "&user_info="+localStorage.getItem('user_sub')+"&roomName=" + roomName;
      if(this.reviewTransmission) {
        callbackUrl = callbackUrl + "&transmission_id=" + this.reviewTransmission;
      }
      if (localStorage.getItem('USER-DETAILS')) {
        var loggedinUser = JSON.parse(localStorage.getItem("USER-DETAILS"));
        // console.log(loggedinUser);
        // console.log(this.providerlist);
        for(var x=0; x<this.providerlist.length; x++) {
          if(loggedinUser.email == this.providerlist[x]['email']) {
            callbackUrl = callbackUrl + "&provider_sub=" + this.providerlist[x]['sub'];
          }
          // else {
          //   console.log("Not matched ->"+loggedinUser.email +" with "+this.providerlist[x]['email']);
          // }
        }
      }
      console.log(callbackUrl);
      // return false;

      let tokenParams = {
        twilioNumber: this.twilioConstantNumber,
        fromNumber: this.twilioNumber,
        patientNumber: patientNumber,
        callbackUrl: encodeURI(callbackUrl),
        roomName: roomName
      };

      this.httpClient.post(this.twilioEndPoint + '/stage/twilio/call/token3', tokenParams, { headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) })
      .subscribe( async (response: any) => {
        console.log("token call Sucess");
        console.log(response.token);

        var tokenparam = {
          fakeLocalDTMF: true,
        };

        Twilio.Device.setup(response.token, tokenparam);
        this.hubcall = false;
        this.endButtonShow = true;
        console.log("device setup");
        await this.delay(3000);
        console.log("call setup");
        var callParams = {
          // phoneNumber: this.twilioConstantNumber,
          phoneNumber: patientNumber,
          recording : true,
          practice: practice,
          patientName: patientName,
          reason : reason,
          roomName: roomName,
          isWeb: true,
          fromNumber: this.twilioNumber,
          twilioNumber: this.twilioConstantNumber, //'+14083518666' // twilio number
          user_info: localStorage.getItem('user_sub'),
          user_group: this.userGroup,
          loction: "PatientDetails",
          extra_param: JSON.stringify({subtype:subtype, description: "" })
        };
        Twilio.Device.connect(callParams);       

        await this.delay(3000);
        
        this.getSecondCallSid();

        this.callWatcher();

        this.callConfarenceWatcher();
        
      },
      async (error: any) => {
        console.log(error);
      });
    }

  }

  patienttwilioClickDigit = async (digit) => {
    console.log("digit pressed")
    console.log(digit)
    Twilio.Device.activeConnection().sendDigits(digit)
  }

  patienttwiliocallEndButtonClick = () => {
    console.log("call end Sucess");
    this.endButtonShow = false;
    this.phonecall = true;
    this.hubcall = true;
    this.clickCallisDisabled = false;
    Twilio.Device.disconnectAll();

    clearInterval(this.twlioConfarencetimer);
    clearInterval(this.twlioSecondtimer);
  }

  callWatcher = () => {
    this.twliotimer = setInterval(async () => {
      console.log("hhhhhh")
      console.log(Twilio.Device.status())
      if (Twilio.Device.status() == 'ready'){
        console.log("call end by user");
        this.endButtonShow = false;
        this.phonecall = true;
        this.hubcall = true;
        this.clickCallisDisabled = false;
        clearInterval(this.twliotimer);

        clearInterval(this.twlioConfarencetimer);
        clearInterval(this.twlioSecondtimer);
      }
    }, 600);
  }

  patienttwilioSMSButtonClick = () => {
    console.log("patienttwilioSMSButtonClick");
    this.patientlang = false;
    this.showpatientlang = false;
    let getSmsClass = this;
    getSmsClass.screen.style.display = 'block';    
    this.smstype = "";
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
      }
    });
    // this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getsmstemplatelist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getsmstemplate', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      if (response.status === "success") {
        getSmsClass.screen.style.display = 'none';
        this.smsTextList = JSON.parse(response.data);
        // this.smsTextList.push({ id: 0, shortname: "Custom" })
        this.smsTextList.push({ shortname: "Custom" })
        let patientSmsModal = document.getElementById("patient-sms-ankerTag") as HTMLElement;
        patientSmsModal.click();    
      }
    },
    async (error: any) => {
      await Auth.signOut({
        global: true
      });
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });    
  }

  smstypeChange = (smstype) => {
    // if (smstype > 0){
    //   for(var temp of this.smsTextList){
    //     if (temp.id == smstype){
    //       this.smsText = temp.smstext;
    //       this.smsTypeError = false;
    //       this.smsError = false;
    //       break;
    //     }
    //   }
    // }
    this.patientlang = false;
    this.showpatientlang = false;
    if (smstype != "Custom"){
      for(var temp of this.smsTextList){
        if (temp.shortname == smstype){
          this.smsText = temp['en-US'].smstext;
          if(this.languageStr != 'en-US' && temp[this.languageStr]) {
            this.showpatientlang = true;
            // this.smsText = temp[this.languageStr].smstext;
          }
          this.smsTypeError = false;
          this.smsError = false;
          break;
        }
      }
    }else{
      this.smsText = "";
      this.smsTypeError = false;
    }
    
  }

  smstextlangChange = (smslang) => {
    for(var temp of this.smsTextList){
      if (temp.shortname == smslang.smstype){
        this.smsText = temp['en-US'].smstext;
        if(temp[this.languageStr] && smslang.patientlang) {
          this.smsText = temp[this.languageStr].smstext;
        }
        this.smsTypeError = false;
        this.smsError = false;
        break;
      }
    }
  }

  smstextChange = (smsText) => {
    if (smsText !== ''){
      this.smsError = false;
    }
  }

  patienttwilioSMSSubmitButtonClick = (userDetails, smsForm) => {
    console.log("patienttwilioSMSSubmitButtonClick")
    console.log(userDetails)
    console.log(smsForm)

    // if (smsForm.smstype >= 0) {
    if (smsForm.smstype != undefined) {
      this.smsTypeError = false;
    }else{
      this.smsTypeError = true;
    }

    if (smsForm.smsText !== ''){
      this.smsError = false;
    }else{
      this.smsError = true;
    }

    // if (smsForm.smsText !== '' && smsForm.smstype >= 0) {
    if (smsForm.smsText !== '' && smsForm.smstype != undefined) {
      this.smsError = false;
      let smsParams = {
        dialingFrom: this.twilioNumber,
        patientName: userDetails.sub,
        phoneNo: userDetails.phoneNoSms,
        practice: localStorage.getItem('userpull_id'),
        reason: "",
        reasonOther: "",
        smsnumbers: [userDetails.phoneNoSms],
        smstext: smsForm.smsText,
        twilioNumber: this.twilioConstantNumber
      };

      this.screen.style.display = 'block';

      this.httpClient.post(this.twilioEndPoint + '/stage/twilio/sms/send', smsParams, { headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) })
      .subscribe( async (response: any) => {
        this.screen.style.display = 'none';
        console.log("SMS call Sucess"); 
        if(response.message == "SMS send success") {
          alert("SMS sent successfully");
          // location.reload();

          let patientSmsModal = document.getElementById("patient-sms-modal-close-button") as HTMLElement;
          patientSmsModal.click();
        }
        else {
          alert("Could not send SMS");
        }
      },
      async (error: any) => {
        console.log(error);
        this.screen.style.display = 'none';
      });
    }
    
  }

  

  pageTimerButtonClick() {
    this.running = !this.running;
    if (this.running) {
      const startTime = Date.now() - (this.counter || 0);
      this.timerRef = setInterval(() => {
        this.counter = Date.now() - startTime;

        let totalSeconds = Math.floor(this.counter / 1000);     


        if (totalSeconds >= 3600) {
          this.pagehours = Math.floor(totalSeconds / 3600);      
          totalSeconds -= 3600 * this.pagehours;      
        }
    
        if (totalSeconds >= 60) {
          this.pageminutes = Math.floor(totalSeconds / 60);
          totalSeconds -= 60 * this.pageminutes;
        }
    
        this.pageseconds = totalSeconds;



      });
    } else {
      clearInterval(this.timerRef);
    }
  }

  pageTimerResetButtonClick() {
    this.running = false;
    this.counter = undefined;
    clearInterval(this.timerRef);

    this.pagehours = 0;
    this.pageminutes = 0;
    this.pageseconds = 0;
  }

  patienttwilioPostButtonClick = (userDetails) => {
    let durationSec = this.pageseconds;
    let durationSecStr = "";

    if (durationSec < 10) {
      durationSecStr = "0" + durationSec;
    }else{
      durationSecStr = durationSec.toString();
    }
    
    let duration = (this.pageminutes + (this.pagehours * 60)) + "." + durationSecStr ;

    console.log("total duration", duration)

    let addreviewClass = this;
    addreviewClass.screen.style.display = 'block';
    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      user_sub: localStorage.getItem('user_sub'),
      user_group: this.userGroup,
      patient_sub: userDetails.sub,
      mode: "datareview",
      duration: duration,
      timestamp: this.formatUTCDBDateTime(this.formatTime(new Date())),
      userauth: localStorage.getItem('AUTH-TOKEN'),
      type: "auto",
      review: ""
    };
    if(this.reviewTransmission) {
      params['transmission_id'] = this.reviewTransmission;
    }
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addrpmreview', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        if(response.status == "success") {

          this.running = false;
          this.counter = undefined;
          clearInterval(this.timerRef);

          this.pagehours = 0;
          this.pageminutes = 0;
          this.pageseconds = 0;


          alert("Review added successfully");
          // location.reload();
          this.getPatient(this);
        }
        else if(response.message){
          alert(response.message);
        }
        else {
          alert("Could not add review. Please try again.");
        }
        addreviewClass.screen.style.display = 'none';
      },
      async (error: any) => {
        console.log(error);
        alert("Could not add review. Please try again.");
        addreviewClass.screen.style.display = 'none';
      });
  }

  patienttwilioHistoryButtonClick = (userDetails) => {
    console.log("patienttwilioHistoryButtonClick")
    console.log(userDetails.phone_number)   

    let getHistoryClass = this;
    getHistoryClass.screen.style.display = 'block';  

    this.httpClient.get(this.twilioEndPoint + '/stage/logs/phoneHistory?phoneNo=' + encodeURIComponent(userDetails.sub) , {  headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) })
    .subscribe((response: any) => {
      getHistoryClass.screen.style.display = 'none';
      if (response.message == 'list success') {
        this.historyList = response.data;
      }

      let patientCallModal = document.getElementById("patient-call-history-ankerTag") as HTMLElement;
      patientCallModal.click();      
    },
    async (error: any) => {
      console.log(error);
      getHistoryClass.screen.style.display = 'none';
    });
  }

  getGlobalConfig = () => {
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getglobalconfig', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      if (response.status === "success") {
        let globalConfig = JSON.parse(response.data);

        for(var temp of globalConfig){
          if (temp.config_key === 'twilio_number' && temp.config_value !== ''){
            this.isTwilioEnabled = true;
            this.twilioNumber = temp.config_value;
          }
        }

        console.log(globalConfig)
      }
    },
    async (error: any) => {
      await Auth.signOut({
        global: true
      });
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });
  }

  getA1c = async (strArray) => {
    let totalval = 0;
    for (var str of strArray){
      console.log(str);
      totalval += Number(str);
    }
    let ac1 = ((46.7 + (totalval/strArray.length))/28.7);
    return ac1.toFixed(2);
  }

  getPatientStockHistory = (PatientClass) => {
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
        patient_sub: this.subId
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getpatientstockhistory', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      if (response.status === "success") {
        var temps = JSON.parse(response.data);
        for(var temp of temps){
          var temptemp = temp;
          temptemp["date"] = this.formatDateM(temp.create_time);
          // temptemp["date"] = temp.create_time;

          this.patientStockHistory.push(temptemp);
        }
      }
    },
    async (error: any) => {
      await Auth.signOut({
        global: true
      });
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });
  }

  patientcareplanList = [];

  getPatientCareplan = (PatientClass) => {
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
        patient_sub: this.subId
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/careplanlist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      if (response.status === "success") {
        var temps = JSON.parse(response.data);
        for(var temp of temps){
          var temptemp = temp;
          temptemp["date"] = this.formatDateM(temp.create_time);
          // temptemp["date"] = temp.create_time;

          this.patientcareplanList.push(temptemp);
        }
      }
    },
    async (error: any) => {
      await Auth.signOut({
        global: true
      });
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });
  }

  delay(ms: number) {
      return new Promise( resolve => setTimeout(resolve, ms) );
  }


  validateStatusForm = (statusformdetail) => {
    console.log(statusformdetail);
    let reason = "";
    let strbrief = statusformdetail.statusbrief;
    if(strbrief) {
      reason = strbrief.charAt(0).toUpperCase() + strbrief.slice(1);
      if(statusformdetail.statusreason && statusformdetail.statusreason != "") {
        reason = reason + ": "+statusformdetail.statusreason;
      }
    }
    if(statusformdetail.statusfromdate == undefined || statusformdetail.statusfromdate == "") {
      alert("Please provide Start Date");
    }
    else if(statusformdetail.statustodate == undefined || statusformdetail.statustodate == "") {
      alert("Please provide End Date");
    }
    else if(statusformdetail.statusfromdate > statusformdetail.statustodate) {
      alert("From Date must be less than from To Date");
    }
    else if(!strbrief) {
      alert("Please choose Status");
    }
    else {
      this.screen.style.display = 'block';
      let params = {
        userpull_id: localStorage.getItem('userpull_id'),
        patient_sub: this.subId,
        from_date: this.formatUTCDBDateTime(statusformdetail.statusfromdate+" 00:00:00"),
        to_date: this.formatUTCDBDateTime(statusformdetail.statustodate+" 23:59:59"),
        reason: reason
      };
      console.log(params);
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/patientstatusadd', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          console.log(response);
          if(response.status == "success") {
            let params = new HttpParams({
              fromObject: {
                userpull_id: localStorage.getItem('userpull_id'),
                patient_sub: this.subId
              }
            });
            this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/patientstatusfetch', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
            .subscribe((responsestatus: any) => {
              console.log(responsestatus);
              if (responsestatus.status === "success") {
                this.patientCurrentStatus = false;
                this.patientCurrentStatusMessage = "";
                let responsestatusdata = JSON.parse(responsestatus.data);
                if (responsestatusdata.length > 0) {
                  this.patientStatuses = [];
                  for(var z=0; z < responsestatusdata.length; z++) {
                    responsestatusdata[z]['from_date_updated'] = this.formatDateMSlash(responsestatusdata[z]['from_date'].replaceAll('-','/') + " UTC");
                    responsestatusdata[z]['to_date_updated'] = this.formatDateMSlash(responsestatusdata[z]['to_date'].replaceAll('-','/') + " UTC");
                    var d1 = new Date();
                    var d2 = new Date( d1.getUTCFullYear(), d1.getUTCMonth(), d1.getUTCDate(), d1.getUTCHours(), d1.getUTCMinutes(), d1.getUTCSeconds() );
                    // console.log(this.formatDBDateTime(d2.getTime()));
                    // console.log(this.formatDBDateTime(Date.parse(responsestatusdata[z]['from_date'])));
                    // console.log(this.formatDBDateTime(Date.parse(responsestatusdata[z]['to_date'])));
                    if(Date.parse(responsestatusdata[z]['from_date']) < d2.getTime() && Date.parse(responsestatusdata[z]['to_date']) > d2.getTime() && responsestatusdata[z]['active_status'] == 1) {
                      this.patientCurrentStatus = true;
                      this.patientCurrentStatusMessage = "Not available from "+responsestatusdata[z]['from_date_updated']+" to "+responsestatusdata[z]['to_date_updated']+" for "+responsestatusdata[z]['reason'];
                    }
                    console.log(responsestatusdata[z]);
                    this.patientStatuses.push(responsestatusdata[z]);
                  }
                }
              }
              let patientAddStatus = document.getElementById("collapseAddStatus") as HTMLElement;
              patientAddStatus.click();
              this.screen.style.display = 'none';
            },
            async (error: any) => {
              console.log(error);
              this.screen.style.display = 'none';
            });
          }
          else if(response.message) {
            alert(response.message);
            this.screen.style.display = 'none';
          }
          else {
            alert("Could not add the status. Please try again.");
            this.screen.style.display = 'none';
          }
        },
        async (error: any) => {
          alert("Could not add status. Please try again.");
          this.screen.style.display = 'none';
          console.log(error);
        });
    }
  }

  patientStatusUpdate = (statusdetail) => {
    console.log(statusdetail);
    this.screen.style.display = 'block';
    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      patient_sub: this.subId,
      uniqueid: statusdetail['id_availability']
    };
    if(statusdetail['active_status'] == '1') {
      params['statusupdate'] = "inactive";
    }
    else {
      params['statusupdate'] = "active";
    }
    console.log(params);
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/patientstatusupdate', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
        console.log(response);
        if(response.status == "success") {
          let params = new HttpParams({
            fromObject: {
              userpull_id: localStorage.getItem('userpull_id'),
              patient_sub: this.subId
            }
          });
          this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/patientstatusfetch', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
          .subscribe((responsestatus: any) => {
            console.log(responsestatus);
            if (responsestatus.status === "success") {
              this.patientCurrentStatus = false;
              this.patientCurrentStatusMessage = "";
              let responsestatusdata = JSON.parse(responsestatus.data);
              if (responsestatusdata.length > 0) {
                this.patientStatuses = [];
                for(var z=0; z < responsestatusdata.length; z++) {
                  responsestatusdata[z]['from_date_updated'] = this.formatDateMSlash(responsestatusdata[z]['from_date'].replaceAll('-','/') + " UTC");
                  responsestatusdata[z]['to_date_updated'] = this.formatDateMSlash(responsestatusdata[z]['to_date'].replaceAll('-','/') + " UTC");
                  var d1 = new Date();
                  var d2 = new Date( d1.getUTCFullYear(), d1.getUTCMonth(), d1.getUTCDate(), d1.getUTCHours(), d1.getUTCMinutes(), d1.getUTCSeconds() );
                  // console.log(this.formatDBDateTime(d2.getTime()));
                  // console.log(this.formatDBDateTime(Date.parse(responsestatusdata[z]['from_date'])));
                  // console.log(this.formatDBDateTime(Date.parse(responsestatusdata[z]['to_date'])));
                  if(Date.parse(responsestatusdata[z]['from_date']) < d2.getTime() && Date.parse(responsestatusdata[z]['to_date']) > d2.getTime() && responsestatusdata[z]['active_status'] == 1) {
                    this.patientCurrentStatus = true;
                    this.patientCurrentStatusMessage = "Not available from "+responsestatusdata[z]['from_date_updated']+" to "+responsestatusdata[z]['to_date_updated']+" for "+responsestatusdata[z]['reason'];
                  }
                  console.log(responsestatusdata[z]);
                  this.patientStatuses.push(responsestatusdata[z]);
                }
              }
            }
            this.screen.style.display = 'none';
          },
          async (error: any) => {
            console.log(error);
            this.screen.style.display = 'none';
          });
        }
        else {
          alert(response.message);
          this.screen.style.display = 'none';
        }
      },
      async (error: any) => {
        alert("Could not add status. Please try again.");
        this.screen.style.display = 'none';
        console.log(error);
      });
  }

  clickfrom = "";
  devicedeviceName = "";
  deviceEscalationItemList = [];
  deviceEscalationSettingList = [];

  viewDeviceescalation = (comefrom, deviceid, deviceName, escalation) =>{
    console.log("viewDeviceescalation")
    console.log(deviceid)
    console.log(escalation)

    var escalationIdList = [];

    for(var i of escalation){
      escalationIdList.push(i['id'])
    }

    this.clickfrom = comefrom

    this.deviceEscalationItemList = [];
    this.deviceEscalationSettingList = [];

    this.devicedeviceName = deviceName;

    this.screen.style.display = 'block';

    //Setting Popup close
    if (comefrom === 'Setting'){
      let settingModal = document.getElementById("settings-details-modal-close-button") as HTMLElement;
      settingModal.click();
    }

    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      device: deviceid
    };
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getpracticedeviceglobalsettings', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
      console.log(response);
      this.screen.style.display = 'none';
      if(response.status == "success") {
        var tempescalationSettingList = JSON.parse(response.data);

        let esclationLabel = {          

          //{"bmiMax": 25, "bmiMin": 18, "wtMax": 200, "wtMin": 10}

          bmiMax: "BMI (Max)",
          bmiMin: "BMI (Min)",
          wtMax: "Weight (Max)",
          wtMin: "Weight (Min)",

          //{"diaMax": 80, "diaMin": 60, "pulseMax": 100, "pulseMin": 60, "sysMax": 85, "sysMin": 55}

          diaMax: "DIA (Max)",
          diaMin: "DIA (Min)",
          pulseMax: "PULSE (Max)",
          pulseMin: "PULSE (Min)",
          sysMax: "SYS (Max)",
          sysMin: "SYS (Min)",

          //{"tempMax": 99, "tempMin": 97}

          tempMax: "Temp (Max)",
          tempMin: "Temp (Min)",

          //{"prMax": 120, "prMin": 60, "spo2Max": 99, "spo2Min": 92}

          prMax: "PR (Max)",
          prMin: "PR (Min)",
          spo2Max: "SPO2 (Max)",
          spo2Min: "SPO2 (Min)",

          //{"glucoseAfterMealMax": 250, "glucoseAfterMealMin": 80, "glucoseFastingMax": 160, "glucoseFastingMin": 40, "glucoseNormalTestMax": 214, "glucoseNormalTestMin": 60}

          glucoseAfterMealMax: "Within 2hr. Meal (Max)",
          glucoseAfterMealMin: "Within 2hr. Meal (Min)",
          glucoseFastingMax: "Fasting (Max)",
          glucoseFastingMin: "Fasting (Min)",
          glucoseNormalTestMax: "Normal (Max)",
          glucoseNormalTestMin: "Normal (Min)"
        };

        for(var temp of tempescalationSettingList){
          console.log(temp.name)
          console.log(temp.value)

          this.deviceEscalationSettingList.push({ name : esclationLabel[temp.name], value: temp.value });
        }
        

        //List
        this.screen.style.display = 'block';
        let params = {
          userpull_id: localStorage.getItem('userpull_id'),
          device: deviceid
        };
        this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getescalation', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
          .subscribe((response: any) => {
          console.log(response);
          this.screen.style.display = 'none';
          if(response.status == "success") {
            let deviceEscalationItemList = JSON.parse(response.data);

            for(var tempdeviceEscalationItem of deviceEscalationItemList){
              var color = "";
              if(escalationIdList.indexOf(tempdeviceEscalationItem['id']) > -1) {
                color = "red";
              }
              tempdeviceEscalationItem['color'] = color;
              console.log(tempdeviceEscalationItem);
              this.deviceEscalationItemList.push(tempdeviceEscalationItem);
            }
          }
        },
        async (error: any) => {
          Auth.signOut();
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.screen.style.display = 'none';
          this.router.navigateByUrl('/sign-in');
        });
      }
    },
    async (error: any) => {
      Auth.signOut();
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });

    let patientSmsModal = document.getElementById("device-escalation-ankerTag") as HTMLElement;
    patientSmsModal.click();
    
  }

  deviceEscalationModalClosoButtonClick = (clickfrom) => {
    console.log("deviceEscalationModalClosoButtonClick")
    console.log(clickfrom)

    let patientSmsModal = document.getElementById("device-escalation-modal-close-button") as HTMLElement;
    patientSmsModal.click();

    if (clickfrom == 'Setting'){
      this.getSettings(this.userDetails)
    }    

  }

  PatientEligibilty = [];
  PatientIsAllEligibility = 0;
  PatientIsOpenZohoTicket = false;

  payerList = [];

  primarytype = "";
  primarypayerCode = "";
  primarymemberID = "";
  primarystatus = "";
  primaryrundate = "";
  primaryremainingamount = "";

  secondarytype = "";
  secondarypayerCode = "";
  secondarymemberID = "";
  secondarystatus = "";
  secondaryrundate = "";
  secondaryremainingamount = "";

  primary_partb = "Inactive";
  primary_ppo = "Inactive";
  primary_hmo = "Inactive";
  primary_msp = "Inactive";

  secondary_partb = "Inactive";
  secondary_ppo = "Inactive";
  secondary_hmo = "Inactive";
  secondary_msp = "Inactive";

  is_save_primary = 0;
  is_save_secondary = 0;

  primary_pdf_url = "";
  secondary_pdf_url = "";

  primary_r_pdf_url = "";
  secondary_r_pdf_url = "";


  insuranceButtonClick = (user) => {
    console.log("insuranceButtonClick")

    this.primarytype = "";
    this.primarypayerCode = "";
    this.primarymemberID = "";
    this.primarystatus = "";
    this.primaryrundate = "";
    this.primaryremainingamount = "";

    this.secondarytype = "";
    this.secondarypayerCode = "";
    this.secondarymemberID = "";
    this.secondarystatus = "";
    this.secondaryrundate = "";
    this.secondaryremainingamount = "";

    this.primary_partb = "Inactive";
    this.primary_ppo = "Inactive";
    this.primary_hmo = "Inactive";
    this.primary_msp = "Inactive";

    this.secondary_partb = "Inactive";
    this.secondary_ppo = "Inactive";
    this.secondary_hmo = "Inactive";
    this.secondary_msp = "Inactive";

    this.is_save_primary = 0;
    this.is_save_secondary = 0;

    this.primary_pdf_url = "";
    this.secondary_pdf_url = "";

    this.primary_r_pdf_url = "";
    this.secondary_r_pdf_url = "";

    this.screen.style.display = 'block';

    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      sub: user.sub
    };

    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getpracticeeligibilitypayerlist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((responsepractice: any) => { 
      this.screen.style.display = 'none';
      if (responsepractice.status === "success") { 
        
        let Practice = JSON.parse(responsepractice.data);
        this.payerList = Practice['list'];

        this.primarytype = "Primary";

        this.secondarytype = "Secondary";

        this.primarypayerCode = "00007";
        this.secondarypayerCode = "00007";

        for(var elegibility of this.PatientEligibilty){
          if (elegibility['type'] == 'Primary') {
            this.primarypayerCode = elegibility['payercode'];
            this.primarymemberID = elegibility['memberid'];

            var tempdata = JSON.parse(elegibility['data']);

            var splitted = tempdata['rundate'].split("-");
            this.primaryrundate = splitted[2]+"-"+splitted[0]+"-"+splitted[1];

            this.primarystatus = tempdata['status'];
            this.primaryremainingamount = tempdata['remainingamount'];

            this.primary_partb = tempdata['partb'];
            this.primary_ppo = tempdata['ppo'];
            this.primary_hmo = tempdata['hmo'];
            this.primary_msp = tempdata['msp'];
            this.primary_pdf_url = elegibility['pdfurl'];
            this.primary_r_pdf_url = elegibility['recursive_pdfurl'];
          }else if (elegibility['type'] == 'Secondary') {
            this.secondarypayerCode = elegibility['payercode'];
            this.secondarymemberID = elegibility['memberid'];

            var tempdata = JSON.parse(elegibility['data']);

            var splitted = tempdata['rundate'].split("-");
            this.secondaryrundate = splitted[2]+"-"+splitted[0]+"-"+splitted[1];

            this.secondarystatus = tempdata['status'];
            this.secondaryremainingamount = tempdata['remainingamount'];

            this.secondary_partb = tempdata['partb'];
            this.secondary_ppo = tempdata['ppo'];
            this.secondary_hmo = tempdata['hmo'];
            this.secondary_msp = tempdata['msp'];
            this.secondary_pdf_url = elegibility['pdfurl'];
            this.secondary_r_pdf_url = elegibility['recursive_pdfurl'];
          }
        }



        let patientSmsModal = document.getElementById("insurance-ankerTag") as HTMLElement;
        patientSmsModal.click();
      }
  },
  async (error: any) => {
    Auth.signOut();
    localStorage.removeItem('AUTH');
    localStorage.removeItem('AUTH-LIFE');
    localStorage.removeItem('AUTH-PROFILE-PIC');
    localStorage.removeItem('USER-DETAILS');
    this.screen.style.display = 'none';
    this.router.navigateByUrl('/sign-in');
    });

    
  }  


  insuranceAutoUpdateButtonClick = async (form, userDetails, type) => {
    console.log("insuranceAutoUpdateButtonClick");
    console.log(form)

    if (form.payerCode == ''){
      alert("Select "+type+" Payer");
    }else if (form.memberID == ''){
      alert("enter "+type+" Member id");
    }else if (form.payerCode != '' && form.memberID != ''){
      console.log("All Ok");

      let subscriber = {
        memberID: form.memberID,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        dob: userDetails.birthdate
      }

      // let subscriber = {
      //   memberID: "3UQ5CQ0VA61",
      //   firstName: "John",
      //   lastName: "Blackman",
      //   dob: "04/22/1952"
      // }

      let usersub = "";

      if (localStorage.getItem('USER-DETAILS')) {
        let userDetails = JSON.parse(localStorage.getItem('USER-DETAILS'));

        if(userDetails.sub){
          usersub = userDetails.sub;
        }
      }

      let payername = "";

      for(var payer of this.payerList){
        if(payer.payerCode == form.payerCode){
          payername = payer.payerName;
        }
      }


      let params = {
        userpull_id: localStorage.getItem('userpull_id'),
        user_type: "user",
        user_id: usersub,
        doS_StartDate: this.formatDateM(this.thisDate),
        doS_EndDate: this.formatDateM(this.thisDate),
        isSubscriberPatient: true,
        payerCode: form.payerCode,
        payername: payername,
        type: type,
        sub: userDetails.sub,
        subscriber: subscriber
      };

      this.screen.style.display = 'block';

      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/geteligibility', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe( async (response: any) => {
        this.screen.style.display = 'none';
        console.log(response);
        if(response.status == "success") {

          var EligibilityData = JSON.parse(response.data);

          this.getPatient(this);

          if (type == 'Primary'){
            this.is_save_primary = 0;

            var splitted = EligibilityData["Run_Date"].split("-");
            this.primaryrundate = splitted[2]+"-"+splitted[0]+"-"+splitted[1];

            this.primaryremainingamount = EligibilityData["Part_B_Deductible_Remaining_Value"];
            this.primarystatus = EligibilityData["Status"];

            this.primary_partb = EligibilityData["Part_B"];
            this.primary_ppo = EligibilityData["PPO"];
            this.primary_hmo = EligibilityData["HMO"];
            this.primary_msp = EligibilityData["MSP"];
            this.primary_pdf_url = EligibilityData['pdfurl'];
            this.primary_r_pdf_url = EligibilityData['recursive_pdfurl'];
          }else{
            this.is_save_secondary = 0;

            var splitted = EligibilityData["Run_Date"].split("-");
            this.secondaryrundate = splitted[2]+"-"+splitted[0]+"-"+splitted[1];

            this.secondaryremainingamount = EligibilityData["Part_B_Deductible_Remaining_Value"];
            this.secondarystatus = EligibilityData["Status"];

            this.secondary_partb = EligibilityData["Part_B"];
            this.secondary_ppo = EligibilityData["PPO"];
            this.secondary_hmo = EligibilityData["HMO"];
            this.secondary_msp = EligibilityData["MSP"];
            this.secondary_pdf_url = EligibilityData['pdfurl'];
            this.secondary_r_pdf_url = EligibilityData['recursive_pdfurl'];
          }

        }else{
          alert(response.message);
        }
      },
      async (error: any) => {
        Auth.signOut();
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        this.screen.style.display = 'none';
        this.router.navigateByUrl('/sign-in');
      });
    }

  }

  insuranceSaveButtonClick = (form, userDetails, type) => {
    console.log("insuranceSaveButtonClick");
    console.log(form);

    if (form.payerCode == ''){
      alert("Select "+type+" Payer");
    }else if (form.memberID == ''){
      alert("enter "+type+" Member id");
    }else if (form.status == ''){
      alert("select "+type+" Status");
    }else if (form.rundate == ''){
      alert("enter "+type+" Rundate");
    }else if (form.remainingamount === ''){
      alert("enter "+type+" Deductible Remaining");
    }else if (form.payerCode != '' && form.memberID != '' && form.status != '' && form.rundate != ''){
      console.log("All Ok");
      console.log(form);

      if(confirm("Do you want to offline save this data?")) {

        let usersub = "";

        if (localStorage.getItem('USER-DETAILS')) {
          let userDetails = JSON.parse(localStorage.getItem('USER-DETAILS'));

          if(userDetails.sub){
            usersub = userDetails.sub;
          }
        }

        let params = {};

        if (type == 'Primary'){

          var pdata = {};
          pdata["partb"] = this.primary_partb;
          pdata["ppo"] = this.primary_ppo;
          pdata["hmo"] = this.primary_hmo;
          pdata["msp"] = this.primary_msp;

          pdata["status"] = this.primarystatus;

          var splitted = this.primaryrundate.split("-");
          pdata["rundate"] = splitted[1]+"-"+splitted[2]+"-"+splitted[0];

          pdata["remainingamount"] = this.primaryremainingamount;

          let payername = "";

          for(var payer of this.payerList){
            if(payer.payerCode == form.payerCode){
              payername = payer.payerName;
            }
          }

          params = {
            userpull_id: localStorage.getItem('userpull_id'),
            user_type: "user",
            user_id: usersub,
            patient_id: userDetails.sub,
            payercode: form.payerCode,
            payername: payername,
            memberid: form.memberID,
            type: type,
            data: pdata,
            save_type: "Offline"
          };
        }else{
          var sdata = {};
          sdata["partb"] = this.secondary_partb;
          sdata["ppo"] = this.secondary_ppo;
          sdata["hmo"] = this.secondary_hmo;
          sdata["msp"] = this.secondary_msp;

          sdata["status"] = this.secondarystatus;

          var splitted = this.secondaryrundate.split("-");
          sdata["rundate"] = splitted[1]+"-"+splitted[2]+"-"+splitted[0];

          sdata["remainingamount"] = this.secondaryremainingamount;

          let payername = "";

          for(var payer of this.payerList){
            if(payer.payerCode == form.payerCode){
              payername = payer.payerName;
            }
          }

          params = {
            userpull_id: localStorage.getItem('userpull_id'),
            user_type: "user",
            user_id: usersub,
            patient_id: userDetails.sub,
            payercode: form.payerCode,
            payername: payername,
            memberid: form.memberID,
            type: type,
            data: sdata,
            save_type: "Offline"
          };

        }

        console.log("All Ok Final Param ", params);

        this.screen.style.display = 'block';

        this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addeligibility', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe( async (response: any) => {
          this.screen.style.display = 'none';
          console.log(response);
          if(response.status == "success") {
            alert(type +" updated successfully");

            this.getPatient(this);
          }
        },
        async (error: any) => {
          Auth.signOut();
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.screen.style.display = 'none';
          this.router.navigateByUrl('/sign-in');
        });
      }
    }
  }

  insuranceReusultButtonClick = (type, item) => {
    console.log("insuranceReusultButtonClick");
    console.log(type);
    console.log(item);

    // let index = this.primarydata.indexOf(item);
    // if (item['status'] == '' || item['status'] == 'Inactive'){
    //   item["status"] = 'Active';
    // }else{
    //   item["status"] = 'Inactive';
    // }
    
    // this.primarydata[index] = item;

    if(type == 'Primary'){
      this.is_save_primary = 1;
      if (item == 'Part_B'){
        if(this.primary_partb == 'Active'){
          this.primary_partb = 'Inactive';
        }else{
          this.primary_partb = 'Active';
        }
      }else if (item == 'PPO'){
        if(this.primary_ppo == 'Active'){
          this.primary_ppo = 'Inactive';
        }else{
          this.primary_ppo = 'Active';
        }
      }else if (item == 'HMO'){
        if(this.primary_hmo == 'Active'){
          this.primary_hmo = 'Inactive';
        }else{
          this.primary_hmo = 'Active';
        }
      }else if (item == 'MSP'){
        if(this.primary_msp == 'Active'){
          this.primary_msp = 'Inactive';
        }else{
          this.primary_msp = 'Active';
        }
      }
    }else{
      if (item == 'Part_B'){
        if(this.secondary_partb == 'Active'){
          this.secondary_partb = 'Inactive';
        }else{
          this.secondary_partb = 'Active';
        }
      }else if (item == 'PPO'){
        if(this.secondary_ppo == 'Active'){
          this.secondary_ppo = 'Inactive';
        }else{
          this.secondary_ppo = 'Active';
        }
      }else if (item == 'HMO'){
        if(this.secondary_hmo == 'Active'){
          this.secondary_hmo = 'Inactive';
        }else{
          this.secondary_hmo = 'Active';
        }
      }else if (item == 'MSP'){
        if(this.secondary_msp == 'Active'){
          this.secondary_msp = 'Inactive';
        }else{
          this.secondary_msp = 'Active';
        }
      }
    }

  }

  insuranceViewAllBenifitButtonClick = () => {
    console.log("insuranceViewAllBenifitButtonClick");
  }

  primaryvaluechange = () => {
    console.log("primaryvaluechange");
    this.is_save_primary = 1;
  }

  secondaryvaluechange = () => {
    console.log("secondaryvaluechange");
    this.is_save_secondary = 1;
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }


  addTicketPatient: any;
  PriorityList = [{id: "High", name: "High"}, {id: "Medium", name: "Medium"}, {id: "Low", name: "Low"}];

  addTicketpriorityError = false;
  addTicketsubjectError = false;
  addTicketDescriptionError = false;
  addTicketavailabilityError = false;

  patientTicketList = [];

  Availability_List = [{id: "Yes", name: "Yes"}, {id: "No", name: "No"}];

  AddZohoTicket = (patient) => {
    console.log("AddZohoTicket");
    console.log(patient);

    this.addTicketpriorityError = false;
    this.addTicketsubjectError = false;
    this.addTicketDescriptionError = false;
    this.addTicketavailabilityError = false;

    this.patientTicketList = [];

    this.addTicketPatient = patient;

    let patientSmsModal = document.getElementById("patient-add-ticket-modal-close-button") as HTMLElement;
    patientSmsModal.click();

    this.screen.style.display = 'block';

    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
        patient_sub: patient.sub
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getpatientzohoticketlist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response)
      this.screen.style.display = 'none';
      if (response.status === "success") { 
        var temppatientTicketList = JSON.parse(response.data);

        for (var tempItem of temppatientTicketList){
          
          if (tempItem['Created_Time']){
            var tempCreated_Time = tempItem['Created_Time'].split('T');
            tempItem['Created_Time_str'] = tempCreated_Time[0];
          }

          this.patientTicketList.push(tempItem);
        }

        let patientaddTicketModal = document.getElementById("patient-add-ticket-ankerTag") as HTMLElement;
        patientaddTicketModal.click();   
      }
    },
    async (error: any) => {
      await Auth.signOut({
        global: true
      });
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });
  }

  addTicketDescriptionChange = (value) => {
    console.log("addTicketDescriptionChange");
    console.log(value);

    if (value !== '' && value !== undefined){
      this.addTicketDescriptionError = false;
    }else{
      this.addTicketDescriptionError = true;
    }
  }

  addTicketSubjectChange = (value) => {
    console.log("addTicketSubjectChange");
    console.log(value);

    if (value !== '' && value !== undefined){
      this.addTicketsubjectError = false;
    }else{
      this.addTicketsubjectError = true;
    }
  }

  addTicketPriorityChange = (value) => {
    console.log("addTicketPriorityChange");
    console.log(value);

    this.addTicketpriorityError = false;
  }

  addTicketAvailabilityChange = (value) => {
    console.log("addTicketAvailabilityChange");
    console.log(value);

    if (value !== '' && value !== undefined){
      this.addTicketavailabilityError = false;
    }else{
      this.addTicketavailabilityError = true;
    }
  }


  patientAddTicketSubmitButtonClick = (addTicketForm) => {
    console.log("patientAddTicketSubmitButtonClick");

    console.log(addTicketForm);

    if (addTicketForm.priority !== '' && addTicketForm.priority !== undefined){
      this.addTicketpriorityError = false;
    }else{
      this.addTicketpriorityError = true;
    }

    if (addTicketForm.subject !== '' && addTicketForm.subject !== undefined){
      this.addTicketsubjectError = false;
    }else{
      this.addTicketsubjectError = true;
    }

    if (addTicketForm.description !== '' && addTicketForm.description !== undefined){
      this.addTicketDescriptionError = false;
    }else{
      this.addTicketDescriptionError = true;
    }

    if (addTicketForm.availability !== '' && addTicketForm.availability !== undefined){
      this.addTicketavailabilityError = false;
    }else{
      this.addTicketavailabilityError = true;
    }

    if(this.addTicketpriorityError == false && this.addTicketsubjectError == false && this.addTicketDescriptionError == false && this.addTicketavailabilityError == false){
      let addTicketParams = {
        userpull_id: localStorage.getItem('userpull_id'),
        
        Priority: addTicketForm.priority,
        Subject: addTicketForm.subject,
        Description: addTicketForm.description,
        availability: addTicketForm.availability,
        patient_id: this.addTicketPatient.sub,
        start_date:  this.formatUTCDBDateTime(this.ZohoTicketDate+" 00:00:00"),
        end_date:  this.formatUTCDBDateTime(this.ZohoTicketDate+" 23:59:59")
      };

      this.screen.style.display = 'block';

      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addzohoticket', addTicketParams, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe( async (response: any) => {
        console.log("response");
        console.log(response)
        this.screen.style.display = 'none';
        if (response.status === "success") {
          alert("Ticket save successfully");
          let patientSmsModal = document.getElementById("patient-add-ticket-modal-close-button") as HTMLElement;
          patientSmsModal.click();
          
          this.AddZohoTicket(this.addTicketPatient);
        }else{
          alert(response.message);
        }
      },
      async (error: any) => {
        console.log(error);
        alert("Could not save Ticket. Please try again.");
        this.screen.style.display = 'none';
      });
    }

  }


  addZohoTicketModalClosoButtonClick = () =>{
    this.patientTicketList = [];

    let patientzohoModal = document.getElementById("patient-add-ticket-modal-close-button") as HTMLElement;
    patientzohoModal.click();
  }

  RefreshZohoTicketList = (patient) => {
    this.addZohoTicketModalClosoButtonClick();

    this.AddZohoTicket(patient);
  }

}
