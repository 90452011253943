import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { SignInComponent } from './sign-in/sign-in.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { IfAllowed } from './auth.middleware';
import { ProviderComponent } from './provider/provider.component';
import { PatientComponent } from './patient/patient.component';
import { PatientDetailComponent } from './patient-detail/patient-detail.component';
import { PatientReportComponent } from './patient-report/patient-report.component';
import { ConsentComponent } from './consent/consent.component';
import { AdminComponent } from './admin/admin.component';
import { ProfileComponent } from './profile/profile.component';
import { PatientSymptomComponent } from './patient-symptom/patient-symptom.component';
import { CareplanComponent } from './careplan/careplan.component';
import { ZohochatComponent } from './zohochat/zohochat.component';

const routes: Routes = [
  { path: '', component: SignInComponent, pathMatch: 'full' },
  // { path: 'admin', component: SignInComponent },
  { path: 'sign-in', component: SignInComponent },
  { path: 'zohochat', component: ZohochatComponent },
  { path: 'consent/:practice/:sub', component: ConsentComponent },
  { path: 'profile/:practice/:sub', component: ProfileComponent },
  { path: 'patient-symptom/:practice/:sub/:reading', component: PatientSymptomComponent },
  { 
    path: '', 
    component: AuthLayoutComponent,
    children: [
      { path: 'admin', component: AdminComponent, canActivate: [IfAllowed] },
      { path: 'dashboard', component: DashboardComponent, canActivate: [IfAllowed] },
      { path: 'provider', component: ProviderComponent, canActivate: [IfAllowed] },
      { path: 'patient', component: PatientComponent, canActivate: [IfAllowed] },
      { path: 'patient-detail/:sub', component: PatientDetailComponent, canActivate: [IfAllowed] },
      { path: 'patient-report/:sub', component: PatientReportComponent, canActivate: [IfAllowed] },
      { path: 'careplan/:sub', component: CareplanComponent, canActivate: [IfAllowed] }
    ]    
  },
  { path: '**', redirectTo: '' }
];

@NgModule({ 
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
